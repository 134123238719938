<template>
  <div class="container-fluid mt-3 text-start">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        :is-global-close="false"
        @close="isConfirmDeleteModal = false"
    />
    <div v-if="isLoaded">
      <h5 class="card-title placeholder-glow">
        <span class="placeholder col-6"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-12 my-2 fs-26px"></span>
        <span class="placeholder col-12 my-2 fs-26px"></span>
        <span v-if="'new' === uuid" class="placeholder col-12 my-2 fs-26px"></span>
        <span v-if="'new' === uuid" class="placeholder col-12 my-2 fs-26px"></span>
        <span v-if="'new' === uuid" class="placeholder col-12 my-2 fs-26px"></span>
      </p>
      <div class="text-end">
        <a class="btn btn-primary disabled placeholder col-6" aria-disabled="true"></a>
      </div>
    </div>
    <div v-if="false === isLoaded">
      <div>
        <div>
          <label class="form-label">Наименование</label>
          <input type="text" class="form-control" placeholder="Куратор(ы)"
                 v-model="data.name"
                 :disabled="!inArray('name', enabledFields)"
                 :class="{ 'is-invalid': !!errors['name'] }"
          >
          <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
        </div>
      </div>

      <div class="mt-3">
        <label class="form-label">Список должностей</label>
        <multiselect-input
            v-model="data.personnelStructures"
            :disabled="!inArray('personnelStructures', enabledFields)"
            :class-name="{ 'is-invalid': !!errors['personnelStructures'] }"
            :list="data.listPersonnelStructures"
            :multiselect="true"
            :is-global-close="false"
        />
        <div class="text-secondary fs-12px">
          Укажите перечень должностей, сотрудники которых могут быть назначены на курс как <b>"{{ data.name }}"</b>
        </div>
        <div class="text-danger" v-if="!!errors['personnelStructures']">{{ errors['personnelStructures'].join(', ') }}</div>
      </div>

      <div class="text-end mt-3">
        <a v-if="false === this.empty(this.enabledFields)" class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="save">Сохранить</a>
        <a v-if="inArray('delete', data.buttons)" class="btn btn-danger sm-w-100 mt-1 ms-sm-2" @click="openModalDelete">Удалить</a>

        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeForm">Закрыть</a>
      </div>
    </div>
  </div>
</template>

<script>

import {subformMixin} from '@/mixins/subformMixin'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import MultiselectInput from "@/components/form/MultiselectInput.vue";

export default {
  name: 'SchoolRelationUserTypeView',
  components: {MultiselectInput, ConfirmActionModal},
  mixins: [subformMixin],
  props: {
    uuid: {
      type: String,
      required: true
    },
    updateParent: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    }
  },
  beforeMount() {
    this.formUrl.params.uuid = this.uuid + ''
  },
  data() {
    return {
      formName: 'school-relation-type',
      formUrl: {string: '/school-relation-type?uuid=${uuid}', params: {uuid: 'new'}},
      editRoute: {name:  'school-relation-type'},
      exitRoute: {name: 'profile'},

      isConfirmDeleteModal: false,

      isAtLeastOnceSaved: false,
    }
  },
  methods: {
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
    },
    afterSave: function () {
      this.isAtLeastOnceSaved = true
      this.close()
      this.updateParent()
    },
    afterDelete: function () {
      let self = this
      this.isConfirmDeleteModal = false
      setTimeout(function () {
        self.close()
        self.updateParent()
      }, 200)
    },
    closeForm: function () {
      let self = this
      self.isConfirmDeleteModal = false
      self.close()
      if (true === self.isAtLeastOnceSaved) {
        setTimeout(function () {
          self.updateParent()
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.new-notification-email-enter-active,
.new-notification-email-leave-active {
  transition: opacity 0.5s ease;
}

.new-notification-email-enter-from,
.new-notification-email-leave-to {
  opacity: 0;
}
</style>
