<template>
  <div class="page-list">
    <school-top-tabs />
    <page-top-bar
        :title="title"
        :subtitle="'Все слушатели'"
        :buttons="[]"
    />
    <div class="page-list-content">
      <div class="card border-0 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-12 overflow-x-auto" :class="{'edit-planning-process': inArray('editPlanningProcess', buttons)}">
              <table class="table table-sm table-hover">
                <thead>
                <tr>
                  <th scope="col">Учебный процесс</th>
                  <th scope="col">Курс</th>
                  <th scope="col">Контрагент</th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'ФИО'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.email'" :name-field="'email'" :input-name="'Email'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.phone'" :name-field="'phone'" :input-name="'Телефон'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.comment'" :name-field="'comment'" :input-name="'Комментарий'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">Заметки</th>
                </tr>
                <tr>
                  <th scope="col"><search-input :name-input="this.listName + '.search.processName'" :name-field="'processName'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.courseName'" :name-field="'courseName'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.contractorName'" :name-field="'contractorName'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.email'" :name-field="'email'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.phone'" :name-field="'phone'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.comment'" :name-field="'comment'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.noteText'" :name-field="'noteText'" :input-text="searchField_input" /></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in items"
                    :key="'list.' + listName + '.' + i_item"
                    @click="openItem_click(item.uuid)"
                >
                  <td>{{ item.processName }}</td>
                  <td>{{ item.courseName }}</td>
                  <td>{{ item.contractorName }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.comment }}</td>
                  <td>
                    <div v-if="isLoadingNotes">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Загрузка...</span>
                      </div>
                    </div>
                    <object-note-list
                        v-if="!isLoadingNotes"
                        object-type="school_process_student"
                        :notes="item.notes"
                        :object-uuid="item.uuid"
                        :object-name="item.name"
                        :object-description="item.description"
                        :update-list="loadNotes"
                    />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-list-paginator
        :paginator="paginator"
        :link-page="paginatorLinkPage_click"
    />
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput.vue'
import PageListPaginator from '@/components/PageListPaginator.vue'
import SortInput from '@/components/SortInput.vue'
import SchoolTopTabs from '@/views/app/school/components/SchoolTopTabs'
import PageTopBar from '@/components/PageTopBar'
import ObjectNoteList from '@/components/ObjectNoteList'
export default {
  name: 'SchoolStudentsList',
  components: {ObjectNoteList, PageTopBar, SchoolTopTabs, SortInput, PageListPaginator, SearchInput},
  mixins: [listMixin],
  data() {
    return {
      title: '<i class="fa-solid fa-user-graduate"></i> Слушатели',
      listName: 'SchoolStudentsList',
      listUrl: {string: '/school-students', params:[]},
      itemNameRoute: 'school-students',

      isLoadingNotes: false,

      listSearch: {
        processName: '',
        courseName: '',
        contractorName: '',
        name: '',
        email: '',
        phone: '',
        comment: '',
      },
      listSearchDefault: {
        processName: '',
        courseName: '',
        contractorName: '',
        name: '',
        email: '',
        phone: '',
        comment: '',
      },
    }
  },
  mounted: function() {
    this.emitter.on('SchoolStudentsList', this.emitterSchoolStudentsList_reload)
  },
  unmounted: function() {
    this.emitter.off('SchoolStudentsList', this.emitterSchoolStudentsList_reload)
  },
  methods: {
    afterLoad: function () {
      this.loadNotes()
    },

    emitterSchoolStudentsList_reload: function () {
      this.load()
    },

    openItem_click: function(uuid) {
      let route = {name: this.itemNameRoute, params: {uuid: uuid}}
      this.toRoute(route)
    },

    loadNotes: function () {
      this.isLoadingNotes = true

      let uuids  = []
      for (let i in this.items) {
        uuids.push(this.items[i].uuid)
      }

      window.axios.post(
          this.globalVar.app.api.url + '/notes' + '?' + (new Date()).getTime(), {
            search: {studentUuids: uuids, isDone: false, perPage: 1000},
            sort: {}
          })
          .then(response => {
            for (let i in this.items) {
              let item = this.items[i]
              item.notes = []
              for (let c in response.data.items) {
                let note = response.data.items[c]
                for (let o in note.objects) {
                  let object = note.objects[o]
                  if (item.uuid === object.uuid) {
                    item.notes.push(note)
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error, error.data)
          })
          .finally(() => {
            this.isLoadingNotes = false
          })
    }
  },
}
</script>

<style scoped>
.page-list-pagination {
  display: inline-block;
  padding: 0;
}
</style>
