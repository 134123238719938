<template>
  <div class="container-fluid mt-3">
    <div v-if="isLoaded">
      <h5 class="card-title placeholder-glow">
        <span class="placeholder col-6"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-12 my-2 fs-26px"></span>
        <span class="placeholder col-12 my-2 fs-26px"></span>
      </p>
      <div class="text-end">
        <a class="btn btn-primary disabled placeholder col-6" aria-disabled="true"></a>
      </div>
    </div>
    <div v-if="false === isLoaded">
      <div>
        <div class="mt-2">
          <label class="form-label">Результат</label>
          <multiselect-input
              v-model="data.value"
              :disabled="!inArray('value', enabledFields)"
              :class-name="{ 'is-invalid': !!errors['value'] }"
              :list="data.listValues"
              :is-global-close="false"
          />
          <div class="text-danger" v-if="!!errors['value']">{{ errors['value'].join(', ') }}</div>
        </div>
        <div class="mt-2">
          <label class="form-label">Комментарий</label>
          <textarea class="form-control"
                    rows="3"
                    placeholder="Тянет до последнего"
                    v-model="data.comment"
          />
          <div class="text-danger" v-if="!!errors['comment']">{{ errors['comment'].join(', ') }}</div>
        </div>
        <div v-if="empty(crmContractorUuid)" class="mt-4 text-start">
          <div class="form-control-checkbox">
            <input type="checkbox" v-model="data.isSetAutoValuesInAllCrmContractor"
                   class="form-check-input" id="isSetAutoValues">
            <label class="form-check-label" for="isSetAutoValues">Проставить результат всем слушателям контрагента</label>
          </div>
          <div class="text-secondary fs-12px">Комментарий остается без изменений</div>
        </div>
      </div>
      <div class="text-end mt-3">
        <a v-if="false === empty(enabledFields)" class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="save">Сохранить</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeForm">Закрыть</a>
      </div>
    </div>
  </div>
</template>

<script>

import {subformMixin} from '@/mixins/subformMixin'
import MultiselectInput from '@/components/form/MultiselectInput'

export default {
  name: 'SchoolProcessProgressView',
  components: {MultiselectInput},
  mixins: [subformMixin],
  props: {
    uuid: {
      type: String,
      required: true
    },
    paramUuid: {
      type: String,
      required: true
    },
    studentUuid: {
      type: String,
      required: true
    },
    crmContractorUuid: {
      type: String,
      required: true
    },
    updateParent: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    }
  },
  beforeMount() {
    this.formUrl.params.uuid = this.uuid + ''
    this.formUrl.params.paramUuid = this.paramUuid + ''
    this.formUrl.params.studentUuid = this.studentUuid + ''
    this.formUrl.params.crmContractorUuid = this.crmContractorUuid + ''
  },
  data() {
    return {
      formName: 'school-process-progress',
      formUrl: {
        string: '/school-process-progress?uuid=${uuid}&paramUuid=${paramUuid}&studentUuid=${studentUuid}&crmContractorUuid=${crmContractorUuid}',
        params: {uuid: 'new', paramUuid: '', studentUuid: '', crmContractorUuid: ''}
      },
      editRoute: {name:  'school-process-progress'},
      exitRoute: {name: 'school-process-progress'},

      data: {
        value: '',
        comment: '',
        isSetAutoValuesInAllCrmContractor: false,
        listValues: [],
      },

      isAtLeastOnceSaved: false,
    }
  },
  methods: {
    afterSave: function () {
      this.isAtLeastOnceSaved = true
      this.close()
      this.updateParent()
    },
    afterDelete: function () {
      let self = this
      this.isConfirmDeleteModal = false
      setTimeout(function () {
        self.close()
        self.updateParent()
      }, 200)
    },
    closeForm: function () {
      let self = this
      self.close()
      if (true === self.isAtLeastOnceSaved) {
        setTimeout(function () {
          self.updateParent()
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
</style>
