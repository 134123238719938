export const webViewMixin = {
  methods: {
      webView_runPush: function() {
          let message = localStorage.getItem('webView_regPush') ?? ''
          if ('' !== message) {
              let push = JSON.parse(message ?? null)
              if (null !== push) {
                  console.log('Выполнить действие: ')
                  console.log(push)
              }
          }
          localStorage.removeItem('webView_regPush')
      },
      webView_regFreebaseToken() {
          if ('false' === this.globalVar.app.regFirebase) {
              let firebaseToken = localStorage.webView_regFirebaseToken ?? ''
              if ('' !== firebaseToken) {
                  this.globalVar.app.regFirebase = 'true'
                  localStorage.webView_regFirebase = 'true'
                  window.axios.post(
                      `${this.globalVar.app.api.url}/reg-firebase-token?${(new Date()).getTime()}`, {
                          token: firebaseToken
                      })
                      .then((response) => {
                          this.globalVar.app.regFirebase = response.data.result
                          localStorage.webView_regFirebase = response.data.result
                      })
                      .catch(() => {
                          this.globalVar.app.regFirebase = 'false'
                          localStorage.webView_regFirebase = 'false'
                      })
              }
          }
      },
      webView_regHuaweiToken() {
          if ('false' === this.globalVar.app.regHuawei) {
              let huaweiToken = localStorage.webView_regHuaweiToken ?? ''
              if ('' !== huaweiToken) {
                  this.globalVar.app.regHuawei = 'true'
                  localStorage.webView_regHuawei = 'true'
                  window.axios.post(
                      `${this.globalVar.app.api.url}/reg-huawei-token?${(new Date()).getTime()}`, {
                          token: huaweiToken
                      })
                      .then((response) => {
                          this.globalVar.app.regHuawei = response.data.result
                          localStorage.webView_regHuawei = response.data.result
                      })
                      .catch(() => {
                          this.globalVar.app.regHuawei = 'false'
                          localStorage.webView_regHuawei = 'false'
                      })
              }
          }
      },
      webView_setSystemColorTheme: function() {
          let currentSystemColorTheme = localStorage.webView_systemColorTheme ?? 'light'
          if (currentSystemColorTheme !== this.globalVar.app.systemColorTheme) {
              this.globalVar.app.systemColorTheme = currentSystemColorTheme
              this.emitter.emit('app', {event: 'app.colorTheme'})
          }
      },
  }
}
