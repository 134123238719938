<template>
  <div>
    <i class="fa-solid fa-link"></i>
    <a target="_blank" class="ms-2" :href="file.url">
      {{ file.name }} {{ file.size }}
    </a>
    <a href="#" class="text-danger ms-2" @click="deleteClick(file.uuid)">
      <i class="fa-solid fa-trash"></i>
    </a>
  </div>
</template>
<script>

export default {
  name: 'FileLink',
  props: {
    file: {
      type: Array,
      required: true,
    },
    deleteClick: {
      type: Function,
      required: true,
    }
  },
}
</script>

<style scoped>
</style>
