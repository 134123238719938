<template>
  <div>
    <div class="text-start" style="min-height:43px;">
      <page-list-paginator
          v-if="0 < items.length && false === isLoading"
          :paginator="paginatorList"
          :link-page="paginatorLinkPage_click"
          size="sm"
      />
    </div>
    <table class="table table-sm table-hover">
      <thead>
      <tr>
        <th scope="col">
          <sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Наименование'" :change-sort="sortField_change" />
        </th>
        <th scope="col">
          Наименование курса
        </th>
      </tr>
      <tr>
        <th scope="col"><search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" /></th>
        <th scope="col"><search-input :name-input="this.listName + '.search.courseName'" :name-field="'courseName'" :input-text="searchField_input" /></th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="cursor-pointer"
          v-for="(item, i_item) in items"
          :key="'list.' + listName + '.' + i_item"
          @click="openItem_click(item.uuid)"
      >
        <td>{{ item.name }}</td>
        <td>{{ item.courseName }}</td>
      </tr>
      </tbody>
    </table>

    <div class="d-block" v-if="0 === items.length && false === isLoading">
      <div class="my-3 text-center text-secondary">
        Пусто)
      </div>
    </div>
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput'
import PageListPaginator from '@/components/PageListPaginator'
import SortInput from '@/components/SortInput'

export default {
  name: 'SchoolPlanningProcessCopyProcessesList',
  components: {SortInput, PageListPaginator, SearchInput},
  mixins: [listMixin],
  props: {
    selectedUuid: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      title: '<i class="fa-solid fa-address-book"></i> Контрагенты',

      listName: 'SchoolPlanningProcessCopyProcessesList',
      listUrl: {string: '/school-copy-processes', params:[]},
      itemNameRoute: 'school-copy-process',
      pageButtons: [],

      paginatorList: {
        currentPage: 0,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
      },
    }
  },
  watch: {
    paginator: function (newVal) {
      let self = this
      setTimeout(function () {
        self.paginatorList = newVal
      }, 10)
    },
  },
  mounted: function() {
    this.emitter.on('schoolPlanningProcessCopyProcessesList', this.emitterSchoolPlanningProcessCopyProcessesList_reload)
  },
  unmounted: function() {
    this.emitter.off('schoolPlanningProcessCopyProcessesList', this.emitterSchoolPlanningProcessCopyProcessesList_reload)
  },
  methods: {
    emitterSchoolPlanningProcessCopyProcessesList_reload: function () {
      this.load()
    },

    openItem_click(uuid) {
      this.selectedUuid(uuid)
    },
  },
}
</script>

<style scoped>
.page-list-pagination {
  display: inline-block;
  padding: 0;
}

.add-process-button-container {
  min-height: 38px;
}
</style>
