<template>
  <div class="container-fluid mt-3">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        :is-global-close="false"
        @close="isConfirmDeleteModal = false"
    />
    <div v-if="isLoaded">
      <h5 class="card-title placeholder-glow">
        <span class="placeholder col-6"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-12 my-2 fs-26px"></span>
        <span class="placeholder col-12 my-2 fs-26px"></span>
      </p>
      <div class="text-end">
        <a class="btn btn-primary disabled placeholder col-6" aria-disabled="true"></a>
      </div>
    </div>
    <div v-if="false === isLoaded">
      <div>
        <div class="mt-2">
          <label class="form-label">Наименование курса</label>
          <label class="form-label">Дата, до которой приостановлено обучение</label>
          <date-time-picker-input
              v-model="data.suspend_at"
              :is-global-close="false"
              :is-time="false"
              :disabled="!inArray('suspend_at', enabledFields)"
              :class-name="{ 'is-invalid': !!errors['suspend_at'] }"
          />
          <div class="text-danger" v-if="!!errors['suspend_at']">{{ errors['suspend_at'].join(', ') }}</div>
        </div>
        <div class="mt-2">
          <label class="form-label">Комментарий</label>
          <input type="text" class="form-control" placeholder="Ждут с моря погоды"
                 v-model="data.suspend_comment"
                 :disabled="!inArray('suspend_comment', enabledFields)"
                 :class="{ 'is-invalid': !!errors['suspend_comment'] }"
          >
          <div class="text-danger" v-if="!!errors['suspend_comment']">{{ errors['suspend_comment'].join(', ') }}</div>
        </div>
      </div>
      <div class="text-end mt-3">
        <a v-if="false === empty(enabledFields)" class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="save">Сохранить</a>
        <a v-if="inArray('delete', data.buttons)" class="btn btn-danger sm-w-100 mt-1 ms-sm-2" @click="openModalDelete">Удалить</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeForm">Закрыть</a>
      </div>
    </div>
  </div>
</template>

<script>

import {subformMixin} from '@/mixins/subformMixin'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import DateTimePickerInput from '@/components/form/DateTimePickerInput'

export default {
  name: 'CrmContractorRelationSuspensionView',
  components: {DateTimePickerInput, ConfirmActionModal},
  mixins: [subformMixin],
  props: {
    uuid: {
      type: String,
      required: true
    },
    crmContractorUuid: {
      type: String,
      required: true
    },
    updateParent: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    }
  },
  beforeMount() {
    this.formUrl.params.uuid = this.uuid + ''
    this.formUrl.params.crmContractorUuid = this.crmContractorUuid + ''
  },
  data() {
    return {
      formName: 'crm-contractor-relation-suspension',
      formUrl: {string: '/crm-contractor-relation-suspension?uuid=${uuid}&crmContractorUuid=${crmContractorUuid}', params: {uuid: 'new', crmContractorUuid: ''}},
      editRoute: {name:  'crm-contractor-relation-suspension'},
      exitRoute: {name: 'profile'},

      isConfirmDeleteModal: false,

      isAtLeastOnceSaved: false,
    }
  },
  methods: {
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
    },
    afterSave: function () {
      this.isAtLeastOnceSaved = true
      this.close()
      this.updateParent()
    },
    afterDelete: function () {
      let self = this
      this.isConfirmDeleteModal = false
      setTimeout(function () {
        self.close()
        self.updateParent()
      }, 200)
    },
    closeForm: function () {
      let self = this
      self.isConfirmDeleteModal = false
      self.close()
      if (true === self.isAtLeastOnceSaved) {
        setTimeout(function () {
          self.updateParent()
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.new-notification-email-enter-active,
.new-notification-email-leave-active {
  transition: opacity 0.5s ease;
}

.new-notification-email-enter-from,
.new-notification-email-leave-to {
  opacity: 0;
}
</style>
