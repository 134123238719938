<template>
  <div class="page-content">
    <school-top-tabs />
    <school-analytics-crm-contractors-list />
  </div>
</template>

<script>

import SchoolTopTabs from '@/views/app/school/components/SchoolTopTabs'
import SchoolAnalyticsCrmContractorsList from '@/views/app/school/analytics/SchoolAnalyticsCrmContractorsList'

export default {
  name: 'SchoolAnalyticsView',
  components: {SchoolAnalyticsCrmContractorsList, SchoolTopTabs},
}
</script>

<style scoped>
</style>
