<template>
  <div class="page-list">
    <page-top-bar
        :title="'[SYS] Бизнесы'"
        :subtitle="'Список всех зарегистрированных бизнесов в aVicrm'"
        :buttons="pageButtons"
    />
    <div class="page-list-content">
      <div class="card border-0 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-12 overflow-x-auto">
              <table class="table table-sm table-hover">
                <thead>
                <tr>
                  <th scope="col">Тип</th>
                  <th scope="col"><sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Имя'" :change-sort="sortField_change" /></th>
                </tr>
                <tr>
                  <th scope="col"><search-input :name-input="this.listName + '.search.type'" :name-field="'type'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" /></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in items"
                    :key="'list.' + listName + '.' + i_item"
                    @click="openItem_click(item.uuid)"
                >
                  <td>{{ item.type }}</td>
                  <td>{{ item.name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-list-paginator
        :paginator="paginator"
        :link-page="paginatorLinkPage_click"
    />
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput'
import PageTopBar from '@/components/PageTopBar'
import PageListPaginator from '@/components/PageListPaginator'
import SortInput from '@/components/SortInput'

export default {
  name: 'SysBusinessesList',
  components: {SortInput, PageTopBar, PageListPaginator, SearchInput},
  mixins: [listMixin],
  data() {
    return {
      listName: 'SysBusinessesList',
      listUrl: {string: '/sys-businesses', params:[]},
      itemNameRoute: 'sys-business',
      pageButtons: [],

      listSearch: {
        type: '',
        name: '',
      },
      listSearchDefault: {
        type: '',
        name: '',
      },
    }
  },

  methods: {
    afterLoad: function () {
      let self = this
      self.pageButtons = []
      if (-1 !== self.buttons.indexOf('addBusiness')) {
        self.pageButtons.push({
          name: 'Добавить бизнес',
          class: 'btn-primary', click: function() {
            self.toRoute({name: self.itemNameRoute, params: {uuid: 'new'}})
          }
        })
      }
    },
  }
}
</script>

<style scoped>
</style>
