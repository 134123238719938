<template>
  <page-top-tabs class="mt-2" :tabs="tabs" />
</template>
<script>

import PageTopTabs from '@/components/PageTopTabs'

export default {
  name: 'CrmTopTabs',
  components: {PageTopTabs},
  data() {
    return {
      tabs: [],
    }
  },
  mounted: function() {
    let self = this
    this.tabs = [
      {
        name: '<i class="fa-solid fa-file-signature"></i> Сделки',
        isDisabled: true,
        isActive: 'crm-deals' === self.$route.name,
        click: function () {self.toRoute({name: 'crm-deals'})},
      },
      {
        name: '<i class="fa-solid fa-address-book"></i> Контрагенты',
        isDisabled: false,
        isActive: 'crm-contractors' === self.$route.name,
        click: function () {self.toRoute({name: 'crm-contractors'})},
      },
      {
        name: '<i class="fa fa-chart-column"></i> Статистика',
        isDisabled: true,
        isActive: false,
        click: function () {},
      },
      {
        name: '<i class="fa-solid fa-filter"></i> Воронки',
        isDisabled: true,
        isActive: false,
        click: function () {},
      },
    ]
  }
}
</script>

<style scoped>
</style>
