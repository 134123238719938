<template>
  <div class="overflow-y-hidden overflow-x-auto">
    <table class="table-sm">
      <tbody>
      <tr :class="{'my-1': isEdit}" v-for="(timetable, i_timetable) in timetables" :key="'timetable.' + i_timetable">
        <td class="p-0 ps-2" v-if="isEdit">
          <a href="#" @click="timetableOpen(i_timetable, timetable)" class="me-1">
            <i class="fa-solid fa-pen-to-square"></i>
          </a>
        </td>
        <td class="p-0 pe-2">{{ enums.weekdays[timetable.weekday - 1].name }}</td>
        <td class="p-0 pe-2">{{ timetable.time }}</td>
        <td class="p-0">{{ timetable.name }}</td>
        <td>
          <div class="badge ms-1 text-bg-secondary"
               v-for="(relationTypeName, i_relationType) in timetable.nameSchoolRelationUserTypes"
               :key="'relationType.' + i_relationType">
            {{ relationTypeName }}
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'SchoolProcessTimetableView',
  props: {
    timetables: {
      type: Array,
      required: true
    },
    timetableOpen: {
      type: Function,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    }
  }
}
</script>

<style scoped>
</style>
