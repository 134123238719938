<template>
  <div class="page-list">
    <page-top-bar
        :title="'[SYS] Пользователи'"
        :subtitle="'Список всех пользователей aVicrm'"
        :buttons="pageButtons"
    />
    <div class="page-list-content">
      <div class="card border-0 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-12 overflow-x-auto">
              <table class="table table-sm table-hover">
                <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Роль</th>
                  <th scope="col"><sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Имя'" :change-sort="sortField_change" /></th>
                  <th scope="col"><sort-input :name-input="this.listName + '.sort.email'" :name-field="'email'" :input-name="'Email'" :change-sort="sortField_change" /></th>
                  <th scope="col">Текущий бизнес</th>
                </tr>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.role'" :name-field="'role'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.email'" :name-field="'email'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.business'" :name-field="'business'" :input-text="searchField_input" /></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in items"
                    :key="'list.' + listName + '.' + i_item"
                    :class="{'table-danger': false === item.isLeastOneBusiness}"
                    @click="openItem_click(item.uuid)"
                >
                  <td v-if="false === item.is_blocked" class="text-secondary"><i class="fa-solid fa-user"></i></td>
                  <td v-if="true === item.is_blocked" class="text-danger"><i class="fa-solid fa-user-slash"></i></td>
                  <td>{{ item.role }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.currentBusiness }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-list-paginator
        :paginator="paginator"
        :link-page="paginatorLinkPage_click"
    />
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput'
import PageTopBar from '@/components/PageTopBar'
import PageListPaginator from '@/components/PageListPaginator'
import SortInput from '@/components/SortInput'

export default {
  name: 'SysUsersList',
  components: {SortInput, PageTopBar, PageListPaginator, SearchInput},
  mixins: [listMixin],
  data() {
    return {
      listName: 'SysUsersList',
      listUrl: {string: '/sys-users', params:[]},
      itemNameRoute: 'sys-user',
      pageButtons: [],

      listSort: {
        name: '',
        email: '',
      },
      listSortDefault: {
        name: '',
        email: '',
      },
      listSearch: {
        role: '',
        name: '',
        email: '',
        business: '',
      },
      listSearchDefault: {
        role: '',
        name: '',
        email: '',
        business: '',
      },
    }
  },

  methods: {
    afterLoad: function () {
      let self = this
      self.pageButtons = []
      if (-1 !== self.buttons.indexOf('addUser')) {
        self.pageButtons.push({
          name: 'Создать пользователя',
          class: 'btn-primary', click: function() {
            self.toRoute({name: self.itemNameRoute, params: {uuid: 'new'}})
          }
        })
      }
    },
  }
}
</script>

<style scoped>
</style>
