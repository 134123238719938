<template>
  <div class="page-list">
    <crm-top-tabs />
    <page-top-bar
        :title="title"
        :subtitle="'Список всех зарегистрированных контрагентов'"
        :buttons="pageButtons"
    />
    <div class="page-list-content">
      <div class="card border-0 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-12 overflow-x-auto">
              <table class="table table-sm table-hover">
                <thead>
                <tr>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Наименование'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.description'" :name-field="'description'" :input-name="'Описание'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    Контакты
                  </th>
                  <th scope="col">Заметки</th>
                </tr>
                <tr>
                  <th scope="col"><search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.description'" :name-field="'description'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.contacts'" :name-field="'contacts'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.noteText'" :name-field="'noteText'" :input-text="searchField_input" /></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in items"
                    :key="'list.' + listName + '.' + i_item"
                >
                  <td @click="openItem_click(item.uuid)">{{ item.name }}</td>
                  <td @click="openItem_click(item.uuid)">{{ item.description }}</td>
                  <td @click="openItem_click(item.uuid)" v-html="item.contacts.join('<br>')"></td>
                  <td>
                    <div v-if="isLoadingNotes">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Загрузка...</span>
                      </div>
                    </div>
                    <object-note-list
                        v-if="!isLoadingNotes"
                        :object-uuid="item.uuid"
                        object-type="crm_contractor"
                        :notes="item.notes"
                        :object-name="item.name"
                        :object-description="item.description"
                        :update-list="loadNotes"
                    />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-list-paginator
        :paginator="paginator"
        :link-page="paginatorLinkPage_click"
    />
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput'
import PageTopBar from '@/components/PageTopBar'
import PageListPaginator from '@/components/PageListPaginator'
import SortInput from '@/components/SortInput'
import CrmTopTabs from '@/views/app/crm/components/CrmTopTabs'
import ObjectNoteList from '@/components/ObjectNoteList'

export default {
  name: 'CrmContractorsList',
  components: {ObjectNoteList, CrmTopTabs, SortInput, PageTopBar, PageListPaginator, SearchInput},
  mixins: [listMixin],
  data() {
    return {
      title: '<i class="fa-solid fa-address-book"></i> Контрагенты',

      isLoadingNotes: false,

      listName: 'CrmContractorsList',
      listUrl: {string: '/crm-contractors', params:[]},
      itemNameRoute: 'crm-contractor',
      pageButtons: [],

      listSort: {
        name: '',
      },
      listSortDefault: {
        name: '',
      },
      listSearch: {
        name: '',
      },
      listSearchDefault: {
        name: '',
      },
    }
  },

  methods: {
    afterLoad: function () {
      let self = this
      self.loadNotes()
      self.pageButtons = []
      if (-1 !== self.buttons.indexOf('addContractor')) {
        self.pageButtons.push({
          name: 'Добавить контрагента',
          class: 'btn-primary', click: function() {
            self.toRoute({name: self.itemNameRoute, params: {uuid: 'new'}})
          }
        })
      }
    },

    loadNotes: function () {
      this.isLoadingNotes = true

      let uuids  = []
      for (let i in this.items) {
        uuids.push(this.items[i].uuid)
      }

      window.axios.post(
          this.globalVar.app.api.url + '/notes' + '?' + (new Date()).getTime(), {
            search: {crmContractorUuids: uuids, isDone: false, perPage: 1000},
            sort: {}
          })
          .then(response => {
            for (let i in this.items) {
              let item = this.items[i]
              item.notes = []
              for (let c in response.data.items) {
                let note = response.data.items[c]
                for (let o in note.objects) {
                  let object = note.objects[o]
                  if (item.uuid === object.uuid) {
                    item.notes.push(note)
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error, error.data)
          })
          .finally(() => {
            this.isLoadingNotes = false
          })
    }
  }
}
</script>

<style scoped>
</style>
