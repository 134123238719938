<template>
  <div class="container-fluid text-start">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        :is-global-close="false"
        @close="isConfirmDeleteModal = false"
    />
    <confirm-action-modal
        :show="isToDoneModal"
        :text="'Закрыть ' + data.name + '?'"
        :btn-text="'Закрыть'" :btn-type="'dark'"
        :confirm-action="toDone"
        :is-global-close="false"
        @close="isToDoneModal = false"
    />
    <confirm-action-modal
        :show="isRollBackDoneModal"
        :text="'Возобновить ' + data.name + '?'"
        :btn-text="'Возобновить'" :btn-type="'dark'"
        :confirm-action="toRollBackDone"
        :is-global-close="false"
        @close="isRollBackDoneModal = false"
    />

    <div v-if="isLoaded">
      <h5 class="card-title placeholder-glow">
        <span class="placeholder col-6"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-12 my-2 fs-26px"></span>
        <span class="placeholder col-12 my-2 fs-26px"></span>
      </p>
      <div class="text-end">
        <a class="btn btn-primary disabled placeholder col-6" aria-disabled="true"></a>
      </div>
    </div>
    <div v-if="false === isLoaded">
      <div>
        <div class="mb-2 has-text-weight-bold" v-if="'new' === uuid">Новая заметка</div>
        <div v-if="data.is_done" class="mt-2">
          <div class="mb-2 text-bg-secondary px-3 py-1 border-radius-10 fs-12px">
            Заметка закрыта {{ stringDateYmdhisToHidmy(data.done_at, true) }}
          </div>
        </div>
        <div class="form-control" v-for="(obj, i_obj) in data.objects" :key="'object.' + i_obj">
          <div class="text-secondary fs-12px">{{ getValueEnums(globalVar.db.objectNoteTypes, obj.type, 'name') }}</div>
          <div v-html="obj.name"></div>
          <div class="text-secondary" v-html="obj.description"></div>
        </div>
        <div class="mt-3">
          <label class="form-label">Наименование</label>
          <input type="text" class="form-control" placeholder="Заполнить отчет"
                 v-model="data.name"
                 :disabled="!inArray('name', enabledFields)"
                 :class="{ 'is-invalid': !!errors['name'] }"
          >
          <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
        </div>
        <div class="mt-3">
          <label class="form-label">Краткое примечание</label>
          <input type="text" class="form-control" placeholder="Краткая информация"
                 v-model="data.description"
                 :disabled="!inArray('description', enabledFields)"
                 :class="{ 'is-invalid': !!errors['description'] }"
          >
          <div class="text-danger" v-if="!!errors['description']">{{ errors['description'].join(', ') }}</div>
        </div>
        <div class="mt-3 row">
          <div class="col-sm-6">
            <input :disabled="!inArray('is_show_all', enabledFields)" type="checkbox" v-model="data.is_show_all" @change="dataIsEditAll_change" class="form-check-input" id="dataIsShowAll">
            <label class="form-check-label" for="dataIsShowAll">Доступна всем</label>
            <div class="text-secondary fs-12px">
              Заметка будет доступна на просмотр всем пользователям, которым доступен основной объект заметки
            </div>
            <div class="text-danger" v-if="!!errors['is_show_all']">{{ errors['is_show_all'].join(', ') }}</div>
          </div>
          <Transition name="data-is-edit-all">
            <div v-if="data.is_show_all" class="col-sm-6 mt-sm-0 mt-2">
              <input :disabled="!inArray('is_edit_all', enabledFields)" type="checkbox" v-model="data.is_edit_all" class="form-check-input" id="dataIsEditAll">
              <label class="form-check-label" for="dataIsEditAll">Редактирование всем</label>
              <div class="text-secondary fs-12px">
                Дополнительно вы можете разрешить вносить изменения в заметку всем пользователям
              </div>
              <div class="text-danger" v-if="!!errors['is_show_all']">{{ errors['is_show_all'].join(', ') }}</div>
            </div>
          </Transition>
        </div>
        <div class="mt-3">
          <input :disabled="!inArray('is_notification', enabledFields)" type="checkbox" v-model="data.is_notification" @change="dataIsNotificationAll_change" class="form-check-input" id="dataIsNotification">
          <label class="form-check-label" for="dataIsNotification">Добавить уведомление</label>
          <div class="text-danger" v-if="!!errors['is_notification']">{{ errors['is_notification'].join(', ') }}</div>
        </div>
        <Transition name="data-notification-params">
          <div v-if="data.is_notification" class="mt-2 my-2 row">
            <div class="col-sm-6">
              <label class="form-label">Дата уведомления</label>
              <date-time-picker-input :disabled="!inArray('deadline_at', enabledFields)" v-model="data.deadline_at" :is-global-close="false" :is-time="true" />
              <div class="text-danger" v-if="!!errors['deadline_at']">{{ errors['deadline_at'].join(', ') }}</div>
            </div>
            <Transition name="data-notification-param-interval">
              <div v-if="!empty(data.deadline_at)" class="col-sm-6 mt-sm-0 mt-2">
                <label class="form-label">Предварительно уведомить</label>
                <interval-input
                    :disabled="!inArray('notification_interval', enabledFields)"
                    v-model="data.notification_interval"
                    :list="listTypeInterval"
                    :is-global-close="false"
                />
                <div class="text-secondary fs-12px">
                  Укажите интервал времени, за который необходимо дополнительно направить Вам предварительное уведомление
                </div>
                <div class="text-danger" v-if="!!errors['notification_interval']">{{ errors['notification_interval'].join(', ') }}</div>
              </div>
            </Transition>
          </div>
        </Transition>
      </div>
      <div class="text-end mt-3">
          <button
              class="btn sm-w-100 mt-1 ms-sm-2"
              v-for="(button, i_button) in buttons.filter(function(item) {
                return item.uuid !== 'exit'
              })"
              :key="'page-top-bar-button-' + i_button"
              :class="button.class"
              type="submit"
              @click="button.click()"
              v-html="button.name"
          />
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeForm">Отмена</a>
      </div>
    </div>
  </div>
</template>

<script>

import {subformMixin} from '@/mixins/subformMixin'
import DateTimePickerInput from '@/components/form/DateTimePickerInput'
import IntervalInput from '@/components/form/IntervalInput'
import ConfirmActionModal from '@/components/ConfirmActionModal'

export default {
  name: 'ObjectNoteView',
  components: {ConfirmActionModal, IntervalInput, DateTimePickerInput},
  mixins: [subformMixin],
  props: {
    uuid: {
      type: String,
      required: true
    },
    objects: {
      type: Array,
      required: true
    },
    updateParent: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    }
  },
  beforeMount() {
    this.formUrl.params.uuid = this.uuid + ''
  },
  data() {
    return {
      isConfirmDeleteModal: false,
      isToDoneModal: false,
      isRollBackDoneModal: false,

      formName: 'note',
      formUrl: {
        string: '/note?uuid=${uuid}',
        params: {uuid: 'new'}
      },
      editRoute: {name:  'note'},
      exitRoute: {name: 'note'},

      data: {
        is_done: false,
        done_at: null,
        is_show_all: false,
        is_notification: false,
      },

      isAtLeastOnceSaved: false,

      listTypeInterval: [
        {uuid: 'hours', name: 'Час(а)'},
        {uuid: 'days', name: 'День(й)'},
        {uuid: 'months', name: 'Месяц(а)'},
        {uuid: 'years', name: 'Год(а)'},
      ],
    }
  },
  methods: {
    afterLoad: function () {
      if ('new' === this.uuid) {
        this.data.objects = JSON.parse(JSON.stringify(this.objects))
      }

      let self = this
      let buttons = []

      if ( this.inArray('toDone', this.data.buttons)) {
        buttons.push({
          name: 'Закрыть',
          class: 'btn-outline-dark',
          click: function() {
            self.isToDoneModal = true
            self.emitter.emit('modal', {event: 'open'})
          }
        })

        for (let i in this.buttons) {
          buttons.push(this.buttons[i])
        }
      } else if ( this.inArray('rollBackDone', this.data.buttons)) {
        buttons.push({
          name: 'Возобновить',
          class: 'btn-outline-dark',
          click: function() {
            self.isRollBackDoneModal = true
            self.emitter.emit('modal', {event: 'open'})
          }
        })
      } else {
        for (let i in this.buttons) {
          buttons.push(this.buttons[i])
        }
      }

      this.buttons = buttons
    },
    afterSave: function () {
      this.isAtLeastOnceSaved = true
      this.close()
      this.updateParent()
    },
    afterDelete: function () {
      let self = this
      this.isConfirmDeleteModal = false
      setTimeout(function () {
        self.close()
        self.updateParent()
        self.emitter.emit('dashboardCalendarList', {event: 'reload'})
      }, 200)
    },

    toDone: function () {
      this.isToDoneModal = false
      this.data.is_done = true
      this.save()
    },

    toRollBackDone: function () {
      this.isRollBackDoneModal = false
      this.data.is_done = false
      this.save()
    },

    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    dataIsEditAll_change: function() {
      if (false === this.data.is_show_all) {
        this.data.is_edit_all = false
      }
    },
    dataIsNotificationAll_change: function() {
      if (false === this.data.is_notification) {
        this.data.deadline_at = null
        this.data.notification_interval = null
      }
    },

    closeForm: function () {
      let self = this
      self.close()
      if (true === self.isAtLeastOnceSaved) {
        setTimeout(function () {
          self.updateParent()
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.data-is-edit-all-enter-active,
.data-is-edit-all-leave-active {
  transition: opacity 0.5s ease;
}

.data-is-edit-all-enter-from,
.data-is-edit-all-leave-to {
  opacity: 0;
}

.data-notification-params-enter-active,
.data-notification-params-leave-active {
  transition: opacity 0.5s ease;
}

.data-notification-params-enter-from,
.data-notification-params-leave-to {
  opacity: 0;
}

.data-notification-param-interval-enter-active,
.data-notification-param-interval-leave-active {
  transition: opacity 0.5s ease;
}

.data-notification-param-interval-enter-from,
.data-notification-param-interval-leave-to {
  opacity: 0;
}
</style>
