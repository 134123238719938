export const globalVar = {
    app: {
        v: 'v 1.3.2',
        length: 'ru',
        regFirebase: localStorage.webView_regFirebase ?? 'false',
        regHuawei: localStorage.webView_regHuawei ?? 'false',
        api: {
            url: '/server/api/app-v1',
        },
        lan: {
            time: null,
            status: true,
        },
        isStatusAuthChangedUser: false,
        user: JSON.parse(localStorage.user ?? '{"isAuth":false, "name":"","avatarText":"","businessName":"","businessType":"","avatarImg":"","routes":[]}'),
        monitor: {
            globalMixin: {
                app: null,
                ping: null,
                statusLogin: null,
            },
        },
        colorTheme: localStorage.webView_colorTheme ?? 'system',
        systemColorTheme: localStorage.webView_systemColorTheme ?? 'light',
    },
    db: {
        requests: [],
        roles: [],
        businessTypes: [],
        taskStatuses: [],
        taskRelationUserTypes: [],
        userNotificationTypes: [],
        objectNoteTypes: [],
    },
    dbConfig: {
        dbName: 'appBDv1',
        isInit: false,
        instanceDB: null,
        isSendRequests: false,
        stores: {
            requests: {
                isUpdated: false,
                keyPath: 'uuid',
                indexes: [],
            },
            roles: {
                isUpdated: false,
                keyPath: 'uuid',
                indexes: [
                    {name: 'name', unique: false}
                ],
            },
            businessTypes: {
                isUpdated: false,
                keyPath: 'uuid',
                indexes: [
                    {name: 'name', unique: false}
                ],
            },
            taskStatuses: {
                isUpdated: false,
                keyPath: 'uuid',
                indexes: [
                    {name: 'name', unique: false}
                ],
            },
            taskRelationUserTypes: {
                isUpdated: false,
                keyPath: 'uuid',
                indexes: [
                    {name: 'name', unique: false}
                ],
            },
            userNotificationTypes: {
                isUpdated: false,
                keyPath: 'uuid',
                indexes: [
                    {name: 'name', unique: false}
                ],
            },
            objectNoteTypes: {
                isUpdated: false,
                keyPath: 'uuid',
                indexes: [
                    {name: 'name', unique: false}
                ],
            },
        },
    },
}