<template>
  <div class="py-1">
    <div class="has-text-weight-bold alert alert-light py-2 px-3 mb-1">
      <b>{{ item.name }}</b>
      <button v-if="isEdit && 'param' === item.type && (true === item.is_datetime || true === item.is_deadline)"
              class="btn btn-very-sm btn-outline-primary fs-12px ms-2"
              @click="editStructureItemParams(item)">
        Править
      </button>
      <school-course-item-show-fields-view
          :process-params="processParams"
          :is-show-params="true"
          :item="item"
          :list-relation-user-type="listRelationUserType"
      />
    </div>
    <div class="container-children-container position-relative" v-if="'structure' === item.type">
      <div v-if="0 < item.children.length">
        <a class="btn btn-link container-children-btn" @click="isChildrenShow = !isChildrenShow">
          <span v-show="!isChildrenShow"><i class="fa-solid fa-plus" /></span>
          <span v-show="isChildrenShow"><i class="fa-solid fa-minus" /></span>
        </a>
        <transition appear name="container-children" mode="out-in">
          <div v-if="false === isChildrenShow" class="py-1">
            <div class="has-text-weight-bold alert alert-light py-2 px-3 mb-1">Содержимое скрыто ...</div>
          </div>
          <div v-if="true === isChildrenShow" class="inputBox input-password w-100">
            <planning-school-course-item-view
                :indexes="getIndexByParent(i_child)"
                :item="child"
                :is-edit="isEdit"
                :list-relation-user-type="listRelationUserType"
                :edit-structure-item-params="editStructureItemParams"
                :process-params="processParams"
                v-for="(child, i_child) in item.children" :key="i_child"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

import SchoolCourseItemShowFieldsView from '@/views/app/school/components/SchoolCourseItemShowFieldsView'

export default {
  name: 'PlanningSchoolCourseItemView',
  components: {SchoolCourseItemShowFieldsView},
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
    indexes: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    processParams: {
      type: Array,
      required: true,
    },
    editStructureItemParams: {
      type: Function,
      required: true,
    },
    listRelationUserType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isChildrenShow: true,
    }
  },
  methods: {
    getIndexByParent: function(index) {
      let indexes = JSON.parse(JSON.stringify(this.indexes))
      indexes.push(index)

      return indexes
    },
  },
}
</script>

<style scoped>
.container-children-container {
  padding-left: 44px;
}
.container-children-btn {
  position: absolute;
  left: 0;
  top: 4px;
}
.container-children-enter-active, .container-children-leave-active {
  transition: opacity .3s;
}
.container-children-enter, .container-children-leave-to /* .container-children-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
