<template>
  <div>
    <div v-if="'note' === caseData.type">
      <span class="text-secondary me-1"><i class="fa-solid fa-note-sticky"></i></span>
      в <b>{{ getTime(caseData.intTime) }}</b>
      <div>{{caseData.name}}</div>
      <div class="text-secondary fs-12px">{{caseData.description}}</div>
    </div>

    <div v-else-if="'task' === caseData.type">
      <span class="text-secondary me-1"><i class="fa-solid fa-list-check"></i></span>
      <span class="badge me-2" :class="`task-bg-${caseData.description}`">
                  {{ getValueEnums(globalVar.db.taskStatuses,caseData.description, 'name') }}
                </span>
      <span v-if="'00:00' !== stringDateYmdhisToHidmy(caseData.date, true).substr(0, 5)">
        до <b>{{ getTime(caseData.intTime) }}</b>
      </span>
      <div>{{ caseData.name }}</div>
    </div>

    <div v-else-if="'school-process' === caseData.type">
      <span class="text-secondary me-1"><i class="fa-solid fa-school"></i></span>
      <span v-if="empty(caseData.duration_in_minutes)">
        в <b>{{ getTime(caseData.intTime) }}</b>
      </span>
      <span v-else>
        c <b>{{ getTime(caseData.intTime) }}</b> по <b>{{getTime(caseData.intEndTime)}}</b>
      </span>
      <div>{{caseData.name}}</div>
      <div class="text-secondary fs-12px">{{caseData.description}}</div>
    </div>
    <div v-else-if="'event' === caseData.type">
      <span class="text-secondary me-1"><i class="fa-solid fa-timeline"></i></span>
      c <b>{{ getTime(caseData.intTime) }}</b> по <b>{{getTime(caseData.intEndTime)}}</b>
      <div>{{ caseData.name }}</div>
      <div class="text-secondary fs-12px" v-html="caseData.description"></div>
    </div>
    <div v-else>
      <div>{{caseData.name}}</div>
      <div class="text-secondary fs-12px">{{caseData.description}}</div>
    </div>
    <div class="text-secondary" v-html="getTimeDurationLessonInMinutes(caseData.duration_in_minutes)"></div>
  </div>
</template>

<script>

export default {
  name: 'DashboardCaseView',
  props: {
    caseData: {
      type: Object,
      required: true
    },
  },
  methods: {
    getTimeDurationLessonInMinutes: function (duration_in_minutes) {
      if (this.empty(duration_in_minutes)) {
        return ''
      }
      let h = Math.floor(duration_in_minutes / 60)
      let m = duration_in_minutes - (h * 60)
      return 'Продолжительность: <b>' + (h > 0 ? h + ' ч. ' : '') + (m > 0 ? ' ' + m + ' мин.' : '') + '</b>'
    },

    getTime: function (intTime) {
      let time = this.getTimeLength4(intTime + '')

      if ('59' === time.substring(2, 4)) {
        return (time.substring(0, 2) * 1 + 1) + ':00'
      }

      if ('0' !== time.substring(3, 4)) {
        time = this.getTimeLength4((intTime + 1) + '')
      }

      return time.substring(0, 2) + ':' + time.substring(2, 4)
    },

    getTimeLength4: function (time) {
      if ( 3 === time.length) {
        return '0' + time
      }

      return time
    }
  }
}
</script>

<style scoped>
</style>
