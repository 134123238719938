<template>
  <div class="object-notes-list">
    <base-modal title="" :show="currentNote.isEditModal" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <object-note-view
            :uuid="currentNote.uuid"
            :objects="currentNote.objects"
            :update-parent="updateList"
            :close="close"
         />
      </template>
    </base-modal>

    <div class="position-relative text-end mb-1">
      <div class="position-absolute text-secondary">Заметки</div>
      <button type="button" class="btn btn-very-sm btn-primary" @click="editNote('new')">+ <i class="fa-solid fa-note-sticky"></i></button>
    </div>
    <div class="btn btn-light py-1 px-2 mb-2 text-start fs-14px w-100"
         @click="editNote(note.uuid)"
         v-for="(note, i_note) in notes" :key="'note.' + i_note">
      <div>{{ note.name }}</div>
      <div class="mb-2 fs-12px text-secondary">{{ note.description }}</div>
      <div class="d-inline-block" style="width: 70px">
        <div v-if="false === note.is_show_all && false === note.is_edit_all" class="badge text-bg-secondary"><i class="fa-solid fa-users-slash"></i></div>
        <div v-if="true === note.is_show_all && false === note.is_edit_all" class="badge text-bg-dark"><i class="fa-solid fa-users"></i></div>
        <div v-if="true === note.is_edit_all" class="badge text-bg-success"><i class="fa-solid fa-users"></i></div>

        <div v-if="true === note.is_notification" class="badge text-bg-success ms-2"><i class="fa-solid fa-bell"></i></div>
      </div>

      <div class="badge" :class="isValidDate(note.deadline_at) ? 'bg-success': 'bg-danger'">
        {{ stringDateYmdhisToHidmy(note.deadline_at, true) }}
      </div>
    </div>
  </div>
</template>
<script>

import BaseModal from '@/components/BaseModal'
import ObjectNoteView from '@/components/ObjectNoteView'

export default {
  name: 'ObjectNoteList',
  components: {ObjectNoteView, BaseModal},
  props: {
    objectType: {
      type: String,
      required: true,
    },
    objectUuid: {
      type: String,
      required: true,
    },
    objectName: {
      type: String,
      required: true,
    },
    objectDescription: {
      type: String,
      required: true,
    },
    notes: {
      type: Array,
      required: true,
    },
    updateList: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      currentNote: {
        isEditModal: false,
        uuid: '',
        objects: [],
      }
    }
  },
  methods: {
    editNote: function (noteUuid) {
      this.currentNote.uuid = noteUuid + ''
      this.currentNote.objects = [{
        uuid: this.objectUuid,
        type: this.objectType,
        name: this.objectName,
        description: this.objectDescription,
      }]
      this.currentNote.isEditModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    close: function () {
      this.currentNote.isEditModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.object-notes-list {
  min-width: 186px;
}
</style>
