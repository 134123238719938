<template>
  <ul class="nav nav-underline page-top-bar">
    <li v-for="(tab, i_tab) in tabs" :key="'ptt.' + i_tab" class="nav-item">
      <a class="nav-link"
         :class="{'disabled': tab.isDisabled, 'active': tab.isActive}"
         aria-current="page"
         href="#"
         @click="function() {if (false === tab.isDisabled) tab.click() }"
         v-html="tab.name"></a>
    </li>
  </ul>
</template>
<script>

export default {
  name: 'PageTopTabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
</style>
