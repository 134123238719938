<template>
  <div class="card border-0 shadow">
    <div class="card-header">
      Задачи без сроков
      <div  v-if="isLoading" class="spinner-border spinner-border-sm text-primary position-absolute end-0 top-0 m-3" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 overflow-x-auto">
          <table v-if="0 < items.length" class="table table-sm table-hover">
            <tbody>
            <tr
                class="cursor-pointer"
                v-for="(item, i_item) in items"
                :key="'list.' + listName + '.' + i_item"
                @click="openItem_click(item.uuid)"
            >
              <td><span class="badge" :class="`task-bg-${item.status}`">{{ getValueEnums(globalVar.db.taskStatuses,item.status, 'name') }} </span></td>
              <td>{{ item.name }}</td>
              <td>
                <div class="badge" :class="isValidDate(item.deadline_at) ? 'bg-success': 'bg-danger'">
                  {{  stringDateYmdhisToHidmy(item.deadline_at, item.is_time_in_deadline_at) }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div v-if="0 === items.length">
            <div class="my-3 text-center text-secondary">
              Нет задач
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'

export default {
  name: 'DashboardTasksList',
  mixins: [listMixin],
  data() {
    return {
      listName: 'DashboardTasksList',
      listUrl: {string: '/dashboard-tasks', params:[]},
      itemNameRoute: 'task',
      pageButtons: [],

      isLoadAtMantling: false,

      listTabs: [],
      tabSubtitle: '',
    }
  },
  mounted() {
    this.load(false)
  }
}
</script>

<style scoped>
.card-header {
  background-color: initial;
  border-bottom: 0;
}
</style>
