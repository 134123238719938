<template>
  <div>
    <base-modal title="" :show="crmContractorRelationSchoolCourse.isModel" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <crm-contractor-relation-school-course-view
            :crm-contractor-uuid="crmContractorUuid"
            :uuid="crmContractorRelationSchoolCourse.uuid"
            :update-parent="load"
            :close="close"
        />
      </template>
    </base-modal>
    <div>
      <div v-if="inArray('addRelation', buttons)" class="float-start add-relation-button-container">
          <button class="btn btn-sm btn-outline-secondary w-100" type="submit" @click="openEditRelation('new')">
            Добавить курс
          </button>
      </div>
      <page-list-paginator
          v-if="0 < items.length && false === isLoading"
          :paginator="paginatorList"
          :link-page="paginatorLinkPage_click"
          size="sm"
      />
    </div>
    <div class="overflow-y-hidden overflow-x-auto">
      <table class="table table-sm table-hover">
        <thead>
        <tr>
          <th scope="col"><sort-input :name-input="this.listName + '.sort.is_was_used'" :name-field="'is_was_used'" :input-name="'Реализован'" :change-sort="sortField_change" /></th>
          <th scope="col">Наименование курса</th>
          <th scope="col">Стоимость курса</th>
          <th scope="col">Реализация</th>
        </tr>
        <tr>
          <th scope="col"></th>
          <th scope="col"><search-input :name-input="this.listName + '.search.schoolCourse'" :name-field="'schoolCourse'" :input-text="searchField_input" /></th>
          <th scope="col"><search-input :name-input="this.listName + '.search.price'" :name-field="'price'" :input-text="searchField_input" /></th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr
            class="cursor-pointer"
            v-for="(item, i_item) in items"
            :key="'list.' + listName + '.' + i_item"
        >
          <td @click="openEditRelation(item.uuid)">{{ item.is_was_used }}</td>
          <td @click="openEditRelation(item.uuid)">{{ item.school_course }}</td>
          <td @click="openEditRelation(item.uuid)">{{ item.price }} р.</td>
          <td>
            <div
                class="mb-2 dt-progresses"
                v-for="(implementation, i_implementation) in item.implementations"
                :key="'list.' + listName + '.' + i_item + '.' + i_implementation"

                @click="openSchoolCourse(implementation.school_process_is_done, implementation.school_process_uuid)"
            >
              <div class="badge bg-secondary w-100">
                <div class="row">
                  <div class="col-6 text-start">
                    {{ implementation.school_process_name }}
                  </div>
                  <div class="col-6 text-end">
                    <b>{{ implementation.price_all }} р.</b>
                  </div>
                </div>
              </div>

              <div v-if="!empty(implementation.end_at)" class="text-end fs-12px text-secondary px-2">
                из них реализовано <b>{{ implementation.price_work }} р.</b>
                <div>
                  Обучение с <b>{{ implementation.start_at }}</b> по <b>{{ implementation.end_at }}</b>
                </div>
              </div>
              <div class="px-2 pt-1 fs-12px text-secondary" v-if="empty(implementation.end_at)">
                Проходит процесс обучения с <b>{{ implementation.start_at }}</b>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="d-block" v-if="0 === items.length && false === isLoading">
      <div class="my-3 text-center text-secondary">
        Приобретенных курсов нет
      </div>
    </div>
    <div class="d-block text-center py-2" v-if="isLoading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import PageListPaginator from '@/components/PageListPaginator'
import BaseModal from '@/components/BaseModal'
import CrmContractorRelationSchoolCourseView from '@/views/app/crm/components/CrmContractorRelationSchoolCourseView'
import SortInput from "@/components/SortInput.vue";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: 'CrmContractorRelationSchoolCourseList',
  components: {SearchInput, SortInput, CrmContractorRelationSchoolCourseView, BaseModal, PageListPaginator},
  mixins: [listMixin],
  props: {
    crmContractorUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      listName: 'CrmContractorRelationSchoolCourseList',
      listUrl: {string: '/crm-contractor-relation-school-courses', params:[]},
      itemNameRoute: 'crm-contractor-relation-school-courses',
      pageButtons: [],

      listSearch: {
        crmContractorUuid: this.crmContractorUuid,
      },
      listSearchDefault: {
        crmContractorUuid: this.crmContractorUuid,
      },

      paginatorList: {
        currentPage: 0,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
      },

      crmContractorRelationSchoolCourse: {
        isModel: false,
        uuid: ''
      },
    }
  },
  watch: {
    paginator: function (newVal) {
      let self = this
      setTimeout(function () {
        self.paginatorList = newVal
      }, 10)
    },
  },
  mounted: function() {
    this.emitter.on('crmContractorRelationSchoolCourseList', this.emitterCrmContractorRelationSchoolCourseList_reload)
  },
  unmounted: function() {
    this.emitter.off('crmContractorRelationSchoolCourseList', this.emitterCrmContractorRelationSchoolCourseList_reload)
  },
  methods: {
    emitterCrmContractorRelationSchoolCourseList_reload: function () {
      this.load()
    },
    openEditRelation(uuid) {
      this.crmContractorRelationSchoolCourse.isModel = true
      this.crmContractorRelationSchoolCourse.uuid = uuid
      this.emitter.emit('modal', {event: 'open'})
    },

    openSchoolCourse(isDone, uuid) {
      let typeList = isDone ? 'done' : 'run'
      let typeForm = isDone ? 'Done' : 'Run'
      let route = {name:  `school-${typeList}-process`, params: {uuid: uuid}}
      this.setFormExitRoute(
          `school${typeForm}Process`,
          {name:  'crm-contractor', params: {uuid: this.crmContractorUuid}}
      )
      this.toRoute(route)
    },

    close: function () {
      this.crmContractorRelationSchoolCourse.isModel = false
      this.emitter.emit('modal', {event: 'close'})
    },
  },
}
</script>

<style scoped>
.page-list-pagination {
  display: inline-block;
  padding: 0;
}

.add-relation-button-container {
  min-height: 38px;
}

.dt-progresses {
  min-width: 300px;
}
</style>
