<template>
  <div class="page-top-bar my-2">
    <div class="container-fluid">
      <div class="row row-cols-auto justify-content-between">
        <div class="col sm-w-100 m-0 p-0">
          <div>
            <div class="page-top-bar-title" v-html="title"></div>
            <div class="page-top-bar-subtitle" v-html="subtitle"></div>
          </div>
        </div>
        <div class="col sm-w-100 m-0 p-0">
          <button
              v-for="(button, i_button) in buttons"
              :key="'page-top-bar-button-' + i_button"
              class="btn page-top-bar-button"
              :class="button.class"
              type="submit"
              @click="button.click()"
              v-html="button.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'PageTopBar',
  props: {
    isTitle: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

.page-top-bar-title {
  font-size: 20px;
}
.page-top-bar-subtitle {
  font-size: 12px;
}

.page-top-bar-button {
  margin: 0.25rem!important;
}
.page-top-bar-button:last-child {
  margin-right: 0!important;
}
@media screen and (max-width: 575px) {
  .page-top-bar-button {
    width: calc(50% - 0.5rem) !important;
  }
  .page-top-bar-button:last-child {
    margin-right: 0.25rem!important;
  }
  .page-top-bar-title
  ,.page-top-bar-subtitle {
    margin: 0.25rem!important;
  }

  .page-top-bar .col {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
