export const swMixin = {
  data() {
    return {
      sw: {
        registration: null,
        updateExists: false,
      }
    }
  },
  mounted: function() {
    document.addEventListener('swUpdated', this.sw_updateAvailable, { once: true })

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true

      this.globalVar.app.regFirebase = 'false'
      localStorage.removeItem('webView_regFirebase')

      this.globalVar.app.regHuawei = 'false'
      localStorage.removeItem('webView_regHuawei')

      window.location.reload()
    })
  },
  methods: {
    sw_updateAvailable(event) {
      this.sw.registration = event.detail
      this.sw.updateExists = true
      this.sw_refreshApp()
    },
    sw_refreshApp() {
      this.sw.updateExists = false
      if (!this.sw.registration || !this.sw.registration.waiting) return
      this.sw.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  }
}
