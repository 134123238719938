export const listMixin = {
    data() {
        return {
            listName: '',
            listUrl: {string: '', params:[]},
            _listUrl: '',
            isLoading: false,

            isLoadAtMantling: true,

            items: [],
            itemNameRoute: '',
            paginator: {
                currentPage: 0,
                pageCount: 0,
                perPage: 10,
                totalCount: 0,
            },
            buttons: [],
            searchErrors: {},
            generalError: '',

            listSearch: {},
            listSearchDefault: {},

            listSort: {},
            listSortDefault: {},


            isConfirmEventModal: false,
            isOpenMenuSearch: false,
        };
    },
    mounted: function() {
        for (let fieldName in this.listSearchDefault) {
            let currentValue = JSON.parse(localStorage.getItem(`${this.listName}.search.${fieldName}`) ?? '""')
            if (true === this.empty(currentValue)) {
                this.listSearch[fieldName] = JSON.parse(JSON.stringify(this.listSearchDefault[fieldName]))
            } else {
                this.listSearch[fieldName] = currentValue
            }
        }

        if (this.isLoadAtMantling) {
            this.load()
        }
    },
    methods: {
        makeUrl() {
            this._listUrl = this.globalVar.app.api.url + this.listUrl.string
            for (let listUrlParam in this.listUrl.params) {
                this._listUrl = this._listUrl.replace( '${'+listUrlParam+'}', this.listUrl.params[listUrlParam])
            }
            this._listUrl = this._listUrl + (-1 === this._listUrl.indexOf('?') ? '?' : '&') + (new Date()).getTime()
        },
        load(isLoadSpinnerShow) {
            if (isLoadSpinnerShow === undefined) {
                isLoadSpinnerShow = true
            }
            this.makeUrl()
            this.isLoading = true
            this.beforeLoad()
            this.paginator.currentPage = localStorage[`${this.listName}.paginator.page`] ?? 0;
            if (isLoadSpinnerShow) {
                this.emitter.emit('app', {event: 'spinner.show'})
            }

            let searchData = JSON.parse(JSON.stringify(this.listSearch))
            searchData.page = this.paginator.currentPage

            let sortData = JSON.parse(JSON.stringify(this.listSort))

            let self = this
            window.axios.post(this._listUrl, {search: searchData, sort: sortData})
                .then(response => {
                    if (!self.isOpenMenuSearch) {
                        self.items = response.data.items
                        self.paginator = JSON.parse(JSON.stringify(response.data.paginator))
                        self.buttons = response.data.buttons
                        self.searchErrors = response.data.searchErrors
                        self.generalError = response.data.generalError
                        if (false === self.empty(self.generalError)) {
                            self.errors = JSON.parse(JSON.stringify(self.generalError))
                        }
                        self.afterLoad()
                    }
                })
                .catch((error) => {
                    console.log(error, error.data)
                })
                .finally(() => {
                    this.isLoading = false
                    if (isLoadSpinnerShow) {
                        this.emitter.emit('app', {event: 'spinner.hide'})
                    }
                })
        },
        beforeLoad: function () {

        },
        afterLoad: function() {

        },
        openItem_click(uuid) {
            let route = {name: this.itemNameRoute, params: {uuid: uuid}}
            this.toRoute(route)
        },
        paginatorLinkPage_click(numPage) {
            this.paginator.currentPage = numPage
            localStorage[`${this.listName}.paginator.page`] = numPage
            this.load()
        },
        // Поисковые запросы на текстовое поле
        searchField_input(value, name, isStartLoad) {
            localStorage.setItem(`${this.listName}.paginator.page`, 1)
            localStorage.setItem(`${this.listName}.search.${name}`, JSON.stringify(value))
            this.listSearch[name] = value
            if (false === isStartLoad) {
                this.load()
            }
        },
        // Сортировка
        sortField_change(text, name, isStartLoad) {
            localStorage.setItem(`${this.listName}.paginator.page`, 1)
            this.listSort[name] = text.toLowerCase()
            if (false === isStartLoad) {
                this.load()
            }
        },
        clearSearchData() {
            localStorage.setItem(`${this.listName}.paginator.page`, 1)
            this.listSearch = JSON.parse(JSON.stringify(this.listSearchDefault))
            this.load()
        },




        deleteItem(obj) {
            this.$refs.сonfirmEventModal.updateData({
                text: 'Удалить ' + obj.text + '?',
                btn: 'Удалить',
                style: 'danger',
                data: {event: 'delete', url: obj.url}
            });
            this.isConfirmEventModal = true
            document.body.classList.add('modal-open')
        },
        modalEvent(data) {
            switch (data.event) {
                case 'delete':
                    this.$refs.loadingFullHtml.show();
                    window.axios.delete(data.url).then(() => this.load());
                    break
            }
        },
    },
}
