<template>
  <div class="page-list">
    <page-top-bar
        :title="'Штатная структура'"
        :subtitle="''"
        :buttons="pageButtons"
    />
    <div class="page-list-content">
      <div class="card border-0 shadow">
        <div class="card-body h-25">
          <div class="overflow-x-auto">
          <table class="w-100">
            <tr>
              <td class="text-center"
                  v-for="(ps, i_ps) in items.filter(function(item) {
                  return null === item.parent_personnel_structure_uuid
                })"
                  :key="'ps.' + i_ps">
                <item-personnel-structure
                    :item="ps" :items="items"
                    :is-edit="-1 !== buttons.indexOf('edit')"
                    :click-edit-item="openItem_click"
                />
              </td>
            </tr>
          </table>
          </div>
          <div v-if="-1 !== buttons.indexOf('edit') && 0 === items.length" class="text-center">
            <button
                class="btn btn-primary"
                type="submit"
                @click="openItem_click('new', null)"
            >
              Создать первую должность в штатной структуре
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import PageTopBar from '@/components/PageTopBar'
import ItemPersonnelStructure from '@/views/app/personnelStructure/components/ItemPersonnelStructure'

export default {
  name: 'PersonnelStructuresList',
  components: {ItemPersonnelStructure, PageTopBar},
  mixins: [listMixin],
  data() {
    return {
      listName: 'PersonnelStructuresList',
      listUrl: {string: '/personnel-structures', params: []},
      itemNameRoute: 'personnel-structure',
      pageButtons: [],
    }
  },

  methods: {
    openItem_click(uuid, parentId) {
      if('new' === uuid) {
        localStorage.setItem('PersonnelStructureView.newItem.parentId', parentId)
      }
      let route = {name: this.itemNameRoute, params: {uuid: uuid}}
      this.toRoute(route)
    },
  }
}
</script>

<style scoped>
</style>
