<template>
  <div>
    <div v-if="'' === processUuid">
      <school-planning-process-copy-processes-list :selected-uuid="selectedUuid" />
    </div>

    <div v-if="'' !== processUuid">
      <school-planning-process-copy-progress-form-view
          :current-course-uuid="currentCourseUuid"
          :uuid="processUuid"
          :close="null"
          :update-parent="updateDataCopy"
      />
    </div>
  </div>
</template>

<script>

import SchoolPlanningProcessCopyProcessesList from '@/views/app/school/components/SchoolPlanningProcessCopyProcessesList'
import SchoolPlanningProcessCopyProgressFormView
  from '@/views/app/school/components/SchoolPlanningProcessCopyProgressFormView'

export default {
  name: 'SchoolPlanningProcessCopyProcessView',
  components: {SchoolPlanningProcessCopyProgressFormView, SchoolPlanningProcessCopyProcessesList},
  props: {
    currentCourseUuid: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    loadData: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      processUuid: '',
    }
  },
  mounted() {

  },
  methods: {
    selectedUuid: function (uuid) {
      this.processUuid = uuid
      this.loadData(this.processUuid, {
        relationUsers: [],
        students: [],
      })
    },
    updateDataCopy: function (data) {
      this.loadData(this.processUuid, data)
    }
  }
}
</script>

<style scoped>
</style>
