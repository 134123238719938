<template>
  <div>
    <base-modal
        :show="show"
        :is-bg-click-close="false"
        title=""
        :footer="true"
        :header="false"
        @close="close"
        :is-global-close="isGlobalClose"
        >
      <template v-slot:body>
        <div class="my-2">
        <vue-date-wheel
            :layout="isTime ? [['day', 'month', 'year'], ['hour', 'minute']] : [['day', 'month', 'year']]"
            v-model="date"
            :min-year="(new Date()).getFullYear() - 10"
            :max-year="(new Date()).getFullYear() + 20"
            :locale="locale"
        />
        </div>

        <div class="mb-1">&nbsp;</div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="modalOk_click">OK</a>
        <a class="btn btn-dark sm-w-100 mt-1 ms-sm-2" @click="modalClear_click">Очистить</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="close">Отмена</a>
      </template>
    </base-modal>
    <div
        class="form-control cursor-pointer position-relative"
        :class="className"
        :data-disabled="disabled"
    >
      <div class="multiselect-indicator text-secondary"><i class="fa-solid fa-calendar-days"></i></div>
      <span> {{ label }}</span>
      <div class="btn-click-input-open-model" @click="openModel"></div>
      <div v-if="!empty(label) && !disabled" class="btn-clear-input" @click="modalClear_click">
        <div class="btn btn-very-sm btn-dark"><i class="fa-solid fa-broom"></i></div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseModal from '../BaseModal'

export default {
  name: 'DateTimePickerInput',
  components: {BaseModal},
  props: {
    modelValue: {
      type: String,
    },
    isTime: {
      type: Boolean,
      default: false,
    },
    className: {
      default: {},
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    isGlobalClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      date: new Date(),
      label: '',

      locale: {
        datetime: {
          year: 'Год',
          month: 'Месяц',
          day: 'День',
          hour: 'Часы',
          minute: 'Минуты',
          second: 'Секунды',
        },
        calendar: {
          gregory: {
            monthNames: [
              'Январь',
              'Февраль',
              'Март',
              'Апрель',
              'Май',
              'Июнь',
              'Июль',
              'Август',
              'Сентябрь',
              'Октябрь',
              'Ноябрь',
              'Декабрь'
            ]
          },
        }
      },
    }
  },
  watch: {
    modelValue: function () {
      this.setValue()
    },
    isTime: function () {
      this.setValue()
    },
  },
  mounted: function() {
    this.setValue()
  },
  methods: {
    setValue: function () {
      this.date = new Date()

      if (null !== this.modelValue) {
        this.date = new Date(this.modelValue)
      }

      if (true === this.isTime) {
        this.date.setSeconds(0, 0)
        if (0 === this.date.getHours()) {
          this.date.setHours(12)
        }
      } else {
        this.date.setHours(12, 0, 0, 0)
      }

      this.setLabel(this.modelValue)
    },
    setLabel: function (modelValue) {
      if (null === modelValue) {
        this.label = ''
      } else {
        let dt = (9 < this.date.getDate() ? '' : '0') + this.date.getDate() + '.'
            + (8 < this.date.getMonth() ? '' : '0') + (this.date.getMonth() + 1) + '.'
            + this.date.getFullYear()

        if (true === this.isTime) {
          this.label = (9 < this.date.getHours() ? '' : '0') + this.date.getHours()
              + ':' + (9 < this.date.getMinutes() ? '' : '0') + this.date.getMinutes()
              //+ ':' +(9 < this.date.getSeconds() ? '' : '0') +  this.date.getSeconds()
              + ' ' + dt
        } else {
          this.label = dt
        }
      }
    },
    openModel: function () {
      if (false === this.disabled) {

        this.show = true
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    modalOk_click() {
      let date = this.getDateToStringYmdHis(this.date.date)

      let self = this
      setTimeout(function () {
        self.$emit('update:modelValue', date)
      }, 20)

      this.close()
    },
    modalClear_click() {
      this.$emit('update:modelValue', null)
      this.close()
    },
    close: function () {
      this.show = false
      if (true === this.isGlobalClose) {
        this.emitter.emit('modal', {event: 'close'})
      }
    },
  }
}
</script>

<style>
.vdw-row:not(:last-child) {
  margin-bottom: 6vh;
}
.vdw-row .selector .label {
  font-size: 20px;
}
</style>

<style scoped>
.form-control[data-disabled="true"] {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control {
  min-height: 38px;
  padding-left: 40px;
}

.multiselect-indicator {
  position: absolute;
  top: 6px;
  left: 10px;
}

.btn-clear-input {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  margin-top: 4px;
  z-index: 2;
}

.btn-click-input-open-model {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
</style>
