<template>
  <div>
    <span v-show="'asc' === currentSort"><i class="fa-solid fa-arrow-down-short-wide"></i></span>
    <span v-show="'desc' === currentSort"><i class="fa-solid fa-arrow-down-wide-short"></i></span>
      <a href="#" @click="sort_click">{{ inputName }}</a>
  </div>
</template>

<script>
export default {
  name: 'SortInput',
  props: {
    nameInput: {
      type: String,
      required: true,
    },
    nameField: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    changeSort: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentSort: '',
    }
  },
  mounted: function() {
    this.currentSort = localStorage.getItem(this.nameInput) ?? ''
    let self = this
    self.changeSort(this.currentSort, this.nameField, true)
  },
  methods: {
    sort_click: function() {
      if ('' === this.currentSort) {
        localStorage.setItem(this.nameInput, 'asc')
      } else if ('asc' === this.currentSort) {
        localStorage.setItem(this.nameInput, 'desc')
      } else {
        localStorage.removeItem(this.nameInput)
      }
      this.currentSort = localStorage.getItem(this.nameInput) ?? ''
      let self = this
      self.changeSort(this.currentSort, this.nameField, false)
    },
  },
}
</script>

<style scoped>
.search-input {
  position: relative;
}

.search-input .search-input-icon {
  position: absolute;
  left: 10px;
  top: 7px;
  color: rgba(var(--bs-secondary-rgb), 0.8);
}

.search-input .form-control {
  padding-left: 35px;
}

.search-input {
  background: var(--app-page-title-search-bg);

  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.search-input.text-enter {
  margin-right: 45px;
}

.clear-search {
  position: absolute;
  display: none;
  right: -45px;
  top: 0;
}

.search-input.text-enter .clear-search {
  display: initial;
}
</style>
