<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <base-modal title="Должности" :show="isListPersonnelStructuresModel" :footer="true" @close="close">
      <template v-slot:body>
        <div>
          <ul class="list-group mt-1 mb-4">
            <li class="list-group-item cursor-pointer"
                v-for="(item, i_item) in data.listPersonnelStructures"
                :key="'o.' + i_item"
                :class="{'d-none': isActiveListPersonnelStructures(item.uuid)}"
                @click="itemListPersonnelStructure_click(item.uuid)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="close">ОК</a>
      </template>
    </base-modal>
    <page-top-bar
        :title="title"
        :subtitle="'Карточка события'"
        :buttons="buttons"
    />
    <div class="card border-0 shadow mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div v-if="!inArray('name', enabledFields)" class="fw-bold fs-20px">
                {{ data.name }}
              </div>
              <div v-if="inArray('name', enabledFields)">
                <label class="form-label">Наименование</label>
                <input type="text" class="form-control" placeholder="Обсудить отсутствие сладостей в офисе"
                       v-model="data.name"
                       :class="{ 'is-invalid': !!errors['name'] }"
                >
                <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
              </div>
            </div>
            <div class="mt-3">
              <div v-if="!inArray('url', enabledFields) && !empty(data.url)">
                <a target="_blank" :href="data.url">Ссылка</a>
                <div class="text-secondary fs-10px">{{ data.url }}</div>
              </div>
              <div v-if="inArray('url', enabledFields)">
                <label class="form-label">
                  url
                  <span class="text-secondary fs-10px ms-1">по необходимости</span>
                </label>
                <input type="text" class="form-control" placeholder="https://avicrm.ru"
                       v-model="data.url"
                       :class="{ 'is-invalid': !!errors['url'] }"
                >
                <div class="text-danger" v-if="!!errors['url']">{{ errors['url'].join(', ') }}</div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-5">
                <label class="form-label">Начало в</label>
                <div class="input-group">
                  <input type="time" placeholder="00:00" class="form-control"
                         v-model="data.time"
                         :disabled="!inArray('time', enabledFields)"
                         :class="{ 'is-invalid': !!errors['time'] }"
                  />
                  <button v-if="!empty(data.time)" class="btn btn-outline-dark" type="button" @click="data.time = null">
                    <i class="fas fa-broom"></i>
                  </button>
                </div>
                <div class="text-danger" v-if="!!errors['time']">{{ errors['time'].join(', ') }}</div>
              </div>
              <div class="col-sm-7">
                <label class="form-label">Продолжительность</label>
                <input type="number" step="1" class="form-control" placeholder="60"
                       :disabled="!inArray('duration_in_minutes', enabledFields)"
                       :class="{ 'is-invalid': !!errors['duration_in_minutes'] }"
                       v-model="data.duration_in_minutes"
                >
                <div><b>в минутах</b></div>
                <div class="text-danger" v-if="!!errors['duration_in_minutes']">{{ errors['duration_in_minutes'].join(', ') }}</div>
              </div>
            </div>
            <div v-if="inArray('interval', enabledFields)">
              <label class="form-label">Интервал повторения</label>
              <multiselect-input
                  v-model="data.interval"
                  :disabled="!inArray('interval', enabledFields)"
                  :class-name="{ 'is-invalid': !!errors['interval'] }"
                  :list="data.listIntervals"
              />
              <div class="text-danger" v-if="!!errors['interval']">{{ errors['interval'].join(', ') }}</div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mt-3 mt-sm-0">
              <label class="form-label">Начальная дата</label>
              <date-picker-input v-model="data.start_at" :disabled="!inArray('start_at', enabledFields)"/>
              <div class="text-danger" v-if="!!errors['start_at']">{{ errors['start_at'].join(', ') }}</div>
              <div class="text-secondary fs-12px">
                Укажите стартовую дату генерации события
              </div>
            </div>
            <div class="mt-3">
              <label class="form-label">
                Конечная дата
                <span class="text-secondary fs-10px ms-1">по необходимости</span>
              </label>
              <date-picker-input v-model="data.end_at" :disabled="!inArray('end_at', enabledFields)"/>
              <div class="text-danger" v-if="!!errors['end_at']">{{ errors['end_at'].join(', ') }}</div>
              <div class="text-secondary fs-12px">
                Ограничение генерации события по дате
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="mt-3">
              <div v-if="inArray('description', enabledFields) || !empty(data.description)">
                <label class="form-label">
                  Пояснительная записка
                  <span v-if="inArray('description', enabledFields)" class="text-secondary fs-10px ms-1">по необходимости</span>
                </label>
                <textarea class="form-control textarea-description"
                          placeholder="1. узнать ответственного, 2. обязать закупать сладости"
                          v-model="data.description"
                          :disabled="!inArray('description', enabledFields)"
                          :class="{ 'is-invalid': !!errors['description'], 'fs-12px': !inArray('description', enabledFields) }"
                />
                <div class="text-danger" v-if="!!errors['description']">{{ errors['description'].join(', ') }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="mt-3">
              <label class="form-label">
                Участники события
                <a v-if="inArray('personnelStructures', enabledFields)" @click="openPersonnelStructuresModel" type="button" class="btn btn-sm btn-link">
                  <i class="fa-solid fa-plus"></i>
                </a>
              </label>
              <div v-if="inArray('personnelStructures', enabledFields) && empty(data.personnelStructures)" class="text-secondary">
                <b>Обратите внимание!</b> на данной форме вы добавляете должности.
                Пользователи будут добавлены в конкретное событие, согласно занимаемым должностям, в момент его генерации
              </div>
              <div
                  v-for="(userUuid, i_userUuid) in data.personnelStructures"
                  :key="'u.i.' + i_userUuid"
                  class="px-2"
              >
                {{ personnelStructureName(userUuid) }}
                <a v-if="inArray('personnelStructures', enabledFields)"
                    @click="itemRelationPersonnelStructures_click(i_userUuid)" type="button" class="text-danger ms-2">
                  <i class="fa-solid fa-trash"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import BaseModal from '@/components/BaseModal'
import DatePickerInput from '@/components/form/DatePickerInput'
import MultiselectInput from '@/components/form/MultiselectInput'

export default {
  name: 'EventRecurringView',
  components: {MultiselectInput, DatePickerInput, BaseModal, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      isConfirmDeleteModal: false,

      data: {
        interval: null,
        time: null,
        start_at: null,
        end_at: null,
        duration_in_minutes: null,
        name: '',
        description: '',
        url: '',
        personnelStructures: [],
        listIntervals: [],
        listPersonnelStructures: [],
      },

      formName: 'event-recurring',
      formUrl: {string: '/event-recurring?uuid=${uuid}'},
      editRoute: {name:  'event-recurring', params: {uuid: 0}},
      exitRoute: {name: 'event-recurrings'},

      title: '<i class="fa-solid fa-gear"></i> Регулярное событие',

      isListPersonnelStructuresModel: false,
      typeRelationUser: '',
    }
  },
  unmounted: function() {
    clearInterval(this.timer)
  },
  methods: {
    personnelStructureName: function(uuidUser) {
      for (let i in this.data.listPersonnelStructures) {
        if (uuidUser === this.data.listPersonnelStructures[i].uuid) {
          return this.data.listPersonnelStructures[i].name
        }
      }
      return ''
    },
    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    openPersonnelStructuresModel: function () {
      this.isListPersonnelStructuresModel = true
      this.emitter.emit('modal', {event: 'open'})
    },

    isActiveListPersonnelStructures: function(uuid) {
      return this.inArray(uuid, this.data.personnelStructures)
    },
    itemListPersonnelStructure_click: function(uuid) {
      let strUuid = uuid + ''
      if (true === this.isActiveListPersonnelStructures(strUuid)) {
        this.data.personnelStructures = this.data.personnelStructures.filter(function (userUuid) {
          return userUuid !== strUuid
        })
      } else {
        this.data.personnelStructures.push(strUuid)
      }

      this.close()
    },
    itemRelationPersonnelStructures_click: function (i) {
      this.data.personnelStructures.splice(i, 1)
    },
    close: function () {
      this.isConfirmDeleteModal = false
      this.isListPersonnelStructuresModel = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.textarea-description {
  height: 100%;
  min-height: 300px;
}
</style>
