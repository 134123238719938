<template>
  <div class="card border-0 shadow card-calendar">
    <div class="card-body">
      <div  v-if="isLoading" class="spinner-border spinner-border-sm text-primary position-absolute end-0 top-0 m-3" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
      <div class="row">
        <div class="col-12 px-0" v-for="(month, i_month) in data" :key="'odcm.' + i_month">
          <div class="text-center fs-0_84 text-secondary position-relative">
            <button class="btn btn-link position-absolute start-0 top-0"
                    v-if="selectDate !== getDateToStringYmd(new Date())"
                    @click="selectNowDate">
              <i class="fa-solid fa-arrow-rotate-left"></i>
            </button>
            <button class="btn btn-link" @click="editMonth(-1)"><i class="fa-solid fa-chevron-left"></i></button>
            {{month.monthName}} {{month.year}}
            <button class="btn btn-link" @click="editMonth(1)"><i class="fa-solid fa-chevron-right"></i></button>
          </div>
          <table class="month m-auto">
            <thead>
            <tr>
              <th class="">Пн</th>
              <th class="">Вт</th>
              <th class="">Ср</th>
              <th class="">Чт</th>
              <th class="">Пт</th>
              <th class="text-secondary">Сб</th>
              <th class="text-secondary">Вс</th>
            </tr>
            </thead>
            <tr class="" v-for="(week, i_week) in month.days" :key="'odcm.' + i_month + '.w.' + i_week">
              <td :class="{'text-secondary': i_day === 5 || i_day === 6}" v-for="(day, i_day) in week" :key="'odcm.' + i_month + '.w.' + i_week + '.d.' + i_day">
                <div class="day" @click="selectCalendarDay(day)"
                     :class="{'day-now': day.now, 'day-selected': selectDate === day.date, 'in-circle-item-8': !empty(day.data) }">
                  <div v-for="(dataItem, i_dataItem) in day.data"
                       :key="'odcm.' + i_month + '.w.' + i_week + '.d.' + i_day + '.dtw.' + i_dataItem"
                       :class="{'d-none': 0 < i_dataItem}"
                       class="item">
                    <div class="data-item"></div>
                  </div>
                  {{day.day}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DashboardCalendarList',
  props: {
    selectionDay: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,

      month: null,
      year: null,

      selectDate: null,
      selectDay: {
        date: null,
        now: false,
        day: null,
        weekN: null,
        weekNName: '',
        data: []
      },

      data: [],
    }
  },
  mounted() {
    this.selectDate = localStorage.getItem('DashboardCalendarList.selectDate')
    if (this.empty(this.selectDate)) {
      let now = new Date()

      this.selectDate = this.getDateToStringYmd(now)
      this.month = now.getMonth() + 1
      this.year = now.getFullYear()
    } else {
      this.month = this.selectDate.substr(5,2) * 1
      this.year = this.selectDate.substr(0,4) * 1
    }

    this.loadCalendar()
    this.emitter.on('dashboardCalendarList', this.emitterDashboardCalendarList_reload)
  },
  unmounted: function() {
    this.emitter.off('dashboardCalendarList', this.emitterDashboardCalendarList_reload)
  },
  methods: {
    emitterDashboardCalendarList_reload: function () {
      this.loadCalendar()
    },

    loadCalendar: function () {
      let self = this
      self.isLoading = true
      window.axios.post(
          `${this.globalVar.app.api.url}/dashboard-calendar?${(new Date()).getTime()}`,
          {month: this.month, year: this.year}
      )
          .then(response => {
            if (!self.isOpenMenuSearch) {
              self.data = response.data
              for(let m in response.data) {
                for(let w in response.data[m].days) {
                  for(let d in response.data[m].days[w]) {
                    if (this.selectDate === response.data[m].days[w][d].date) {
                      self.selectCalendarDay(response.data[m].days[w][d])
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error, error.data)
          })
          .finally(() => {
            self.isLoading = false
              self.emitter.emit('app', {event: 'spinner.hide'})
          })
    },

    editMonth: function (interval) {
      this.month += interval
      if (this.month > 12) {
        this.month = 1
        this.year += 1
      }

      if (this.month < 1) {
        this.month = 12
        this.year -= 1
      }

      this.loadCalendar()
    },

    selectCalendarDay: function (day) {
      localStorage.setItem('DashboardCalendarList.selectDate', day.date)
      this.selectDate = day.date
      this.selectDay = day
      this.selectionDay(day)
    },

    selectNowDate: function () {
      let now = new Date()

      this.selectDate = this.getDateToStringYmd(now)
      this.month = now.getMonth() + 1
      this.year = now.getFullYear()

      this.loadCalendar()
    }
  }
}
</script>

<style scoped>
.card-calendar {
  min-height: 231px;
}

.day {
  position: relative;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 20px;
  text-align: center;
}

.day.day-selected {
  background: var(--app-calendar-day-selected);
}

.day-now {
  background: var(--app-calendar-day-now);
}

.day-now.day-selected {
  background: var(--app-calendar-day-now-selected);
}

.data-item {
  background: rgb(var(--bs-secondary-rgb))!important;
  font-size: 8px;
  text-align: center;
}

.in-circle-item-8 .item {
  position: absolute;
  left: 40%;
  top: 0;
  height: 100%;
  width: 20%;
  opacity: 0;
}

.in-circle-item-8 .item div {
  margin: 0 auto;
  height: 6px;
  width: 6px;
  border-radius: 3px;
}

.in-circle-item-8 > .item:nth-child(1){
  opacity: 1;
}

.in-circle-item-8 > .item:nth-child(2){
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.in-circle-item-8 > .item:nth-child(3){
  opacity: 1;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.in-circle-item-8 > .item:nth-child(4){
  opacity: 1;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.in-circle-item-8 > .item:nth-child(5){
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.in-circle-item-8 > .item:nth-child(6){
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.in-circle-item-8 > .item:nth-child(7){
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.in-circle-item-8 > .item:nth-child(8){
  -webkit-transform: rotate(315deg);
  -moz-transform: rotate(315deg);
  -o-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}
</style>
