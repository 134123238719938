<template>
    <section class="content">
        <slot/>
    </section>
</template>

<script>
export default {
    name: "AuthLayout",
}
</script>

<style scoped>

</style>
