<template>
  <div>
    <base-modal
        :show="show"
        :is-bg-click-close="true"
        title=""
        :footer="true"
        :header="false"
        @close="close"
        :is-global-close="true"
    >
      <template v-slot:body>
        <div class="my-3 text-start">
          <label class="form-label">Начальная дата</label>
          <date-time-picker-input v-model="dateStart" :is-global-close="false" :is-time="isTimeDateStart" />
          <div>
            <input type="checkbox" v-model="isTimeDateStart"
                   @change="setDateStart"
                   class="form-check-input" id="isTimeDateStart">
            <label class="form-check-label" for="isTimeDateStart">С установкой времени</label>
          </div>
        </div>
        <div class="my-3 text-start">
          <label class="form-label">Конечная дата</label>
          <date-time-picker-input v-model="dateEnd" :is-global-close="false" :is-time="isTimeDateEnd" />
          <div>
            <input type="checkbox" v-model="isTimeDateEnd"
                   @change="setDateEnd"
                   class="form-check-input" id="isTimeDateEnd">
            <label class="form-check-label" for="isTimeDateEnd">С установкой времени</label>
          </div>
        </div>
        <div class="mb-1">&nbsp;</div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="close">ОК</a>
      </template>
    </base-modal>
    <div class="search-input" :class="{'text-enter': isInput}">
      <div class="search-input-icon"><i class="fa-solid fa-magnifying-glass"></i></div>
      <div class="form-control cursor-pointer fs-12px input-search-date-range" :class="{'input-not-full-date': empty(dateStart) || empty(dateEnd)}" @click="openModel">
          <div v-if="!empty(dateStart)">
            <span class="text-secondary">c: </span>
            {{ stringDateYmdhisToHidmy(dateStart, isTimeDateStart) }}
          </div>
          <div v-if="!empty(dateEnd)">
            <span class="text-secondary">по: </span>
            {{ stringDateYmdhisToHidmy(dateEnd, isTimeDateEnd)  }}
          </div>
      </div>
      <a class="clear-search btn btn-primary" @click="clearSearch">
        <i class="fa-solid fa-magnifying-glass-minus"></i>
      </a>
    </div>
  </div>
</template>

<script>
import DateTimePickerInput from '@/components/form/DateTimePickerInput'
import BaseModal from '@/components/BaseModal'

export default {
  name: 'SearchInputDateRange',
  components: {BaseModal, DateTimePickerInput},
  props: {
    nameInput: {
      type: String,
      required: true,
    },
    nameField: {
      type: String,
      required: true,
    },
    inputDate: {
      type: Object,
      required: true,
    },
  },
  watch: {
    dateStart: function () {
      this.isChangeDates = true
    },
    dateEnd: function () {
      this.isChangeDates = true
    },
  },
  data() {
    return {
      isChangeDates: false,
      show: false,
      isInput: false,
      dateStart: null,
      isTimeDateStart: false,
      dateEnd: null,
      isTimeDateEnd: false,
    }
  },
  mounted: function() {
    this.isChangeDates = false
    let self = this

    let dataDates = JSON.parse(localStorage.getItem(self.nameInput) ?? '[]')

    this.dateStart = dataDates[0] ?? null
    this.dateEnd = dataDates[1] ?? null

    let isTimeDataDates = JSON.parse(localStorage.getItem(self.nameInput + '.isTime') ?? '[]')

    this.isTimeDateStart = isTimeDataDates[0] ?? false
    this.isTimeDateEnd = isTimeDataDates[1] ?? false

    this.setInputDate()
    this.isChangeDates = true
  },
  methods: {
    openModel: function () {
      this.isChangeDates = false
      this.show = true
      this.emitter.emit('modal', {event: 'open'})
    },
    setInputDate() {
      let self = this

      if (false === this.empty(this.dateStart) || false === this.empty(this.dateEnd)) {
        this.isInput = true
        self.inputDate([this.dateStart, this.dateEnd], this.nameField, !this.isChangeDates)
        localStorage.setItem(self.nameInput + '.isTime', JSON.stringify([this.isTimeDateStart, this.isTimeDateEnd]))
      } else {
        this.isInput = false
        self.inputDate([], this.nameField, !this.isChangeDates)
        localStorage.setItem(self.nameInput + '.isTime', JSON.stringify([]))
      }
    },
    setDateStart() {
      if (null !== this.dateStart) {
        let date = new Date(this.dateStart)
        date.setHours(8, 0,0,0)

        this.dateStart = true === this.isTime
            ? this.getDateToStringYmdHis(date)
            : this.getDateToStringYmd000(date)
      }
    },
    setDateEnd() {
      if (null !== this.dateEnd) {
        let date = new Date(this.dateEnd)
        date.setHours(8, 0,0,0)

        this.dateEnd = true === this.isTime
            ? this.getDateToStringYmdHis(date)
            : this.getDateToStringYmd000(date)
      }
    },
    clearSearch() {
      this.dateStart = null
      this.dateEnd = null

      this.setInputDate()
    },
    close: function () {
      this.setInputDate()

      this.show = false
      this.emitter.emit('modal', {event: 'close'})
    },
  },
}
</script>

<style scoped>
.form-control {
  min-height: 38px;
}

.search-input {
  position: relative;
}

.search-input .search-input-icon {
  position: absolute;
  left: 10px;
  top: 7px;
  color: rgba(var(--bs-secondary-rgb), 0.8);
}

.search-input .form-control {
  padding-left: 35px;
}

.search-input {
  background: var(--app-page-title-search-bg);

  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.search-input.text-enter {
  margin-right: 45px;
}

.clear-search {
  position: absolute;
  display: none;
  right: -45px;
  top: 0;
}

.search-input.text-enter .clear-search {
  display: initial;
}

.input-search-date-range {
  min-width: 174px;
}

.input-not-full-date {
  line-height: 24px;
}

.form-control:not(.input-not-full-date) {
  min-height: 38px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
