//import {functionsMixin} from '@/mixins/functionsMixin'

export const globalMixin = {
  mounted: function() {
    window.addEventListener('online', this.global_lan_updateStatus)
    window.addEventListener('offline', this.global_lan_updateStatus)
    if (null === this.globalVar.app.monitor.globalMixin.app) {
      this.globalVar.app.monitor.globalMixin.app = setInterval(() => {
        if (true === this.globalVar.app.lan.status) {
          this.db_updateDB()
          this.db_sendRequests()
        }
        this.webView_runPush()
        this.webView_regHuaweiToken()
        this.webView_regFreebaseToken()
        this.webView_setSystemColorTheme()
      }, 100)
    }
    if (null === this.globalVar.app.monitor.globalMixin.ping) {
      this.globalVar.app.monitor.globalMixin.ping = setInterval(() => {
        this.global_ping()
      }, 3000)
    }
    if (null === this.globalVar.app.monitor.globalMixin.statusLogin) {
      this.globalVar.app.monitor.globalMixin.statusLogin = setInterval(() => {
        this.global_status_login()
      }, 10000)
    }

    if (false === this.globalVar.dbConfig.isInit) {
      this.db_initDB()
    }
  },
  destroyed() {
    window.removeEventListener('online', this.global_lan_updateStatus)
    window.removeEventListener('offline', this.global_lan_updateStatus)

    clearInterval(this.globalVar.app.monitor.globalMixin.app)
    clearInterval(this.globalVar.app.monitor.globalMixin.ping)
    clearInterval(this.globalVar.app.monitor.globalMixin.statusLogin)
  },
  methods: {
    global_lan_updateStatus: function() {
      let oldStatus =  true === this.globalVar.app.lan.status
      if (typeof window.navigator.onLine === 'undefined') {
        this.globalVar.app.lan.status = true
      } else {
        this.globalVar.app.lan.status = window.navigator.onLine
      }
      if (oldStatus !== this.globalVar.app.lan.status) {
        this.emitter.emit('app', {event: 'status.update'})
      }
    },
    global_ping: function () {
      let oldStatus =  true === this.globalVar.app.lan.status
      this.globalVar.app.lan.time = new Date();
      window.axios.get(`/ping?${this.globalVar.app.lan.time.getTime()}`)
          .then(() => this.globalVar.app.lan.status = true)
          .catch(() => this.globalVar.app.lan.status = false)
          .finally(() => {
            if (oldStatus !== this.globalVar.app.lan.status) {
              this.emitter.emit('app', {event: 'status.update'})
            }
          })
    },
    global_status_login: function() {
      if (true === this.globalVar.app.isStatusAuthChangedUser) {
        this.globalVar.app.isStatusAuthChangedUser = false
      } else {
        let self = this
        window.axios.get(
            `${this.globalVar.app.api.url}/status-login?tz=${this.localTimeZone()}&${(new Date()).getTime()}`,
        )
            .then(response => {
              if (true === self.globalVar.app.isStatusAuthChangedUser) {
                self.globalVar.app.isStatusAuthChangedUser = false
              } else {
                let isChangeAuth = self.globalVar.app.user.isAuth !== response.data.isAuth
                self.globalVar.app.user.isAuth = response.data.isAuth
                self.globalVar.app.user.routes = response.data.routes
                localStorage.user = JSON.stringify(self.globalVar.app.user)

                for(let i in response.data.pushes) {
                  self.app_addPush(response.data.pushes[i])
                }

                for(let storeName in self.globalVar.dbConfig.stores) {
                  if (-1 !== response.data.listUpdate.indexOf(storeName)) {
                    self.globalVar.dbConfig.stores[storeName].isUpdated = true
                  }
                }

                if (true === isChangeAuth) {
                  self.emitter.emit('app', {
                    event:
                        false === self.globalVar.app.user.isAuth ? 'logout' : 'login'
                  })

                  clearInterval(this.globalVar.app.monitor.globalMixin.statusLogin)

                  this.globalVar.app.monitor.globalMixin.statusLogin = setInterval(() => {
                    this.global_status_login()
                  }, 10000)
                }
              }
            })
      }
    },
  }
}
