<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <base-modal title="Пользователи" :show="isListUsersModel" :footer="true" @close="close">
      <template v-slot:body>
        <div>
          <ul class="list-group mt-1 mb-4">
            <li class="list-group-item cursor-pointer"
                v-for="(item, i_item) in data.listUsers"
                :key="'o.' + i_item"
                :class="{'d-none': isActiveListUsers(item.uuid)}"
                @click="itemListUser_click(item.uuid)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="close">ОК</a>
      </template>
    </base-modal>
    <page-top-bar
        :title="title"
        :subtitle="'Задача'"
        :buttons="buttons"
    />
    <div class="card border-0 shadow mb-3">
      <div class="card-body">
        <div class="row mt-3">
          <div class="col-12">
            <div v-if="!inArray('name', enabledFields)" class="fw-bold fs-20px">
              {{ data.name }}
            </div>
            <div v-if="inArray('name', enabledFields)">
              <label class="form-label">Наименование</label>
              <input type="text" class="form-control" placeholder="Подготовить кота к новогодним праздникам"
                     v-model="data.name"
                     :class="{ 'is-invalid': !!errors['name'] }"
              >
              <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
            </div>
          </div>
        </div>

        <div v-if="!inArray('deadline_at', enabledFields)" class="row mt-3">
          <div class="col-sm-3 text-secondary">Крайняя дата</div>
          <div class="col-sm-9">
            {{ stringDateYmdhisToHidmy(data.deadline_at, data.is_time_in_deadline_at) }}
            <span  v-if="!inArray(data.status, ['aside', 'cancel', 'done'])" class="badge" :class="isValidDate(data.deadline_at) ? 'bg-success': 'bg-danger'">
            {{ intervalDiffDeadlineAt }}
          </span>
          </div>
        </div>
        <div v-if="inArray('deadline_at', enabledFields)"  class="row mt-3">
          <div class="col-sm-4">
            <label class="form-label">Крайняя дата</label>
            <date-time-picker-input
                v-model="data.deadline_at"
                :is-time="data.is_time_in_deadline_at"
            />
            <div class="text-danger" v-if="!!errors['deadline_at']">{{ errors['deadline_at'].join(', ') }}</div>
          </div>

          <div class="col-sm-4">
            <div class="form-control-checkbox">
              <input type="checkbox" v-model="data.is_time_in_deadline_at"
                     @change="setDeadlineAt"
                     class="form-check-input" id="taskIsTimeInDeadlineAt">
              <label class="form-check-label" for="taskIsTimeInDeadlineAt">С установкой времени</label>
            </div>
            <div class="text-danger" v-if="!!errors['is_time_in_deadline_at']">{{ errors['is_time_in_deadline_at'].join(', ') }}</div>
          </div>
          <div v-if="!empty(data.deadline_at)" class="col-sm-4">
            <label class="form-label">Время на выполнение</label>
            <div>
              <div class="badge" :class="isValidDate(data.deadline_at) ? 'bg-success': 'bg-danger'">
                {{ intervalDiffDeadlineAt }}
              </div>
            </div>
          </div>
        </div>

        <div v-for="(userType, i_userType) in [
          {name: 'performer', title: 'Исполнитель(и)'},
          {name: 'inspector', title: 'Контроль исполнения'},
          {name: 'spectator', title: 'Наблюдатель(и)'},
        ]" :key="'user.type.' + i_userType">
          <div v-if="!inArray('relationUsers', enabledFields)" class="row mt-3">
            <div class="col-sm-3 text-secondary">{{ userType.title }}</div>
            <div class="col-sm-9">
              <div
                  v-for="(user, i_user) in data.relationUsers"
                  :key="'u.i.' + i_user"
                  :class="{'d-none': userType.name !== user.type}"
              >
                {{ userName(user.uuid) }}
              </div>
            </div>
          </div>
          <div v-if="inArray('relationUsers', enabledFields)" class="row mt-3">
            <div class="col-12 pb-1">
              <label class="form-label">
                {{ userType.title }}
                <a @click="openUsersModel(userType.name)" type="button" class="btn btn-sm btn-link">
                  <i class="fa-solid fa-plus"></i>
                </a>
              </label>
              <div
                  v-for="(user, i_user) in data.relationUsers"
                  :key="'u.i.' + i_user"
                  class="px-2"
                  :class="{'d-none': userType.name !== user.type}"
              >
                {{ userName(user.uuid) }}
                <a @click="itemRelationUsers_click(i_user)" type="button" class="text-danger ms-2">
                  <i class="fa-solid fa-trash"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div v-if="!inArray('description', enabledFields)" class="col-12 mt-2">
            <div v-html="data.description"></div>
          </div>
          <div v-if="inArray('description', enabledFields)" class="col-12 pb-1">
            <label class="form-label">Описание</label>
            <div>
              <quill-editor
                  v-model:value="data.description"
                  :options="{placeholder: 'Вставьте содержимое сюда …'}"
              ></quill-editor>
            </div>
            <div class="text-danger" v-if="!!errors['description']">{{ errors['description'].join(', ') }}</div>
          </div>
        </div>

        <div v-if="!empty(data.uuid)" class="row mt-3">
          <div class="col-12">
            <ul class="nav nav-underline mb-3" id="taskTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages-tab-pane" type="button" role="tab" aria-controls="messages-tab-pane" aria-selected="true">
                  <i class="fa-regular fa-comment"></i> Комментарии
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="file-tab" data-bs-toggle="tab" data-bs-target="#file-tab-pane" type="button" role="tab" aria-controls="file-tab-pane" aria-selected="false">
                  <i class="fa-regular fa-file"></i> Файлы
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="hist-tab" data-bs-toggle="tab" data-bs-target="#hist-tab-pane" type="button" role="tab" aria-controls="hist-tab-pane" aria-selected="false">
                  <i class="fa-solid fa-clock-rotate-left"></i> История
                </button>
              </li>
            </ul>
            <div class="tab-content my-1" id="taskTabContent">
              <div class="tab-pane fade show active" id="messages-tab-pane" role="tabpanel" aria-labelledby="messages-tab" tabindex="0">
                <task-messages-list :task-uuid="this.data.uuid" />
              </div>
              <div class="tab-pane fade" id="file-tab-pane" role="tabpanel" aria-labelledby="file-tab" tabindex="0">
                <task-files-list :task-uuid="this.data.uuid" />
              </div>
              <div class="tab-pane fade" id="hist-tab-pane" role="tabpanel" aria-labelledby="hist-tab" tabindex="0">
                hist
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import DateTimePickerInput from '@/components/form/DateTimePickerInput'
import { quillEditor } from 'vue3-quill'
import BaseModal from '@/components/BaseModal'
import TaskMessagesList from '@/views/app/task/components/TaskMessagesList'
import TaskFilesList from '@/views/app/task/components/TaskFilesList'

export default {
  name: 'TaskView',
  components: {
    TaskFilesList,
    TaskMessagesList, BaseModal, DateTimePickerInput, ConfirmActionModal, PageTopBar, quillEditor},
  mixins: [formMixin],
  data() {
    return {
      isConfirmDeleteModal: false,

      data: {
        deadline_at: null,
        relationUsers: [],
      },

      formName: 'task',
      formUrl: {string: '/task?uuid=${uuid}'},
      editRoute: {name:  'task', params: {uuid: 0}},
      exitRoute: {name: 'tasks'},

      title: 'Статус: ',
      intervalDiffDeadlineAt: '',

      isListUsersModel: false,
      typeRelationUser: '',

      timer: null,
    }
  },
  mounted: function() {
    let self = this
    this.timer = setInterval(function () {
      self.setIntervalDiffDeadlineAt()
    }, 1000)
  },
  unmounted: function() {
    clearInterval(this.timer)
  },
  methods: {
    setIntervalDiffDeadlineAt: function () {
      if (false === this.empty(this.data.deadline_at)) {
        this.intervalDiffDeadlineAt = this.intervalDiffBetweenDate(this.data.deadline_at)
      } else {
        this.intervalDiffDeadlineAt = ''
      }
    },
    statusName: function() {
      for (let i in this.globalVar.db.taskStatuses) {
        if (this.data.status === this.globalVar.db.taskStatuses[i].uuid) {
          return this.globalVar.db.taskStatuses[i].name
        }
      }
      return ''
    },
    userName: function(uuidUser) {
      for (let i in this.data.listUsers) {
        if (uuidUser === this.data.listUsers[i].uuid) {
          return this.data.listUsers[i].name
        }
      }
      return ''
    },
    afterLoad: function () {
      this.setIntervalDiffDeadlineAt()
      this.title = `Статус: <span class="badge task-bg-${this.data.status}">${this.statusName()}</span>`

      let self = this
      let buttons = []

      for(let i in self.globalVar.db.taskStatuses) {
        let status = self.globalVar.db.taskStatuses[i]
        if (self.data.status === status.uuid) {
          for(let ni in self.data.listNewStatuses) {
            let newStatus = self.data.listNewStatuses[ni]
            buttons.push({
              name: status.param.titleButtonToStatus[newStatus] + '',
              class: 'btn-outline-primary',
              click: function() {
                self.data.status = newStatus + ''
                self.save(false)
                self.emitter.emit('taskMessagesList', {event: 'reload'})
                self.emitter.emit('taskFilesList', {event: 'reload'})
              }
            })
          }
        }
      }

      // Стандартные кнопки отображаем, если есть что изменять
      if (
          this.inArray('deadline_at', this.enabledFields) ||
          this.inArray('is_time_in_deadline_at',this.enabledFields) ||
          this.inArray('name', this.enabledFields) ||
          this.inArray('description', this.enabledFields) ||
          this.inArray('relationUsers', this.enabledFields) ||
          this.inArray('relationTags', this.enabledFields)
      ) {
        for (let i in this.buttons) {
          buttons.push(this.buttons[i])
        }
        this.buttons = buttons
      } else {
        this.buttons = buttons
        this.buttons.push({name: 'Назад', class: 'btn-outline-secondary', click: function() {self.toRoute(self.exitRoute)}})
      }
    },
    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    setDeadlineAt: function () {
      if (null !== this.data.deadline_at) {
        let date = new Date(this.data.deadline_at)
        if (true === this.isTime) {
          date.setSeconds(0, 0)
        } else {
          date.setHours(12, 0, 0, 0)
        }

        this.data.deadline_at = this.getDateToStringYmdHis(date)
      }
    },

    openUsersModel: function (typeRelationUser) {
      this.typeRelationUser = typeRelationUser
      this.isListUsersModel = true
      this.emitter.emit('modal', {event: 'open'})
    },

    isActiveListUsers: function(uuid) {
      for (let i in this.data.relationUsers) {
        if (
            uuid + '' === this.data.relationUsers[i].uuid &&
            this.typeRelationUser === this.data.relationUsers[i].type
        ) {
          return true
        }
      }
      return false
    },
    itemListUser_click: function(uuid) {
      let strUuid = uuid + ''
      if (true === this.isActiveListUsers(strUuid)) {
        let self = this
        this.data.relationUsers = this.data.relationUsers.filter(function (item) {
          return item.uuid !== strUuid && self.typeRelationUser === item.type
        })
      } else {
        this.data.relationUsers.push({
          type: this.typeRelationUser,
          uuid: strUuid,
        })
      }

      this.close()
    },
    itemRelationUsers_click: function (i) {
      this.data.relationUsers.splice(i, 1)
    },
    close: function () {
      this.isConfirmDeleteModal = false
      this.isListUsersModel = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
</style>
