<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        text="Удалить процесс обучения?"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <confirm-action-modal
        :show="isToRunModel"
        :text="'Учебный процесс спланирован и его можно запустить в работу?<div class=\'text-secondary fs-12px\'>Отменить это действие не возможно</div>'"
        :btn-text="'Запустить'" :btn-type="'dark'"
        :confirm-action="processToRun_click"
        @close="close"
    />
    <confirm-action-modal
        :show="student.isExcludeModel"
        :text="'Исключить слушателя ' + student.current.name + '?'"
        :btn-text="'Исключить'" :btn-type="'danger'"
        :confirm-action="excludeStudent_click"
        @close="closeStudent"
    />
    <confirm-action-modal
        :show="student.isIncludeModel"
        :text="'Восстановить слушателя ' + student.current.name + '?'"
        :btn-text="'Восстановить'" :btn-type="'success'"
        :confirm-action="includeStudent_click"
        @close="closeStudent"
    />
    <base-modal title="Расписание" :show="timetable.isModel" :footer="true" @close="close">
      <template v-slot:body>
        <div class="text-start">
          <div class="row text-start mb-2">
            <div class="col-12">
                <label class="form-label">Наименование</label>
                <input class="form-control" placeholder="Урок" type="text" v-model="timetable.current.name">
            </div>
          </div>
          <div class="row text-start mb-2">
            <div class="col-12">
                <label>Время начала</label>
                <div class="input-group">
                  <input type="time" placeholder="00:00" class="form-control" v-model="timetable.current.time" />
                  <button v-if="!empty(timetable.current.time)" class="btn btn-outline-dark" type="button" @click="timetable.current.time = null">
                    <i class="fas fa-broom"></i>
                  </button>
                </div>
            </div>
          </div>
          <div class="row text-start mb-2">
            <div class="col-12">
                <label class="form-label">День недели</label>
                <multiselect-input
                    v-model="timetable.current.weekday"
                    :list="enums.weekdays"
                    :is-global-close="false"
                />
            </div>
          </div>
          <div class="row text-start mb-2">
            <div class="col-12">
                <label class="form-label">Продолжительность мероприятия в минутах</label>
                <input type="number" step="1" class="form-control" placeholder="60"
                       v-model="timetable.current.duration_lesson_in_minutes"
                >
                <div class="fs-12px text-secondary">
                  Укажите продолжительность мероприятия. Например: ваше мероприятие "Урок" и его продолжительность 45 мин
                </div>
            </div>
          </div>
          <div class="row text-start mb-2">
            <div class="col-12">
              <label class="form-label">Типы ответственных</label>
              <ul class="list-group mt-1 mb-4">
                <li class="list-group-item cursor-pointer multiselect-form-list-group-item"
                    v-for="(listRelationUserType, i_listRelationUserType) in data.listRelationUserType"
                    :key="'timetable.current.listRelationUserType.' + i_listRelationUserType"
                    :class="{'active': inArray(listRelationUserType.uuid, timetable.current.school_relation_user_types)}"
                    @click="editTimetable_relation_click(listRelationUserType.uuid)"
                    v-html="listRelationUserType.name"
                />
              </ul>
              <div class="text-secondary fs-12px">
                Выбранные типы в процессе обучения получат возможность выставить значение пункта.
                Дополнительно данные ответственные получат метрику по данному пункту для аналитики
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a v-if="-1 !== timetable.indexCurrent" class="btn btn-danger sm-w-100 mt-1" @click="deleteTimetable">Удалить</a>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="saveTimetable">Зафиксировать</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeTimetable">Отмена</a>
      </template>
    </base-modal>
    <base-modal title="Пользователи" :show="relationUser.isModal" :footer="true" @close="close">
      <template v-slot:body>
        <div>
          <div v-if="0 === relationUser.listUsers.filter(function(user) {return !isActiveListUsers(user.uuid)}).length" class="py-3">
            Нет доступных вариантов
          </div>
          <button class="btn btn-outline-primary w-100 my-1"
              v-for="(item, i_item) in relationUser.listUsers.filter(function(user) {return !isActiveListUsers(user.uuid)})"
              :key="'o.' + i_item"
              @click="itemListUser_click(item.uuid)"
          >
            {{ item.name }}
          </button>
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="close">Отмена</a>
      </template>
    </base-modal>
    <base-modal title="Внимание!" :show="student.isExcludeCrmContractorModel" :footer="true" @close="close">
      <template v-slot:body>
        <div class="text-secondary">
          Вы исключаете последнего слушателя из списка для данного контрагента
        </div>
        <div class="my-3">
          <div class="fs-16px badge bg-light text-bg-light" v-html="studentContractName(student.current.crm_contractor_uuid)"></div>
        </div>
        <div>
          У данного контрагента будет зафиксирован текущий результат реализации, который составляет
          <div class="my-2">
            <span class="fs-16px badge bg-secondary text-bg-secondary"> {{ data.moneyProgress.crmContractors[student.excludeCrmContractorParams.current.uuid].worked }} р.</span>
          </div>
          <div v-if="data.moneyProgress.crmContractors[student.excludeCrmContractorParams.current.uuid].allCount === data.moneyProgress.crmContractors[student.excludeCrmContractorParams.current.uuid].currentCount">
            <b>Обратите внимание</b> у контрагента в списке приобретенных курсов, напротив данного статус реализован будет установлен на "Да".
          </div>
          <div v-if="data.moneyProgress.crmContractors[student.excludeCrmContractorParams.current.uuid].allCount !== data.moneyProgress.crmContractors[student.excludeCrmContractorParams.current.uuid].currentCount">
            <b>Обратите внимание</b> у контрагента, по данному курсу, остаются не реализованные средства в размере <b> {{ data.moneyProgress.crmContractors[student.excludeCrmContractorParams.current.uuid].remains }} р.</b>.
            В связи с этим контрагент не теряет возможность пройти обучение.
          </div>
        </div>
        <div class="text-start px-1">
          <div class="mt-3">
            <b>Дополнительно (при необходимости)</b> контрагенту возможно установить дату приостановки обучения.
            До указанной даты контрагент не будет предлагаться как претендент на новые процесс обучения
          </div>
          <div class="row mt-3">
            <div class="col-sm-6">
              <label class="form-label">Дата, до которой приостановлено обучение</label>
              <date-time-picker-input v-model="student.excludeCrmContractorParams.current.suspend_at" :is-global-close="false" :is-time="false" />
            </div>
            <!--
            <div class="col-sm-6 mt-sm-0 mt-3">
              <input type="checkbox" v-model="student.excludeCrmContractorParams.current.is_current_course" class="form-check-input" id="studentExcludeCrmContractorParamsCurrentIsCurrentCourse">
              <label class="form-check-label" for="studentExcludeCrmContractorParamsCurrentIsCurrentCourse">Применить приостановку только к {{ getValueEnums(data.listSchoolCourses, data.school_course_uuid, 'name') }}</label>
            </div>
            -->
          </div>
          <div class="my-3">
            <label class="form-label">Комментарий</label>
            <input type="text" class="form-control" placeholder="Комментарий"
                   v-model="student.excludeCrmContractorParams.current.suspend_comment"
            >
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-danger sm-w-100 mt-1" @click="excludeCrmContractor_click">Исключить</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeStudent">Отмена</a>
      </template>
    </base-modal>
    <base-modal title="Слушатель" :show="student.isEditModel" :footer="true" @close="close">
      <template v-slot:body>
        <div class="text-start">
          <div class="row text-start mb-2">
            <div class="col-12">
              <div>
                <label class="form-label">Контрагент</label>
                <multiselect-input
                    v-model="student.current.crm_contractor_uuid"
                    :list="data.listCrmContractors"
                    :disabled="true === data.is_run && 'new' !== student.current.uuid"
                    :after-select-option="student_current_setCrmContractUuid"
                    :is-global-close="false"
                />
              </div>
              <div v-if="0 < student.current.contacts.length" class="my-1">
                <div class="text-secondary">
                  Контакты контрагента
                </div>
                <a class="btn btn-very-sm btn-outline-primary w-100 mb-1"
                   @click="student_current_setFields(contact)"
                   v-for="(contact, i_contact) in student.current.contacts"
                   :key="'student.current.contacts.' + i_contact"
                >
                  {{ contact.name }}
                </a>
                <div class="text-secondary fs-12px">
                  У контрагента есть контакты, нажмите на нужный контакт, для автозаполнения полей слушателя
                </div>
              </div>
            </div>
          </div>
          <div class="row text-start mb-2">
            <div class="col-12">
              <div>
                <label class="form-label">ФИО</label>
                <input class="form-control" type="text" v-model="student.current.name">
              </div>
            </div>
          </div>
          <div class="row text-start mb-2">
            <div class="col-12">
              <div>
                <label class="form-label">Email</label>
                <input class="form-control" type="text" v-model="student.current.email">
              </div>
            </div>
          </div>
          <div class="row text-start mb-2">
            <div class="col-12">
              <div>
                <label class="form-label">Телефон</label>
                <input class="form-control" type="text" v-model="student.current.phone">
              </div>
            </div>
          </div>
          <div class="row text-start mb-2">
            <div class="col-12">
              <div>
                <label class="form-label">Комментарий</label>
                <textarea class="form-control"
                          rows="3"
                          placeholder="Тянет до последнего"
                          v-model="student.current.comment"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a v-if="-1 !== student.indexCurrent && (false === data.is_run || 'new' === student.current.uuid)"
           class="btn btn-danger sm-w-100 mt-1" @click="deleteStudent">Удалить</a>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="saveStudent">Зафиксировать</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeStudent">Отмена</a>
      </template>
    </base-modal>
    <base-modal title="Дополнительные параметры пункта" :show="structure.isModel" :footer="true" @close="close">
      <template v-slot:body>
        <div class="text-start">
          <div class="mb-2">
            <b>{{ structure.current.name }}</b>
          </div>
          <div class="mb-3">
            <school-course-item-show-fields-view
                :process-params="data.processParams"
                :is-show-params="true"
                :item="structure.current"
                :list-relation-user-type="data.listRelationUserType"
            />
          </div>
          <div class="mt-2" v-if="structure.current.is_datetime">
            <label class="form-label">Дата мероприятия</label>
            <date-time-picker-input
                v-model="structure.param.day_event_at"
                :is-time="true"
                :is-global-close="false"
            />
          </div>
          <div class="mt-2" v-if="structure.current.is_deadline">
            <label class="form-label">Крайняя дата и время заполнения результата</label>
            <date-time-picker-input
                v-model="structure.param.deadline_at"
                :is-time="true"
                :is-global-close="false"
            />
          </div>
        </div>
        <div class="mt-4 text-start">
          <div class="form-control-checkbox">
            <input type="checkbox" v-model="structure.isSetAutoValues"
                   class="form-check-input" id="isSetAutoValues">
            <label class="form-check-label" for="isSetAutoValues">Автоматически переопределить зависимые даты</label>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="saveStructureItemParam">Зафиксировать</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="close">Отмена</a>
      </template>
    </base-modal>
    <base-modal title="Загрузить данные" :show="copyProcess.isModal" :is-all-screen="true" :footer="true" @close="close">
      <template v-slot:body>
        <school-planning-process-copy-process-view
            :current-course-uuid="data.school_course_uuid"
            :close="close"
            :load-data="loadCopyProcessData"
         />
      </template>
      <template v-slot:footer>
          <a v-if="'' !== copyProcess.processUuid" class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="setCopyProcessData">Загрузить</a>
          <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="close">Закрыть</a>
      </template>
    </base-modal>

    <page-top-bar
        :title="title"
        :subtitle="'Форма планирования процесса обучения'"
        :buttons="buttons"
    />

    <ul v-if="false === data.is_run" class="nav nav-underline mb-3" id="processTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="process-tab" data-bs-toggle="tab" data-bs-target="#process-tab-pane" type="button" role="tab" aria-controls="process-tab-pane" aria-selected="false">
          <i class="fa-solid fa-clipboard-list"></i> Процесс
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="analytics-tab" data-bs-toggle="tab" data-bs-target="#analytics-tab-pane" type="button" role="tab" aria-controls="analytics-tab-pane" aria-selected="false">
          <i class="fa-solid fa-calendar-days"></i> Аналитика свободного времени
        </button>
      </li>
    </ul>

    <div class="tab-content" id="processTabContent">
      <div class="tab-pane fade show active" id="process-tab-pane" role="tabpanel" aria-labelledby="process-tab" tabindex="0">
        <div class="card border-0 shadow mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div>
                  <label class="form-label">Наименование</label>
                  <input type="text" class="form-control" placeholder="Урок"
                         v-model="data.name"
                         :disabled="!inArray('name', enabledFields)"
                         :class="{ 'is-invalid': !!errors['name'] }"
                  >
                  <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
                </div>
                <div>
                  <label class="form-label">Курс</label>
                  <multiselect-input
                      v-model="data.school_course_uuid"
                      :disabled="!inArray('school_course_uuid', enabledFields)"
                      :class-name="{ 'is-invalid': !!errors['school_course_uuid'] }"
                      :list="data.listSchoolCourses"
                      :after-select-option="function() {
                    data.processParams = []
                    data.structure = []
                    data.students = []
                  }"
                  />
                  <div class="text-danger" v-if="!!errors['school_course_uuid']">{{ errors['school_course_uuid'].join(', ') }}</div>
                </div>
                <div class="mt-3">
                  <div v-for="(userType, i_userType) in data.listRelationUserType" :key="'user.type.' + i_userType">
                    <div v-if="!inArray('relationUsers', enabledFields)" class="mt-3">
                      <div class="text-secondary">{{ userType.name }}</div>
                      <div
                          class="ms-2"
                          v-for="(relation, i_relation) in data.relationUsers"
                          :key="'u.i.' + i_relation"
                          :class="{'d-none': userType.uuid !== relation.type}"
                      >
                        {{ userName(relation.user) }}
                      </div>
                    </div>
                    <div v-if="inArray('relationUsers', enabledFields)" class="row mt-3">
                      <div class="col-12 pb-1">
                        <label class="form-label">
                          {{ userType.name }}
                          <a @click="openRelationUsersModel(userType)" type="button" class="btn btn-sm btn-link">
                            <i class="fa-solid fa-plus"></i>
                          </a>
                        </label>
                        <div
                            v-for="(relation, i_relation) in data.relationUsers"
                            :key="'u.i.' + i_relation"
                            class="px-2"
                            :class="{'d-none': userType.uuid !== relation.type}"
                        >
                          {{ userName(relation.user) }}
                          <a @click="itemRelationUsers_click(i_relation)" type="button" class="text-danger ms-2">
                            <i class="fa-solid fa-trash"></i>
                          </a>

                          <div class="mb-2 text-bg-warning px-3 py-1 border-radius-10 fs-12px" v-if="false === data.is_run && false === empty(relation.timetables)">
                            <div class="text-secondary"><b>Внимание!</b> есть пересечение по времени</div>
                            <div v-for="(timetable, i_timetable) in relation.timetables"
                                :key="'relationUser.timetables.' + i_timetable">
                              {{ timetable.name }} <b>{{ timetable.schoolProgressName }}</b> {{ timetable.time }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mt-3 mt-sm-0">
                  <div class="pb-1">
                    <label class="form-label">Расписание</label>
                    <school-process-timetable-view
                        :timetable-open="openTimetable_click"
                        :timetables="data.timetables"
                        :is-edit="inArray('timetables', enabledFields)"
                    />
                    <div class="text-danger" v-if="!!errors['timetables']">{{ errors['timetables'].join(', ') }}</div>
                  </div>
                  <div v-if="inArray('timetables', enabledFields)" class="row mt-2">
                    <div class="col-12 text-end">
                      <button class="btn btn-sm btn-primary" type="submit" @click="openTimetable_click(-1, null)">
                        Добавить
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-3 mt-sm-0">
                  <div v-if="!inArray('description', enabledFields)" class="mt-2">
                    <div v-html="data.description"></div>
                  </div>
                  <div v-if="inArray('description', enabledFields)" class="pb-1">
                    <label class="form-label">Заметки</label>
                    <div>
                      <quill-editor
                          v-model:value="data.description"
                          :options="{placeholder: 'Вставьте содержимое сюда …'}"
                      ></quill-editor>
                    </div>
                    <div class="text-danger" v-if="!!errors['description']">{{ errors['description'].join(', ') }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="fs-16px mt-3 mb-2">
                  Слушатели
                </div>
                <div v-if="true === empty(data.structure)" class="text-center fs-16px text-secondary">
                  <span v-if="true === empty(data.school_course_uuid)">укажите "Курс" и </span>нажмите "Сохранить" для обновления структуры
                </div>
                <div v-if="false === empty(data.structure)" class="overflow-x-auto">
                  <table class="table table-sm table-hover table-striped table-students"
                         :class="{'replace-students': inArray('crmContractorSuspensions', enabledFields)}">
                    <thead>
                    <tr>
                      <th scope="col">Контрагент</th>
                      <th scope="col">Слушатель</th>
                      <th class="" scope="col">Комментарий</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        class="cursor-pointer"
                        :class="{'table-danger': item.is_excluded}"
                        v-for="(item, i_item) in data.students"
                        :key="'students.' + i_item"
                    >
                      <td v-html="studentContractName(item.crm_contractor_uuid)"
                          @click="openStudent_click(i_item, item)"></td>
                      <td @click="openStudent_click(i_item, item)">
                        {{ item.name }}
                        <div class="fs-12px text-secondary">{{ item.email }}</div>
                        <div class="fs-12px text-secondary">{{ item.phone }}</div>
                        <div v-if="!empty(item.move_student_uuid)" class="fs-12px text-secondary mt-2 has-text-weight-bold">
                          {{ item.is_excluded ? 'заменен на: ' : 'вместо: ' }}
                          <span
                              v-for="(moveItem, i_moveItem) in data.students.filter(function(filterItem) {
                                return item.move_student_uuid === filterItem.uuid && item.uuid !== filterItem.uuid
                              })"
                              :key="'students.' + i_item + '.move.' + i_moveItem"
                          >
                            {{ moveItem.name }}
                          </span>
                        </div>
                      </td>
                      <td @click="openStudent_click(i_item, item)">{{ item.comment }}</td>
                      <td v-if="inArray('crmContractorSuspensions', enabledFields)">
                        <div v-if="'new' !== item.uuid" class="table-students-row-buttons">
                            <button v-if="!item.is_excluded && this.inArray('replace', item.buttons)" title="Заменить" type="button" class="btn btn-sm btn-dark me-2" @click="openAddStudent_click(item)">
                              <i class="fa-solid fa-arrows-rotate"></i>
                            </button>
                          <button v-if="!item.is_excluded && this.inArray('exclude', item.buttons)" title="Исключить" type="button" class="btn btn-sm btn-danger" @click="openExcludeStudent_click(i_item, item)">
                            <i class="fa-solid fa-ban"></i>
                          </button>
                          <button v-if="item.is_excluded && this.inArray('include', item.buttons)" title="Возобновить" type="button" class="btn btn-sm btn-success" @click="openIncludeStudent_click(i_item, item)">
                            <i class="fa-solid fa-arrow-rotate-left"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="false === empty(data.structure) && inArray('students', enabledFields)" class="row mt-2">
              <div class="col-12 text-end">
                <button class="btn btn-sm btn-primary" type="submit" @click="openStudent_click(-1, null)">
                  Добавить слушателя
                </button>
              </div>
            </div>

            <div class="fs-16px mt-3 mb-2">
              Структура курса
            </div>
            <div v-if="true === empty(data.structure)" class="text-center fs-16px text-secondary">
              <span v-if="true === empty(data.school_course_uuid)">укажите "Курс" и </span>нажмите "Сохранить" для обновления структуры
            </div>
            <div v-if="false === empty(data.structure)">
              <planning-school-course-item-view
                  :indexes="[i_child]"
                  :item="child"
                  :is-edit="inArray('processParams', enabledFields)"
                  :edit-structure-item-params="editStructureItemParams"
                  :list-relation-user-type="data.listRelationUserType"
                  :process-params="data.processParams"
                  v-for="(child, i_child) in data.structure"
                  :key="'structure.' + i_child"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="false === data.is_run" class="tab-pane fade" id="analytics-tab-pane" role="tabpanel" aria-labelledby="analytics-tab" tabindex="0">
        <school-processes-timetable-chart-view :current-process-uuid="$route.params.uuid" :get-timetable-items="loadAnalyticsTimetablesToUsers" />
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import MultiselectInput from '@/components/form/MultiselectInput'
import {quillEditor} from 'vue3-quill'
import BaseModal from '@/components/BaseModal'
import PlanningSchoolCourseItemView from '@/views/app/school/components/PlanningSchoolCourseItemView'
import SchoolCourseItemShowFieldsView from '@/views/app/school/components/SchoolCourseItemShowFieldsView'
import DateTimePickerInput from '@/components/form/DateTimePickerInput'
import SchoolPlanningProcessCopyProcessView
  from '@/views/app/school/components/SchoolPlanningProcessCopyProcessView'
import SchoolProcessTimetableView from '@/views/app/school/components/SchoolProcessTimetableView'
import SchoolProcessesTimetableChartView from '@/views/app/school/components/SchoolProcessesTimetableChartView'

export default {
  name: 'SchoolPlanningProcessView',
  components: {
    SchoolProcessesTimetableChartView,
    SchoolProcessTimetableView,
    SchoolPlanningProcessCopyProcessView,
    DateTimePickerInput,
    SchoolCourseItemShowFieldsView,
    PlanningSchoolCourseItemView, BaseModal, MultiselectInput, ConfirmActionModal, PageTopBar, quillEditor},
  mixins: [formMixin],
  data() {
    return {
      title: '<i class="fa-solid fa-school-circle-exclamation"></i> Планирование процесса обучения',
      isConfirmDeleteModal: false,
      isToRunModel: false,
      formName: 'schoolPlanningProcess',
      formUrl: {string: '/school-planning-process?uuid=${uuid}'},
      editRoute: {name:  'school-planning-process', params: {uuid: ''}},
      exitRoute: {name: 'school-planning'},

      isAnalyticsLoading: false,

      data: {
        name: '',
        id_run: false,
        description: '',
        school_course_uuid: '',
        timetables: [],
        relationUsers: [],
        students: [],
        crmContractorSuspensions: [],
        processParams: [],
        listSchoolCourses: [],
        listRelationUserType: [],
        listUsers: [],
        listCrmContractors: [],
        listCrmContractorsForTable: {},
        moneyProgress: {
          all: 0,
          current: 0,
          must: 0,
          money: {all: 0, remains: 0, worked: 0},
          crmContractors: [],
        }
      },

      analytics: {
        tableTimetables: {
          weekdayStart: 1,
          weekdayEnd: 5,
          timeStart: 8,
          timeEnd: 20,
          userUuids: [],
          users: [],

          intervalMultiselectInput: null,
          interval: {uuid: 'u4', name: '30 минут', m: 30, h: 40},

          timeIntervals: [
            {uuid: 'u1', name: '10 минут', m: 10, h: 10},
            {uuid: 'u2', name: '15 минут', m: 15, h: 20},
            {uuid: 'u3', name: '20 минут', m: 20, h: 30},
            {uuid: 'u4', name: '30 минут', m: 30, h: 40},
            {uuid: 'u5', name: '1 час',    m: 60, h: 50},
          ],

          wCellWeekday: 150,
          wCell: 60,
          hFirstRowCell: 30,

          table: [],
          timetables: [],
          tableWidth: 0,
          tableHeight: 0,
        },
        timetables: [],
      },

      copyProcess: {
        isModal: false,
        processUuid: '',
        relationUsers: [],
        students: [],
      },

      relationUser: {
        isModal: false,
        type: '',
        listUsers: [],
      },

      timetable: {
        isModel: false,
        indexCurrent: -1,
        current: {
          uuid: '',
          name: '',
          weekday: 1,
          time: '09:00',
          duration_lesson_in_minutes: 60,
          school_relation_user_types: [],
          nameSchoolRelationUserTypes: [],
        },
        new: {
          uuid: 'new',
          name: '',
          weekday: 1,
          time: '09:00',
          duration_lesson_in_minutes: 60,
          school_relation_user_types: [],
          nameSchoolRelationUserTypes: [],
        },
      },

      student: {
        isModel: false,
        isExcludeModel: false,
        isExcludeCrmContractorModel: false,
        isIncludeModel: false,
        excludeCrmContractorParams: {
          current: {
            uuid: '',
            suspend_at: null,
            suspend_comment: '',
            is_current_course: false,
          },
          new: {
            uuid: '',
            suspend_at: null,
            suspend_comment: '',
            is_current_course: false,
          },
          textError: '',
        },
        indexCurrent: -1,
        current: {
          uuid: '',
          crm_contractor_uuid: '',
          name: '',
          email: '',
          phone: '',
          comment: '',
          is_excluded: false,
          move_student_uuid: '',
          contacts: [],
        },
        new: {
          uuid: 'new',
          crm_contractor_uuid: '',
          name: '',
          email: '',
          phone: '',
          comment: '',
          is_excluded: false,
          move_student_uuid: '',
          contacts: [],
        },
      },

      structure: {
        isModel: false,
        current: {
          uuid: '',
          type: '',
          name: '',
          children: [],
          values: [],
          is_datetime: false,
          auto_value_day_event_at_param_interval: '1 days',
          auto_value_day_event_at_param_field: '',
          auto_value_day_event_at_param_time: null,
          auto_value_day_event_at_param_uuid: null,
          is_deadline: false,
          auto_value_deadline_at_param_interval: '1 days',
          auto_value_deadline_at_param_field: '',
          auto_value_deadline_at_param_time: null,
          auto_value_deadline_at_param_uuid: null,
          duration_lesson_in_minutes: null,
          is_student_stat: false,
          is_money_stat: false,
          is_crm_contractor: false,
          is_crm_contractor_analytics: false,
          relation_type_edit_param: [],

        },
        isSetAutoValues: false,
        param: {
          uuid: '',
          school_course_structure_param_uuid: '',
          day_event_at: null,
          deadline_at: null,
        },
        listAutoValueStructureItemUuid: [],
      },
    }
  },
  methods: {
    afterSave: function () {
      this.loadAnalyticsData()
    },
    afterLoad: function () {
      let self = this
      let buttons = []
      if ( this.inArray('toRun', this.data.buttons)) {
        buttons.push({
          name: 'Запустить',
          class: 'btn-outline-success',
          click: function() {
            self.isToRunModel = true
            self.emitter.emit('modal', {event: 'open'})
          }
        })
      }

      if ( this.inArray('copyProcess', this.data.buttons)) {
        buttons.push({
          name: 'Загрузить данные',
          class: 'btn-outline-primary',
          click: function() {
            self.copyProcess.isModal = true
            self.emitter.emit('modal', {event: 'open'})
          }
        })
      }

      for (let i in this.buttons) {
        buttons.push(this.buttons[i])
      }

      this.buttons = buttons
    },
    loadAnalyticsData: function() {
      if (false === this.data.is_run) {
        this.emitter.emit('SchoolProcessesTimetableChartList')
      }
    },
    processToRun_click: function () {
      this.data.is_run = true
      this.save(true)
    },
    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    userName: function(uuidUser) {
      for (let i in this.data.listUsers) {
        if (uuidUser === this.data.listUsers[i].uuid) {
          return this.data.listUsers[i].name
        }
      }
      return ''
    },
    studentContractName: function(uuid) {
      let contractor = this.data.listCrmContractorsForTable[uuid]
      if (!this.empty(contractor)) {
          return contractor.name + '<div class="text-secondary fs-12px">' + contractor.description + '</div>'
      }
      return ''
    },

    loadCopyProcessData: function (uuid, data) {
      this.copyProcess.processUuid = uuid
      this.copyProcess.relationUsers = data.relationUsers
      this.copyProcess.students = data.students
    },
    setCopyProcessData: function () {
      this.data.relationUsers = JSON.parse(JSON.stringify(this.copyProcess.relationUsers))
      this.data.students = JSON.parse(JSON.stringify(this.copyProcess.students))

      this.close()
    },

    openTimetable_click: function (index, timetable) {
      if (this.inArray('timetables', this.enabledFields)) {
        this.timetable.indexCurrent = index
        Object.assign(this.timetable.current, -1 === index ? this.timetable.new : timetable)
        this.timetable.isModel = true
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    editTimetable_relation_click: function(uuid) {
      if(this.inArray(uuid, this.timetable.current.school_relation_user_types)) {
        this.timetable.current.school_relation_user_types =
            this.timetable.current.school_relation_user_types.filter(function (item) {
              return item !== uuid
            })
      } else {
        this.timetable.current.school_relation_user_types.push(uuid)
      }

      this.timetable.current.nameSchoolRelationUserTypes = []
      for (let i in this.data.listRelationUserType) {
        if (this.inArray(this.data.listRelationUserType[i].uuid, this.timetable.current.school_relation_user_types)) {
          this.timetable.current.nameSchoolRelationUserTypes.push(this.data.listRelationUserType[i].name)
        }
      }

    },
    saveTimetable: function () {
      let contactData = JSON.parse(JSON.stringify(this.timetable.current))

      if (-1 === this.timetable.indexCurrent) {
        this.data.timetables.push(contactData)
      } else {
        Object.assign(this.data.timetables[this.timetable.indexCurrent], contactData)
      }
      this.timetable.indexCurrent = -1
      Object.assign(this.timetable.current, this.timetable.new)
      this.close()
    },
    deleteTimetable: function () {
      this.data.timetables.splice(this.timetable.indexCurrent, 1)
      this.timetable.indexCurrent = -1
      this.close()
    },
    closeTimetable: function () {
      this.timetable.indexCurrent = -1
      this.close()
    },

    openRelationUsersModel: function (type) {
      this.relationUser.type = type.uuid
      this.relationUser.listUsers = this.data.listUsers.filter(function(user) {
        for (let u in user.personnelStructures) {
          for (let t in type.personnelStructures) {
            if (user.personnelStructures[u] === type.personnelStructures[t]) {
              return true
            }
          }
        }
        return false
      })
      this.relationUser.isModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    isActiveListUsers: function(user) {
      for (let i in this.data.relationUsers) {
        if (
            user + '' === this.data.relationUsers[i].user &&
            this.relationUser.type === this.data.relationUsers[i].type
        ) {
          return true
        }
      }
      return false
    },
    itemListUser_click: function(user) {
      let strUser = user + ''
      if (true === this.isActiveListUsers(strUser)) {
        let self = this
        this.data.relationUsers = this.data.relationUsers.filter(function (item) {
          return item.uuid !== strUser && self.relationUser.type === item.type
        })
      } else {
        this.data.relationUsers.push({
          type: this.relationUser.type,
          user: strUser,
        })
      }

      this.close()
    },
    itemRelationUsers_click: function (i) {
      this.data.relationUsers.splice(i, 1)
    },

    openStudent_click: function (index, student) {
      if (this.inArray('students', this.enabledFields)) {
        this.student.indexCurrent = index
        Object.assign(this.student.current, -1 === index ? this.student.new : student)
        this.student.isEditModel = true
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    student_current_setCrmContractUuid: function() {
      for (let i in this.data.listCrmContractors) {
        if (this.student.current.crm_contractor_uuid === this.data.listCrmContractors[i].uuid) {
          this.student.current.contacts = JSON.parse(JSON.stringify(this.data.listCrmContractors[i].contacts))
        }
      }
    },
    student_current_setFields: function (contact) {
      if (false === this.empty(contact.name)) {
        this.student.current.name = contact.name + ''
      }
      if (false === this.empty(contact.email)) {
        this.student.current.email = contact.email + ''
      }
      if (false === this.empty(contact.phone)) {
        this.student.current.phone = contact.phone + ''
      }
    },
    saveStudent: function () {
      let contactData = JSON.parse(JSON.stringify(this.student.current))

      if (-1 === this.student.indexCurrent) {
        this.data.students.push(contactData)

        this.data.students = this.arraySort(this.data.students, 'crm_contractor_uuid')

        // Если есть замена
        if (!this.empty(contactData.move_student_uuid)) {
          for (let s in this.data.students) {
            if (contactData.move_student_uuid === this.data.students[s].uuid) {
              this.data.students[s].is_excluded = true
            }
          }
        }

      } else {
        Object.assign(this.data.students[this.student.indexCurrent], contactData)
      }

      this.student.indexCurrent = -1
      Object.assign(this.student.current, this.student.new)
      this.close()
    },
    deleteStudent: function () {
      let self = this
      let crmContractorUuid = this.student.current.crm_contractor_uuid + ''

      let students = this.data.students.filter(function (item, index) {
        return crmContractorUuid === item.crm_contractor_uuid &&
            index !== self.student.indexCurrent &&
            false === item.is_excluded
      })

      if (0 === students.length) {
        for (let s in this.data.students) {
          let item = this.data.students[s]
          if (
              crmContractorUuid === item.crm_contractor_uuid &&
              s !== self.student.indexCurrent &&
              true === item.is_excluded
          ) {
            item.is_excluded = false
            break
          }
        }
      }

      this.data.students.splice(this.student.indexCurrent, 1)
      this.student.indexCurrent = -1
      this.close()
    },
    closeStudent: function () {
      this.student.indexCurrent = -1
      this.close()
    },
    openAddStudent_click: function (student) {
      if (this.inArray('crmContractorSuspensions', this.enabledFields)) {
        this.student.indexCurrent = -1
        Object.assign(this.student.current, this.student.new)

        this.student.current.crm_contractor_uuid = student.crm_contractor_uuid
        this.student.current.move_student_uuid = student.uuid

        this.student.isEditModel = true
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    openExcludeStudent_click: function (index, student) {
      if (this.inArray('crmContractorSuspensions', this.enabledFields)) {

        let isLastStudent = this.data.students.filter(function (item) {
          return item.crm_contractor_uuid === student.crm_contractor_uuid &&
              item.uuid !== student.uuid &&
              false === item.is_excluded
        }).length === 0

        this.student.indexCurrent = index
        Object.assign(this.student.current, student)
        if (isLastStudent) {
          Object.assign(this.student.excludeCrmContractorParams.current, this.student.excludeCrmContractorParams.new)
          this.student.excludeCrmContractorParams.current.uuid = student.crm_contractor_uuid + ''
          this.student.isExcludeCrmContractorModel = true
        } else {
          this.student.isExcludeModel = true
        }
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    excludeStudent_click: function () {
      let student = this.data.students[this.student.indexCurrent]
      student.is_excluded = true
      this.student.indexCurrent = -1
      Object.assign(this.student.current, this.student.new)
      this.close()
    },
    excludeCrmContractor_click: function () {
      let student = this.data.students[this.student.indexCurrent]
      student.is_excluded = true
      this.student.indexCurrent = -1
      Object.assign(this.student.current, this.student.new)
      let pushParams = JSON.parse(JSON.stringify(this.student.excludeCrmContractorParams.current))
      this.data.crmContractorSuspensions.push(pushParams)
      Object.assign(this.student.excludeCrmContractorParams.current, this.student.excludeCrmContractorParams.new)
      this.close()
    },
    openIncludeStudent_click: function (index, student) {
      if (this.inArray('crmContractorSuspensions', this.enabledFields)) {
        this.student.indexCurrent = index
        Object.assign(this.student.current, student)
        this.student.isIncludeModel = true
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    includeStudent_click: function () {
      let student = this.data.students[this.student.indexCurrent]
      student.is_excluded = false
      this.student.indexCurrent = -1
      Object.assign(this.student.current, this.student.new)
      this.close()
    },

    editStructureItemParams: function(structure) {
      Object.assign(this.structure.current, structure)
      let param = this.getStructureItemParam(structure.uuid)
      Object.assign(this.structure.param, param)
      this.structure.isSetAutoValues = false

      this.structure.isModel = true
      this.emitter.emit('modal', {event: 'open'})
    },
    getStructureItemParam(structureUuid) {
      for (let i in this.data.processParams) {
        if (structureUuid === this.data.processParams[i].school_course_structure_param_uuid) {
          return this.data.processParams[i]
        }
      }
      return {uuid: '', school_course_structure_param_uuid: structureUuid, day_event_at: null, deadline_at: null}
    },
    saveStructureItemParam: function () {
      let param = null

      for (let i in this.data.processParams) {
        if (
            this.structure.param.school_course_structure_param_uuid ===
            this.data.processParams[i].school_course_structure_param_uuid
        ) {
          param = this.data.processParams[i]
        }
      }

      if (null === param) {
        let newParam = JSON.parse(JSON.stringify(this.structure.param))
        newParam.uuid = 'new'
        this.data.processParams.push(newParam)
      } else {
        Object.assign(param, this.structure.param)
      }

      if (true === this.structure.isSetAutoValues) {
        this.structure.listAutoValueStructureItemUuid = [this.structure.current.uuid + '']
        let maxCount = 300
        while (0 < this.structure.listAutoValueStructureItemUuid.length && 0 < maxCount) {
          maxCount--
          let uuid = this.structure.listAutoValueStructureItemUuid[0] + ''
          this.structure.listAutoValueStructureItemUuid.splice(0, 1)

          let parentParam = this.getStructureItemParam(uuid)

          for (let i in this.data.structure) {
            this.saveAutoValueStructureItemParam(parentParam, this.data.structure[i])
          }
        }
      }

      this.close()
    },
    saveAutoValueStructureItemParam: function (parentParam, itemStructure) {
      if ('param' === itemStructure.type) {
        let param = null
        if (
            parentParam.school_course_structure_param_uuid === itemStructure.auto_value_day_event_at_param_uuid &&
            false === this.empty(itemStructure.auto_value_day_event_at_param_field) &&
            false === this.empty(itemStructure.auto_value_day_event_at_param_interval) &&
          false === this.empty(parentParam[itemStructure.auto_value_day_event_at_param_field])
        ) {
          param = this.getStructureItemParam(itemStructure.uuid)
          let datetime = parentParam[itemStructure.auto_value_day_event_at_param_field]
          param.day_event_at = this.stringDateAddInterval(
              datetime.substr(0, 10),
              itemStructure.auto_value_day_event_at_param_interval
          )
              + (false === this.empty(itemStructure.auto_value_day_event_at_param_time)
                  ? ' ' + itemStructure.auto_value_day_event_at_param_time + datetime.substr(16)
                  : datetime.substr(10))
        }

        if (
            parentParam.school_course_structure_param_uuid === itemStructure.auto_value_deadline_at_param_uuid &&
            false === this.empty(itemStructure.auto_value_deadline_at_param_field) &&
            false === this.empty(itemStructure.auto_value_deadline_at_param_interval) &&
            false === this.empty(parentParam[itemStructure.auto_value_deadline_at_param_field])
        ) {
          if (null === param) {
            param = this.getStructureItemParam(itemStructure.uuid)
          }
          let datetime = parentParam[itemStructure.auto_value_deadline_at_param_field]
          param.deadline_at = this.stringDateAddInterval(
              datetime.substr(0, 10),
              itemStructure.auto_value_deadline_at_param_interval
          )
              + (false === this.empty(itemStructure.auto_value_deadline_at_param_time)
                  ? ' ' + itemStructure.auto_value_deadline_at_param_time + datetime.substr(16)
                  : datetime.substr(10))
        }
        if (null !== param) {
          if ('' === param.uuid) {
            param.uuid = 'new'
            this.data.processParams.push(param)
          }

          this.structure.listAutoValueStructureItemUuid.push(param.school_course_structure_param_uuid)
        }
      } else {
        for (let i in itemStructure.children) {
          this.saveAutoValueStructureItemParam(parentParam, itemStructure.children[i])
        }
      }
    },

    loadAnalyticsTimetablesToUsers: function(analyticsTimetables) {
      for (let ru in this.data.relationUsers) {
        let relationUser = this.data.relationUsers[ru]
        relationUser.timetables = []
        for (let t in analyticsTimetables) {
          let timetable = analyticsTimetables[t]
          if (this.$route.params.uuid !== timetable.schoolProgressUuid) {
            let isUser = false
            for (let tu in timetable.users) {
              if (timetable.users[tu].uuid === relationUser.user) {
                isUser = true
                break
              }
            }

            if (isUser) {
              let isRelationType = false

              for (let dt in this.data.timetables) {
                let dataTimetable = this.data.timetables[dt]
                for (let tu in timetable.school_relation_user_types) {
                  if (
                      this.inArray(timetable.school_relation_user_types[tu], dataTimetable.school_relation_user_types) &&
                      dataTimetable.weekday === timetable.weekday &&
                      //a2 >= b1 && b2 >= a1
                      dataTimetable.intEndTime >= timetable.intTime && timetable.intEndTime >= dataTimetable.intTime
                  ) {
                    isRelationType = true
                    break
                  }
                }
                if (isRelationType) {
                  break
                }
              }

              if (isRelationType) {
                relationUser.timetables.push({
                  name: timetable.name,
                  schoolProgressName: timetable.schoolProgressName,
                  time: timetable.time,
                })
              }
            }

          }
        }
      }
    },

    close: function () {
      this.isConfirmDeleteModal = false
      this.timetable.isModel = false
      this.relationUser.isModal = false
      this.student.isEditModel = false
      this.student.isExcludeModel = false
      this.student.isExcludeCrmContractorModel = false
      this.student.isIncludeModel = false
      this.structure.isModel = false
      this.isToRunModel = false
      this.copyProcess.isModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.replace-students {
  padding-right: 80px;
}

.table-students td {
  position: relative;
}

.table-students-row-buttons {
  position: absolute;
  top: 0;
  right: -80px;
}
</style>
