<template>
    <transition name="modal">
        <div v-if="show" class="modal__wrapper" @click="bgClick" :class="{'all-screen': isAllScreen}">
            <div class="modal-content" @click.stop="" :class="{'not-max-width': !isMaxWidth,'min-width-100-p': isMinWidth100p}">
                <div v-if="header" class="modal-header">
                    <span class="modal-title"> {{ title }} </span>
                    <span class="button-close" @click="close">×</span>
                </div>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
                <div v-if="footer" class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
  name: 'BaseModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isBgClickClose: {
      type: Boolean,
      default: false
    },
    isMaxWidth: {
      type: Boolean,
      default: true
    },
    isMinWidth100p: {
      type: Boolean,
      default: false
    },
    isAllScreen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    header: {
      type: Boolean,
      default: true
    },
    footer: {
      type: Boolean,
      default: false
    },
    isGlobalClose: {
      type: Boolean,
      default: true
    }

  },
  mounted () {
    document.body.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.close()
      }
    })
  },
  methods: {
    bgClick: function () {
      if (true === this.isBgClickClose) {
        this.close()
      }
    },
    close: function () {
      this.$emit('close')
      if (true === this.isGlobalClose) {
        this.emitter.emit('modal', {event: 'close'})
      }
    },
  }
}
</script>

<style scoped>
.modal-enter-active {
  animation: model-in .2s;
}
.modal-leave-active {
  animation: model-in .2s reverse;
}
@keyframes model-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-enter-active .modal-content {
  animation: modal-content-in .2s;
}
.modal-leave-active .modal-content {
  animation: modal-content-in .2s reverse;
}
@keyframes modal-content-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.modal__wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transition: opacity .2s ease;
    right: 0;
    z-index: 998;
    padding: 30px;
    background-color: var(--app-modal-wrapper-bg);
    backdrop-filter: blur(5px);

    @media screen and (max-width: 700px) {
        padding: 10px;
    }
}
.modal-content {
    position: relative;

    width: auto;
    margin: auto;

    max-width: 600px;
    padding: 20px 18px;
    background: var(--app-modal-content-bg);
    border: 1px solid var(--app-model-content-br);
    box-shadow: 0 0 30px 8px rgba(221, 221, 221, 1);
    transition: all .2s ease;
    border-radius: 20px;
    z-index: 1100;
    /** overflow: hidden; **/
    @media screen and (min-width: 700px) {
        min-width: 600px;
    }

    @media screen and (max-width: 700px) {
        width: 100%;
        margin: auto 0 30px 0;
    }
}
.modal-content.not-max-width {
    max-width: initial;
}
.modal-content.min-width-100-p {
    min-width: 100%;
}
.modal-header {
    display: flex;
    align-self: center;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 100%;
    span {
        font-size: 24px;
    }
    .button-close {
        box-sizing: border-box;
        color: var(--app-modal-button-close-color);
        display: block;
        font-size: 24px;
        font-weight: 400;
        height: 33px;
        line-height: 33.6px;
        margin: 0px;
        padding: 0px;
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;

        cursor: pointer;
    }
}
.modal-body {
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-content {
  max-height: calc(100vh - 60px);
}
.modal-bottom {
    padding-top: 40px;
}
a.another-modal {
    color: var(--app-modal-another-modal-color);
    border-bottom: 1px solid var(--app-modal-another-modal-color);
}

.modal__wrapper.all-screen {
    @media screen and (max-width: 700px) {
        padding: 0;
    }
}
.modal__wrapper.all-screen .modal-content {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    max-width: initial;
    width: auto;
    margin: auto;
}
</style>
