export const dbMixin = {
  methods: {
    db_initDB() {
      this.globalVar.dbConfig.isInit = true
      let request = indexedDB.open(this.globalVar.dbConfig.dbName);
      // err
      request.onerror = event => {
        console.log("Ошибка открытия / создания базы данных", event)
        this.globalVar.dbConfig.isInit = false
      }
      // Если указанный номер версии больше, чем фактический номер версии базы данных,
      // произойдет событие обновления базы данных, необходимое обновление.
      // Автоматически запускать успех после обновления
      request.onupgradeneeded = event => {
        let db = event.target.result;

        for(let storeName in this.globalVar.dbConfig.stores) {
          let storeConfig = this.globalVar.dbConfig.stores[storeName]
          let store = db.createObjectStore(storeName, {
            keyPath: storeConfig.keyPath
          })
          for(let i in storeConfig.indexes) {
            let index = storeConfig.indexes[i]
            // Новое имя индекса, атрибут, в котором расположен индекс,
            // и объект конфигурации (укажите, допускает ли атрибут повторяющиеся значения)
            store.createIndex('index_' + storeName + '_' + index.name, index.name, {
              unique: index.unique
            })
          }
        }
      }
      // success
      request.onsuccess = event => {
        this.globalVar.dbConfig.instanceDB = event.target.result
        for(let storeName in this.globalVar.dbConfig.stores) {
          this.db_loadStoreToApp(storeName)
        }
      }
    },
    db_updateDB() {
      if (null !== this.globalVar.dbConfig.instanceDB) {
        for(let storeName in this.globalVar.dbConfig.stores) {
          if (true === this.globalVar.dbConfig.stores[storeName].isUpdated) {
            this.globalVar.dbConfig.stores[storeName].isUpdated = false
            let requestCount = this.globalVar.dbConfig.instanceDB.transaction(['requests'])
                .objectStore('requests')
                .count()

            let self = this
            requestCount.onsuccess = function () {
              if (0 === requestCount.result) {
                self.db_updateLocalStore(storeName)
              } else {
                self.globalVar.dbConfig.stores[storeName].isUpdated = true
              }
            }

          }
        }
      }
    },
    db_upsert(storeName, object) {
      let db = this.globalVar.dbConfig.instanceDB
      let requestAdd = db.transaction([storeName], 'readwrite').objectStore(storeName).add(object)
      requestAdd.onerror = () => {
        db.transaction([storeName], 'readwrite').objectStore(storeName).put(object)
      }
    },
    db_delete(storeName, id) {
      this.globalVar.dbConfig.instanceDB.transaction([storeName], 'readwrite')
          .objectStore(storeName)
          .delete(id)
    },
    db_allDelete(storeName) {
      this.globalVar.dbConfig.instanceDB.transaction([storeName], 'readwrite')
          .objectStore(storeName)
          .clear()
    },

    db_loadStoreToApp(storeName) {
      let self = this
      let transaction = this.globalVar.dbConfig.instanceDB.transaction([storeName])
      let objectStore = transaction.objectStore(storeName)
      let list = []

      objectStore.openCursor().onsuccess = event => {
        let cursor = event.target.result
        if (cursor) {
          list.push(cursor.value)
          cursor.continue()
        } else {
          self.globalVar.db[storeName] = list
          self.emitter.emit('db', {event: 'update', data: storeName})
        }
      }
    },

    db_updateLocalStore(storeName) {
      let urlName = storeName.replace(/[A-Z]/g, m => "-" + m.toLowerCase())
      window.axios.get(
          `${this.globalVar.app.api.url}/load-${urlName}?${(new Date()).getTime()}`)
          .then((response) => {
            this.db_allDelete(storeName)
            for (let i in response.data) {
              this.db_upsert(storeName, response.data[i])
            }
            this.globalVar.db[storeName] = response.data

            this.emitter.emit('db', {event: 'update', data: storeName})
          })
    },

    db_sendRequests() {
      let self  = this
      if (null !== self.globalVar.dbConfig.instanceDB) {
        if (false === self.globalVar.dbConfig.isSendRequests) {
          self.globalVar.dbConfig.isSendRequests = true
          let transaction = self.globalVar.dbConfig.instanceDB.transaction(['requests'])
          let objectStore = transaction.objectStore('requests')
          objectStore.openCursor().onsuccess = event => {
            let cursor = event.target.result
            if (cursor) {
              window.axios.post(
                  `${self.globalVar.app.api.url}/update-data-item?${(new Date()).getTime()}`,
                  cursor.value
              )
                  .then(response => {
                    if (true === response.data.save) {
                      self.globalVar.dbConfig.instanceDB.transaction(['requests'], 'readwrite')
                          .objectStore('requests')
                          .delete(cursor.value.id)
                    }
                  })
                  .finally(() => self.globalVar.dbConfig.isSendRequests = false)
            } else {
              self.globalVar.dbConfig.isSendRequests = false
            }
          }
        }
      }
    }
  }
}
