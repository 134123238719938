export const formMixin = {
    data() {
        return {
            isLoaded: false,
            isLoadByMounted: true,
            formName: '',
            formUrl: {string: '', params:[]},
            editRoute: {},
            exitRoute: {},
            objId: 'uuid',
            isDataLoaded: false,
            data: {
                buttons: [],
            },
            enabledFields: [],
            generalError: '',
            errors: {},

            buttons: [],

            _formUrl: '',
        };
    },
    mounted: function() {
        let exitRouteName = localStorage.getItem(this.formName + '.currentExitRoute')
        if (false === this.empty(exitRouteName)) {
            this.exitRoute = JSON.parse(exitRouteName)
        }

        if (this.isLoadByMounted) {
            this.load()
        }
    },
    methods: {
        makeUrl() {
            this._formUrl = this.globalVar.app.api.url + this.formUrl.string
            this._formUrl = this._formUrl.replace( '${'+this.objId + '}', this.$route.params[this.objId])
            for (let formUrlParam in this.formUrl.params) {
                this._formUrl = this._formUrl.replace( '${'+formUrlParam+'}', this.formUrl.params[formUrlParam])
            }
            this._formUrl = this._formUrl + (-1 === this._formUrl.indexOf('?') ? '?' : '&') + (new Date()).getTime()
        },
        beforeLoad: function() {

        },
        load(isIndicator) {
            if (undefined === isIndicator) {
                isIndicator = true
            }

            let self = this
            this.beforeLoad()
            this.makeUrl()
            if (true === isIndicator) {
                this.isLoaded = true
                this.emitter.emit('app', {event: 'spinner.show'})
            }

            this.clearErrors()

            window.axios.get(this._formUrl)
                .then(response => {
                    if (false === self.empty(response.data.operation) && 'get' === response.data.operation) {
                        if (true === response.data.result) {
                            let newData = JSON.parse(JSON.stringify(response.data.data))
                            Object.assign(self.data, newData)
                            self.enabledFields = JSON.parse(JSON.stringify(response.data.enabledFields))
                            self.pushButtons()
                            self.afterLoad()
                        } else {
                            self.afterErrorLoad()
                        }
                    }

                    if (false === self.empty(response.data.dataErrors)) {
                        self.errors = JSON.parse(JSON.stringify(response.data.dataErrors))
                    }

                    self.generalError = response.data.generalError
                })
                .finally(() => {
                    self.isLoaded = false
                    self.isDataLoaded = true
                    self.emitter.emit('app', {event: 'spinner.hide'})
                })
        },
        pushButtons: function () {
            let self = this
            this.buttons = []
            if (false === this.empty(this.enabledFields)) {
                this.buttons.push({uuid: 'save', name: 'Сохранить', class: 'btn-primary', click: function() {self.save(false)}})
                // ??? ?? Под вопросом, нужна ли эт кнопка)
                //this.buttons.push({uuid: 'saveAndExit', name: 'Сохранить и выйти', class: 'btn-primary', click: function() {self.save(true)}})
            }
            if (true === this.inArray('delete', this.data.buttons)) {
                this.buttons.push({uuid: 'delete', name: 'Удалить', class: 'btn-danger', click: function() {self.openModalDelete()}})
            }
            this.buttons.push({
                uuid: 'exit',
                name: 'Назад',
                class: 'btn-outline-secondary',
                click: function() {
                    localStorage.removeItem(self.formName + '.currentExitRoute')
                    self.toRoute(self.exitRoute)
                }
            })
        },
        afterLoad: function() {
            //console.log(this.data)
        },
        afterErrorLoad: function() {
            //console.log(this.data)
        },
        save(exit) {
            let self = this
            this.makeUrl()

            this.emitter.emit('app', {event: 'spinner.show'})
            this.clearErrors()

            this.beforeSave()

            for (let i in this.data) {
                if ('null' === this.data[i] || 'undefined' === this.data[i]) {
                    this.data[i] = null
                }
            }

            window.axios
                .post(this._formUrl, this.data)
                .then((response) => {

                    let isAfterResult = null

                    if (false === self.empty(response.data.operation) && 'upsert' === response.data.operation) {
                        if (true === response.data.result) {
                            if(exit) {
                                self.toRoute(self.exitRoute)
                            } else if(response.data.data[self.objId] !== self.data[self.objId]) {
                                let newData = JSON.parse(JSON.stringify(response.data.data))
                                Object.assign(self.data, newData)
                                isAfterResult = true

                                // ПОЧЕМУ НЕ ПЕРЕХОДИТ ПОЛНОЦЕННО ПО РОУТУ
                                let letEditRoute = JSON.parse(JSON.stringify(self.editRoute))
                                letEditRoute.params[self.objId] = response.data.data[self.objId]
                                setTimeout(()=> {self.toRoute(letEditRoute)}, 100)
                            } else {
                                let newData = JSON.parse(JSON.stringify(response.data.data))
                                Object.assign(self.data, newData)
                                self.enabledFields = JSON.parse(JSON.stringify(response.data.enabledFields))

                                isAfterResult = true
                            }
                        } else {
                            isAfterResult = false
                        }
                    }

                    if (false === self.empty(response.data.dataErrors)) {
                        self.errors = JSON.parse(JSON.stringify(response.data.dataErrors))
                    }
                    self.generalError = response.data.generalError

                    self.emitter.emit('app', {event: 'spinner.hide'})

                    if (true === isAfterResult) {
                        self.pushButtons()
                        self.afterLoad()
                        self.afterSave()
                    }
                    if (false === isAfterResult) {
                        self.afterErrorSave()
                    }
                })
                .catch((error) => {
                    if(error.response !== undefined && error.response['data'] !== undefined) {
                        self.errors = error.response.data['errors']
                        self.generalError = error.response.data['generalError']
                    }
                    self.emitter.emit('app', {event: 'spinner.hide'})
                    self.afterErrorSave()
                })
        },
        beforeSave: function() {
            //this.addAppAlerts()
        },
        afterSave: function() {
            //this.addAppAlerts()
        },
        afterErrorSave: function() {
            //this.addAppAlerts()
        },
        clearErrors() {
            this.generalError = '';
            this.errors = {};
        },

        openModalDelete() {
        },
        formDelete() {
            this.makeUrl()
            window.axios.delete(this._formUrl).then(() => {
                this.toRoute(this.exitRoute)
                this.emitter.emit('modal', {event: 'close'})
            });
        },


        preparingFileOnServer(event, fieldFile, formSave = false) {
            this.$refs.loadingFullHtml.show()
            let self = this
            fieldFile.file = event.target.files[0]
            fieldFile.name = fieldFile.file.name
            fieldFile.size = fieldFile.file.size
            let formData = new FormData()
            formData.append('id', fieldFile.id ?? 0)
            formData.append('file', fieldFile.file)
            window.axios.post( '/api/working-with-files', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(function(request) {
                    fieldFile.tempName = request.data.fileTempName
                    self.afterPreparingFileOnServer()
                    if (formSave) {
                        self.save(false)
                    }
                })
        },
        afterPreparingFileOnServer: function() {
            this.$refs.loadingFullHtml.hide()
        },
        deleteFileOnServer(fieldFile, formSave = false) {
            fieldFile.file = null
            fieldFile.name = ''
            fieldFile.size = 0
            fieldFile.tempName = ''
            if (formSave) {
                this.save(false)
            }
        }
    }
}
