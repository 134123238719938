<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <page-top-bar
        :title="'[SYS] Бизнес'"
        :subtitle="'Данные бизнеса aVicrm'"
        :buttons="buttons"
    />
    <div class="card border-0 shadow">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <label class="form-label">Контрагент (пользователю не доступно)</label>
            <multiselect-input
                v-model="data.contractor_id"
                :disabled="!inArray('contractor_id', enabledFields)"
                :class-name="{ 'is-invalid': !!errors['contractor_id'] }"
                :list="data.listContractors"
            />
            <div class="text-danger" v-if="!!errors['contractor_id']">{{ errors['contractor_id'].join(', ') }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label class="form-label">Тип</label>
            <multiselect-input
                v-model="data.type"
                :disabled="!inArray('type', enabledFields)"
                :class-name="{ 'is-invalid': !!errors['type'] }"
                :list="globalVar.db.businessTypes"
            />
            <div class="text-danger" v-if="!!errors['type']">{{ errors['type'].join(', ') }}</div>
          </div>
          <div class="col-sm-6">
            <label class="form-label">Наименование</label>
            <input type="text" class="form-control" placeholder="ПАО Тест"
                   v-model="data.name"
                   :disabled="!inArray('name', enabledFields)"
                   :class="{ 'is-invalid': !!errors['name'] }"
            >
            <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import MultiselectInput from '@/components/form/MultiselectInput'

export default {
  name: 'SysBusinessView',
  components: {MultiselectInput, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      isConfirmDeleteModal: false,

      formName: 'sysBusiness',
      formUrl: {string: '/sys-business?uuid=${uuid}'},
      editRoute: {name:  'sys-business', params: {uuid: 0}},
      exitRoute: {name: 'sys-businesses'},
    }
  },
  methods: {
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    close: function () {
      this.isConfirmDeleteModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
</style>
