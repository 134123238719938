<template>
  <div class="container-fluid mt-3">
    <base-modal title="Внимание!" :show="isDeleteNetworkModel" :footer="true" @close="close">
      <template v-slot:body>
        <div class="py-3">
          Отключить профиль авторизации?
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="deleteNetwork">Отключить</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="close">Отмена</a>
      </template>
    </base-modal>

    <base-modal title="Внимание!" :show="isDeleteDeviceModel" :footer="true" @close="close">
      <template v-slot:body>
        <div class="py-3">
          Отключить устройство?
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="deleteDevice">Отключить</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="close">Отмена</a>
      </template>
    </base-modal>

    <base-modal title="" :show="newNotificationProfile.isModel" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <profile-notification-channel-view
            :type="newNotificationProfile.type"
            :uuid="newNotificationProfile.uuid"
            :update-parent="load"
            :close="close"
        />
      </template>
    </base-modal>

    <base-modal title="" :show="newNotificationSetting.isModel" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <profile-notification-setting-view
            :type="newNotificationSetting.type"
            :uuid="newNotificationSetting.uuid"
            :update-parent="load"
            :close="close"
        />
      </template>
    </base-modal>

    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-5 col-sm-12">
        <div class="card border-0 shadow">
          <div class="card-body">
            <div class="div-user-avatar position-relative">
              <div class="user-avatar position-absolute top-50 start-50 translate-middle">
                {{ globalVar.app.user.avatarText }}
              </div>
            </div>
            <div class="text-center pt-2"> {{ data.name }}</div>
            <div class="pt-2">
              <div class="border-bottom pt-2 pb-1"></div>
              <div class="pt-2">
                <span class="text-secondary">Email: </span>
                {{ data.email }}
              </div>
              <div class="pt-2">
                <span class="text-secondary">Занимаемые должности: </span>
                {{ data.personnelStructures.join(', ') }}
              </div>
            </div>

          </div>
        </div>
        <div class="card border-0 shadow mt-3 mb-4">
          <div v-if="isShowDoughnutTasks" class="card-body">
            <Doughnut :data="doughnutTasks.data" :options="doughnutTasks.option" />
          </div>
        </div>
      </div>
      <div class="col-xl-9 col-lg-8 col-md-7 col-sm-12">
        <ul class="nav nav-underline mb-3" id="userTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="notifications-tab" data-bs-toggle="tab" data-bs-target="#notifications-tab-pane" type="button" role="tab" aria-controls="notifications-tab-pane" aria-selected="false">
              <i class="fa-regular fa-bell"></i> Уведомления
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="devices-tab" data-bs-toggle="tab" data-bs-target="#devices-tab-pane" type="button" role="tab" aria-controls="devices-tab-pane" aria-selected="false">
              <i class="fa-regular fa-address-card"></i> Авторизация
            </button>
          </li>
        </ul>
        <div class="tab-content my-1" id="userTabContent">
          <div class="tab-pane fade show active" id="notifications-tab-pane" role="tabpanel" aria-labelledby="notifications-tab" tabindex="0">
            <div class="card border-0 shadow">
              <div class="card-body">
                <div class="border-bottom pb-1 mb-1">Каналы</div>
                <div class="row">
                  <div class="col notification-channel-indicator">
                    <div class="border border-radius-10 text-center p-2">
                      <div><i class="fa-solid fa-bolt"></i></div>
                      <div class="text-secondary">внутри сервиса</div>
                    </div>
                  </div>
                  <div class="col notification-channel-body">
                    <div class="fs-12px text-secondary">
                      В aVIcrm уведомления всплывают на экране.
                      Окна уведомления расположено в верхнем правом углу.
                      Если пользователь не онлайн, уведомления сохраняются в специальном разделе - «Центр уведомлений».
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col notification-channel-indicator">
                    <div class="border border-radius-10 text-center p-2">
                      <div><i class="fa-solid fa-envelope"></i></div>
                      <div class="text-secondary">почта</div>
                    </div>
                  </div>
                  <div class="col notification-channel-body">
                    <div>
                      <b>{{ data.email }}</b>
                      <span class="text-success px-2"><i class="fa-regular fa-circle-check"></i></span>
                      <span class="text-secondary fs-12px">(почта профиля)</span>
                    </div>
                    <div v-for="(channel, i_channel) in data.notificationChannels.filter(function(item) {return 'email' === item.type})" :key="'channel.email.' + i_channel">
                      <b>{{ channel.network_name }}</b>
                      <span v-show="true === channel.is_validate" class="text-success px-2"><i class="fa-regular fa-circle-check"></i></span>
                      <span v-show="false === channel.is_validate" class="text-danger px-2"><i class="fa-regular fa-circle-xmark"></i></span>
                      <a class="ms-2" href="#" @click="openNotificationChannelModel('email', channel.uuid)"><i class="fa-solid fa-pen-to-square"></i></a>
                    </div>
                    <div v-if="inArray('addNotificationChannelEmail', data.buttons)" class="my-1">
                      <button class="btn btn-very-sm btn-outline-primary" @click="openNotificationChannelModel('email', 'new')">Добавить почту</button>
                    </div>
                    <div class="fs-12px text-secondary bt-2 border-top">
                      Подключите дополнительную почту для более вариативной настройки уведомлений в виде электронного письма.
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col notification-channel-indicator">
                    <div class="border border-radius-10 text-center p-2">
                      <div><i class="fa-solid fa-comments"></i></div>
                      <div class="text-secondary">Мессенджер</div>
                    </div>
                  </div>
                  <div class="col notification-channel-body">
                    <div v-for="(channel, i_channel) in data.notificationChannels.filter(function(item) {return 'messenger' === item.type})" :key="'channel.messenger.' + i_channel">
                      <span class="me-1" v-html="notificationChannelConfig.networkTypes[channel.network]"></span>
                      <b>{{ channel.network_name }}</b>
                      <span v-show="true === channel.is_validate" class="text-success px-2"><i class="fa-regular fa-circle-check"></i></span>
                      <span v-show="false === channel.is_validate" class="text-danger px-2"><i class="fa-regular fa-circle-xmark"></i></span>
                      <a class="ms-2" href="#" @click="openNotificationChannelModel('messenger', channel.uuid)"><i class="fa-solid fa-pen-to-square"></i></a>
                    </div>
                    <div v-if="inArray('addNotificationChannelMessenger', data.buttons)" class="my-1">
                      <button class="btn btn-very-sm btn-outline-primary" @click="openNotificationChannelModel('messenger', 'new')">Добавить профиль мессенджера</button>
                    </div>
                    <div class="fs-12px text-secondary bt-2 border-top">
                      Профиль мессенджера позволит вам получать уведомления в виде коротких сообщений в чат с aVIcrm.
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col notification-channel-indicator">
                    <div class="border border-radius-10 text-center p-2">
                      <div><i class="fa-solid fa-mobile-button"></i></div>
                      <div class="text-secondary">Push в приложение</div>
                    </div>
                  </div>
                  <div class="col notification-channel-body">
                    <div v-for="(device, i_device) in data.devices.filter(function(item) {return item.isPush})" :key="'channel.device.' + i_device">
                      <b>{{ device.name }} ({{ device.app_type }})</b>
                      <span class="text-success px-2"><i class="fa-regular fa-circle-check"></i></span>
                    </div>
                    <div class="fs-12px text-secondary bt-2 border-top">
                      Данный раздел отображает все доступные устройства,
                      на которых Вы установили приложение и авторизовались в нем
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 shadow mt-3">
              <div class="card-body">
                <div class="border-bottom pb-1 mb-1">Уведомления</div>
                <div class="fs-16px my-2"><i class="fa-solid fa-timeline"></i> События</div>
                <profile-notification-list
                    :open-notification-setting-model="openNotificationSettingModel"
                    :list="globalVar.db.userNotificationTypes.filter(function(item) {return 'events' === item.param.group})"
                    :profile-data="data"
                />
                <div class="fs-16px my-2"><i class="fa-solid fa-list-check"></i> Задачи</div>
                <profile-notification-list
                    :open-notification-setting-model="openNotificationSettingModel"
                    :list="globalVar.db.userNotificationTypes.filter(function(item) {return 'tasks' === item.param.group})"
                    :profile-data="data"
                />
                <div class="fs-16px my-2"><i class="fa-solid fa-note-sticky"></i> Заметки</div>
                <profile-notification-list
                    :open-notification-setting-model="openNotificationSettingModel"
                    :list="globalVar.db.userNotificationTypes.filter(function(item) {return 'notes' === item.param.group})"
                    :profile-data="data"
                />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="devices-tab-pane" role="tabpanel" aria-labelledby="devices-tab" tabindex="0">
            <div class="card border-0 shadow">
              <div class="card-body">
                <div class="border-bottom pb-1 mb-1">Авторизация по email</div>
                <div>Базовая авторизация по
                  <b>{{ data.email }}</b>
                  <span class="text-success ps-2"><i class="fa-regular fa-circle-check"></i></span>
                </div>
                <div class="fs-12px text-secondary">
                  Авторизация через Одноразовый пароль.
                  Одноразовый пароль - это набор символов, который придет вам на указанный адрес электронной почты (email).
                  Одноразовый пароль действует небольшой промежуток времени и позволяет войти в аккаут только один раз, после чего утрачивает силу.</div>
              </div>
            </div>
            <div class="card border-0 shadow mt-3">
              <div class="card-body">
                <div class="border-bottom pb-1 mb-1">Дополнительно</div>
                <div class="mb-3">
                  <div class="mt-4">
                    <svg class="svg-yandex" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
                      <path d="M2.04 12c0-5.523 4.476-10 10-10 5.522 0 10 4.477 10 10s-4.478 10-10 10c-5.524 0-10-4.477-10-10z" fill="#FC3F1D"/>
                      <path d="M13.32 7.666h-.924c-1.694 0-2.585.858-2.585 2.123 0 1.43.616 2.1 1.881 2.959l1.045.704-3.003 4.487H7.49l2.695-4.014c-1.55-1.111-2.42-2.19-2.42-4.015 0-2.288 1.595-3.85 4.62-3.85h3.003v11.868H13.32V7.666z" fill="#fff"/>
                    </svg>
                    Авторизация с Яндекс ID
                    <span v-show="undefined !== data.networks['yandex']" class="text-success ps-2">
                      <i class="fa-regular fa-circle-check"></i>
                      <span v-show="undefined !== data.networks['yandex']" class="ps-2 text-secondary"> {{ data.networks.yandex }}</span>
                    </span>
                    <a v-show="undefined !== data.networks['yandex']" class="ms-2" href="#" @click="openDeleteNetwork('yandex')">Отключить</a>
                    <a v-show="undefined === data.networks['yandex']" class="ms-2" target="_blank" :href="data.networkRegUrls.yandex">Подключить</a>
                    <div class="fs-12px text-secondary">
                      Если вы входили в Почту или регистрировались на других сервисах Яндекса, у вас уже есть ID.
                    </div>
                  </div>

                  <div class="mt-4">
                    <svg class="svg-vk" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 2880.000000 2294.000000" preserveAspectRatio="xMidYMid meet">
                      <g transform="translate(0.000000,2294.000000) scale(0.100000,-0.100000)"
                         fill="#0B5CFE" stroke="none">
                        <path d="M12380 18020 c0 -6 -48 -10 -124 -10 -73 0 -127 -4 -131 -10 -4 -6
-55 -10 -121 -10 -93 0 -116 -3 -120 -15 -5 -12 -30 -15 -135 -15 -79 0 -129
-4 -129 -10 0 -5 -22 -10 -50 -10 -27 0 -50 -4 -50 -10 0 -5 -25 -10 -55 -10
-30 0 -55 -4 -55 -10 0 -5 -13 -10 -30 -10 -16 0 -30 -4 -30 -10 0 -6 -28 -10
-64 -10 -36 0 -68 -4 -71 -10 -3 -5 -23 -10 -44 -10 -40 0 -101 -25 -101 -41
0 -5 -9 -9 -19 -9 -11 0 -23 -4 -26 -10 -3 -5 -19 -10 -36 -10 -16 0 -29 -4
-29 -10 0 -5 -20 -10 -45 -10 -25 0 -45 -4 -45 -10 0 -5 -11 -10 -25 -10 -14
0 -35 -9 -47 -20 -12 -11 -31 -20 -42 -21 -12 0 -34 -9 -51 -20 -16 -10 -37
-22 -45 -26 -8 -3 -22 -15 -31 -25 -9 -10 -21 -18 -28 -18 -6 0 -11 -4 -11
-10 0 -5 -7 -10 -15 -10 -22 0 -55 -21 -91 -57 -17 -18 -36 -33 -43 -33 -17 0
-232 -220 -225 -231 3 -5 -2 -12 -10 -15 -9 -3 -16 -17 -16 -30 0 -13 -4 -24
-10 -24 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -19 -10 -42 0 -37
4 -45 27 -55 15 -7 35 -13 44 -13 10 0 21 -4 24 -10 3 -5 44 -10 91 -10 49 0
84 -4 84 -10 0 -5 16 -10 35 -10 19 0 35 -4 35 -10 0 -5 9 -10 20 -10 11 0 20
-4 20 -10 0 -5 11 -10 24 -10 13 0 29 -7 36 -15 7 -9 29 -15 51 -15 22 0 39
-4 39 -10 0 -5 11 -10 25 -10 14 0 25 -4 25 -10 0 -5 9 -10 20 -10 11 0 20 -4
20 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 12 -10 27 -10 14 0 41 -11 59
-25 18 -14 36 -25 41 -25 5 0 15 -9 23 -20 8 -11 22 -20 31 -20 9 0 30 -13 47
-30 18 -16 39 -30 47 -30 8 0 15 -4 15 -10 0 -5 5 -10 11 -10 7 0 34 -22 60
-50 27 -27 56 -53 63 -57 8 -4 16 -15 18 -23 2 -8 18 -30 35 -50 18 -19 33
-41 33 -47 0 -7 4 -13 9 -13 5 0 16 -16 25 -35 9 -19 21 -37 26 -40 6 -4 8
-11 4 -16 -3 -6 3 -17 14 -25 25 -18 52 -69 52 -101 0 -12 5 -23 10 -23 6 0
10 -13 10 -30 0 -16 5 -30 10 -30 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10
-11 10 -24 0 -13 7 -27 16 -30 11 -4 15 -16 13 -41 -2 -19 2 -40 9 -47 6 -6
12 -31 12 -55 0 -24 5 -43 10 -43 6 0 10 -13 10 -29 0 -17 5 -33 10 -36 6 -3
10 -24 10 -46 0 -21 5 -39 10 -39 6 0 10 -43 10 -110 0 -67 4 -110 10 -110 6
0 10 -38 10 -95 0 -57 4 -95 10 -95 6 0 10 -23 10 -50 0 -36 4 -52 15 -56 13
-5 15 -37 15 -204 0 -122 4 -201 10 -205 6 -4 10 -167 10 -456 0 -293 3 -449
10 -449 6 0 10 -105 10 -295 0 -190 -4 -295 -10 -295 -6 0 -10 -110 -10 -309
0 -196 -4 -312 -10 -316 -6 -4 -10 -55 -10 -121 0 -86 -3 -114 -13 -114 -9 0
-14 -19 -15 -62 -2 -35 -7 -64 -12 -66 -6 -2 -10 -42 -10 -88 0 -49 -4 -84
-10 -84 -6 0 -10 -41 -10 -104 0 -59 -4 -108 -10 -111 -5 -3 -10 -24 -10 -46
0 -21 -4 -39 -10 -39 -5 0 -10 -16 -10 -35 0 -19 -4 -35 -10 -35 -6 0 -10 -27
-10 -60 0 -39 -4 -62 -13 -65 -8 -4 -11 -21 -9 -50 3 -34 0 -46 -12 -51 -9 -3
-16 -17 -16 -30 0 -13 -4 -24 -10 -24 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10
-20 -5 0 -10 -13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -16 -10 -35 0 -19 -4
-35 -10 -35 -5 0 -10 -7 -10 -16 0 -9 -11 -25 -25 -36 -14 -11 -23 -23 -20
-28 3 -5 -2 -15 -10 -23 -8 -9 -15 -23 -15 -31 0 -9 -4 -16 -10 -16 -5 0 -10
-7 -10 -16 0 -21 -179 -194 -201 -194 -10 0 -24 -7 -33 -15 -8 -8 -22 -15 -30
-15 -9 0 -16 -4 -16 -10 0 -5 -16 -10 -35 -10 -19 0 -35 -4 -35 -10 0 -6 -40
-10 -100 -10 -60 0 -100 4 -100 10 0 6 -18 10 -40 10 -22 0 -40 5 -40 10 0 6
-6 10 -13 10 -13 0 -59 19 -99 41 -10 5 -26 9 -36 9 -11 0 -33 14 -50 30 -18
17 -36 30 -42 30 -5 0 -10 5 -10 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6
-7 10 -15 10 -9 0 -26 11 -39 25 -13 14 -27 25 -32 25 -5 0 -22 11 -39 25 -16
14 -50 42 -75 63 -71 58 -170 159 -170 174 -1 7 -21 31 -46 53 -25 22 -58 53
-72 69 -15 16 -44 46 -64 67 -21 20 -38 41 -38 45 0 5 -14 23 -30 41 -17 18
-30 37 -30 43 0 6 -25 35 -55 65 -30 30 -55 60 -55 66 0 6 -13 24 -30 39 -16
15 -30 34 -30 41 0 6 -16 27 -35 46 -19 19 -35 40 -35 46 0 7 -9 19 -20 27
-11 8 -20 22 -20 32 0 10 -4 18 -10 18 -5 0 -10 7 -10 15 0 8 -4 15 -10 15 -5
0 -10 7 -10 16 0 16 -47 64 -62 64 -5 0 -8 8 -8 18 0 10 -9 28 -20 40 -11 12
-20 26 -20 33 0 6 -12 22 -27 35 -15 13 -24 26 -20 30 3 3 -9 22 -28 40 -19
19 -35 40 -35 49 0 8 -4 15 -10 15 -5 0 -10 9 -10 20 0 11 -4 20 -9 20 -5 0
-16 15 -24 33 -9 18 -25 41 -36 51 -10 10 -22 28 -25 42 -4 13 -11 24 -16 24
-5 0 -17 16 -26 35 -9 19 -20 35 -25 35 -5 0 -9 9 -9 20 0 11 -4 20 -10 20 -5
0 -10 7 -10 16 0 9 -16 31 -35 50 -19 18 -35 40 -35 49 0 8 -4 15 -10 15 -5 0
-10 8 -10 18 0 10 -9 24 -20 32 -11 8 -20 22 -20 31 0 9 -7 22 -15 29 -8 7
-12 16 -9 22 3 5 -6 18 -21 29 -14 12 -24 26 -22 32 2 7 -7 20 -20 30 -13 10
-23 27 -23 38 0 10 -4 19 -10 19 -5 0 -10 8 -10 18 0 10 -9 24 -20 32 -11 8
-20 22 -20 31 0 9 -7 19 -15 23 -8 3 -15 15 -15 26 0 11 -4 20 -10 20 -5 0
-10 8 -10 19 0 10 -7 24 -15 31 -16 14 -45 67 -45 84 0 6 -11 19 -24 30 -14
10 -23 23 -20 29 2 7 -7 21 -21 31 -14 10 -25 27 -25 37 0 11 -4 19 -10 19 -5
0 -10 8 -10 18 0 11 -9 27 -20 37 -11 10 -20 22 -20 27 0 5 -11 21 -24 35 -14
15 -23 30 -20 35 3 4 -2 11 -10 14 -9 3 -16 15 -16 25 0 10 -4 19 -10 19 -5 0
-10 8 -10 18 0 10 -9 28 -20 40 -11 12 -20 28 -20 37 0 8 -4 15 -10 15 -5 0
-10 9 -10 20 0 11 -4 20 -10 20 -5 0 -10 9 -10 19 0 10 -7 21 -15 25 -8 3 -15
15 -15 26 0 11 -4 20 -10 20 -5 0 -10 6 -10 13 0 20 -28 73 -45 87 -8 7 -15
21 -15 31 0 11 -4 19 -10 19 -5 0 -10 9 -10 20 0 11 -4 20 -10 20 -5 0 -10 9
-10 20 0 11 -7 23 -16 27 -8 3 -13 12 -10 19 3 7 -6 22 -19 33 -14 10 -25 26
-25 35 0 9 -4 16 -10 16 -5 0 -10 9 -10 19 0 11 -4 23 -10 26 -5 3 -10 14 -10
23 0 10 -11 26 -25 36 -14 11 -24 25 -21 32 3 7 -2 15 -10 18 -8 3 -14 19 -14
36 0 16 -4 30 -10 30 -5 0 -10 9 -10 20 0 11 -9 25 -20 32 -11 7 -20 21 -20
30 0 10 -4 18 -10 18 -5 0 -10 16 -10 35 0 19 -4 35 -10 35 -5 0 -10 9 -10 19
0 10 -7 22 -16 25 -9 3 -14 13 -11 20 3 8 -1 17 -9 20 -8 3 -14 15 -14 26 0
11 -4 20 -10 20 -5 0 -10 9 -10 20 0 11 -4 20 -10 20 -5 0 -10 13 -10 28 0 16
-9 37 -20 47 -11 10 -20 26 -20 37 0 10 -4 18 -10 18 -5 0 -10 9 -10 19 0 10
-7 22 -16 25 -11 4 -15 14 -11 31 3 19 -2 31 -19 44 -13 10 -24 26 -24 35 0 9
-4 16 -10 16 -5 0 -10 13 -10 29 0 17 -4 33 -10 36 -5 3 -10 18 -10 33 0 16
-8 34 -20 42 -11 8 -20 22 -20 31 0 9 -7 19 -15 23 -8 3 -15 14 -15 25 0 11
-4 23 -10 26 -5 3 -10 17 -10 31 0 13 -4 24 -10 24 -5 0 -10 7 -10 15 0 9 -9
25 -20 37 -11 12 -20 30 -20 40 0 10 -4 18 -10 18 -5 0 -10 11 -10 23 0 32
-27 85 -51 100 -10 6 -19 20 -19 29 0 10 -4 18 -10 18 -5 0 -10 13 -10 29 0
17 -4 33 -10 36 -6 3 -10 19 -10 36 0 16 -4 29 -10 29 -5 0 -10 9 -10 20 0 11
-4 20 -10 20 -5 0 -10 8 -10 19 0 10 -7 24 -15 31 -8 7 -15 23 -15 36 0 12 -4
26 -10 29 -5 3 -10 17 -10 30 0 13 -4 27 -10 30 -5 3 -10 15 -10 26 0 10 -4
19 -10 19 -5 0 -10 9 -10 20 0 11 -4 20 -10 20 -5 0 -10 14 -10 30 0 17 -4 30
-10 30 -5 0 -10 16 -10 35 0 19 -4 35 -10 35 -5 0 -10 8 -10 18 0 9 -11 26
-25 36 -16 12 -25 29 -25 47 0 16 -4 29 -10 29 -5 0 -10 14 -10 30 0 17 -4 30
-10 30 -5 0 -10 8 -10 18 0 11 -9 27 -20 37 -11 10 -20 31 -20 46 0 15 -5 29
-11 31 -6 2 -11 16 -11 31 0 15 -7 32 -14 38 -8 6 -12 15 -9 19 3 5 -6 17 -20
28 -14 11 -25 27 -25 36 0 9 -4 16 -10 16 -5 0 -10 9 -10 19 0 11 -4 23 -10
26 -5 3 -10 13 -10 21 0 8 -15 30 -32 49 -45 46 -60 65 -53 65 3 0 -11 18 -31
40 -21 22 -41 40 -45 40 -10 0 -38 22 -75 58 -13 12 -30 22 -39 22 -9 0 -18 7
-21 15 -4 8 -14 15 -23 15 -9 0 -23 9 -31 20 -8 12 -26 20 -42 20 -15 0 -30 5
-33 10 -3 6 -19 10 -36 10 -16 0 -29 5 -29 10 0 6 -18 10 -40 10 -22 0 -40 5
-40 10 0 6 -16 10 -35 10 -19 0 -35 5 -35 10 0 6 -9 10 -19 10 -10 0 -21 7
-25 15 -5 13 -231 15 -1950 15 -1719 0 -1945 -2 -1950 -15 -3 -10 -19 -15 -46
-15 -22 0 -40 -4 -40 -10 0 -5 -13 -10 -29 -10 -17 0 -33 -4 -36 -10 -3 -5
-31 -10 -61 -10 -30 0 -54 -4 -54 -10 0 -5 -10 -10 -23 -10 -13 0 -36 -13 -52
-30 -16 -16 -36 -30 -46 -30 -9 0 -19 -7 -23 -15 -3 -8 -13 -15 -22 -15 -19 0
-74 -56 -74 -75 0 -8 -11 -22 -25 -33 -14 -11 -25 -29 -25 -41 0 -12 -4 -21
-10 -21 -5 0 -10 -13 -10 -30 0 -16 -4 -30 -10 -30 -13 0 -13 -147 0 -155 6
-3 10 -28 10 -56 0 -27 5 -49 10 -49 6 0 10 -16 10 -35 0 -24 5 -35 15 -35 10
0 15 -10 15 -29 0 -17 5 -33 10 -36 6 -3 10 -24 10 -46 0 -21 5 -39 10 -39 6
0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0
10 -7 10 -15 0 -15 4 -25 28 -72 7 -13 12 -31 12 -42 0 -10 7 -22 16 -25 9 -4
14 -13 10 -21 -3 -9 1 -18 9 -21 8 -4 15 -15 15 -25 0 -10 3 -19 8 -19 8 0 32
-71 32 -95 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -7
10 -16 0 -8 7 -23 16 -31 8 -9 13 -24 11 -34 -3 -10 1 -23 9 -30 8 -6 11 -15
8 -20 -3 -5 2 -12 10 -15 9 -3 16 -13 16 -20 0 -8 9 -24 20 -36 11 -12 20 -34
20 -50 0 -15 5 -28 10 -28 6 0 10 -13 10 -30 0 -16 4 -30 9 -30 5 0 16 -16 25
-35 9 -19 21 -35 26 -35 6 0 10 -9 10 -19 0 -11 5 -23 10 -26 5 -3 10 -17 10
-30 0 -13 5 -27 10 -30 5 -3 10 -15 10 -26 0 -10 5 -19 10 -19 6 0 10 -9 10
-20 0 -11 5 -20 10 -20 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -15 10
-34 0 -21 6 -36 16 -40 8 -3 12 -11 9 -17 -4 -5 1 -16 11 -24 11 -7 16 -16 12
-20 -4 -4 4 -17 18 -30 14 -13 24 -34 24 -49 0 -14 5 -26 10 -26 6 0 10 -11
10 -25 0 -14 9 -35 20 -47 11 -12 20 -29 20 -39 0 -9 7 -20 16 -23 11 -4 15
-14 12 -31 -3 -14 0 -26 8 -29 8 -3 14 -15 14 -26 0 -11 5 -20 10 -20 6 0 10
-8 10 -18 0 -10 9 -24 20 -32 12 -8 20 -26 20 -42 0 -15 5 -30 10 -33 6 -3 10
-18 10 -33 0 -17 9 -34 25 -46 14 -11 24 -25 21 -32 -3 -7 2 -15 10 -18 8 -3
14 -15 14 -26 0 -11 5 -20 10 -20 6 0 10 -13 10 -30 0 -16 4 -30 8 -30 13 0
52 -79 52 -106 0 -13 5 -24 10 -24 6 0 10 -8 10 -19 0 -10 7 -24 15 -31 9 -7
13 -15 10 -18 -2 -3 7 -15 20 -28 14 -13 25 -30 25 -39 0 -8 5 -15 10 -15 6 0
10 -13 10 -29 0 -17 5 -33 10 -36 6 -3 10 -14 10 -23 0 -9 16 -30 35 -47 24
-21 35 -38 35 -57 0 -16 5 -28 10 -28 6 0 10 -13 10 -30 0 -19 7 -34 20 -42
11 -7 20 -21 20 -30 0 -10 5 -18 10 -18 6 0 10 -20 10 -45 0 -25 4 -45 9 -45
5 0 14 -13 20 -28 6 -15 23 -36 36 -46 14 -10 25 -29 25 -41 0 -25 59 -145 72
-145 4 0 8 -13 8 -29 0 -17 6 -31 16 -35 9 -3 14 -12 12 -18 -3 -7 9 -27 25
-45 35 -38 57 -78 57 -104 0 -10 5 -21 10 -24 6 -3 10 -14 10 -23 0 -10 11
-26 25 -36 14 -11 24 -25 21 -32 -3 -7 2 -15 10 -18 8 -3 14 -19 14 -36 0 -16
5 -30 10 -30 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -8 10 -18 0 -10 9
-24 20 -32 13 -9 20 -26 20 -46 0 -19 6 -34 15 -38 8 -3 12 -11 10 -18 -3 -7
6 -22 20 -32 14 -10 25 -27 25 -37 0 -11 5 -19 10 -19 6 0 10 -9 10 -20 0 -11
5 -20 10 -20 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -7 10 -15 0 -9 11
-28 24 -42 13 -14 22 -31 20 -38 -3 -7 2 -15 10 -18 9 -4 16 -14 16 -24 0 -10
9 -29 20 -43 11 -14 20 -32 20 -40 0 -9 9 -22 20 -30 12 -8 20 -26 20 -42 0
-15 5 -28 10 -28 6 0 10 -9 10 -19 0 -11 11 -28 25 -39 14 -11 25 -27 25 -36
0 -9 5 -16 10 -16 6 0 10 -9 10 -19 0 -11 5 -23 10 -26 6 -3 10 -14 10 -23 0
-10 9 -24 20 -32 11 -8 20 -22 20 -32 0 -10 5 -18 10 -18 6 0 10 -9 10 -19 0
-10 7 -22 16 -25 9 -4 14 -13 10 -21 -3 -9 1 -18 9 -21 8 -4 15 -15 15 -25 0
-10 5 -19 10 -19 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -8 10 -18 0 -10
9 -28 20 -40 11 -12 20 -28 20 -37 0 -8 5 -15 10 -15 6 0 10 -9 10 -19 0 -10
7 -22 16 -25 9 -4 14 -13 10 -21 -3 -9 1 -18 9 -21 8 -4 15 -15 15 -25 0 -10
9 -24 20 -31 11 -7 20 -20 20 -30 0 -9 5 -20 10 -23 6 -3 10 -17 10 -31 0 -14
9 -34 20 -46 11 -12 20 -27 20 -33 0 -5 11 -19 25 -29 14 -10 25 -27 25 -37 0
-11 5 -19 10 -19 6 0 10 -7 10 -15 0 -19 20 -55 43 -76 9 -9 17 -25 17 -37 0
-11 7 -23 16 -26 8 -3 12 -11 9 -16 -4 -6 3 -20 15 -30 25 -22 50 -65 50 -86
0 -8 4 -14 9 -14 5 0 11 -13 14 -29 3 -16 13 -36 22 -44 8 -9 15 -22 15 -30 0
-7 11 -24 25 -37 14 -13 25 -33 25 -45 0 -13 18 -42 40 -66 22 -24 40 -50 40
-56 0 -7 5 -13 10 -13 6 0 10 -7 10 -15 0 -9 11 -28 25 -43 14 -15 25 -32 25
-39 0 -7 5 -13 10 -13 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -8 10 -18
0 -11 9 -27 20 -37 11 -10 20 -26 20 -37 0 -10 5 -18 10 -18 6 0 10 -7 10 -15
0 -21 21 -56 48 -80 12 -11 22 -26 22 -33 0 -7 9 -22 20 -34 11 -12 20 -31 20
-41 0 -11 16 -35 35 -54 20 -19 33 -39 30 -44 -4 -5 1 -12 9 -15 9 -3 16 -15
16 -25 0 -10 5 -19 10 -19 6 0 10 -8 10 -19 0 -10 14 -32 30 -49 17 -18 30
-38 30 -46 0 -8 7 -16 15 -20 8 -3 12 -11 10 -18 -3 -7 6 -22 20 -32 14 -10
25 -25 25 -33 0 -8 9 -23 20 -35 11 -12 20 -28 20 -35 0 -7 5 -13 10 -13 6 0
10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -6 10 -14 0 -8 7 -17 16 -20 8 -3 12
-11 9 -17 -4 -5 1 -16 11 -24 11 -7 16 -17 12 -21 -4 -4 8 -20 27 -37 19 -16
35 -38 35 -48 0 -11 5 -19 10 -19 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10
-7 10 -16 0 -9 11 -27 25 -40 14 -13 25 -30 25 -39 0 -8 5 -15 10 -15 6 0 10
-8 10 -18 0 -9 12 -29 28 -43 15 -14 34 -39 42 -55 8 -16 23 -37 34 -46 21
-20 46 -64 46 -82 0 -6 9 -18 20 -26 11 -8 20 -21 20 -30 0 -8 16 -28 35 -45
19 -16 35 -38 35 -47 0 -10 5 -18 10 -18 6 0 10 -9 10 -20 0 -11 9 -25 20 -32
11 -7 20 -19 20 -26 0 -7 9 -22 20 -34 11 -12 20 -29 20 -38 0 -8 11 -24 25
-34 14 -10 25 -24 25 -31 0 -6 9 -20 20 -30 11 -10 20 -25 20 -34 0 -9 9 -23
20 -31 11 -8 20 -20 20 -28 0 -7 11 -24 25 -38 14 -14 23 -29 20 -34 -3 -4 11
-22 30 -39 19 -17 35 -37 35 -46 0 -8 4 -15 9 -15 5 0 16 -16 26 -36 9 -19 30
-48 46 -63 16 -16 29 -31 29 -35 0 -6 35 -49 68 -82 6 -7 12 -19 12 -26 0 -7
11 -22 25 -32 13 -10 22 -22 19 -27 -2 -4 11 -25 31 -46 19 -20 35 -42 35 -48
0 -6 10 -21 23 -32 20 -18 60 -81 58 -93 0 -3 15 -21 33 -40 18 -19 43 -51 56
-70 13 -19 35 -45 49 -57 14 -12 22 -25 18 -29 -3 -4 5 -15 18 -26 14 -11 25
-25 25 -32 0 -7 14 -27 30 -44 16 -18 30 -37 30 -42 0 -6 20 -31 45 -56 25
-24 45 -48 45 -54 0 -5 20 -31 45 -56 24 -26 42 -50 40 -54 -2 -4 16 -26 40
-50 25 -24 45 -48 45 -52 0 -10 26 -42 63 -78 15 -15 27 -31 27 -36 0 -8 18
-27 113 -124 20 -21 37 -42 37 -46 0 -5 18 -28 40 -52 22 -23 40 -47 40 -52 0
-6 25 -36 55 -68 30 -31 55 -60 55 -65 0 -4 24 -31 53 -61 28 -29 65 -69 82
-89 16 -21 36 -37 42 -37 7 0 13 -7 13 -16 0 -9 63 -80 140 -156 77 -77 140
-144 140 -150 0 -12 224 -238 237 -238 5 0 79 -69 164 -153 149 -146 193 -187
204 -187 3 0 34 -29 70 -65 35 -36 68 -65 74 -65 6 0 11 -4 11 -10 0 -5 5 -10
11 -10 7 0 30 -20 53 -45 24 -25 48 -45 55 -45 7 0 30 -18 51 -40 21 -22 43
-40 49 -40 6 0 26 -15 44 -32 17 -18 44 -39 60 -47 15 -8 27 -18 27 -23 0 -4
7 -8 15 -8 8 0 36 -20 62 -45 25 -25 51 -45 57 -45 6 0 25 -14 43 -30 18 -17
37 -30 44 -30 6 0 25 -15 43 -33 38 -40 89 -77 108 -77 7 0 20 -9 28 -20 8
-11 19 -20 26 -20 18 0 70 -29 89 -50 10 -11 23 -20 30 -20 6 0 24 -14 40 -30
16 -16 36 -30 45 -30 8 0 22 -9 30 -20 8 -11 21 -20 30 -20 8 0 24 -11 34 -25
10 -14 27 -25 37 -25 11 0 19 -4 19 -10 0 -5 7 -10 16 -10 9 0 29 -13 44 -30
15 -16 35 -30 44 -30 9 0 16 -4 16 -10 0 -5 15 -10 33 -10 21 0 38 -7 47 -20
16 -22 64 -50 87 -50 7 0 13 -4 13 -10 0 -5 8 -10 18 -10 10 0 24 -9 32 -20 8
-11 22 -20 32 -20 10 0 18 -4 18 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -8 0 -4
12 -13 28 -19 15 -7 46 -23 70 -37 23 -15 49 -26 57 -26 8 0 15 -4 15 -10 0
-5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5
9 -10 20 -10 11 0 20 -4 20 -9 0 -12 57 -41 81 -41 21 0 99 -41 99 -52 0 -4 9
-8 20 -8 11 0 20 -4 20 -10 0 -5 14 -10 30 -10 17 0 30 -4 30 -10 0 -5 9 -10
19 -10 11 0 22 -4 25 -9 10 -16 109 -61 133 -61 13 0 23 -4 23 -10 0 -5 9 -10
20 -10 11 0 20 -4 20 -10 0 -5 11 -10 25 -10 14 0 25 -3 25 -7 0 -10 49 -33
71 -33 9 0 22 -7 29 -15 7 -8 23 -15 36 -15 12 0 26 -4 29 -10 3 -5 14 -10 23
-10 10 0 24 -9 32 -20 9 -13 26 -20 47 -20 18 0 33 -4 33 -10 0 -5 23 -10 50
-10 28 0 50 -4 50 -10 0 -5 6 -10 13 -10 21 0 77 -30 77 -41 0 -5 13 -9 29 -9
17 0 33 -4 36 -10 3 -5 24 -10 46 -10 21 0 39 -4 39 -10 0 -5 9 -10 20 -10 11
0 20 -4 20 -10 0 -5 9 -10 19 -10 11 0 23 -4 26 -10 3 -5 22 -10 41 -10 19 0
34 -4 34 -10 0 -5 24 -10 54 -10 30 0 58 -4 61 -10 3 -5 17 -10 29 -10 13 0
29 -7 36 -15 7 -8 21 -15 31 -15 11 0 19 -4 19 -10 0 -5 14 -10 30 -10 17 0
30 -4 30 -10 0 -5 22 -10 49 -10 28 0 53 -4 56 -10 3 -5 24 -10 46 -10 21 0
39 -4 39 -10 0 -5 16 -10 35 -10 19 0 35 -4 35 -10 0 -5 15 -10 33 -10 18 0
38 -5 44 -11 12 -12 105 -28 208 -36 39 -2 74 -9 79 -14 6 -5 27 -9 48 -9 21
0 38 -4 38 -10 0 -5 22 -10 49 -10 28 0 53 -4 56 -10 3 -5 28 -10 56 -10 27 0
49 -4 49 -10 0 -5 25 -10 55 -10 30 0 55 -4 55 -10 0 -6 35 -10 84 -10 65 0
85 -3 90 -15 4 -11 22 -15 74 -15 37 0 72 -4 78 -9 12 -13 275 -20 1017 -27
346 -3 623 -2 630 3 7 5 115 6 242 2 130 -4 234 -3 239 2 6 5 38 9 73 9 35 0
63 4 63 10 0 6 14 10 30 10 18 0 33 6 36 15 4 8 13 15 20 15 8 0 24 9 36 20
12 11 34 20 50 20 15 0 28 5 28 10 0 6 9 10 20 10 11 0 20 5 20 10 0 6 11 10
24 10 43 0 150 64 223 134 40 38 70 73 66 79 -3 5 5 19 18 31 28 25 49 60 49
81 0 8 5 15 10 15 6 0 10 20 10 45 0 25 5 45 10 45 6 0 10 9 10 20 0 11 5 20
10 20 6 0 10 9 10 20 0 11 6 23 13 25 8 4 11 25 9 69 -3 47 0 67 12 79 14 13
16 53 16 286 0 169 4 272 10 276 6 3 10 52 10 111 0 63 4 104 10 104 6 0 10
28 10 65 0 37 4 65 10 65 6 0 10 32 10 75 0 43 4 75 10 75 6 0 10 35 10 85 0
50 4 85 10 85 6 0 10 18 10 40 0 25 5 43 14 46 8 3 11 15 8 29 -3 17 1 27 12
31 11 4 16 19 16 50 0 24 5 44 10 44 6 0 10 23 10 50 0 28 5 50 10 50 6 0 10
16 10 35 0 19 5 35 10 35 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 9 10 20 0 11
5 20 10 20 6 0 10 18 10 40 0 27 5 43 15 46 10 4 13 13 10 24 -3 10 1 25 9 33
21 21 56 85 56 102 0 8 5 15 10 15 6 0 10 13 10 29 0 18 8 35 24 46 13 10 22
24 19 32 -3 7 2 16 11 19 9 3 16 15 16 25 0 10 5 19 10 19 6 0 10 7 10 17 0
18 201 223 219 223 6 0 25 14 43 30 17 17 35 30 40 30 4 0 24 16 42 35 27 27
42 35 70 35 19 0 38 5 41 10 3 6 19 10 36 10 16 0 29 5 29 10 0 6 14 10 30 10
17 0 30 5 30 10 0 6 36 10 89 10 50 0 93 -4 96 -10 3 -5 24 -10 46 -10 21 0
39 -4 39 -10 0 -5 14 -10 30 -10 17 0 30 -4 30 -10 0 -5 16 -10 35 -10 19 0
35 -4 35 -10 0 -5 14 -10 30 -10 17 0 30 -4 30 -10 0 -5 7 -10 16 -10 9 0 25
-11 36 -25 11 -14 28 -25 39 -25 10 0 19 -4 19 -10 0 -5 8 -10 18 -10 10 0 24
-9 32 -20 8 -11 22 -20 32 -20 10 0 18 -4 18 -9 0 -5 15 -16 33 -24 18 -9 41
-25 52 -37 10 -11 22 -20 26 -20 8 0 36 -20 64 -47 8 -7 16 -13 19 -13 20 0
557 -539 595 -597 8 -12 25 -24 38 -28 13 -3 23 -12 23 -20 0 -8 38 -54 85
-101 47 -48 85 -91 85 -95 0 -5 29 -39 65 -76 36 -38 65 -72 65 -78 0 -5 30
-39 65 -75 36 -36 65 -69 65 -74 0 -5 11 -21 25 -35 55 -57 95 -105 95 -114 0
-9 25 -37 34 -37 10 0 56 -43 56 -52 0 -5 38 -47 85 -93 47 -46 85 -88 85 -94
0 -5 63 -74 140 -152 77 -78 140 -146 140 -151 0 -12 246 -258 258 -258 6 0
45 -34 86 -75 41 -41 79 -75 84 -75 5 0 22 -12 38 -27 16 -16 52 -46 79 -68
28 -22 62 -52 77 -67 14 -16 33 -28 42 -28 9 0 16 -4 16 -10 0 -5 9 -10 19
-10 11 0 37 -18 58 -40 37 -40 84 -70 107 -70 7 0 18 -9 26 -20 8 -11 22 -20
32 -20 10 0 18 -4 18 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 7 -10 15
-10 8 0 30 -16 49 -35 22 -22 45 -35 61 -35 14 0 25 -4 25 -10 0 -5 11 -10 25
-10 14 0 25 -4 25 -10 0 -5 9 -10 20 -10 11 0 20 -3 20 -7 0 -5 19 -16 43 -27
23 -10 50 -22 59 -27 10 -5 33 -9 52 -9 19 0 38 -5 41 -10 3 -6 15 -10 26 -10
10 0 19 -3 19 -7 0 -9 49 -33 67 -33 7 0 13 -4 13 -10 0 -5 20 -10 45 -10 25
0 45 -4 45 -10 0 -5 13 -10 29 -10 16 0 31 -6 35 -15 3 -9 18 -15 39 -15 18 0
37 -4 43 -9 5 -6 38 -11 74 -12 36 -1 70 -6 76 -11 7 -5 209 -7 475 -5 255 3
473 4 484 4 80 -5 2874 3 2963 8 61 3 112 10 112 16 0 5 13 9 29 9 16 0 31 6
35 15 3 9 18 15 36 15 16 0 30 5 30 10 0 6 20 10 45 10 25 0 45 5 45 10 0 6
14 10 30 10 17 0 30 5 30 10 0 6 14 10 30 10 17 0 30 5 30 10 0 6 9 10 19 10
11 0 23 4 26 10 3 5 19 10 35 10 16 0 32 5 35 10 3 6 17 10 29 10 13 0 29 7
36 15 7 8 18 15 24 15 6 0 18 9 26 20 8 11 19 20 26 20 28 0 76 36 154 116 47
48 84 89 82 91 -1 2 4 11 13 19 8 9 13 19 10 24 -3 4 2 13 10 20 7 6 12 17 9
24 -3 7 7 21 21 32 18 13 25 28 25 51 0 18 5 33 10 33 6 0 10 63 10 170 0 107
-4 170 -10 170 -5 0 -10 16 -10 35 0 19 -4 35 -10 35 -5 0 -10 9 -10 19 0 10
-7 21 -15 25 -9 3 -15 18 -15 36 0 16 -4 30 -10 30 -5 0 -10 18 -10 40 0 22
-4 40 -10 40 -5 0 -10 8 -10 18 0 11 -9 27 -20 37 -11 10 -20 26 -20 37 0 10
-4 18 -10 18 -5 0 -10 9 -10 20 0 11 -4 20 -10 20 -5 0 -10 9 -10 19 0 10 -7
22 -16 25 -9 3 -14 13 -10 21 3 8 -5 22 -19 34 -14 10 -25 23 -25 29 0 16 -43
92 -52 92 -4 0 -8 9 -8 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 16 0 9 -12 27
-26 40 -14 13 -23 28 -20 33 3 5 -6 17 -19 27 -14 10 -25 24 -25 30 0 19 -26
63 -58 98 -17 19 -29 36 -26 38 2 3 -7 15 -21 28 -14 13 -25 28 -25 34 -1 6
-14 27 -30 46 -17 19 -30 39 -30 45 0 5 -16 23 -35 40 -19 16 -35 38 -35 47 0
10 -4 18 -10 18 -5 0 -10 8 -10 18 0 9 -25 41 -55 70 -30 30 -53 57 -51 61 3
4 -15 27 -40 51 -24 24 -44 48 -44 55 0 6 -16 26 -35 45 -19 19 -35 39 -35 46
0 6 -34 46 -75 87 -41 42 -75 80 -75 85 0 5 -9 17 -20 27 -11 10 -20 24 -20
32 0 8 -76 91 -170 184 -93 93 -170 174 -170 180 0 5 -15 25 -32 43 -102 105
-146 154 -162 179 -9 15 -27 32 -39 37 -30 13 -67 46 -67 60 0 15 -89 102
-122 119 -16 8 -28 21 -28 28 0 17 -412 433 -429 433 -9 0 -92 80 -253 246
-88 90 -125 124 -137 124 -5 0 -78 68 -161 150 -83 83 -155 150 -159 150 -5 0
-66 56 -136 125 -70 69 -133 125 -140 125 -7 0 -21 11 -31 25 -10 14 -25 25
-32 25 -9 0 -12 6 -8 16 6 15 -38 63 -60 64 -13 0 -64 56 -64 69 0 6 -45 56
-100 112 -55 56 -98 104 -96 106 2 2 -32 40 -75 83 -44 43 -79 86 -79 94 0 9
-4 16 -8 16 -5 0 -15 12 -23 28 -8 15 -22 36 -31 46 -10 11 -18 25 -18 32 0 6
-7 17 -15 24 -8 7 -15 25 -15 41 0 16 -4 29 -10 29 -5 0 -10 7 -10 15 0 8 -9
23 -20 32 -11 10 -20 25 -20 35 0 10 -4 18 -10 18 -5 0 -10 14 -10 30 0 17 -4
30 -10 30 -5 0 -10 24 -10 54 0 39 -4 55 -15 60 -13 4 -15 34 -15 176 0 142 2
172 15 176 10 4 15 20 15 44 0 22 5 42 10 45 6 3 10 28 10 56 0 27 5 49 10 49
6 0 10 11 10 25 0 14 5 25 10 25 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 11 10
24 0 14 5 28 10 31 6 3 10 15 10 26 0 11 7 22 15 25 8 4 15 15 15 25 0 10 5
19 10 19 6 0 10 9 10 20 0 11 3 20 8 20 4 0 13 15 20 33 7 17 18 40 23 49 5
10 9 23 9 29 0 6 11 20 25 31 14 11 23 23 21 27 -3 4 6 19 20 34 13 14 24 31
24 38 0 6 14 24 30 39 17 15 30 32 30 38 0 21 28 61 68 100 23 22 42 47 42 55
0 7 9 22 20 32 11 10 20 24 20 31 0 7 12 22 26 33 14 11 23 24 20 29 -3 5 10
25 29 45 19 21 35 41 35 45 0 5 9 17 20 27 11 10 20 23 20 29 0 6 15 27 33 46
17 19 38 47 46 63 8 15 18 27 23 27 4 0 8 8 8 17 0 9 16 31 35 48 19 18 32 35
28 39 -4 3 9 21 28 39 19 17 41 47 48 65 7 17 18 32 23 32 14 0 55 44 62 66 3
11 21 36 40 56 18 19 35 40 37 46 2 6 22 30 44 54 22 24 46 55 54 71 8 15 18
27 23 27 4 0 8 7 8 15 0 9 16 30 35 49 19 18 32 35 30 38 -3 2 11 21 30 41 19
20 35 42 35 49 0 6 25 35 56 65 30 29 52 58 49 63 -3 6 1 13 9 16 9 3 16 17
16 30 0 13 4 24 9 24 12 0 83 71 77 77 -4 4 19 35 52 69 6 7 12 17 12 22 0 5
14 23 30 40 17 18 30 38 30 45 0 6 16 26 35 42 19 17 35 35 35 41 0 6 18 30
40 54 22 24 40 48 40 54 0 6 16 25 35 41 19 17 35 37 35 46 0 8 18 33 40 55
22 21 40 44 40 50 0 6 11 21 25 34 13 12 22 26 19 31 -2 4 11 24 31 43 19 19
35 40 35 45 0 6 15 25 33 43 17 18 33 39 35 47 2 8 16 26 32 41 17 16 30 33
30 40 0 6 13 25 30 43 16 18 30 37 30 42 0 6 16 24 35 40 19 17 35 37 35 45 0
9 18 34 40 55 22 21 40 44 40 50 0 6 6 17 13 24 6 6 12 16 12 21 0 4 15 24 33
44 17 19 32 41 32 48 0 7 11 22 25 32 13 10 22 23 19 28 -3 5 10 24 30 42 20
18 36 38 36 44 0 7 14 26 30 44 17 17 30 39 30 49 0 11 5 19 10 19 6 0 10 7
10 16 0 9 11 25 25 36 14 11 23 23 21 27 -3 4 10 24 29 44 19 21 35 40 35 45
0 4 15 23 33 42 19 19 32 41 29 48 -2 6 3 15 12 18 9 3 16 13 16 21 0 8 13 28
30 43 16 15 30 32 30 37 0 20 32 73 52 87 11 8 18 19 14 24 -3 5 6 20 19 32
14 13 25 29 25 35 0 7 14 26 30 43 17 18 30 39 30 47 0 8 5 15 10 15 6 0 10 8
10 18 0 9 16 31 35 47 19 17 35 38 35 48 0 9 5 17 10 17 6 0 10 6 10 14 0 20
21 57 55 95 17 18 29 37 28 41 -1 5 4 14 12 20 8 7 15 20 15 29 0 9 9 23 20
31 11 8 20 22 20 32 0 10 5 18 10 18 6 0 10 6 10 14 0 21 25 64 50 86 12 10
19 24 15 30 -3 5 1 13 9 16 9 3 16 14 16 23 0 10 14 30 30 46 17 16 30 37 30
47 0 10 5 18 10 18 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 9 10 20 0 11 7 23
16 27 8 3 13 12 10 20 -3 7 1 16 9 19 8 4 15 15 15 25 0 10 5 19 10 19 6 0 10
9 10 19 0 11 5 23 10 26 6 3 10 15 10 26 0 10 5 19 10 19 6 0 10 9 10 20 0 11
5 20 10 20 6 0 10 9 10 19 0 10 7 21 15 25 8 3 12 12 9 21 -4 9 1 17 10 21 11
4 14 13 11 24 -4 12 3 28 20 44 14 14 25 34 25 46 0 11 5 20 10 20 6 0 10 9
10 20 0 11 5 20 10 20 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 8 10 19 0 10
11 27 25 37 16 12 25 29 25 46 0 16 5 28 10 28 6 0 10 16 10 35 0 19 5 35 10
35 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 13
10 29 0 17 5 33 10 36 6 3 10 24 10 46 0 21 5 39 10 39 6 0 10 23 10 50 0 36
4 52 15 56 8 4 15 15 15 25 0 10 5 19 10 19 6 0 10 20 10 45 0 25 5 45 10 45
6 0 10 65 10 175 0 110 -4 175 -10 175 -5 0 -10 7 -10 16 0 9 -12 27 -26 39
-19 16 -25 29 -21 44 3 14 -1 24 -11 30 -9 5 -13 13 -9 17 8 8 -20 44 -34 44
-10 0 -38 22 -75 58 -13 12 -30 22 -37 22 -8 0 -17 7 -21 15 -3 8 -16 15 -29
15 -13 0 -33 9 -45 20 -12 11 -28 20 -37 20 -8 0 -15 5 -15 10 0 6 -13 10 -30
10 -16 0 -30 5 -30 10 0 6 -31 10 -74 10 -41 0 -78 5 -81 10 -3 6 -19 10 -36
10 -16 0 -29 5 -29 10 0 6 -23 10 -50 10 -36 0 -52 4 -56 15 -5 12 -28 15
-119 15 -65 0 -116 4 -120 10 -3 6 -21 10 -40 10 -19 0 -37 -4 -40 -10 -4 -7
-733 -10 -2154 -10 -1898 0 -2150 -2 -2161 -15 -7 -8 -23 -15 -36 -15 -13 0
-24 -4 -24 -10 0 -5 -11 -10 -25 -10 -14 0 -25 -4 -25 -10 0 -5 -13 -10 -30
-10 -16 0 -30 -4 -30 -10 0 -5 -11 -10 -25 -10 -14 0 -25 -4 -25 -10 0 -5 -7
-10 -15 -10 -8 0 -15 -7 -15 -15 0 -18 -59 -74 -77 -75 -7 0 -13 -6 -13 -12 0
-7 -14 -27 -30 -45 -17 -18 -30 -37 -30 -44 0 -6 -11 -22 -24 -34 -14 -13 -22
-26 -18 -29 3 -4 -6 -17 -21 -30 -15 -13 -27 -31 -27 -40 0 -9 -4 -16 -10 -16
-5 0 -10 -16 -10 -35 0 -19 -4 -35 -10 -35 -5 0 -10 -11 -10 -25 0 -14 -9 -35
-20 -47 -11 -12 -20 -32 -20 -44 0 -13 -4 -26 -10 -29 -5 -3 -10 -21 -10 -39
0 -19 -7 -39 -15 -46 -8 -7 -15 -20 -15 -29 0 -9 -9 -23 -20 -31 -11 -8 -20
-22 -20 -32 0 -10 -4 -18 -10 -18 -5 0 -10 -18 -10 -39 0 -22 -4 -43 -10 -46
-5 -3 -10 -18 -10 -32 0 -15 -10 -33 -25 -44 -14 -12 -22 -26 -19 -34 4 -8 -1
-18 -10 -21 -10 -4 -16 -18 -16 -35 0 -16 -4 -29 -10 -29 -5 0 -10 -17 -10
-38 0 -24 -7 -46 -20 -60 -11 -12 -20 -28 -20 -37 0 -8 -4 -15 -10 -15 -5 0
-10 -11 -10 -24 0 -14 -4 -28 -10 -31 -5 -3 -10 -20 -10 -36 0 -18 -6 -32 -16
-36 -8 -3 -13 -12 -10 -20 3 -7 -1 -16 -9 -19 -8 -4 -15 -17 -15 -30 0 -13 -4
-24 -10 -24 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -13 -10 -30 0
-16 -4 -30 -10 -30 -5 0 -10 -11 -10 -25 0 -14 -4 -25 -8 -25 -5 0 -15 -16
-22 -35 -8 -20 -19 -40 -26 -46 -7 -5 -10 -18 -7 -28 2 -10 -3 -25 -11 -34 -9
-8 -16 -22 -16 -29 0 -8 -9 -20 -20 -28 -11 -8 -20 -22 -20 -32 0 -10 -4 -18
-10 -18 -5 0 -10 -18 -10 -39 0 -22 -4 -43 -10 -46 -5 -3 -10 -14 -10 -25 0
-10 -7 -20 -15 -24 -8 -3 -12 -11 -9 -19 2 -7 -7 -22 -21 -33 -16 -12 -25 -28
-25 -46 0 -16 -4 -28 -10 -28 -5 0 -10 -7 -10 -15 0 -9 -9 -25 -20 -37 -11
-12 -20 -30 -20 -40 0 -10 -4 -18 -10 -18 -5 0 -10 -9 -10 -20 0 -11 -4 -20
-10 -20 -5 0 -10 -14 -10 -30 0 -18 -6 -33 -15 -36 -8 -4 -15 -15 -15 -25 0
-10 -4 -19 -10 -19 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -11
-10 -24 0 -14 -5 -28 -10 -31 -5 -3 -10 -17 -10 -31 0 -13 -4 -24 -10 -24 -5
0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10 -19 0 -10 -7 -22 -16
-25 -8 -3 -13 -10 -10 -15 4 -5 -1 -16 -9 -24 -8 -9 -15 -23 -15 -31 0 -9 -4
-16 -10 -16 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -10 -10 -23 0
-12 -9 -28 -20 -35 -11 -7 -20 -23 -20 -36 0 -14 -4 -28 -10 -31 -5 -3 -10
-13 -10 -21 0 -8 -11 -25 -25 -38 -14 -13 -25 -31 -25 -40 0 -9 -4 -16 -10
-16 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -6 -10 -13 0 -20 -28
-73 -45 -87 -8 -7 -15 -25 -15 -40 0 -15 -7 -30 -15 -34 -8 -3 -12 -11 -9 -19
2 -7 -7 -22 -21 -33 -14 -10 -25 -27 -25 -36 0 -10 -4 -18 -10 -18 -5 0 -10
-9 -10 -19 0 -11 -4 -23 -10 -26 -5 -3 -10 -15 -10 -26 0 -10 -4 -19 -10 -19
-5 0 -10 -6 -10 -14 0 -8 -12 -24 -26 -35 -14 -11 -23 -25 -20 -30 4 -5 -1
-16 -9 -24 -8 -9 -15 -23 -15 -31 0 -9 -4 -16 -10 -16 -5 0 -10 -9 -10 -20 0
-11 -6 -20 -14 -20 -16 0 -46 -42 -46 -65 0 -9 -6 -18 -14 -21 -8 -3 -13 -11
-10 -18 3 -7 -7 -21 -21 -32 -14 -10 -25 -27 -25 -36 0 -10 -4 -18 -10 -18 -5
0 -10 -9 -10 -19 0 -11 -4 -23 -10 -26 -5 -3 -10 -14 -10 -23 0 -10 -9 -23
-20 -30 -11 -7 -20 -21 -20 -31 0 -10 -7 -22 -16 -25 -8 -3 -13 -10 -9 -15 3
-5 -6 -21 -20 -35 -14 -14 -25 -31 -25 -38 0 -8 -9 -20 -20 -28 -11 -8 -20
-20 -20 -26 0 -19 -26 -63 -50 -84 -12 -10 -19 -24 -15 -30 3 -5 -1 -13 -9
-16 -9 -3 -16 -12 -16 -20 0 -8 -4 -14 -10 -14 -5 0 -10 -9 -10 -20 0 -11 -4
-20 -10 -20 -5 0 -10 -6 -10 -13 0 -7 -15 -28 -34 -46 -19 -18 -32 -40 -29
-47 3 -8 -2 -17 -11 -20 -9 -3 -16 -13 -16 -21 0 -9 -8 -23 -17 -32 -17 -15
-32 -38 -58 -88 -6 -12 -26 -35 -44 -52 -18 -17 -30 -35 -27 -40 3 -5 -1 -14
-9 -21 -8 -7 -15 -18 -15 -25 0 -7 -14 -26 -30 -42 -17 -15 -30 -35 -30 -44 0
-9 -7 -20 -16 -23 -8 -3 -13 -10 -10 -14 3 -5 -10 -25 -29 -45 -19 -21 -35
-44 -35 -52 0 -8 -4 -15 -10 -15 -5 0 -10 -6 -10 -14 0 -24 -25 -63 -68 -104
-23 -22 -42 -47 -42 -54 0 -8 -13 -27 -30 -43 -16 -16 -30 -34 -30 -39 0 -6
-20 -31 -45 -56 -24 -25 -44 -51 -45 -58 0 -8 -13 -27 -30 -42 -16 -15 -30
-33 -30 -39 0 -7 -11 -22 -25 -35 -13 -13 -22 -26 -18 -29 3 -4 -15 -27 -40
-51 -26 -24 -47 -49 -47 -56 0 -7 -29 -41 -65 -75 -36 -35 -65 -69 -65 -76 0
-6 -33 -45 -72 -85 -40 -40 -77 -78 -83 -84 -5 -5 -32 -32 -60 -58 -27 -27
-57 -58 -66 -68 -23 -28 -169 -158 -181 -163 -6 -2 -29 -21 -50 -42 -22 -22
-45 -39 -51 -39 -5 0 -24 -16 -43 -35 -18 -19 -40 -35 -48 -35 -9 0 -16 -4
-16 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -8 0 -5 -21 -19 -47 -32 -27
-12 -55 -26 -64 -30 -9 -5 -37 -11 -63 -15 -25 -4 -46 -11 -46 -16 0 -16 -175
-11 -206 6 -16 8 -37 15 -46 15 -10 0 -32 8 -50 19 -41 23 -233 210 -224 218
3 3 -10 21 -29 40 -19 19 -35 40 -35 48 0 8 -4 15 -10 15 -5 0 -10 13 -10 29
0 17 -4 33 -10 36 -5 3 -10 14 -10 23 0 10 -11 26 -25 36 -14 11 -25 29 -25
42 0 12 -4 26 -10 29 -5 3 -10 19 -10 36 0 35 -22 109 -32 109 -5 0 -8 16 -8
35 0 19 -4 35 -10 35 -5 0 -10 22 -10 49 0 28 -4 53 -10 56 -6 3 -10 48 -10
100 0 75 -3 94 -15 99 -14 5 -16 27 -14 138 1 89 -2 133 -10 136 -17 6 -16
792 2 792 9 0 11 113 9 540 -2 476 -1 541 13 546 13 5 15 73 15 545 0 353 3
539 10 539 6 0 10 92 10 255 0 163 4 255 10 255 7 0 10 150 10 430 0 280 -3
430 -10 430 -6 0 -10 40 -10 100 0 60 -4 100 -10 100 -6 0 -10 34 -10 81 0 60
-4 84 -16 96 -10 10 -14 27 -11 46 2 18 -1 31 -10 34 -7 3 -13 18 -13 34 0 16
-4 29 -10 29 -5 0 -10 9 -10 20 0 11 -4 20 -10 20 -5 0 -10 20 -10 45 0 25 -4
45 -10 45 -5 0 -10 12 -10 26 0 16 -13 38 -35 60 -20 19 -34 38 -31 43 3 4 -6
19 -19 31 -14 13 -25 28 -25 34 0 6 -14 25 -30 43 -17 18 -30 37 -30 43 0 13
-25 40 -36 40 -4 0 -20 14 -36 30 -15 17 -33 30 -38 30 -6 0 -10 4 -10 9 0 5
-12 12 -27 16 -15 3 -38 14 -52 24 -14 10 -41 24 -58 32 -18 8 -33 17 -33 21
0 5 -16 8 -35 8 -19 0 -35 5 -35 10 0 6 -16 10 -35 10 -19 0 -35 5 -35 10 0 6
-7 10 -16 10 -8 0 -22 7 -30 15 -9 8 -24 15 -34 15 -10 0 -22 5 -25 10 -3 6
-28 10 -56 10 -27 0 -49 5 -49 10 0 6 -32 10 -75 10 -43 0 -75 4 -75 10 0 6
-16 10 -35 10 -19 0 -35 5 -35 10 0 6 -18 10 -40 10 -22 0 -40 5 -40 10 0 6
-32 10 -75 10 -43 0 -75 4 -75 10 0 6 -35 10 -84 10 -65 0 -85 3 -90 15 -4 12
-26 15 -111 15 -63 0 -105 4 -105 10 0 6 -50 10 -130 10 -80 0 -130 4 -130 10
0 7 -352 10 -1035 10 -683 0 -1035 -3 -1035 -10z"/>
                      </g>
                    </svg>
                    Авторизация с VK ID
                    <span v-show="undefined !== data.networks['vk']" class="text-success ps-2">
                      <i class="fa-regular fa-circle-check"></i>
                      <span v-show="undefined !== data.networks['vk']" class="ps-2 text-secondary">{{ data.networks.vk }}</span>
                    </span>
                    <a v-show="undefined !== data.networks['vk']" class="ms-2" href="#" @click="openDeleteNetwork('vk')">Отключить</a>
                    <a v-show="undefined === data.networks['vk']" class="ms-2" target="_blank" :href="data.networkRegUrls.vk">
                      Подключить
                    </a>
                    <div class="fs-12px text-secondary">
                      Быстрая и удобная авторизация.
                      Всего одно нажатие — и с VK ID вы можете авторизоваться в aVIcrm.
                      Войти в aVIcrm с VK ID можно одним из удобных способов.
                      Например, с помощью кода из SMS или с OnePass.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 shadow mt-3">
              <div class="card-body">
                <div class="border-bottom pb-1 mb-1">Устройства</div>
                <div>
                  <table class="table table-sm table-striped">
                    <tbody>
                    <tr v-for="(device, i_device) in data.devices" :key="'d.' + i_device">
                      <td>
                        {{ device.name }} ({{ device.app_type }} v: {{ device.app_v }})
                        <div class="fs-10px text-secondary">{{ device.user_agent.replace(/###/g, ' ') }}</div>
                      </td>
                      <td>
                        {{ device.dateLastActivity }}
                      </td>
                      <td class="text-end">
                        <div @click="openDeleteDevice(device.uuid)" class="text-danger cursor-pointer">
                          <i class="fa-solid fa-trash"></i>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'vue-chartjs'
ChartJS.register(ArcElement, Tooltip, Legend)
import BaseModal from '@/components/BaseModal'
import ProfileNotificationChannelView from '@/views/app/profile/components/ProfileNotificationChannelView'
import ProfileNotificationSettingView from '@/views/app/profile/components/ProfileNotificationSettingView'
import ProfileNotificationList from '@/views/app/profile/components/ProfileNotificationList'

export default {
  name: 'ProfileView',
  components: {
    ProfileNotificationList,
    ProfileNotificationSettingView, ProfileNotificationChannelView, BaseModal, Doughnut},
  mixins: [formMixin],
  data() {
    return {
      formName: 'profile',
      formUrl: {string: '/profile'},
      editRoute: {name:  'profile'},
      exitRoute: {name: 'dashboard'},

      isShowDoughnutTasks: false,
      doughnutTasks: {
        data: {
          labels: ['В работе', 'Новые', 'На проверке'],
          datasets: [
            {
              backgroundColor: ['#ae55bb', '#c97ed3', '#daa6e1'],
              data: [0, 0, 0]
            }
          ]
        },
        option: {
          responsive: true,
          maintainAspectRatio: false
        }
      },

      data: {
        personnelStructures: [],
        networkRegUrls: {
          vk: '',
          yandex: '',
        },
        networks: [],
        devices: [],
        notificationChannels: [],
        notificationSettings: [],
      },

      currentDeleteNetwork: '',
      isDeleteNetworkModel: false,

      currentDeleteDevice: '',
      isDeleteDeviceModel: false,

      newNotificationProfile: {
        isModel: false,
        type: '',
        uuid: ''
      },

      newNotificationSetting: {
        isModel: false,
        uuid: ''
      },

      notificationChannelConfig: {
        iconTypes: {
          system: '<i class="fa-solid fa-bolt"></i>',
          app: '<i class="fa-solid fa-mobile-button"></i>',
          email: '<i class="fa-solid fa-envelope"></i>',
          messenger: '<i class="fa-solid fa-comments"></i>',
        },
        networkTypes: {
          system: '',
          email: '',
          app: '',
          telegram: '<i class="fa-brands fa-telegram" style="color: var(--app-telegram-bg)"></i>',
          vk: '<i class="fa-brands fa-vk" style="color: var(--app-vk-bg)"></i>',
        }
      }
    }
  },
  methods: {
    beforeLoad: function () {
      this.isShowDoughnutTasks = false
    },
    afterLoad: function() {
      this.doughnutTasks.data.datasets[0].data = this.data.tasks
      this.isShowDoughnutTasks = true
    },
    openDeleteNetwork: function (type) {
      this.currentDeleteNetwork = type
      this.isDeleteNetworkModel = true
      this.emitter.emit('modal', {event: 'open'})
    },
    deleteNetwork: function () {
      window.axios.delete(
          `${this.globalVar.app.api.url}/profile-network-user?uuid=${this.currentDeleteNetwork}&${(new Date()).getTime()}`
      ).then(() => this.load())
      this.close()
    },

    openDeleteDevice: function (uuid) {
      this.currentDeleteDevice = uuid
      this.isDeleteDeviceModel = true
      this.emitter.emit('modal', {event: 'open'})
    },

    deleteDevice: function () {
      window.axios.delete(
          `${this.globalVar.app.api.url}/profile-device?uuid=${this.currentDeleteDevice}&${(new Date()).getTime()}`
      ).then(() => this.load())
      this.close()
    },

    openNotificationChannelModel: function (type, uuid) {
      this.newNotificationProfile.isModel = true
      this.newNotificationProfile.type = type
      this.newNotificationProfile.uuid = uuid
      this.emitter.emit('modal', {event: 'open'})
    },

    openNotificationSettingModel: function (type, uuid) {
      this.newNotificationSetting.isModel = true
      this.newNotificationSetting.type = type
      this.newNotificationSetting.uuid = uuid
      this.emitter.emit('modal', {event: 'open'})
    },

    close: function () {
      this.isDeleteNetworkModel = false
      this.isDeleteDeviceModel = false
      this.newNotificationProfile.isModel = false
      this.newNotificationSetting.isModel = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.div-user-avatar {
  height: 70px;
}
.user-avatar {
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 34px;
  padding: 10px;
  background: var(--app-variant-color-item-7-bg);
  border-radius: 20px;
}

.svg-yandex {
  margin-top: -5px;
}

.svg-vk {
  margin-top: -5px;
}

.notification-channel-indicator {
  width: 130px;
  flex: 0 0 auto;
}

.notification-channel-body {
  width: calc(100% - 130px);
  flex: 0 0 auto;
}

.row:hover .hover-name-text {
  color: var(--app-nav-panel-active-color);
}
</style>
