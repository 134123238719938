/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      //console.log('Приложение обслуживается из кэша SW.')
    },
    registered () {
      //console.log('SW зарегистрирован.')
    },
    cached () { //cached (registration) {
      //console.log('Содержимое было кэшировано для использования в автономном режиме.')
      //console.log(registration)
      //console.log(registration.active.scriptURL)
    },
    updatefound () { // updatefound (registration) {
      //console.log('Загружается новый контент.')
      //console.log(registration)
      //console.log(registration.active.scriptURL)
    },
    updated(registration) {
      //console.log('Доступен новый контент; пожалуйста, обновите его.')
      document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
      )
    },
    offline () {
      //console.log('Подключение к Интернету не найдено. Приложение работает в автономном режиме.')
    },
    error (error) {
      console.error('Ошибка при регистрации SW:', error)
    }
  })
}
