<template>
  <div>
    <div v-if="current === must" class="progress-stacked">
      <div class="progress" role="progressbar" :style="'width: ' + current / all * 100 + '%'">
        <div class="progress-bar" style="background-color:var(--app-school-process-progress-current-norm-color);"></div>
      </div>
    </div>
    <div v-if="current > must" class="progress-stacked">
      <div class="progress" role="progressbar" :style="'width: ' + must / all * 100 + '%'">
        <div class="progress-bar" style="background-color:var(--app-school-process-progress-current-norm-color);"></div>
      </div>
      <div class="progress" role="progressbar" :style="'width: ' + (current - must) / all * 100 + '%'">
        <div class="progress-bar" style="background-color:var(--app-school-process-progress-must-debt-color);"></div>
      </div>
    </div>
    <div v-if="current < must" class="progress-stacked">
      <div class="progress" role="progressbar" :style="'width: ' + current / all * 100 + '%'">
        <div class="progress-bar" style="background-color:var(--app-school-process-progress-must-norm-color);"></div>
      </div>
      <div class="progress" role="progressbar" :style="'width: ' + (must - current) / all * 100 + '%'">
        <div class="progress-bar" style="background-color:var(--app-school-process-progress-current-debt-color);"></div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ProgressBar',
  props: {
    current: {
      type: Number,
      required: true,
    },
    must: {
      type: Number,
      required: false,
    },
    all: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style scoped>
</style>
