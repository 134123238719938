<template>
  <div class="page-content">
    <school-top-tabs />
    <school-processes-timetable-chart-view :current-process-uuid="null" />
  </div>
</template>

<script>

import SchoolTopTabs from '@/views/app/school/components/SchoolTopTabs'
import SchoolProcessesTimetableChartView from '@/views/app/school/components/SchoolProcessesTimetableChartView.vue'

export default {
  name: 'SchoolAnalyticsTimetablesView',
  components: {SchoolProcessesTimetableChartView, SchoolTopTabs},
}
</script>

<style scoped>
</style>
