<template>
  <div class="page-list">
    <page-top-bar
        :title="title"
        :subtitle="tabSubtitle"
        :buttons="pageButtons"
    />
    <page-top-tabs :tabs="listTabs" />

    <div class="page-list-content">
      <div class="card border-0 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-12 overflow-x-auto">
              <table class="table table-sm table-hover">
                <thead>
                <tr>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.status'" :name-field="'status'" :input-name="'Статус'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Задача'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.deadline_at'" :name-field="'deadline_at'" :input-name="'Крайний срок'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">Исполнитель(и)</th>
                </tr>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input-date-range :name-input="this.listName + '.search.deadlineAtDateRange'" :name-field="'deadlineAtDateRange'" :input-date="searchField_input" /></th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in items"
                    :key="'list.' + listName + '.' + i_item"
                    @click="openItem_click(item.uuid)"
                >
                  <td><span class="badge" :class="`task-bg-${item.status}`">{{ taskStatusName(item.status) }} </span></td>
                  <td>{{ item.name }}</td>
                  <td>
                    <div class="badge" :class="isValidDate(item.deadline_at) ? 'bg-success': 'bg-danger'">
                      {{  stringDateYmdhisToHidmy(item.deadline_at, item.is_time_in_deadline_at) }}
                    </div>
                  </td>
                  <td>{{ item.performers.join(', ') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-list-paginator
        :paginator="paginator"
        :link-page="paginatorLinkPage_click"
    />
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput'
import PageTopBar from '@/components/PageTopBar'
import PageListPaginator from '@/components/PageListPaginator'
import SortInput from '@/components/SortInput'
import PageTopTabs from '@/components/PageTopTabs'
import SearchInputDateRange from '@/components/SearchInputDateTimeRange'

export default {
  name: 'TasksList',
  components: {SearchInputDateRange, PageTopTabs, SortInput, PageTopBar, PageListPaginator, SearchInput},
  mixins: [listMixin],
  data() {
    return {
      listName: 'TasksList',
      listUrl: {string: '/tasks', params:[]},
      itemNameRoute: 'task',
      pageButtons: [],

      listTabs: [],
      title: '<i class="fa-solid fa-list-check"></i> Задачи',
      tabSubtitle: '',

      listSearch: {
        typeList: '',
        name: '',
        deadlineAtDateRange: [],
      },
      listSearchDefault: {
        typeList: 'on-performance',
        name: '',
        deadlineAtDateRange: [],
      },
    }
  },
  methods: {
    setListTabs: function () {
      let self = this

      let listParams = [
        {
          name: 'На исполнении',
          typeList: 'on-performance',
          subtitle: 'Список задач, которые Вам необходимо выполнить'
        },
        {
          name: 'Проверить результат',
          typeList: 'check-result',
          subtitle: 'Необходимо принять результат выполнения данных задач'
        },
        {
          name: 'Подписки',
          typeList: 'subscriptions',
          subtitle: 'Простое наблюдение за ходом выполнение задач'
        },
        {
          name: 'Черновики',
          typeList: 'drafts',
          subtitle: 'Задачи доступны только Вам, не зависимо от прикрепленных пользователей'
        },
        {
          name: 'Все актуальные',
          typeList: 'all',
          subtitle: 'Все актуальные задачи'
        },
        {
          name: 'Завершенные',
          typeList: 'finished',
          subtitle: 'Все завершенные задач'
        },
      ]

      this.listTabs = []
      for (let i in listParams) {
        let isActive = listParams[i].typeList === this.listSearch.typeList

        if (isActive) {
          this.tabSubtitle = listParams[i].subtitle
        }

        this.listTabs.push({
          name: listParams[i].name,
          isDisabled: false,
          isActive: isActive,
          click: function () {
            self.searchField_input(listParams[i].typeList ,'typeList', false)
          },
        })
      }
    },

    taskStatusName: function(status) {
      for (let i in this.globalVar.db.taskStatuses) {
        if (status === this.globalVar.db.taskStatuses[i].uuid) {
          return this.globalVar.db.taskStatuses[i].name
        }
      }
      return ''
    },

    afterLoad: function () {
      this.setListTabs()

      let self = this
      self.pageButtons = []
      if (-1 !== self.buttons.indexOf('addTask')) {
        self.pageButtons.push({
          name: 'Новая задача',
          class: 'btn-primary', click: function() {
            self.toRoute({name: self.itemNameRoute, params: {uuid: 'new'}})
          }
        })
      }
    },
  }
}
</script>

<style scoped>
</style>
