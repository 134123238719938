<template>
  <div class="query-builder-item query-builder-rule">
    <button class="btn btn-very-sm btn-outline-danger query-builder-rule-btn-delete" @click="deleteObject(indexes)">
      <i class="fa-solid fa-trash"></i>
    </button>
      <div class="query-builder-rule-fields text-start">
        <div class="row">
          <div class="col-md-4 mb-1">
            <multiselect-input
                v-model="field"
                :list="fieldsList"
                :is-global-close="false"
                :after-select-option="updateField"
            />
          </div>
          <div v-if="1 < conditionList.length" class="col-md-4 mb-1">
            <multiselect-input
                v-model="condition"
                :list="conditionList"
                :is-global-close="false"
                :after-select-option="updateCondition"
            />
          </div>
          <div class="col-md-4 mb-1">
            <div v-if="!inArray(condition, confNotValues)">
              <div v-if="'text' === fieldType">
                <input @input="updateValue" class="form-control" v-model="value">
              </div>
              <div v-if="'select' === fieldType">
                <multiselect-input
                    v-model="value"
                    :list="fieldValues"
                    :is-global-close="false"
                    :after-select-option="updateValue"
                />
              </div>
              <div v-if="'bool' === fieldType" class="pt-2">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio"
                         :checked="'true' === value"
                         :name="'groupConnector.' + indexes.join('.') + '.radio.' + field + '.true'"
                         :id="'groupConnector.' + indexes.join('.') + '.radio.' + field + '.true'"
                         @change="updateBoolValue('true')"
                  >
                  <label class="form-check-label" :for="'groupConnector.' + indexes.join('.') + '.radio.' + field + '.true'">
                    {{ fieldValues.titleTrue }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio"
                         :checked="'false' === value"
                         :name="'groupConnector.' + indexes.join('.') + '.radio.' + field + '.false'"
                         :id="'groupConnector.' + indexes.join('.') + '.radio.' + field + '.false'"
                         @change="updateBoolValue('false')"
                  >
                  <label class="form-check-label" :for="'groupConnector.' + indexes.join('.') + '.radio.' + field + '.false'">
                    {{ fieldValues.titleFalse }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import MultiselectInput from '@/components/form/MultiselectInput'

export default {
  name: 'SearchQueryBuilderRule',
  components: {MultiselectInput},
  props: {
    data: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    indexes: {
      type: Array,
      required: true,
    },
    updateObject: {
      type: Function,
      required: true,
    },
    deleteObject: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      condition: null,
      field: null,
      value: null,

      fieldType: '',
      fieldValues: [],

      fieldsList: [],
      conditionList: [],

      confNotValues: ['is_empty', 'is_not_empty'],

      conditionLists: {
        text: [
          {uuid: 'equal', name: 'равно'},
          {uuid: 'not_equal', name: 'не равно'},
          {uuid: 'begins_with', name: 'начинается с'},
          {uuid: 'not_begins_with', name: 'не начинается с'},
          {uuid: 'contains', name: 'содержит'},
          {uuid: 'not_contains', name: 'не содержит'},
          {uuid: 'ends_with', name: 'оканчивается на'},
          {uuid: 'not_ends_with', name: 'не оканчивается на'},
          {uuid: 'is_empty', name: 'пусто'},
          {uuid: 'is_not_empty', name: 'не пусто'},
        ],
        select: [
          {uuid: 'equal', name: 'равно'},
          {uuid: 'not_equal', name: 'не равно'},
          {uuid: 'is_empty', name: 'пусто'},
          {uuid: 'is_not_empty', name: 'не пусто'},
        ],
        bool: [
          {uuid: 'equal', name: 'равно'},
        ],
        date: [
          {uuid: 'equal', name: 'равно'},
          {uuid: 'not_equal', name: 'не равно'},
          {uuid: 'less', name: 'меньше'},
          {uuid: 'less_or_equal', name: 'меньше или равно'},
          {uuid: 'greater', name: 'больше'},
          {uuid: 'greater_or_equal', name: 'больше или равно'},
          {uuid: 'begins_with', name: 'начинается с'},
          {uuid: 'not_begins_with', name: 'не начинается с'},
          {uuid: 'contains', name: 'содержит'},
          {uuid: 'not_contains', name: 'не содержит'},
          {uuid: 'ends_with', name: 'оканчивается на'},
          {uuid: 'not_ends_with', name: 'не оканчивается на'},
          {uuid: 'is_empty', name: 'пусто'},
          {uuid: 'is_not_empty', name: 'не пусто'},
        ],
      },
    }
  },
  mounted() {
    this.setData()
    this.setFieldsList()
    this.setConditionList()
  },
  methods: {
    setData: function () {
      this.condition = this.data.condition
      this.field = this.data.field
      this.value = this.data.value

      this.fieldType = this.getValueEnums(this.fields, this.field, 'type')
      this.fieldValues = this.getValueEnums(this.fields, this.field, 'values')
    },
    setFieldsList: function () {
      this.fieldsList = []
      for (let f in this.fields) {
        this.fieldsList.push({
          uuid: this.fields[f].uuid,
          name: this.fields[f].name,
          type: this.fields[f].type,
        })
      }
    },
    setConditionList: function () {
      if (false === this.empty(this.field)) {
        let type = this.getValueEnums(this.fieldsList, this.field, 'type')
        this.conditionList = JSON.parse(JSON.stringify(this.conditionLists[type]))

        if (1 === this.conditionList.length) {
          this.condition = this.conditionList[0].uuid + ''
        }
      }
    },

    updateField: function () {
      this.condition = ''
      this.value = ''

      this.setConditionList()

      this.fieldType = this.getValueEnums(this.fields, this.field, 'type')
      this.fieldValues = this.getValueEnums(this.fields, this.field, 'values')

      this.updateRule()
    },

    updateCondition: function () {
      this.value = ''

      this.updateRule()
    },

    updateValue: function () {
      this.updateRule()
    },

    updateBoolValue: function (value) {
      this.value = value + ''
      this.updateRule()
    },

    updateRule: function () {
      this.updateObject(this.indexes, this.condition, this.field, this.value)
    }
  },
}
</script>

<style scoped>
</style>
