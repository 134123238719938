<template>
  <div>
    <div class="mb-2 text-start">
      <label class="form-label">Способ сохранения</label>
      <multiselect-input
          v-model="data.save_to_uuid"
          :list="list"
          :is-bg-click-close="false"
          :is-global-close="false"
          :disabled="!inArray('save_to_uuid', enabledFields)"
          :class-name="{ 'is-invalid': !!errors['save_to_uuid'] }"
      />
      <div class="text-danger" v-if="!!errors['save_to_uuid']">{{ errors['save_to_uuid'].join(', ') }}</div>
    </div>
    <Transition name="edit-template-name">
      <div v-if="'new' === data.save_to_uuid" class="mb-2 text-start">
        <label class="form-label">Наименование</label>
        <input
            type="text"
            class="form-control"
            placeholder="То что надо"
            v-model="data.name"
            :disabled="!inArray('name', enabledFields)"
            :class="{ 'is-invalid': !!errors['name'] }"
        >
        <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
      </div>
    </Transition>
    <div class="text-end mt-3">
      <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="saveTemplate">Сохранить</a>
      <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="close">Закрыть</a>
    </div>
  </div>
</template>
<script>

import {subformMixin} from '@/mixins/subformMixin'
import MultiselectInput from '@/components/form/MultiselectInput'

export default {
  name: 'SearchQueryBuilderSearchTemplateView',
  mixins: [subformMixin],
  components: {MultiselectInput},
  props: {
    uuid: {
      type: String,
      required: true
    },
    nameTemplates: {
      type: String,
      required: true
    },
    listTemplate: {
      type: Array,
      required: true,
    },
    queryJson: {
      type: Object,
      required: true,
    },
    updateParent: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    }
  },
  mounted: function() {
  },
  data() {
    return {
      formName: 'search-template',
      formUrl: {
        string: '/search-template?uuid=${uuid}',
        params: {uuid: 'new'}
      },
      editRoute: {name:  'search-template'},
      exitRoute: {name: 'search-template'},

      list: [],
      data: {
        save_to_uuid: '',
        type: '',
        name: '',
        query_json: {},
      },
    }
  },
  methods: {
    afterLoad: function () {
      this.list = [
        {uuid: 'new', name: 'Сохранить как новый шаблон'}
      ]
      for (let i in this.listTemplate) {
        let itemTemplate = this.listTemplate[i]
        this.list.push(
            {uuid: itemTemplate.uuid + '', name: 'Сохранить изменения в "' + itemTemplate.name + '"'}
        )
      }

      this.data.save_to_uuid = this.uuid + ''
      this.data.type = this.nameTemplates + ''
      this.data.query_json = JSON.parse(JSON.stringify(this.queryJson))
    },
    afterSave: function () {
      localStorage.setItem(this.nameTemplates + '.template', this.data.uuid)
      localStorage.setItem(this.nameTemplates + '.query', JSON.stringify(this.data.query_json))
      this.updateParent()
      this.close()
    },
    saveTemplate: function () {
      this.save()
    },
  }
}
</script>

<style>
.label-query-builder-container {
  position: relative;
  cursor: pointer;
  border: 1px solid var(--bs-border-color);
  padding: 6px 10px 6px 30px;
  min-height: 38px;
}

.label-query-builder-container-indicator {
  position: absolute;
  left: 10px;
  top: 6px;
  color: rgb(var(--bs-secondary-rgb));
}

.label-query-builder-template {

}

.label-query-builder-group {
  display: inline-block;
  border: 1px solid var(--bs-border-color);
  padding: 0 6px;
  border-radius: 6px;
}

.label-query-builder-connector {
  display: inline-block;
  color: rgb(var(--bs-secondary-rgb));
  padding: 0 6px;
}

.label-query-builder-rule {
  display: inline-block;
  padding: 0;
}

.label-query-builder-condition {
  display: inline-block;
  color: rgb(var(--bs-secondary-rgb));
  padding: 0 6px;
}

.label-query-builder-value {
  display: inline-block;
}

.query-builder-item {
  margin-top: 6px;
}

.query-builder-item > .query-builder-item:first-child {
  margin-top: 0;
}

.query-builder-group {
  border: 1px solid var(--bs-border-color);
  padding: 2px 10px;
}

.query-builder-rule {
  position: relative;
}
.query-builder-rule-btn-delete {
  position: absolute;
  right: 0;
  top: 6px;
}

.query-builder-rule-fields {
  margin-right: 45px;
}

.edit-template-name-enter-active,
.edit-template-name-leave-active {
  transition: opacity 0.5s ease;
}

.edit-template-name-enter-from,
.edit-template-name-leave-to {
  opacity: 0;
}

</style>

<style scoped>
</style>
