<template>
  <div>
    <div>
      <div v-if="inArray('addMessage', buttons)" class="my-2" :class="{'add-message-button-container': 0 === items.length}">
        <div v-show="isEditMessage">
          <quill-editor
              v-model:value="editMessage.message"
              :options="{placeholder: 'Вставьте содержимое сюда …'}"
          ></quill-editor>
          <div class="text-end mt-1">
            <button class="btn btn-sm btn-primary" type="submit" @click="addMessage">
              Добавить
            </button>
            <button class="btn btn-sm btn-light ms-1" type="submit" @click="isEditMessage = false">
              Отмена
            </button>
          </div>
        </div>
        <div class="float-start" v-show="!isEditMessage">
          <button class="btn btn-sm btn-outline-secondary w-100" type="submit" @click="startEditMessage('new')">
            Добавить комментарий
          </button>
        </div>
      </div>
      <page-list-paginator
          v-if="0 < items.length && false === isLoading"
          :paginator="paginatorList"
          :link-page="paginatorLinkPage_click"
          size="sm"
      />
    </div>
    <div class="mb-3" v-for="(message,i_message) in items" :key="'m.' + i_message">
      <div class="text-secondary fs-12px mb-1">
        <i class="fa-regular fa-clock"></i> {{ message.created_at }}
        <span class="ps-1"><i class="fa-regular fa-user"></i> {{ message.user }}</span>
      </div>
      <div class="border border-radius-10 px-3 py-2" v-html="message.message"></div>
    </div>
    <div class="d-block" v-if="0 === items.length && false === isLoading">
      <div class="my-3 text-center text-secondary">
        Нет комментариев
      </div>
    </div>
    <div class="d-block text-center py-2" v-if="isLoading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import PageListPaginator from '@/components/PageListPaginator'
import { quillEditor } from 'vue3-quill'

export default {
  name: 'TaskMessagesList',
  components: {PageListPaginator, quillEditor},
  mixins: [listMixin],
  props: {
    taskUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      listName: 'TaskMessagesList',
      listUrl: {string: '/task-messages', params:[]},
      itemNameRoute: 'task-messages',
      pageButtons: [],

      listSearch: {
        taskUuid: this.taskUuid,
      },
      listSearchDefault: {
        taskUuid: this.taskUuid,
      },

      paginatorList: {
        currentPage: 0,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
      },

      isEditMessage: false,
      editMessage: {
        uuid: 'new',
        message: '',
      },
    }
  },
  watch: {
    paginator: function (newVal) {
      let self = this
      setTimeout(function () {
        self.paginatorList = newVal
      }, 10)
    },
  },
  mounted: function() {
    this.emitter.on('taskMessagesList', this.emitterTaskMessagesList_reload)
  },
  unmounted: function() {
    this.emitter.off('taskMessagesList', this.emitterTaskMessagesList_reload)
  },
  methods: {
    emitterTaskMessagesList_reload: function () {
      this.load()
    },
    startEditMessage(uuid) {
      this.isEditMessage = true
      this.editMessage.uuid = uuid
      this.editMessage.message = ''
    },
    addMessage: function () {
      this.emitter.emit('app', {event: 'spinner.show'})
      this.isEditMessage = false

      let self = this
      window.axios.post(`${this.globalVar.app.api.url}/task-message?uuid=${this.editMessage.uuid}&${(new Date()).getTime()}`, {
        taskUuid: this.taskUuid,
        message: this.editMessage.message,
      })
          .then(response => {
            if (false === self.empty(response.data.dataErrors)) {
              self.errors = JSON.parse(JSON.stringify(response.data.dataErrors))
            }
            self.generalError = response.data.generalError

            if (false === self.empty(self.generalError)) {
              this.app_addPush('Ошибка добавления комментария: ' + self.generalError)
            }

            if (true === response.data.result) {
              self.load()
            }
          })
          .finally(() =>
              this.emitter.emit('app', {event: 'spinner.hide'}))
    },
  },
}
</script>

<style scoped>
.page-list-pagination {
  display: inline-block;
  padding: 0;
}

.add-message-button-container {
  min-height: 38px;
}
</style>
