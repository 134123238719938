<template>
  <div class="container-fluid mt-3">
    <div v-if="isLoaded">
      <h5 class="card-title placeholder-glow">
        <span class="placeholder col-6"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-12 my-2 fs-26px"></span>
        <span class="placeholder col-12 my-2 fs-26px"></span>
      </p>
      <div class="text-end">
        <a class="btn btn-primary disabled placeholder col-6" aria-disabled="true"></a>
      </div>
    </div>
    <div v-if="false === isLoaded" class="text-start">
      <div class="mb-2 text-secondary">
        Вы можете опционально загрузить информацию, для этого необходимо указать только нужные пункты (по умолчанию выбрано все).
      </div>

      <div
          class="ms-2"
          v-for="(type, i_type) in (new Set(data.relationUsers.map(function(item) {return item.typeName})))"
          :key="'u.i.' + i_type"
      >
        <div class="text-secondary">{{ type }}</div>
        <div class="ms-3">
          <div
              v-for="(relation, i_relation) in data.relationUsers"
              :key="'u.i.' + i_type + '.user.' + i_relation"
              :class="{'d-none': type !== relation.typeName}"
              class="my-1"
          >
            <input type="checkbox"
                   :disabled="false === relation.isEnabled"
                   :checked="relation.isCopy"
                   @click="updateDataCopyByItem(relation)"
                   class="form-check-input"
            >
            <label class="form-check-label" @click="updateDataCopyByItem(relation)">{{ relation.userName }}</label>
            <div v-if="false === relation.isEnabled" class="text-danger">
              Не возможно добавить в текущий процесс обучения: <b>{{ relation.enabledText }}</b>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-2 fs-16px">
        Слушатели
      </div>

      <div class="overflow-x-auto">
        <table class="table table-sm table-hover table-striped">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Контрагент</th>
            <th scope="col">ФИО (контактные данные)</th>
            <th scope="col">Комментарий</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              class="cursor-pointer"
              v-for="(item, i_item) in data.students"
              :key="'students.' + i_item"
              @click="updateDataCopyByItem(item)"
          >
            <td>
              <input type="checkbox"
                     :disabled="false === item.isEnabled"
                     :checked="item.isCopy"
                     @click="updateDataCopyByItem(item)"
                     class="form-check-input"
              >
            </td>
            <td>{{ item.crmContractorName }}</td>
            <td>
              {{ item.name }}
              <div class="text-secondary">{{ item.email }}</div>
              <div class="text-secondary">{{ item.phone }}</div>
            </td>
            <td>{{ item.comment }}</td>
            <td>
              <div v-if="false === item.isEnabled" class="text-danger">
                Не возможно добавить в текущий процесс обучения: <b>{{ item.enabledText }}</b>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {subformMixin} from '@/mixins/subformMixin'

export default {
  name: 'SchoolPlanningProcessCopyProgressFormView',
  mixins: [subformMixin],
  props: {
    uuid: {
      type: String,
      required: true
    },
    currentCourseUuid: {
      type: String,
      required: true,
    },
    updateParent: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    }
  },
  beforeMount() {
    this.formUrl.params.uuid = this.uuid + ''
    this.formUrl.params.currentCourseUuid = this.currentCourseUuid + ''
  },
  data() {
    return {
      formName: 'school-copy-process',
      formUrl: {
        string: `/school-copy-process?uuid=$\{uuid}&currentCourseUuid=$\{currentCourseUuid}`,
        params: {uuid: ''}
      },
      editRoute: {name:  'school-copy-process'},
      exitRoute: {name: 'school-copy-process'},

      data: {
        relationUsers: [],
        students: [],
      },
    }
  },
  methods: {
    afterLoad: function () {
      this.updateDataCopy()
    },
    updateDataCopyByItem: function (item) {
      if (true === item.isEnabled) {
        item.isCopy = !item.isCopy
        this.updateDataCopy()
      }
    },

    updateDataCopy: function () {
      this.updateParent({
        relationUsers: this.data.relationUsers.filter(function (i) {
          return i.isCopy
        }),
        students: this.data.students.filter(function (i) {
          return i.isCopy
        }),
      })
    },
  }
}
</script>

<style scoped>
</style>
