<template>
  <div class="page-content">
    <school-top-tabs />

    <div>
      <page-top-bar
          :title="titleTypeRelation"
          :subtitle="'Список типов ответственных на курсах и их соответствия должностям'"
          :buttons="[]"
      />
      <div class="row">
        <div class="col-12">
          <div class="card border-0 shadow">
            <div class="card-body">
              <school-relation-user-types-list :key="'school-relation-user-types-list'" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-4">
      <page-top-bar
          :title="titleCourse"
          :subtitle="'Перечень курсов'"
          :buttons="[]"
      />
      <div class="row">
        <div class="col-12">
          <div class="card border-0 shadow">
            <div class="card-body">
              <school-courses-list :key="'school-courses-list'" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PageTopBar from '@/components/PageTopBar'
import SchoolTopTabs from '@/views/app/school/components/SchoolTopTabs'
import SchoolCoursesList from '@/views/app/school/course/SchoolCoursesList'
import SchoolRelationUserTypesList from '@/views/app/school/course/SchoolRelationUserTypesList'

export default {
  name: 'SchoolSettingView',
  components: {SchoolRelationUserTypesList, SchoolCoursesList, SchoolTopTabs, PageTopBar},
  data() {
    return {
      titleTypeRelation: '<i class="fa-solid fa-person-chalkboard"></i> Перечень ответственных',
      titleCourse: '<i class="fa-solid fa-book-open"></i> Курсы',
    }
  },
  mounted() {
    this.emitter.emit('app', {event: 'spinner.hide'})
  },
}
</script>

<style scoped>
</style>
