import {formMixin} from '@/mixins/formMixin'
export const subformMixin = {
    mixins: [formMixin],
    data() {
        return {
            isLoadByMounted: true,
            formName: '',
            formUrl: {string: '', params: {}},
            data: {
                buttons: [],
            },
            enabledFields: [],
            generalError: '',
            errors: {},

            buttons: [],

            _formUrl: '',
        }
    },
    methods: {
        makeUrl() {
            this._formUrl = this.globalVar.app.api.url + this.formUrl.string
            for (let formUrlParam in this.formUrl.params) {
                this._formUrl = this._formUrl.replace( '${'+formUrlParam+'}', this.formUrl.params[formUrlParam])
            }
            this._formUrl = this._formUrl + (-1 === this._formUrl.indexOf('?') ? '?' : '&') + (new Date()).getTime()
        },
        save() {
            let self = this
            this.makeUrl()

            this.emitter.emit('app', {event: 'spinner.show'})
            this.clearErrors()

            this.beforeSave()

            for (let i in this.data) {
                if ('null' === this.data[i] || 'undefined' === this.data[i]) {
                    this.data[i] = null
                }
            }

            window.axios
                .post(this._formUrl, this.data)
                .then((response) => {

                    let isAfterResult = null

                    if (false === self.empty(response.data.operation) && 'upsert' === response.data.operation) {
                        if (true === response.data.result) {
                            let newData = JSON.parse(JSON.stringify(response.data.data))
                            Object.assign(self.data, newData)

                            for (let formUrlParam in this.formUrl.params) {
                                if (undefined !== self.data[formUrlParam])
                                self.formUrl.params[formUrlParam] = self.data[formUrlParam] + ''
                            }

                            self.enabledFields = JSON.parse(JSON.stringify(response.data.enabledFields))

                            isAfterResult = true
                        } else {
                            isAfterResult = false
                        }
                    }

                    if (false === self.empty(response.data.dataErrors)) {
                        self.errors = JSON.parse(JSON.stringify(response.data.dataErrors))
                    }
                    self.generalError = response.data.generalError

                    self.emitter.emit('app', {event: 'spinner.hide'})

                    if (true === isAfterResult) {
                        self.pushButtons()
                        self.afterLoad()
                        self.afterSave()
                    }
                    if (false === isAfterResult) {
                        self.afterErrorSave()
                    }
                })
                .catch((error) => {
                    if(error.response !== undefined && error.response['data'] !== undefined) {
                        self.errors = error.response.data['errors']
                        self.generalError = error.response.data['generalError']
                    }
                    self.emitter.emit('app', {event: 'spinner.hide'})
                    self.afterErrorSave()
                })
        },
        formDelete() {
            let self = this
            self.makeUrl()
            window.axios.delete(self._formUrl).then(() => self.afterDelete());
        },
        afterDelete() {

        },
    }
}
