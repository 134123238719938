<template>
  <div class="page-content">
    <base-modal :is-max-width="false" :title="'Пункт: ' + editStructure.current.name" :show="editStructure.isModel" :is-bg-click-close="false" :footer="true" @close="close">
      <template v-slot:body>
        <div class="text-start">
          <div>
            <label class="form-label">Наименование</label>
            <input type="text" class="form-control" placeholder="Первый урок"
                   v-model="editStructure.current.name"
                   :class="{ 'is-invalid': !!errors['name'] }"
            >
          </div>
          <div v-if="undefined === editStructure.current.children" class="ms-2">
            <div class="mt-3">
              <label class="form-label">Варианты значения</label>
              <div class="fs-12px text-secondary">
                Дополнительно на против каждого пункта укажите кол-во баллов (0-100).
                Данные баллы будут учитываться в аналитических показателях.
              </div>
              <div class="input-group mt-1" v-for="(itemValue, i_itemValue) in editStructure.current.values" :key="'editStructure.value.' + i_itemValue">
                <span class="input-group-text" style="width:54px">{{ itemValue.point }}</span>
                <div class="input-group-text"  style="width:120px">
                  <input type="range" v-model="itemValue.point" class="form-range" min="0" max="100" step="5">
                </div>
                <input type="text" v-model="itemValue.name" class="form-control" placeholder="На лекции был" aria-label="Наименование">
                <button class="btn btn-outline-danger" @click="editStructure_values_delete(i_itemValue)" type="button">
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
              <button class="btn btn-very-sm btn-outline-primary mt-1" @click="editStructure.current.values.push({name: '', point: 100})">
                <i class="fa-solid fa-plus"></i> вариант
              </button>
            </div>
            <div class="mt-3">
              <input type="checkbox" v-model="editStructure.current.is_datetime" class="form-check-input" id="editStructureCurrentIsDatetime">
              <label class="form-check-label" for="editStructureCurrentIsDatetime"><i class="fa-solid fa-calendar-days"></i> Пункт содержит в себе дату мероприятия</label>
              <div class="fs-12px text-secondary">
                Установив данное свойство, при планировании процесса обучения, у данного пункта будет возможно назначить дату мероприятия
              </div>
            </div>
            <Transition name="edit-structure-is-datetime">
              <div v-if="editStructure.current.is_datetime" class="mt-3">
                <label class="form-label">Продолжительность мероприятия в минутах</label>
                <input type="number" step="1" class="form-control" placeholder="60"
                       v-model="editStructure.current.duration_lesson_in_minutes"
                       :class="{ 'is-invalid': !!errors['name'] }"
                >
                <div class="fs-12px text-secondary">
                  Укажите продолжительность мероприятия. Например: ваше мероприятие "Урок" и его продолжительность 45 мин
                </div>
                <div v-if="true === editStructure.current.is_datetime">
                  <div class="mt-3">
                    Автоматическое заполнение даты путем добавления к <b>дате пункта</b> определенного <b>периода</b>.
                    <div class="fs-12px text-secondary">
                      Дополнительно можно указать время, которое будет установлено не зависимо от выбранной <b>даты пункта</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-lg-3">
                      <label>Пункт</label>
                      <multiselect-input
                          v-model="editStructure.current.auto_value_day_event_at_param_uuid"
                          :list="editStructure.listParams"
                          :after-select-option="generateListAutoValueFieldDayEventAt"
                          :is-global-close="false"
                      />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <label>Дата</label>
                      <multiselect-input
                          v-model="editStructure.current.auto_value_day_event_at_param_field"
                          :list="editStructure.listAutoValueField.day_event_at"
                          :is-global-close="false"
                      />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <label>Интервал</label>
                      <interval-input
                          v-model="editStructure.current.auto_value_day_event_at_param_interval"
                          :list="listTypeInterval"
                          :is-global-close="false"
                      />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <label>Время</label>
                      <div class="input-group">
                        <input type="time" placeholder="00:00" class="form-control" v-model="editStructure.current.auto_value_day_event_at_param_time" />
                        <button v-if="!empty(editStructure.current.auto_value_day_event_at_param_time)" class="btn btn-outline-dark" type="button" @click="editStructure.current.auto_value_day_event_at_param_time = null">
                          <i class="fas fa-broom"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <div class="mt-3">
              <input type="checkbox" v-model="editStructure.current.is_student_stat" class="form-check-input" id="editStructureCurrentIsStudentStat">
              <label class="form-check-label" for="editStructureCurrentIsStudentStat"><i class="fa-solid fa-user-graduate"></i> Пункт учитывается как показатель слушателя</label>
              <div class="fs-12px text-secondary">
                Установив данное свойство, пункт получает метрику слушателя, которая будет учитываться при аналитике прогресса обучения слушателя
              </div>
            </div>
            <div class="mt-3">
              <input type="checkbox" v-model="editStructure.current.is_money_stat" class="form-check-input" id="editStructureCurrentIsMoneyStat">
              <label class="form-check-label" for="editStructureCurrentIsMoneyStat"><i class="fa-solid fa-ruble-sign"></i> Пункт учитывается при расчете показателя отработанных средств</label>
              <div class="fs-12px text-secondary">
                Установив данное свойство, пункт получает метрику отработанных средств, которая будет учитываться при аналитике прогресса обучения
              </div>
            </div>
            <div class="mt-3">
              <input type="checkbox" v-model="editStructure.current.is_deadline" class="form-check-input" id="editStructureCurrentIsDeadline">
              <label class="form-check-label" for="editStructureCurrentIsDeadline"><i class="fa-regular fa-calendar-check"></i> Пункт содержит крайнюю дату, до которой необходимо выставить результат</label>
              <div class="fs-12px text-secondary">
                Установив данное свойство, при планировании процесса обучения, у данного пункта будет возможно назначить крайнюю дату, до которой необходимо выставить результат
              </div>
              <div v-if="true === editStructure.current.is_deadline">
                <div class="mt-3">
                  Автоматическое заполнение даты путем добавления к <b>дате пункта</b> определенного <b>периода</b>.
                  <div class="fs-12px text-secondary">
                    Дополнительно можно указать время, которое будет установлено не зависимо от выбранной <b>даты пункта</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-lg-3">
                    <label>Пункт</label>
                    <multiselect-input
                        v-model="editStructure.current.auto_value_deadline_at_param_uuid"
                        :list="editStructure.listParams"
                        :after-select-option="generateListAutoValueFieldDeadlineAt"
                        :is-global-close="false"
                    />
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <label>Дата</label>
                    <multiselect-input
                        v-model="editStructure.current.auto_value_deadline_at_param_field"
                        :list="editStructure.listAutoValueField.deadline_at"
                        :is-global-close="false"
                    />
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <label>Интервал</label>
                    <interval-input
                        v-model="editStructure.current.auto_value_deadline_at_param_interval"
                        :list="listTypeInterval"
                        :is-global-close="false"
                    />
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <label>Время</label>
                    <div class="input-group">
                      <input type="time" placeholder="00:00" class="form-control" v-model="editStructure.current.auto_value_deadline_at_param_time" />
                      <button v-if="!empty(editStructure.current.auto_value_deadline_at_param_time)" class="btn btn-outline-dark" type="button" @click="editStructure.current.auto_value_deadline_at_param_time = null">
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <input type="checkbox" v-model="editStructure.current.is_crm_contractor" class="form-check-input" id="editStructureCurrentIsCrmContractor">
              <label class="form-check-label" for="editStructureCurrentIsCrmContractor"><i class="fa-solid fa-address-book"></i> Пункт заполняется на контрагента</label>
              <div class="fs-12px text-secondary">
                Установив данное свойство, пункт получает будет заполнятся и учитываться на контрагента в целом, а не отдельно по каждому слушателю
              </div>
            </div>
            <Transition name="edit-structure-is-crm-contractor-analytics">
              <div v-if="editStructure.current.is_crm_contractor" class="mt-3">
              <input type="checkbox" v-model="editStructure.current.is_crm_contractor_analytics" class="form-check-input" id="editStructureCurrentIsCrmContractor">
              <label class="form-check-label" for="editStructureCurrentIsCrmContractor"><i class="fa-solid fa-chart-area"></i> Вывести пункт в аналитику</label>
              <div class="fs-12px text-secondary">
                Установив данное свойство, позволяет отобразить результат данного пункта отдельных блоком в аналитических данных по контрагентам
              </div>
            </div>
            </Transition>
            <div class="mt-3 mb-4">
              <label class="form-label">Типы ответственных</label>
              <ul class="list-group mt-1">
                <li class="list-group-item cursor-pointer multiselect-form-list-group-item"
                    v-for="(relationTypeEditParam, i_relationTypeEditParam) in data.listRelationTypeEditParam"
                    :key="'editStructure.relationTypeEditParam.' + i_relationTypeEditParam"
                    :class="{'active': inArray(i_relationTypeEditParam, editStructure.current.relation_type_edit_param)}"
                    @click="editStructure_relation_click(i_relationTypeEditParam)"
                    v-html="relationTypeEditParam.name"
                />
              </ul>
              <div class="text-secondary fs-12px">
                Выбранные типы в процессе обучения получат возможность выставить значение пункта.
                Дополнительно данные ответственные получат метрику по данному пункту для аналитики
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a v-if="'' !== editStructure.current.uuid" class="btn btn-danger sm-w-100 mt-1 ms-sm-2" @click="editStructure.isConfirmDeleteModal = true">Удалить</a>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="saveCurrentStructure">Сохранить</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="close">Отмена</a>
      </template>
    </base-modal>
    <base-modal title="Курс" :show="editData.isModel" :footer="true" @close="dataEditClose">
      <template v-slot:body>
        <div class="text-start">
          <div class="row">
            <div class="col-12">
              <label class="form-label">Наименование</label>
              <input type="text" class="form-control" placeholder="Методика управления светом в ванной"
                     v-model="data.name"
                     :disabled="!inArray('name', enabledFields)"
                     :class="{ 'is-invalid': !!errors['name'] }"
              >
              <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
              <div class="fs-12px text-secondary">Краткое наименование курса, которое отображается по всей системе</div>
            </div>
            <div class="col-12">
              <label class="form-label">Описание</label>
              <textarea class="form-control"
                        rows="3"
                        placeholder="Курс из 36 часов обучения с практическими занятиями на базе квартиры собственника"
                        v-model="data.description"
                        :disabled="!inArray('description', enabledFields)"
                        :class="{ 'is-invalid': !!errors['description'] }"
              />
              <div class="fs-10px text-secondary">(по необходимости)</div>
              <div class="text-danger" v-if="!!errors['description']">{{ errors['description'].join(', ') }}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a
            v-for="(button, i_button) in buttons.filter(function(item) {
              return 'exit' !== item.uuid && 'newExit' !== item.uuid && 'saveAndExit' !== item.uuid && 'openDataModal' !== item.uuid
            })"
            :key="'form-button-' + i_button"
            class="btn sm-w-100 mt-1 ms-sm-2"
            :class="button.class"
            type="submit"
            @click="dataEditButtonClick(button)"
            v-html="button.name"
        />
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="dataEditClose">Отмена</a>
      </template>
    </base-modal>
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <confirm-action-modal
        :show="editStructure.isConfirmDeleteModal"
        :text="'Удалить ' + editStructure.current.name + '? <div><b>Внимание!</b> Пункт убдет удален из всех учебных процессов (планирующиеся, текущие и завершенные)</div>'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="deleteCurrentStructure"
        :is-global-close="false"
        @close="editStructure.isConfirmDeleteModal = false"
    />
    <page-top-bar
        :title="title"
        :subtitle="'Форма редактирования курса'"
        :buttons="buttons.filter(function(button) {
          return 'newExit' === button.uuid || 'openDataModal' === button.uuid
        })"
    />

    <div class="card border-0 shadow">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <label class="form-label">Наименование</label>
            <div class="py-1">
              {{data.name}}
            </div>
            <div class="fs-12px text-secondary">Краткое наименование курса, которое отображается по всей системе</div>
          </div>
          <div class="col-sm-6">
            <label class="form-label">Описание</label>
            <div class="py-1">
              {{ data.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 card border-0 shadow">
      <div class="card-body">
        <div class="fs-18px has-text-weight-bold mb-2">
          Структура курса
          <div v-if="isStructureLoading" class="spinner-border spinner-border-sm text-primary" role="status">
            <span class="visually-hidden">Загрузка...</span>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-sm-6">
            <input type="checkbox" v-model="isStructureDetail" class="form-check-input" id="isStructureDetail">
            <label class="form-check-label" for="isStructureDetail">Подробная информация</label>
          </div>
          <div class="col-sm-6">
            <input type="checkbox" :disabled="!inArray('structure', enabledFields)" v-model="isStructureEdit" class="form-check-input" id="isStructureEdit">
            <label class="form-check-label" for="isStructureEdit">Редактировать структуру</label>
          </div>
        </div>
        <div>
          <school-course-structure-show-view
              :indexes="[i_child]"
              :item="child"
              :is-edit="isStructureEdit"
              :is-detail="isStructureDetail"
              :edit-structure-item="openEditStructureItem"
              :list-relation-type-edit-param="data.listRelationTypeEditParam"
              v-for="(child, i_child) in data.structure"
              :key="'structure.' + i_child"
          />

          <div v-if="isStructureEdit && 0 === data.structure.length" class="has-text-weight-bold alert alert-light py-2 px-3 mb-1">
            <button class="btn btn-very-sm btn-outline-primary" @click="openEditStructureItem([-1], 'param')">
              <i class="fa-solid fa-plus"></i> пункт
            </button>
            <button class="btn btn-very-sm btn-outline-primary ms-2" @click="openEditStructureItem([-1], 'structure')">
              <i class="fa-solid fa-plus"></i> группа
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import BaseModal from '@/components/BaseModal'
import SchoolCourseStructureShowView from '@/views/app/school/components/SchoolCourseStructureShowView'
import MultiselectInput from '@/components/form/MultiselectInput'
import IntervalInput from '@/components/form/IntervalInput'

export default {
  name: 'SchoolCourseView',
  components: {
    IntervalInput,
    MultiselectInput,
    SchoolCourseStructureShowView,
    BaseModal,
    ConfirmActionModal,
    PageTopBar
  },
  mixins: [formMixin],
  data() {
    return {
      title: '<i class="fa-solid fa-book-open"></i> Курс',
      isConfirmDeleteModal: false,
      formName: 'schoolCourse',
      formUrl: {string: '/school-course?uuid=${uuid}'},
      editRoute: {name:  'school-course', params: {uuid: ''}},
      exitRoute: {name: 'school-setting'},

      isStructureLoading: false,

      isStructureDetail: true,
      isStructureEdit: false,

      data: {
        name: '',
        description: '',
        structure: [],
        listRelationTypeEditParam: [],
      },

      editData: {
        isModel: false,
        name: '',
        description: '',
      },

      editStructure: {
        isModel: false,
        isConfirmDeleteModal: false,
        currentIndexes: [],
        current: {
          uuid: '',
          type: '',
          name: '',
          children: [],
          values: [],
          is_datetime: false,
          auto_value_day_event_at_param_interval: '1 days',
          auto_value_day_event_at_param_field: '',
          auto_value_day_event_at_param_time: null,
          auto_value_day_event_at_param_uuid: null,
          is_deadline: false,
          auto_value_deadline_at_param_interval: '1 days',
          auto_value_deadline_at_param_field: '',
          auto_value_deadline_at_param_time: null,
          auto_value_deadline_at_param_uuid: null,
          duration_lesson_in_minutes: null,
          is_student_stat: false,
          is_money_stat: false,
          is_crm_contractor: false,
          is_crm_contractor_analytics: false,
          relation_type_edit_param: [],
        },
        listParams: [],
        listAutoValueField: {
          day_event_at: [],
          deadline_at: [],
        },
        new: {
          structure: {
            uuid: '',
            type: 'structure',
            name: '',
            children: [],
          },
          param: {
            uuid: '',
            type: 'param',
            name: '',
            values: [{name: 'На лекции был', point: 100}],
            is_datetime: false,
            is_deadline: false,
            duration_lesson_in_minutes: null,
            is_student_stat: false,
            is_money_stat: false,
            is_crm_contractor: false,
            is_crm_contractor_analytics: false,
            relation_type_edit_param: [],
          },
        },
      },

      listTypeInterval: [
        {uuid: 'days', name: 'День(й)'},
        {uuid: 'months', name: 'Месяц(а)'},
        {uuid: 'years', name: 'Год(а)'},
      ]
    }
  },
  mounted() {
    if ('new' === this.$route.params.uuid) {
      this.openDataModal()
    }

    this.loadStructure()
  },
  methods: {
    loadStructure: function () {
      if ('new' !== this.$route.params.uuid) {
        this.isStructureLoading = true
        window.axios.get(this.globalVar.app.api.url
            + `/school-course-params?uuid=${this.$route.params.uuid}&${(new Date()).getTime()}`)
            .then(response => {
              this.data.structure = response.data
            })
            .finally(() => {
              this.isStructureLoading = false
            })
      }
    },
    afterLoad: function () {
      let self = this
      this.buttons.push({
        uuid: 'openDataModal',
        name: 'Править',
        class: 'btn-primary', click: function() {self.openDataModal()}
      })
      this.buttons.push({
        uuid: 'newExit',
        name: 'Назад',
        class: 'btn-outline-secondary',
        click: function() {self.toRoute(self.exitRoute)}
      })
      this.isStructureEdit = this.inArray('structure', this.enabledFields)
    },
    afterSave: function () {
      this.loadStructure()
    },
    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    openDataModal: function () {
      this.editData.name = this.data.name + ''
      this.editData.description = this.data.description + ''
      this.editData.isModel = true
      this.emitter.emit('modal', {event: 'open'})
    },
    dataEditClose: function () {
      this.data.name = this.editData.name + ''
      this.data.description = this.editData.description + ''
      this.close()
      if ('new' === this.$route.params.uuid) {
        this.toRoute(this.exitRoute)
      }
    },
    dataEditButtonClick: function (button) {
      button.click()
      this.close()
    },

    openEditStructureItem: function (indexes, type) {
      this.editStructure.currentIndexes = JSON.parse(JSON.stringify(indexes))

      if (true === this.empty(type)) {
        this.editStructure.current = JSON.parse(JSON.stringify(this.data.structure[indexes[0]]))
        for (let i = 1; i < indexes.length; i++) {
          this.editStructure.current =  JSON.parse(JSON.stringify(this.editStructure.current.children[indexes[i]]))
        }
      } else {
        this.editStructure.current = JSON.parse(JSON.stringify(this.editStructure.new[type]))
      }

      if ('param' === this.editStructure.current.type) {
        if (this.empty(this.editStructure.current.auto_value_deadline_at_param_interval)) {
          this.editStructure.current.auto_value_deadline_at_param_interval = '1 days'
        }
        if (this.empty(this.editStructure.current.auto_value_day_event_at_param_interval)) {
          this.editStructure.current.auto_value_day_event_at_param_interval = '1 days'
        }

        let minusIndex = '' === this.editStructure.current.uuid ? 0 : 1

        this.editStructure.listParams = []
        for (let i = 0; i <= indexes[0] - (1 === indexes.length ? minusIndex : 1); i++) {
          this.generateListParams('', this.data.structure[i])
        }
        if (1 < indexes.length) {
          let parentName = this.data.structure[indexes[0]].name
          let structure = this.data.structure[indexes[0]].children
          for (let i = 1; i < indexes.length - 1; i++) {
            for (let c = 0; c < indexes[i]; c++) {
              this.generateListParams(parentName, structure[c])
            }
            parentName += ' - ' + structure[indexes[i]].name
            structure = JSON.parse(JSON.stringify(structure[indexes[i]].children))
          }

          for (let c = 0; c <= indexes[indexes.length - 1] - minusIndex; c++) {
            this.generateListParams(parentName, structure[c])
          }
        }

        this.generateListAutoValueFieldDayEventAt()
        this.generateListAutoValueFieldDeadlineAt()
      }

      this.editStructure.isModel = true
      this.emitter.emit('modal', {event: 'open'})
    },
    generateListParams: function (parentName, item) {
      if ('' !== parentName) {
        parentName += ' - '
      }

      if ('param' === item.type) {
        let field = []
        if (item.is_datetime) {
          field.push({uuid: 'day_event_at', name: 'Дата мероприятия'})
        }
        if (item.is_deadline) {
          field.push({uuid: 'deadline_at', name: 'Крайняя дата'})
        }
        this.editStructure.listParams.push({uuid: item.uuid, field: field, name: parentName + item.name})
      } else {
        for (let c = 0; c < item.children.length; c++) {
          this.generateListParams(parentName + item.name, item.children[c])
        }
      }
    },

    generateListAutoValueFieldDayEventAt: function() {
      this.generateListAutoValueField('day_event_at')
    },
    generateListAutoValueFieldDeadlineAt: function() {
      this.generateListAutoValueField('deadline_at')
    },
    generateListAutoValueField: function (fieldName) {
      this.editStructure.listAutoValueField[fieldName] = []
      for(let i in this.editStructure.listParams) {
        if (
            this.editStructure.current['auto_value_' + fieldName + '_param_uuid']
            ===
            this.editStructure.listParams[i].uuid
        ) {
          this.editStructure.listAutoValueField[fieldName] =
              JSON.parse(JSON.stringify(this.editStructure.listParams[i].field))
          break
        }
      }
    },
    editStructure_values_delete: function (i_itemValue) {
      let isSetNewChecked = false

      if (true === this.editStructure.current.values[i_itemValue].checked) {
        isSetNewChecked = true
      }

      let newValues = {}
      for (let i in this.editStructure.current.values) {
        if (i !== i_itemValue) {
          newValues[i] = JSON.parse(JSON.stringify(this.editStructure.current.values[i]))
        }
      }

      if (false === this.empty(newValues)) {
        for (let i in newValues) {
          if (true === isSetNewChecked) {
            isSetNewChecked = false
            newValues[i].checked = true
          }
        }

        this.editStructure.current.values = newValues
      }
    },

    editStructure_relation_click: function(uuid) {
      if(this.inArray(uuid, this.editStructure.current.relation_type_edit_param)) {
        this.editStructure.current.relation_type_edit_param =
            this.editStructure.current.relation_type_edit_param.filter(function (item) {
              return item !== uuid
            })
      } else {
        this.editStructure.current.relation_type_edit_param.push(uuid)
      }
    },

    saveCurrentStructure: function () {
      let i = 0, currentList = this.data.structure
      if (1 < this.editStructure.currentIndexes.length) {
        currentList = this.data.structure[this.editStructure.currentIndexes[i]]
        for (i = 1; i < this.editStructure.currentIndexes.length - 1; i++) {
          currentList = currentList.children[this.editStructure.currentIndexes[i]]
        }

        currentList = currentList.children
      }

      if ('' === this.editStructure.current.uuid) {
        this.editStructure.current.uuid = 'new'

        let localList = [], isInsert = false

        if (-1 === this.editStructure.currentIndexes[i] * 1) {
          isInsert = true
          localList.push(JSON.parse(JSON.stringify(this.editStructure.current)))
        }

        for (let index in currentList) {
          localList.push(JSON.parse(JSON.stringify(currentList[index])))
          if (false === isInsert && index * 1 === this.editStructure.currentIndexes[i] * 1) {
            isInsert = true
            localList.push(JSON.parse(JSON.stringify(this.editStructure.current)))
          }
        }

        if (1 < this.editStructure.currentIndexes.length) {
          let i = 0, currentList = this.data.structure[this.editStructure.currentIndexes[i]]
          for (i = 1; i < this.editStructure.currentIndexes.length - 1; i++) {
            currentList = currentList.children[this.editStructure.currentIndexes[i]]
          }
          currentList.children =  JSON.parse(JSON.stringify(localList))
        } else {
          this.data.structure = localList
        }
      } else {
        currentList[this.editStructure.currentIndexes[i]] = JSON.parse(JSON.stringify(this.editStructure.current))
      }

      this.save()
      this.close()
    },

    deleteCurrentStructure: function () {
      if (1 === this.editStructure.currentIndexes.length) {
        this.data.structure.splice(this.editStructure.currentIndexes[0], 1)
      } else {
        let i = 0, current = this.data.structure[this.editStructure.currentIndexes[i]]

        for (i = 1; i < this.editStructure.currentIndexes.length - 1; i++) {
          current = current.children[this.editStructure.currentIndexes[i]]
        }

        current.children.splice(this.editStructure.currentIndexes[i], 1)
      }
      this.editStructure.isConfirmDeleteModal = false
      this.save()
      this.close()
    },

    close: function () {
      this.editData.isModel = false
      this.editStructure.isModel = false
      this.editStructure.isConfirmDeleteModal = false
      this.isConfirmDeleteModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.edit-structure-is-datetime-enter-active,
.edit-structure-is-datetime-leave-active {
  transition: opacity 0.5s ease;
}

.edit-structure-is-datetime-enter-from,
.edit-structure-is-datetime-leave-to {
  opacity: 0;
}

.edit-structure-is-crm-contractor-analytics-enter-active,
.edit-structure-is-crm-contractor-analytics-leave-active {
  transition: opacity 0.5s ease;
}

.edit-structure-is-crm-contractor-analytics-enter-from,
.edit-structure-is-crm-contractor-analytics-leave-to {
  opacity: 0;
}
</style>
