<template>
  <div class="card border-0 shadow mb-3">
    <div class="card-body">
      <div v-if="isLoading" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
      <div v-if="!isLoading">
        <div class="mb-3">
          <div class="d-inline-block me-3 mt-2">
            <label class="form-label mb-1">Временной интервал</label>
            <multiselect-input
                :list="analytics.tableTimetables.timeIntervals"
                v-model="analytics.tableTimetables.intervalMultiselectInput"
                :after-select-option="calcTableTimetables_intervalMultiselectInput_change"
            />
          </div>
          <div class="d-inline-block me-3 mt-2">
            <label class="form-label mb-1">Дени недели</label>
            <div>
              <span class="me-1">c:</span>
              <div class="d-inline-block">
                <multiselect-input
                    :list="enums.weekdays"
                    v-model="analytics.tableTimetables.weekdayStart"
                    :after-select-option="calcTableTimetables_weekdayStart_change"
                />
              </div>
              <span class="ms-2 me-1">по:</span>
              <div class="d-inline-block">
                <multiselect-input
                    :list="enums.weekdays"
                    v-model="analytics.tableTimetables.weekdayEnd"
                    :after-select-option="calcTableTimetables_weekdayEnd_change"
                />
              </div>
            </div>
          </div>
          <div class="d-inline-block me-3 mt-2">
            <label class="form-label mb-1">Время</label>
            <div>
              <span class="me-1">c:</span>
              <div class="d-inline-block">
                <multiselect-input
                    :list="enums.hours"
                    v-model="analytics.tableTimetables.timeStart"
                    :after-select-option="calcTableTimetables_timeStart_change"
                />
              </div>
              <span class="ms-2 me-1">по:</span>
              <div class="d-inline-block">
                <multiselect-input
                    :list="enums.hours"
                    v-model="analytics.tableTimetables.timeEnd"
                    :after-select-option="calcTableTimetables_timeEnd_change"
                />
              </div>
            </div>
          </div>
          <div class="mt-2">
            <label class="form-label mb-1">Пользователи</label>
            <multiselect-input
                :list="analytics.tableTimetables.users"
                v-model="analytics.tableTimetables.userUuids"
                :multiselect="true"
                :after-select-option="calcTableTimetables_userUuids_change"
            />
          </div>
        </div>
        <div class="overflow-x-auto">
          <div class="position-relative analytics-timetables-table-container fs-10px"
               :style="`height:${analytics.tableTimetables.tableHeight}px;width:${(analytics.tableTimetables.tableWidth + 36)}px;`">
            <div class="position-absolute analytics-timetables-row-cell-div" :style="`top:${row.top}px;`"
                 v-for="(row, i_row) in analytics.tableTimetables.table"
                 :key="'analytics.tableTimetables.table.row.' + i_row">
              <div class="position-absolute analytics-timetables-row-title-div">{{ row.name }}</div>
              <div class="position-absolute analytics-timetables-cell-div" :style="`top:0;left:${cell.left}px;height:${cell.h}px;width:${cell.w}px;`"
                   v-for="(cell, i_cell) in row.cells"
                   :key="'analytics.tableTimetables.table.cell.' + i_cell">
                {{ cell.name }}
              </div>
            </div>

            <div class="position-absolute analytics-timetables-container"
                 :style="`height:${analytics.tableTimetables.tableHeight}px;width:${(analytics.tableTimetables.tableWidth + 36)}px;`"
            >
              <div class="analytics-timetables-div text-start"
                   :class="{'text-secondary': timetable.schoolProgressIsRun, 'current-process': timetable.schoolProgressUuid === currentProcessUuid}"
                   :style="`width:${timetable.style.width}px;height:${timetable.style.height}px;left:${timetable.style.left}px;top:${timetable.style.top}px;background:${timetable.style.bg}`"
                   v-for="(timetable, i_timetable) in this.analytics.tableTimetables.timetables"
                   :key="'analytics.timetables.' + i_timetable"
              >
                <!--
                <div class="text-start"><b>{{ enums.weekdays[timetable.weekday - 1].name }}</b></div>
                <div class="text-start"><b>{{ timetable.uuid }}</b></div>
                -->
                <div class="text-end"><b>{{ timetable.name }}</b></div>
                <div class="text-end"><b>{{ timetable.schoolProgressName }}</b></div>
                <div class="text-start mb-1"
                     v-for="(type, i_type) in timetable.nameSchoolRelationUserTypes"
                     :key="'analytics.timetables.' + i_timetable + '.typeUser.' + i_type"
                >
                  <div>{{ type }}</div>
                  <div class="text-start"
                       :class="{'d-done': type !== timetable.type}"
                       v-for="(user, i_user) in timetable.users"
                       :key="'analytics.timetables.' + i_timetable + '.typeUser.' + i_type + '.user.' + i_user"
                  >
                    {{ user.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MultiselectInput from '@/components/form/MultiselectInput'
import {listMixin} from '@/mixins/listMixin'

export default {
  name: 'SchoolProcessesTimetableChartView',
  components: {MultiselectInput},
  mixins: [listMixin],
  props: {
    currentProcessUuid: {
      type: String,
      required: true,
    },
    getTimetableItems: {
      type: Function,
      required: false,
    }
  },
  data() {
    return {
      listName: 'SchoolProcessesTimetableChartList',
      listUrl: {string: '/school-analytics-timetables', params:[]},
      itemNameRoute: 'school-analytics-timetables',
      pageButtons: [],

      analytics: {
        tableTimetables: {
          weekdayStart: 1,
          weekdayEnd: 5,
          timeStart: 8,
          timeEnd: 20,
          userUuids: [],
          users: [],

          intervalMultiselectInput: null,
          interval: {uuid: 'u4', name: '30 минут', m: 30, h: 40},

          timeIntervals: [
            {uuid: 'u1', name: '10 минут', m: 10, h: 10},
            {uuid: 'u2', name: '15 минут', m: 15, h: 20},
            {uuid: 'u3', name: '20 минут', m: 20, h: 30},
            {uuid: 'u4', name: '30 минут', m: 30, h: 40},
            {uuid: 'u5', name: '1 час',    m: 60, h: 50},
          ],

          wCellWeekday: 150,
          wCell: 60,
          hFirstRowCell: 30,

          table: [],
          timetables: [],
          tableWidth: 0,
          tableHeight: 0,
        },
        timetables: [],
      },
    }
  },
  mounted() {
    this.emitter.on('SchoolProcessesTimetableChartList', this.emitterSchoolProcessesTimetableChartList_reload)

    this.analytics.tableTimetables.intervalMultiselectInput = localStorage.getItem(`${this.formName}.analytics.interval`) ?? 'u4'
    this.analytics.tableTimetables.weekdayStart = (localStorage.getItem(`${this.formName}.analytics.weekdayStart`) ?? '1') * 1
    this.analytics.tableTimetables.weekdayEnd = (localStorage.getItem(`${this.formName}.analytics.weekdayEnd`) ?? '5') * 1
    this.analytics.tableTimetables.timeStart = (localStorage.getItem(`${this.formName}.analytics.timeStart`) ?? '8') * 1
    this.analytics.tableTimetables.timeEnd = (localStorage.getItem(`${this.formName}.analytics.timeEnd`) ?? '20') * 1
    this.analytics.tableTimetables.userUuids = JSON.parse(localStorage.getItem(`${this.formName}.analytics.userUuids`) ?? '[]')
    this.calcTableTimetables_updateParams()
  },
  unmounted: function() {
    this.emitter.off('SchoolProcessesTimetableChartList', this.emitterSchoolProcessesTimetableChartList_reload)
  },
  methods: {
    emitterSchoolProcessesTimetableChartList_reload: function () {
      this.load()
    },

    afterLoad: function () {
      this.analytics.timetables = this.items
      if (undefined !== this.getTimetableItems) {
        this.getTimetableItems(this.items)
      }
      this.calcTableTimetables()
    },

    calcTableTimetables_intervalMultiselectInput_change: function() {
      localStorage.setItem(`${this.formName}.analytics.interval`, this.analytics.tableTimetables.intervalMultiselectInput)
      this.calcTableTimetables()
    },
    calcTableTimetables_weekdayStart_change: function() {
      localStorage.setItem(`${this.formName}.analytics.weekdayStart`, this.analytics.tableTimetables.weekdayStart)
      this.calcTableTimetables()
    },
    calcTableTimetables_weekdayEnd_change: function() {
      localStorage.setItem(`${this.formName}.analytics.weekdayEnd`, this.analytics.tableTimetables.weekdayEnd)
      this.calcTableTimetables()
    },
    calcTableTimetables_timeStart_change: function() {
      localStorage.setItem(`${this.formName}.analytics.timeStart`, this.analytics.tableTimetables.timeStart)
      this.calcTableTimetables()
    },
    calcTableTimetables_timeEnd_change: function() {
      localStorage.setItem(`${this.formName}.analytics.timeEnd`, this.analytics.tableTimetables.timeEnd)
      this.calcTableTimetables()
    },
    calcTableTimetables_userUuids_change: function() {
      localStorage.setItem(`${this.formName}.analytics.userUuids`, JSON.stringify(this.analytics.tableTimetables.userUuids))
      this.calcTableTimetables()
    },

    calcTableTimetables: function () {
      this.calcTableTimetables_updateParams()
      this.calcTableTimetables_updateUsers()
      this.analytics.tableTimetables.table = []

      let self = this
      this.analytics.tableTimetables.timetables = this.analytics.timetables.filter(function (timetable) {
        let isUser = false
        if (false === self.empty(self.analytics.tableTimetables.userUuids)) {
          for (let u in timetable.users) {
            if (self.inArray(timetable.users[u].uuid, self.analytics.tableTimetables.userUuids)) {
              isUser = true
              break
            }
          }
        } else {
          isUser = true
        }

        return self.analytics.tableTimetables.weekdayStart <= timetable.weekday &&
            timetable.weekday <= self.analytics.tableTimetables.weekdayEnd &&

            self.analytics.tableTimetables.timeStart * 100 <= timetable.intTime &&
            timetable.intEndTime <= self.analytics.tableTimetables.timeEnd * 100 &&

            isUser
      })

      for (let i in this.analytics.tableTimetables.timetables) {
        let timetable = this.analytics.tableTimetables.timetables[i]

        timetable.weekdayColumn = 1
        let left = this.calcTableTimetables_setLeftTimetable(i)

        let rowSeparator = timetable.weekdayColumn > 1 ? 1 : 0
        if (0 < i) {
          let previousTimetable = this.analytics.tableTimetables.timetables[i - 1]
          if (previousTimetable.weekday !== timetable.weekday) {
            rowSeparator = 3
          }
        }

        let bg = 'var(--app-variant-color-item-' + this.getRandomInt(1, 28) + '-bg)'
        for (let j = 0; j < i; j++) {
          let bgTimetable = this.analytics.tableTimetables.timetables[j]
          if (bgTimetable.schoolProgressUuid === timetable.schoolProgressUuid) {
            bg = bgTimetable.style.bg
            break
          }
        }

        timetable.style = {
          left: left + rowSeparator,
          width: this.analytics.tableTimetables.wCellWeekday,
          top: 0,
          height: 0,
          bg: bg,
        }
      }

      let newRow = {name: '', intTime:0, intEndTime:0, top: 0, cells: []}

      // Первая строка с названиями, она же является конфигом на ширину колонок
      for (let t = this.analytics.tableTimetables.weekdayStart - 1; t <= this.analytics.tableTimetables.weekdayEnd - 1; t++) {
        let weekday = this.enums.weekdays[t]
        //begin - Вычислить left и width
        let timetables = this.analytics.tableTimetables.timetables.filter(function (timetable) {
              return timetable.weekday === weekday.uuid
            })

        let left = 0, widthCellNewRow = this.analytics.tableTimetables.wCellWeekday + 3
        if (0 < timetables.length) {
          widthCellNewRow = 0
          for (let iMaxWidthCellNewRow in timetables) {
            if (widthCellNewRow < timetables[iMaxWidthCellNewRow].style.left + timetables[iMaxWidthCellNewRow].style.width) {
              widthCellNewRow = timetables[iMaxWidthCellNewRow].style.left + timetables[iMaxWidthCellNewRow].style.width
            }
          }
          if (0 < newRow.cells.length) {
            for(let iWCell in newRow.cells) {
              widthCellNewRow -= newRow.cells[iWCell].w
            }
          }
          if (widthCellNewRow < this.analytics.tableTimetables.wCellWeekday) {
            widthCellNewRow += this.analytics.tableTimetables.wCellWeekday
          }
          widthCellNewRow += 2
        }
        if (0 < newRow.cells.length) {
          left = newRow.cells[newRow.cells.length - 1].left + newRow.cells[newRow.cells.length - 1].w
        }
        //end - Вычислить left и width

        newRow.cells.push({
          name: weekday.name,
          left: left,
          w: widthCellNewRow,
          h: this.analytics.tableTimetables.hFirstRowCell,
          weekday: weekday.uuid,
        })
      }

      this.analytics.tableTimetables.table.push(newRow)

      for (let rowHour = this.analytics.tableTimetables.timeStart; rowHour <= this.analytics.tableTimetables.timeEnd - 1; rowHour++) {
        for (let rowMinute = 0; rowMinute < 60; rowMinute += this.analytics.tableTimetables.interval.m) {
          let newRow = this.calcTableTimetables_getNewRow(rowHour, rowMinute)

          for (let iFirstRow in this.analytics.tableTimetables.table[0].cells) {
            let firstCell = this.analytics.tableTimetables.table[0].cells[iFirstRow]
            newRow.cells.push({
              name: '',
              left: firstCell.left,
              w: firstCell.w,
              h: this.analytics.tableTimetables.interval.h,
            })
          }

          this.analytics.tableTimetables.table.push(newRow)
        }
      }

      this.calcTableTimetables_setTopAndHeightTimetables()

      this.analytics.tableTimetables.tableWidth = 0
      for(let c in this.analytics.tableTimetables.table[0].cells) {
        this.analytics.tableTimetables.tableWidth += this.analytics.tableTimetables.table[0].cells[c].w
      }

      this.analytics.tableTimetables.tableHeight = 0
      for(let r in this.analytics.tableTimetables.table) {
        this.analytics.tableTimetables.tableHeight += this.analytics.tableTimetables.table[r].cells[0].h
      }
    },
    calcTableTimetables_updateUsers:function () {
      this.analytics.tableTimetables.users = []
      for (let i in this.analytics.timetables) {
        let timetable = this.analytics.timetables[i]
        for (let u in timetable.users) {
          let isPush = true
          for (let tu in this.analytics.tableTimetables.users) {
            if (timetable.users[u].uuid === this.analytics.tableTimetables.users[tu].uuid) {
              isPush = false
              break
            }
          }

          if (isPush) {
            this.analytics.tableTimetables.users.push(timetable.users[u])
          }
        }
      }
    },
    calcTableTimetables_updateParams:function () {
      for (let i in this.analytics.tableTimetables.timeIntervals) {
        if (this.analytics.tableTimetables.timeIntervals[i].uuid === this.analytics.tableTimetables.intervalMultiselectInput) {
          this.analytics.tableTimetables.interval = this.analytics.tableTimetables.timeIntervals[i]
        }
      }
    },
    calcTableTimetables_getNewRow:function (rowHour, rowMinute) {
      let endMinute = rowMinute + this.analytics.tableTimetables.interval.m
      if (endMinute >= 60) {
        endMinute = 59
      }

      let table = this.analytics.tableTimetables.table

      return {
        name: (9 < rowHour ? '' : '0') + rowHour + ':' + (9 < rowMinute ? '' : '0') + rowMinute,
        intTime: ((rowHour + '') + (9 < rowMinute ? '' : '0') + rowMinute) * 1,
        intEndTime: ((rowHour + '') + (9 < endMinute ? '' : '0') + endMinute) * 1,
        top: table[table.length - 1].top + table[table.length - 1].cells[0].h,
        cells: []
      }
    },
    calcTableTimetables_setLeftTimetable: function(i) {
      let timetable = this.analytics.tableTimetables.timetables[i]
      let weekdayCurrentTimetable = this.analytics.tableTimetables.timetables.filter(function (item, index) {
        return timetable.weekday === item.weekday && index < i
      })
      let left = 0
      if (weekdayCurrentTimetable.length > 0) {
        timetable.weekdayColumn = this.calcTableTimetables_setLeftTimetable_defineColumn(
            timetable,
            1,
            weekdayCurrentTimetable
        )
        for (let iCurrentTimetable in weekdayCurrentTimetable) {
          let currentTimetable = weekdayCurrentTimetable[iCurrentTimetable]
          if (currentTimetable.weekdayColumn === timetable.weekdayColumn) {
            left = currentTimetable.style.left
            break;
          }
        }
        for (let iCurrentTimetable in weekdayCurrentTimetable) {
          let currentTimetable = weekdayCurrentTimetable[iCurrentTimetable]
          if (currentTimetable.weekdayColumn === timetable.weekdayColumn - 1) {
            left = currentTimetable.style.left + currentTimetable.style.width
            break;
          }
        }
      } else {
        let maxICurrentTimetable = -1
        for (let iCurrentTimetable = 0;iCurrentTimetable < i; iCurrentTimetable++) {
          let currentTimetable = this.analytics.tableTimetables.timetables[iCurrentTimetable]
          if (left < currentTimetable.style.left + currentTimetable.style.width) {
            left = currentTimetable.style.left + currentTimetable.style.width
            maxICurrentTimetable = iCurrentTimetable
          }
        }

        let count = timetable.weekday - (-1 === maxICurrentTimetable
          ? this.analytics.tableTimetables.weekdayStart
          : this.analytics.tableTimetables.timetables[maxICurrentTimetable].weekday + 1)

        left += (this.analytics.tableTimetables.wCellWeekday + 4) * (0 > count ? 0 : count)
      }

      return left
    },
    calcTableTimetables_setLeftTimetable_defineColumn: function (currentTimetable, currentColumn, timetables) {
      for (let i in timetables) {
        let timetable = timetables[i]
        if (
            timetable.weekdayColumn === currentColumn &&
            //a2 >= b1 && b2 >= a1
            currentTimetable.intEndTime >= timetable.intTime && timetable.intEndTime >= currentTimetable.intTime
      ) {
          currentColumn ++
          return this.calcTableTimetables_setLeftTimetable_defineColumn(currentTimetable, currentColumn, timetables)
        }
      }

      return currentColumn
    },
    calcTableTimetables_setTopAndHeightTimetables: function() {
      for (let t in this.analytics.tableTimetables.timetables) {
        let timetable = this.analytics.tableTimetables.timetables[t]
        timetable.style.height =
            this.analytics.tableTimetables.interval.h *
            (timetable.duration_lesson_in_minutes / this.analytics.tableTimetables.interval.m) - 3

        for(let r in this.analytics.tableTimetables.table) {
          let row = this.analytics.tableTimetables.table[r]
          if (row.intTime <= timetable.intTime && timetable.intTime <= row.intEndTime) {
            timetable.style.top = row.top
                + (this.analytics.tableTimetables.interval.h / this.analytics.tableTimetables.interval.m)
                * (timetable.intTime - row.intTime) + 1
          }
        }
      }
    },

    close: function () {
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style>
.analytics-timetables-table-container {
  padding-left: 36px;
}
.analytics-timetables-container {
  left: 0;
  top: 0;
  margin-left: 36px;
}

.analytics-timetables-row-title-div {
  left: -35px;
  top: -12px;
  vertical-align: middle;
  font-size: 10px;
  color: rgb(var(--bs-secondary-rgb));
}

.analytics-timetables-cell-div {
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: bold;
  color: rgb(var(--bs-secondary-rgb));
  border-bottom: 1px solid rgba(var(--bs-secondary-rgb), 0.3);
  border-right: 1px solid rgba(var(--bs-secondary-rgb), 0.3);
}

.analytics-timetables-row-cell-div:nth-last-child(2) .analytics-timetables-cell-div,
.analytics-timetables-row-cell-div:last-child .analytics-timetables-cell-div {
  border-bottom: none;
}
.analytics-timetables-cell-div:last-child {
  border-right: none;
}

.analytics-timetables-div {
  position: absolute;
  z-index: 1;
  border: 1px solid var(--app-analytics-timetables-table-div-border);
  overflow: auto;
  background: var(--app-analytics-timetables-table-div-bg);
  padding: 2px 3px;
  border-radius: 6px;
}

.analytics-timetables-div.current-process {
   background: var(--app-analytics-timetables-table-div-bg) !important;
}
</style>
