<template>
  <div>
    <base-modal
        :show="show"
        title="Выбор значения"
        :is-bg-click-close="true"
        :header="true"
        :footer="true"
        @close="closeSelectModel"
        :is-global-close="isGlobalClose"
    >
      <template v-slot:body>
        <div class="input-group">
          <span class="input-group-text"><i class="fas fa-search"></i></span>
          <input type="search" class="form-control" v-model="filterText">
        </div>
        <div v-if="0 === list.filter(function(item) {return isFilter(item)}).length" class="py-3">
          Нет доступных вариантов
        </div>
        <ul class="list-group mt-1 mb-4">
          <li class="list-group-item cursor-pointer multiselect-form-list-group-item"
              v-for="(option, i_option) in list.filter(function(item) {
                return isFilter(item)
              })"
              :key="'o.' + i_option"
              :class="{'active': isActiveOption(option.uuid)}"
              @click="clickOption(option.uuid)"
              v-html="option.name"
          >
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <a v-show="true === multiselect" class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="clickOk">OK</a>
        <a class="btn btn-dark sm-w-100 mt-1 ms-sm-2" @click="clearValue">Очистить</a>
      </template>
    </base-modal>
    <div
        class="form-control cursor-pointer position-relative multiselect-form-control"
        :class="className"
        :data-disabled="disabled"
    >
      <div class="multiselect-indicator text-secondary"><i class="fa-regular fa-rectangle-list"></i></div>
      <span
          v-show="true === multiselect"
          v-for="(value, i_value) in values"
          class="badge bg-primary"
          :key="'v.' + i_value"
          v-html="getLabel(value)"
      />
      <span v-show="false === multiselect" v-html="getLabel(values[0])" />
      <div class="btn-click-input-open-model" @click="openModel"></div>
      <div v-if="!empty(values) && !disabled" class="btn-clear-input" @click="labelButtonClearValue">
        <div class="btn btn-very-sm btn-dark"><i class="fa-solid fa-broom"></i></div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseModal from '../BaseModal'

export default {
  name: 'MultiselectInput',
  components: {BaseModal},
  props: {
    modelValue: {
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    className: {
      default: {},
      required: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    isGlobalClose: {
      type: Boolean,
      default: true,
    },
    afterSelectOption: {
      type: Function,
      default: function () {}
    },
    multiselectFinishSelected: {
      type: Function,
      default: function () {}
    }
  },
  watch: {
    modelValue: function () {
      this.setValue()
    },
  },
  mounted: function() {
    this.setValue()
  },
  data() {
    return {
      show: false,
      filterText: '',
      values: [],
    }
  },
  methods: {
    setValue: function () {
      if (Array.isArray(this.modelValue)) {
        this.values = this.modelValue.filter(function() {return true})
      }
      this.values = (this.modelValue + '').split(',')
    },
    isFilter(item) {
      return -1 !== item.name.toLowerCase().indexOf(this.filterText)
    },
    isActiveOption(uuid) {
      return !!this.inArray(uuid + '', this.values);
    },
    openModel: function () {
      if (false === this.disabled) {
        this.show = true
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    clickOption(uuid) {
      let strUuid = uuid + ''
      let value = this.multiselect ? this.values.filter(function() {return true}) : []
      if (true === this.inArray(strUuid, this.values)) {
        value = this.values.filter(function (item) {return item !== strUuid})
      } else {
        value.push(strUuid)
      }
      value = value.filter(function (item) {return '' !== item && 'null' !== item})

      let self = this
      setTimeout(function () {
        let returnValue = value.join(',')
        if ('' === returnValue) {
          returnValue = null
        }

        if (false === self.multiselect || null === returnValue) {
          self.$emit('update:modelValue', returnValue)
        } else {
          self.$emit('update:modelValue', value)
        }
        self.afterSelectOption()
      }, 20)

      if (false === this.multiselect) {
        this.closeSelectModel()
      }
    },
    getLabel(uuid) {
      for (let i in this.list) {
        if (uuid + '' === this.list[i].uuid + '') {
          return this.list[i].name
        }
      }
      return ''
    },
    labelButtonClearValue() {
      this.$emit('update:modelValue', null)
      this.afterSelectOption()
      if (true === this.multiselect) {
        this.multiselectFinishSelected()
      }
    },
    clickOk() {
      this.closeSelectModel()
    },
    clearValue() {
      this.$emit('update:modelValue', null)
      this.afterSelectOption()
      this.closeSelectModel()
    },
    closeSelectModel: function () {
      this.show = false
      if (true === this.multiselect) {
        this.multiselectFinishSelected()
      }
      if (true === this.isGlobalClose) {
        this.emitter.emit('modal', {event: 'close'})
      }
    },
  }
}
</script>

<style>
.multiselect-form-control .badge .fs-12px {
  font-size: 10px;
}
.multiselect-form-list-group-item.active .text-secondary,
.multiselect-form-control .badge .text-secondary {
  color: #e8e2e2 !important;
}
</style>

<style scoped>
.form-control[data-disabled="true"] {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control {
  min-height: 38px;
}

.multiselect-form-control {
  padding-left: 40px;
  padding-right: 58px;
}

.form-control .badge {
  white-space: initial;
}

.form-control .badge:not(:first-child) {
  margin-left: 4px;
  margin-bottom: 4px;
}

.multiselect-indicator {
  position: absolute;
  top: 6px;
  left: 10px;
}

.btn-clear-input {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  margin-top: 4px;
  z-index: 2;
}

.btn-click-input-open-model {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
</style>
