<template>
  <base-modal
      :show="show"
      :is-bg-click-close="true"
      title=""
      :header="false"
      :footer="true"
      :is-global-close="isGlobalClose"
      @close="close"
  >
    <template v-slot:body>
      <div class="my-2">
        <div v-html="text"></div>
      </div>
      <div class="mb-1">&nbsp;</div>
    </template>
    <template v-slot:footer>
      <a class="btn sm-w-100 mt-1" :class="'btn-' + btnType" @click="clickConfirm">{{ btnText }}</a>
      <a class="btn btn-light sm-w-100 ms-sm-2 mt-1" @click="close">Отмена</a>
    </template>
  </base-modal>
</template>
<script>
import BaseModal from './BaseModal'

export default {
  name: 'ConfirmActionModal',
  components: {BaseModal},
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    btnText: {
      type: String,
      required: true
    },
    btnType: {
      type: String,
      required: true
    },
    confirmAction: {
      type: Function,
      required: true
    },
    isGlobalClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      date: null,
    }
  },
  methods: {
    clickConfirm() {
      this.confirmAction()
    },
    close: function () {
      this.$emit('close')
      if (true === this.isGlobalClose) {
        this.emitter.emit('modal', {event: 'close'})
      }
    },
  }
}
</script>

<style scoped>
.div-btn {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.form-control[disabled] {
  background: none;
}

.app-color-theme-dark .form-control[disabled] {
  background: none;
}
</style>
