<template>
  <page-top-tabs class="mt-2" :tabs="tabs" />
</template>
<script>

import PageTopTabs from '@/components/PageTopTabs'

export default {
  name: 'SchoolTopTabs',
  components: {PageTopTabs},
  data() {
    return {
      tabs: [],
    }
  },
  mounted: function() {
    let self = this

    this.tabs = []
    if (this.hasRoute('school-run-processes')) {
      this.tabs.push({
        name: '<i class="fa-solid fa-school"></i> Текущие',
        isDisabled: false,
        isActive: 'school-run-processes' === self.$route.name,
        click: function () {self.toRoute({name: 'school-run-processes'})},
      })
    }
    if (this.hasRoute('school-planning')) {
      this.tabs.push({
        name: '<i class="fa-solid fa-school-circle-exclamation"></i> Планирование',
        isDisabled: false,
        isActive: 'school-planning' === self.$route.name,
        click: function () {self.toRoute({name: 'school-planning'})},
      })
    }
    if (this.hasRoute('school-students')) {
      this.tabs.push({
        name: '<i class="fa-solid fa-user-graduate"></i> Слушатели',
        isDisabled: false,
        isActive: 'school-students' === self.$route.name,
        click: function () {self.toRoute({name: 'school-students'})},
      })
    }
    if (this.hasRoute('school-analytics-timetables')) {
      this.tabs.push({
        name: '<i class="fa-solid fa-calendar-days"></i> Расписание',
        isDisabled: false,
        isActive: 'school-analytics-timetables' === self.$route.name,
        click: function () {self.toRoute({name: 'school-analytics-timetables'})},
      })
    }
    if (this.hasRoute('school-analytics')) {
      this.tabs.push({
        name: '<i class="fa-solid fa-chart-area"></i> Аналитика',
        isDisabled: false,
        isActive: 'school-analytics' === self.$route.name,
        click: function () {self.toRoute({name: 'school-analytics'})},
      })
    }
    if (this.hasRoute('school-done-processes')) {
      this.tabs.push({
        name: '<i class="fa-solid fa-school-circle-check"></i> Завершенные',
        isDisabled: false,
        isActive: 'school-done-processes' === self.$route.name,
        click: function () {self.toRoute({name: 'school-done-processes'})},
      })
    }
    if (this.hasRoute('school-setting')) {
      this.tabs.push({
        name: '<i class="fa-solid fa-gear"></i> Конфигурация',
        isDisabled: false,
        isActive: 'school-setting' === self.$route.name,
        click: function () {self.toRoute({name: 'school-setting'})},
      })
    }
  },
  methods: {
    hasRoute(routeName) {
      let isShow = false
      this.globalVar.app.user.routes.forEach(function (userRoute) {
        if (userRoute === routeName) {
          isShow = true
        }
      })
      return isShow
    },
  }
}
</script>

<style scoped>
</style>
