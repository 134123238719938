<template>
  <div class="container-fluid mt-3">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        :is-global-close="false"
        @close="isConfirmDeleteModal = false"
    />
    <div v-if="isLoaded">
      <h5 class="card-title placeholder-glow">
        <span class="placeholder col-6"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-12 my-2 fs-26px"></span>
        <span class="placeholder col-12 my-2 fs-26px"></span>
        <span v-if="'new' === uuid" class="placeholder col-12 my-2 fs-26px"></span>
        <span v-if="'new' === uuid" class="placeholder col-12 my-2 fs-26px"></span>
        <span v-if="'new' === uuid" class="placeholder col-12 my-2 fs-26px"></span>
      </p>
      <div class="text-end">
        <a class="btn btn-primary disabled placeholder col-6" aria-disabled="true"></a>
      </div>
    </div>
    <div v-if="false === isLoaded">
      <div v-if="null === data.channel">
        <div v-if="0 === data.channels.length" class="py-1 fs-18px">
          Для данного типа уведомлений выбраны все возможные каналы доставки!
        </div>
        <div v-if="0 < data.channels.length" class="pb-1 fs-14px text-secondary">
          Укажите нужный канал для доставки уведомлений:
        </div>
        <div
            class="mb-2 btn btn-light w-100"
            v-for="(channel, i_channel) in data.channels"
            :key="'channel.' + i_channel"
            @click="selectAndSave(channel.uuid)">
          <span class="pe-2" v-html="iconTypes[channel.type]"></span>
          <span v-if="'' !== networkTypes[channel.network]" class="me-1" v-html="networkTypes[channel.network]"></span>
          {{ channel.network_name }}
        </div>
      </div>
      <div v-else class="my-3">
        <div class="pb-1 fs-14px text-secondary">
          Канал доставки уведомлений
        </div>
        <span class="pe-2" v-html="iconTypes[data.channel.type]"></span>
        <span v-if="'' !== networkTypes[data.channel.network]" class="me-1" v-html="networkTypes[data.channel.network]"></span>
        {{ data.channel.network_name }}
      </div>
      <div class="text-end">
        <a v-if="inArray('delete', data.buttons)" class="btn btn-danger sm-w-100 mt-1 ms-sm-2" @click="openModalDelete">Удалить</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeForm">Закрыть</a>
      </div>
    </div>
  </div>
</template>

<script>

import {subformMixin} from '@/mixins/subformMixin'
import ConfirmActionModal from '@/components/ConfirmActionModal'

export default {
  name: 'ProfileNotificationSettingView',
  components: {ConfirmActionModal},
  mixins: [subformMixin],
  props: {
    uuid: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    updateParent: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    }
  },
  beforeMount() {
    this.formUrl.params.uuid = this.uuid + ''
    this.formUrl.params.type = this.type + ''
  },
  data() {
    return {
      formName: 'profile-notification-setting',
      formUrl: {string: '/profile-notification-setting?uuid=${uuid}&type=${type}', params: {uuid: 'new', type: ''}},
      editRoute: {name:  'profile-notification-setting'},
      exitRoute: {name: 'profile'},

      data: {
        uuid: null,
        channel: null,
        channels:[],
        notification_type: '',
        time: null,
      },

      isConfirmDeleteModal: false,

      isAtLeastOnceSaved: false,

      iconTypes: {
        system: '<i class="fa-solid fa-bolt"></i>',
        app: '<i class="fa-solid fa-mobile-button"></i>',
        email: '<i class="fa-solid fa-envelope"></i>',
        messenger: '<i class="fa-solid fa-comments"></i>',
      },
      networkTypes: {
        system: '',
        email: '',
        app: '',
        telegram: '<i class="fa-brands fa-telegram" style="color: var(--app-telegram-bg)"></i>',
        vk: '<i class="fa-brands fa-vk" style="color: var(--app-vk-bg)"></i>',
      }
    }
  },
  methods: {
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
    },
    afterSave: function () {
      this.isAtLeastOnceSaved = true
      this.close()
      this.updateParent()
    },
    afterDelete: function () {
      let self = this
      this.isConfirmDeleteModal = false
      setTimeout(function () {
        self.close()
        self.updateParent()
      }, 200)
    },
    selectAndSave: function (uuid) {
      this.data.channel_uuid = uuid
      this.save()
    },
    closeForm: function () {
      let self = this
      self.isConfirmDeleteModal = false
      self.close()
      if (true === self.isAtLeastOnceSaved) {
        setTimeout(function () {
          self.updateParent()
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.new-notification-email-enter-active,
.new-notification-email-leave-active {
  transition: opacity 0.5s ease;
}

.new-notification-email-enter-from,
.new-notification-email-leave-to {
  opacity: 0;
}
</style>
