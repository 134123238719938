<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <base-modal title="Пользователи" :show="isListUsersModel" :footer="true" @close="close">
      <template v-slot:body>
        <div>
          <ul class="list-group mt-1 mb-4">
            <li class="list-group-item cursor-pointer"
                v-for="(item, i_item) in data.listUsers"
                :key="'o.' + i_item"
                :class="{'d-none': isActiveListUsers(item.uuid)}"
                @click="itemListUser_click(item.uuid)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="close">ОК</a>
      </template>
    </base-modal>
    <page-top-bar
        :title="title"
        :subtitle="'Карточка события'"
        :buttons="buttons"
    />
    <div class="card border-0 shadow mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div v-if="!inArray('name', enabledFields)" class="fw-bold fs-20px">
                {{ data.name }}
              </div>
              <div v-if="inArray('name', enabledFields)">
                <label class="form-label">Наименование</label>
                <input type="text" class="form-control" placeholder="Обсудить отсутствие сладостей в офисе"
                       v-model="data.name"
                       :class="{ 'is-invalid': !!errors['name'] }"
                >
                <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
              </div>
            </div>
            <div class="mt-3">
              <div v-if="!inArray('url', enabledFields) && !empty(data.url)">
                <a target="_blank" :href="data.url">Ссылка</a>
                <div class="text-secondary fs-10px">{{ data.url }}</div>
              </div>
              <div v-if="inArray('url', enabledFields)">
                <label class="form-label">
                  url
                  <span class="text-secondary fs-10px ms-1">по необходимости</span>
                </label>
                <input type="text" class="form-control" placeholder="https://avicrm.ru"
                       v-model="data.url"
                       :class="{ 'is-invalid': !!errors['url'] }"
                >
                <div class="text-danger" v-if="!!errors['url']">{{ errors['url'].join(', ') }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div v-if="!inArray('event_at', enabledFields)" class="row mt-3 mt-sm-0">
              <div class="col-sm-3 text-secondary">Дата</div>
              <div class="col-sm-9">
                {{ stringDateYmdhisToHidmy(data.event_at, true) }}
                <span  v-if="!inArray(data.status, ['aside', 'cancel', 'done'])" class="badge" :class="isValidDate(data.event_at) ? 'bg-success': 'bg-danger'">
                  {{ intervalDiffEventAt }}
                </span>
              </div>
            </div>
            <div v-if="inArray('event_at', enabledFields)"  class="row mt-3 mt-sm-0">
              <div class="col-8">
                <div>
                  <label class="form-label">Дата</label>
                  <date-time-picker-input v-model="data.event_at" :is-time="true"/>
                  <div class="text-danger" v-if="!!errors['event_at']">{{ errors['event_at'].join(', ') }}</div>
                </div>
                <div class="mt-3">
                  <label class="form-label">Продолжительность <b>в минутах</b></label>
                  <input type="number" step="1" class="form-control" placeholder="60"
                         :class="{ 'is-invalid': !!errors['duration_in_minutes'] }"
                         v-model="data.duration_in_minutes"
                  >
                  <div class="text-danger" v-if="!!errors['duration_in_minutes']">{{ errors['duration_in_minutes'].join(', ') }}</div>
                </div>
              </div>
              <div v-if="!empty(data.event_at)" class="col-4">
                <label class="form-label mb-0">До мероприятия</label>
                <div>
                  <div class="badge" :class="isValidDate(data.event_at) ? 'bg-success': 'bg-danger'">
                    {{ intervalDiffEventAt }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="mt-3">
              <div v-if="inArray('description', enabledFields) || !empty(data.description)">
                <label class="form-label">
                  Пояснительная записка
                  <span v-if="inArray('description', enabledFields)" class="text-secondary fs-10px ms-1">по необходимости</span>
                </label>
                <textarea class="form-control textarea-description"
                          placeholder="1. узнать ответственного, 2. обязать закупать сладости"
                          v-model="data.description"
                          :disabled="!inArray('description', enabledFields)"
                          :class="{ 'is-invalid': !!errors['description'], 'fs-12px': !inArray('description', enabledFields) }"
                />
                <div class="text-danger" v-if="!!errors['description']">{{ errors['description'].join(', ') }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="mt-3">
              <label class="form-label">
                Участники события
                <a v-if="inArray('users', enabledFields)" @click="openUsersModel" type="button" class="btn btn-sm btn-link">
                  <i class="fa-solid fa-plus"></i>
                </a>
              </label>
              <div
                  v-for="(userUuid, i_userUuid) in data.users"
                  :key="'u.i.' + i_userUuid"
                  class="px-2"
              >
                {{ userName(userUuid) }}
                <a v-if="inArray('users', enabledFields)"
                    @click="itemRelationUsers_click(i_userUuid)" type="button" class="text-danger ms-2">
                  <i class="fa-solid fa-trash"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import DateTimePickerInput from '@/components/form/DateTimePickerInput'
import BaseModal from '@/components/BaseModal'

export default {
  name: 'EventView',
  components: {BaseModal, DateTimePickerInput, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      isConfirmDeleteModal: false,

      data: {
        event_at: null,
        duration_in_minutes: null,
        name: '',
        description: '',
        url: '',
        users: [],
      },

      formName: 'event',
      formUrl: {string: '/event?uuid=${uuid}'},
      editRoute: {name:  'event', params: {uuid: 0}},
      exitRoute: {name: 'events'},

      title: '<i class="fa-solid fa-timeline"></i> Событие',
      intervalDiffEventAt: '',

      isListUsersModel: false,
      typeRelationUser: '',

      timer: null,
    }
  },
  mounted: function() {
    let self = this
    this.timer = setInterval(function () {
      self.setIntervalDiffEventAt()
    }, 1000)
  },
  unmounted: function() {
    clearInterval(this.timer)
  },
  methods: {
    setIntervalDiffEventAt: function () {
      if (false === this.empty(this.data.event_at)) {
        this.intervalDiffEventAt = this.intervalDiffBetweenDate(this.data.event_at)
      } else {
        this.intervalDiffEventAt = ''
      }
    },
    userName: function(uuidUser) {
      for (let i in this.data.listUsers) {
        if (uuidUser === this.data.listUsers[i].uuid) {
          return this.data.listUsers[i].name
        }
      }
      return ''
    },
    afterLoad: function () {
      this.setIntervalDiffEventAt()
    },
    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    openUsersModel: function () {
      this.isListUsersModel = true
      this.emitter.emit('modal', {event: 'open'})
    },

    isActiveListUsers: function(uuid) {
      return this.inArray(uuid, this.data.users)
    },
    itemListUser_click: function(uuid) {
      let strUuid = uuid + ''
      if (true === this.isActiveListUsers(strUuid)) {
        this.data.users = this.data.users.filter(function (userUuid) {
          return userUuid !== strUuid
        })
      } else {
        this.data.users.push(strUuid)
      }

      this.close()
    },
    itemRelationUsers_click: function (i) {
      this.data.users.splice(i, 1)
    },
    close: function () {
      this.isConfirmDeleteModal = false
      this.isListUsersModel = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.textarea-description {
  height: 100%;
  min-height: 300px;
}
</style>
