<template>
  <div>
    <div>
      <div v-if="inArray('addCourse', buttons)" class="my-2" :class="{'add-relation-button-container': 0 === items.length}">
        <div class="float-start">
          <button class="btn btn-sm btn-outline-secondary w-100" type="submit" @click="openItem_click('new')">
            Добавить курс
          </button>
        </div>
      </div>
      <page-list-paginator
          v-if="0 < items.length"
          :paginator="paginatorList"
          :link-page="paginatorLinkPage_click"
          size="sm"
      />
    </div>
    <table class="table table-sm table-hover">
      <thead>
      <tr>
        <th scope="col"><sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Имя'" :change-sort="sortField_change" /></th>
        <th scope="col"><sort-input :name-input="this.listName + '.sort.description'" :name-field="'description'" :input-name="'Описание'" :change-sort="sortField_change" /></th>
      </tr>
      <tr>
        <th scope="col"><search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" /></th>
        <th scope="col"><search-input :name-input="this.listName + '.search.description'" :name-field="'description'" :input-text="searchField_input" /></th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="cursor-pointer"
          v-for="(item, i_item) in items"
          :key="'list.' + listName + '.' + i_item"
          @click="openItem_click(item.uuid)"
      >
        <td>{{ item.name }}</td>
        <td>{{ item.description }}</td>
      </tr>
      </tbody>
    </table>
    <div class="d-block text-center py-2" v-if="isLoading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
    <div class="d-block" v-if="0 === items.length">
      <div class="my-3 text-center text-secondary">
        Нет курсов
      </div>
    </div>
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import PageListPaginator from '@/components/PageListPaginator'
import SortInput from '@/components/SortInput'
import SearchInput from '@/components/SearchInput'

export default {
  name: 'SchoolCoursesList',
  components: {SearchInput, SortInput, PageListPaginator},
  mixins: [listMixin],
  data() {
    return {
      listName: 'SchoolCoursesList',
      listUrl: {string: '/school-courses', params:[]},
      itemNameRoute: 'school-course',
      pageButtons: [],

      paginatorList: {
        currentPage: 0,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
      },
    }
  },
  watch: {
    paginator: function (newVal) {
      let self = this
      setTimeout(function () {
        self.paginatorList = newVal
      }, 10)
    },
  },
  mounted: function() {
    this.emitter.on('schoolCoursesList', this.emitterSchoolCoursesList_reload)
  },
  unmounted: function() {
    this.emitter.off('schoolCoursesList', this.emitterSchoolCoursesList_reload)
  },
  methods: {
    emitterSchoolCoursesList_reload: function () {
      this.load()
    },

    openItem_click: function (uuid) {
      let route = {name: this.itemNameRoute, params: {uuid: uuid}}
      this.toRoute(route)
    },
  },
}
</script>

<style scoped>
.page-list-pagination {
  display: inline-block;
  padding: 0;
}

.add-relation-button-container {
  min-height: 38px;
}
</style>
