<template>
  <component :is="mountLayout">
    <router-view />
  </component>
</template>

<script>
import authLayout from './AuthLayout'
import appLayout from './app/AppLayout.vue'
export default {
  name: "BaseLayout",
  components: {appLayout, authLayout},
  data() {
    return {
      mountLayout: '',
    }
  },
  watch: {
    $route() {
      this.updateLayout()
    }
  },
  methods: {
    updateLayout() {
      let routeData = JSON.parse(localStorage.router_currentRoute ?? '{"meta":{"layout":"auth"}}')
      this.mountLayout = routeData.meta.layout + 'Layout'
    }
  }
}
</script>

<style scoped>

</style>