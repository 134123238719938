<template>
  <div>
    <base-modal title="" :show="crmContractorRelationSuspension.isModel" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <crm-contractor-relation-suspension-view
            :crm-contractor-uuid="crmContractorRelationSuspension.contractorUuid"
            :uuid="crmContractorRelationSuspension.uuid"
            :update-parent="load"
            :close="close"
        />
      </template>
    </base-modal>
    <div>
      <page-list-paginator
          v-if="0 < items.length && false === isLoading"
          :paginator="paginatorList"
          :link-page="paginatorLinkPage_click"
          size="sm"
      />
    </div>
    <table class="table table-sm">
      <thead>
      <tr>
        <th scope="col">
          <sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Наименование'" :change-sort="sortField_change" />
          <div class="text-secondary fs-10px">Поиск осуществляется по наименованию и описанию</div>
        </th>
        <th scope="col">
          Приобретенные курсы
          <div class="text-secondary fs-10px">Поиск осуществляется по перечню курсов контрагента</div>
        </th>
        <th scope="col">
          Учебные прогрессы
        </th>
      </tr>
      <tr>
        <th scope="col"><search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" /></th>
        <th scope="col"><search-input :name-input="this.listName + '.search.course'" :name-field="'course'" :input-text="searchField_input" /></th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(item, i_item) in items"
          :key="'list.' + listName + '.' + i_item"
      >
        <td>
          {{ item.name }}
          <div class="text-secondary fs-12px">{{ item.description }}</div>
          <div class="mt-1"
               :class="{'btn btn-sm btn-light fs-14px text-start': inArray('crm-contractor-relation-suspension', globalVar.app.user.routes)}"
               v-for="(suspension, i_suspension) in item.suspensions"
               :key="'c.' + i_contractor + '.sus.' + i_suspension"
               @click="openEditRelationSuspension(item.uuid, suspension.uuid)">
            <div class="badge text-bg-warning me-1 fs-12px">Приостановка</div>
            до <b>{{ stringDateYmdToDmy(suspension.suspend_at) }}</b>
            <span class="text-secondary ms-1">{{ suspension.suspend_comment }}</span>
          </div>

        </td>
        <td>
          <div v-for="(course, i_course) in item.courses" :key="'course.' + i_course">
            {{ course.name }}
          </div>
        </td>
        <td>
          <div class="has-text-weight-bold" v-if="empty(item.processes)">
            На текущий момент учебных процессов нет
          </div>
          <div class="fs-12px text-secondary" v-if="!empty(item.processes)">
            <div class="mb-2" v-for="(process, i_process) in item.processes" :key="'process.' + i_process">
              <div class="has-text-weight-bold">{{ process.processName }}</div>
              <div>{{ process.courseName }}</div>
              <progress-bar
                  :all="process.progress.all"
                  :current="process.progress.current"
                  :must="process.progress.must"
              />
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="d-block" v-if="0 === items.length && false === isLoading">
      <div class="my-3 text-center text-secondary">
        Новых контрагентов "обладателей" курсов нет
      </div>
    </div>
    <div class="d-block text-center py-2" v-if="isLoading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput'
import PageListPaginator from '@/components/PageListPaginator'
import SortInput from '@/components/SortInput'
import ProgressBar from '@/components/ProgressBar'
import BaseModal from '@/components/BaseModal.vue'
import CrmContractorRelationSuspensionView from '@/views/app/crm/components/CrmContractorRelationSuspensionView'

export default {
  name: 'PlanningCrmContractorsList',
  components: {CrmContractorRelationSuspensionView, BaseModal, ProgressBar, SortInput, PageListPaginator, SearchInput},
  mixins: [listMixin],
  data() {
    return {
      title: '<i class="fa-solid fa-address-book"></i> Контрагенты',

      listName: 'PlanningCrmContractorsList',
      listUrl: {string: '/school-planning-crm-contractors', params:[]},
      itemNameRoute: 'school-planning-crm-contractor',
      pageButtons: [],

      paginatorList: {
        currentPage: 0,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
      },

      crmContractorRelationSuspension: {
        isModel: false,
        contractorUuid: '',
        uuid: '',
      },
    }
  },
  watch: {
    paginator: function (newVal) {
      let self = this
      setTimeout(function () {
        self.paginatorList = newVal
      }, 10)
    },
  },
  mounted: function() {
    this.emitter.on('planningCrmContractorsList', this.emitterPlanningCrmContractorsList_reload)
  },
  unmounted: function() {
    this.emitter.off('planningCrmContractorsList', this.emitterPlanningCrmContractorsList_reload)
  },
  methods: {
    emitterPlanningCrmContractorsList_reload: function () {
      this.load()
    },

    openEditRelationSuspension(contractorUuid, uuid) {
      if (this.inArray('crm-contractor-relation-suspension', this.globalVar.app.user.routes)) {
        this.crmContractorRelationSuspension.isModel = true
        this.crmContractorRelationSuspension.contractorUuid = contractorUuid
        this.crmContractorRelationSuspension.uuid = uuid
        this.emitter.emit('modal', {event: 'open'})
      }
    },

    close: function () {
      this.crmContractorRelationSuspension.isModel = false
      this.emitter.emit('modal', {event: 'close'})
    },
  },
}
</script>

<style scoped>
.page-list-pagination {
  display: inline-block;
  padding: 0;
}
</style>
