<template>
  <div>
    <div>
      <div v-if="inArray('addFile', buttons)" class="my-2" :class="{'add-file-button-container': 0 === items.length}">
        <div v-show="addFile.isAdd">
          <div v-if="true === addFile.isLoadingFile" class="mt-2 mb-3 text-center">
            <div class="spinner-border text-primary spinner-border-sm" role="status">
              <span class="visually-hidden">Загрузка...</span>
            </div>
            <span class="ms-1 text-secondary">Загрузка файла...</span>
          </div>
          <div v-if="true !== addFile.isLoadingFile">
            <input class="form-control" @change="changeAddFile($event)" type="file">
          </div>
          <div class="text-end mt-1">
            <button class="btn btn-sm btn-primary" type="submit" @click="saveFile">
              Добавить
            </button>
            <button class="btn btn-sm btn-light ms-1" type="submit" @click="addFile.isAdd = false">
              Отмена
            </button>
          </div>
        </div>
        <div class="float-start" v-show="!addFile.isAdd">
          <button class="btn btn-sm btn-outline-secondary w-100" type="submit" @click="startAddFile">
            Добавить файл
          </button>
        </div>
      </div>
      <page-list-paginator
          v-if="0 < items.length && false === isLoading"
          :paginator="paginatorList"
          :link-page="paginatorLinkPage_click"
          size="sm"
      />
    </div>
    <div class="mb-3" v-for="(file,i_file) in items" :key="'m.' + i_file">
      <file-link :delete-click="deleteTaskFile" :file="file" />
    </div>
    <div class="d-block" v-if="0 === items.length && false === isLoading">
      <div class="my-3 text-center text-secondary">
        Нет файлов
      </div>
    </div>
    <div class="d-block text-center py-2" v-if="isLoading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import PageListPaginator from '@/components/PageListPaginator'
import FileLink from '@/components/form/FileLink'

export default {
  name: 'TaskFilesList',
  components: {FileLink, PageListPaginator},
  mixins: [listMixin],
  props: {
    taskUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      listName: 'TaskFilesList',
      listUrl: {string: '/task-files', params:[]},
      itemNameRoute: 'task-files',
      pageButtons: [],

      listSearch: {
        taskUuid: this.taskUuid,
      },
      listSearchDefault: {
        taskUuid: this.taskUuid,
      },

      paginatorList: {
        currentPage: 0,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
      },

      addFile: {
        isAdd: false,
        isLoadingFile: false,
        file: null,
      },
    }
  },
  watch: {
    paginator: function (newVal) {
      let self = this
      setTimeout(function () {
        self.paginatorList = newVal
      }, 10)
    },
  },
  mounted: function() {
    this.emitter.on('taskFilesList', this.emitterTaskFilesList_reload)
  },
  unmounted: function() {
    this.emitter.off('taskFilesList', this.emitterTaskFilesList_reload)
  },
  methods: {
    emitterTaskFilesList_reload: function () {
      this.load()
    },
    startAddFile() {
      this.addFile.isAdd = true
      this.addFile.file = null
    },
    changeAddFile(event) {
      this.addFile.file = event.target.files[0]
    },
    saveFile: function () {
      this.emitter.emit('app', {event: 'spinner.show'})
      this.addFile.isAdd = false
      this.addFile.isLoadingFile = true

      let formData = new FormData()
      formData.append('taskUuid', this.taskUuid)
      formData.append('fileFieldName', 'taskFile')
      formData.append('taskFile', this.addFile.file)

      let self = this
      window.axios.post(
          `${this.globalVar.app.api.url}/task-file?uuid=new&${(new Date()).getTime()}`,
          formData,
          {headers: {'Content-Type': 'multipart/form-data'}}
      )
          .then(response => {
            if (false === self.empty(response.data.dataErrors)) {
              self.errors = JSON.parse(JSON.stringify(response.data.dataErrors))
            }
            self.generalError = response.data.generalError

            if (false === self.empty(self.generalError)) {
              this.app_addPush('Ошибка добавления файла: ' + self.generalError)
            }

            if (true === response.data.result) {
              self.load()
            }
          })
          .finally(() => {
              self.emitter.emit('app', {event: 'spinner.hide'})
              self.addFile.isLoadingFile = false
          })
    },
    deleteTaskFile(uuid) {
      console.log('delete - ' + uuid)
    },
  },
}
</script>

<style scoped>
.page-list-pagination {
  display: inline-block;
  padding: 0;
}

.add-file-button-container {
  min-height: 38px;
}
</style>
