<template>
  <div>
    <div class="row mb-4" v-for="(notification, i_notification) in list" :key="'notification.' + i_notification">
      <div class="col-6">
        {{ notification.name }}
        <div class="text-secondary fs-12px hover-name-text">
          {{ notification.param.params.description }}
        </div>
        <div>
          <a href="#" @click="openNotificationSettingModel(notification.uuid, 'new')">
            Добавить профиль
          </a>
        </div>
      </div>
      <div class="col-6">
        <div
            v-for="(notificationSetting, i_notificationSettings) in profileData.notificationSettings.filter(function(item) {return notification.uuid === item.notification_type})"
            :key="'notificationSettings.' + i_notificationSettings">
                      <span
                          v-for="(notificationChannel, i_notificationChannels) in profileData.notificationChannels.filter(function(item) {return notificationSetting.channel_uuid === item.uuid})"
                          :key="'notificationSettings.' + i_notificationSettings + '.notificationChannel.' + i_notificationChannels">
                        <span class="pe-2" v-html="iconTypes[notificationChannel.type]"></span>
                        <span v-if="'' !== networkTypes[notificationChannel.network]" class="pe-2" v-html="networkTypes[notificationChannel.network]"></span>
                        <b>{{ notificationChannel.network_name }}</b>
                      </span>
          <a class="ms-2" href="#" @click="openNotificationSettingModel(notification.uuid, notificationSetting.uuid)"><i class="fa-solid fa-pen-to-square"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProfileNotificationList',
  props: {
    list: {
      type: Array,
      required: true
    },
    profileData: {
      type: Object,
      required: true
    },
    openNotificationSettingModel: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      iconTypes: {
        system: '<i class="fa-solid fa-bolt"></i>',
        app: '<i class="fa-solid fa-mobile-button"></i>',
        email: '<i class="fa-solid fa-envelope"></i>',
        messenger: '<i class="fa-solid fa-comments"></i>',
      },
      networkTypes: {
        system: '',
        email: '',
        app: '',
        telegram: '<i class="fa-brands fa-telegram" style="color: var(--app-telegram-bg)"></i>',
        vk: '<i class="fa-brands fa-vk" style="color: var(--app-vk-bg)"></i>',
      }
    }
  }
}
</script>

<style scoped>
</style>
