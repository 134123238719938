<template>
  <div class="page-content">
    <school-top-tabs />
    <div>
      <page-top-bar
          :title="titleSchoolCourse"
          :subtitle="'Процессы обучения в статусе планирования'"
          :buttons="[]"
      />
      <div class="row">
        <div class="col-12">
          <div class="card border-0 shadow">
            <div class="card-body">
              <planning-school-processes-list :key="'school-relation-user-types-list'" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <page-top-bar
          :title="titleCrmContractor"
          :subtitle="'Контрагент, который приобрел курс, но процесс обучения по нему еще не спланирован/запушен/пройден'"
          :buttons="[]"
      />
      <div class="row">
        <div class="col-12">
          <div class="card border-0 shadow">
            <div class="card-body">
              <planning-crm-contractors-list :key="'school-courses-list'" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PageTopBar from '@/components/PageTopBar'
import SchoolTopTabs from '@/views/app/school/components/SchoolTopTabs'
import PlanningSchoolProcessesList from '@/views/app/school/components/PlanningSchoolProcessesList.vue'
import PlanningCrmContractorsList from '@/views/app/school/components/PlanningCrmContractorsList'

export default {
  name: 'SchoolPlanningView',
  components: {
    PlanningSchoolProcessesList,
    PlanningCrmContractorsList, SchoolTopTabs, PageTopBar},
  data() {
    return {
      titleSchoolCourse: '<i class="fa-solid fa-school-circle-exclamation"></i> Процессы обучения',
      titleCrmContractor: '<i class="fa-solid fa-address-book"></i> Контрагенты-претенденты на новый процесс обучения',
    }
  },
  mounted() {
    this.emitter.emit('app', {event: 'spinner.hide'})
  },
}
</script>

<style scoped>
</style>
