<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <base-modal title="Доступ к бизнесам" :show="isEditRelationBusinessModal" :footer="true" @close="closeRelationBusiness">
      <template v-slot:body>
        <div class="row text-start mb-2">
          <div class="col-12">
            <div>
              <input type="checkbox" v-model="currentRelationBusiness.is_owner" class="form-check-input" id="currentRelationBusinessIsOwner">
              <label class="form-check-label" for="currentRelationBusinessIsOwner">Пользователь является владельцем бизнеса</label>
              <div class="text-danger" v-if="!!errors['is_blocked']">{{ errors['is_blocked'].join(', ') }}</div>
            </div>
            <div class="mt-3">
              <label class="form-label">Бизнес</label>
              <multiselect-input
                  v-model="currentRelationBusiness.business"
                  :list="data.listBusinesses"
                  :is-global-close="false"
                  :after-select-option="relationBusiness_afterSelectOption"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a v-if="-1 !== indexCurrentRelationBusiness" class="btn btn-danger sm-w-100 mt-1" @click="deleteRelationBusiness">Удалить</a>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="saveRelationBusiness">ОК</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeRelationBusiness">Отмена</a>
      </template>
    </base-modal>
    <page-top-bar
        :title="'[SYS] Пользователь'"
        :subtitle="'Данные пользователя aVicrm'"
        :buttons="buttons"
    />
    <div class="card border-0 shadow">
      <div class="card-body">
        <div class="row mt-2">
          <div class="col-12">
            <div>
              <input type="checkbox" v-model="data.is_blocked" class="form-check-input" id="businessIsBlocked">
              <label class="form-check-label" for="businessIsBlocked">Пользователь заблокирован</label>
              <div class="text-danger" v-if="!!errors['is_blocked']">{{ errors['is_blocked'].join(', ') }}</div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="form-label">Контрагент (пользователю не доступно)</label>
            <multiselect-input
                v-model="data.contractor_id"
                :disabled="!inArray('contractor_id', enabledFields)"
                :class-name="{ 'is-invalid': !!errors['contractor_id'] }"
                :list="data.listContractors"
            />
            <div class="text-danger" v-if="!!errors['contractor_id']">{{ errors['contractor_id'].join(', ') }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-6">
            <label class="form-label">Роль</label>
            <multiselect-input
                v-model="data.role"
                :disabled="!inArray('role', enabledFields)"
                :class-name="{ 'is-invalid': !!errors['role'] }"
                :list="globalVar.db.roles"
            />
            <div class="text-danger" v-if="!!errors['role']">{{ errors['role'].join(', ') }}</div>
          </div>
          <div class="col-sm-6">
            <label class="form-label">ФИО</label>
            <input type="text" class="form-control" placeholder="Фамилия Имя Отчество"
                   v-model="data.name"
                   :disabled="!inArray('name', enabledFields)"
                   :class="{ 'is-invalid': !!errors['name'] }"
            >
            <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-6">
            <label class="form-label">Email</label>
            <input type="text" class="form-control" placeholder="email"
                   v-model="data.email"
                   :disabled="!inArray('email', enabledFields)"
                   :class="{ 'is-invalid': !!errors['email'] }"
            >
            <div class="text-danger" v-if="!!errors['email']">{{ errors['email'].join(', ') }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="form-label">Текущий Бизнес</label>
            <multiselect-input
                v-model="data.current_business_uuid"
                :disabled="!inArray('current_business_uuid', enabledFields)"
                :class-name="{ 'is-invalid': !!errors['current_business_uuid'] }"
                :list="data.listBusinesses"
            />
            <div class="text-danger" v-if="!!errors['current_business_uuid']">{{ errors['current_business_uuid'].join(', ') }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="form-label">Бизнесы</label>
            <div class="overflow-x-auto">
              <table class="table table-sm table-hover table-striped">
                <thead>
                <tr>
                  <th scope="col">Владелец</th>
                  <th scope="col">Наименование</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in data.relationBusinesses"
                    :key="'relationBusinesses.' + i_item"
                    @click="openRelationBusiness_click(i_item, item)"
                    :class="{'table-warning': item.is_owner}"
                >
                  <td>{{ item.is_owner ? 'Да' : 'Нет' }}</td>
                  <td>{{ item._data.businessName }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="inArray('relationBusinesses', enabledFields)" class="row mt-2">
          <div class="col-12 text-end">
            <button class="btn btn-primary" type="submit" @click="openNewRelationBusiness_click">
              Добавить бизнес
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import MultiselectInput from '@/components/form/MultiselectInput'
import BaseModal from '@/components/BaseModal'

export default {
  name: 'UserView',
  components: {BaseModal, MultiselectInput, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      isConfirmDeleteModal: false,

      formName: 'sysUser',
      formUrl: {string: '/sys-user?uuid=${uuid}'},
      editRoute: {name:  'sys-user', params: {uuid: 0}},
      exitRoute: {name: 'sys-users'},

      isEditRelationBusinessModal: false,
      indexCurrentRelationBusiness: -1,
      currentRelationBusiness: {
        is_owner: false,
        business: '',
        _data: {
          businessName: '',
        }
      },
      clearRelationBusiness: {
        is_owner: false,
        business: '',
        _data: {
          businessName: '',
        }
      },
    }
  },
  methods: {
    afterLoad: function () {
      for (let m in this.data.relationBusinesses) {
        for (let l in this.data.listBusinesses) {
          if (this.data.relationBusinesses[m].business + '' === this.data.listBusinesses[l].uuid + '') {
            this.setRelationBusinessData(this.data.relationBusinesses[m], this.data.listBusinesses[l])
          }
        }
      }
    },

    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    openNewRelationBusiness_click: function () {
      this.openRelationBusiness_click(-1, JSON.parse(JSON.stringify(this.currentRelationBusiness)))
    },
    openRelationBusiness_click: function (i, relationBusiness) {
      if (this.inArray('relationBusinesses', this.enabledFields)) {
        this.indexCurrentRelationBusiness = i
        this.currentRelationBusiness = JSON.parse(JSON.stringify(relationBusiness))
        this.isEditRelationBusinessModal = true
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    relationBusiness_afterSelectOption: function () {
      this.currentRelationBusiness['_data'] = {
        businessName: '',
      }
      for (let l in this.data.listBusinesses) {
        if (this.currentRelationBusiness.business + '' === this.data.listBusinesses[l].uuid + '') {
          this.setRelationBusinessData(this.currentRelationBusiness, this.data.listBusinesses[l])
        }
      }
    },
    saveRelationBusiness: function () {
      let relationBusinessData = JSON.parse(JSON.stringify(this.currentRelationBusiness))

      if (-1 === this.indexCurrentRelationBusiness) {
        this.data.relationBusinesses.push(relationBusinessData)
      } else {
        Object.assign(this.data.relationBusinesses[this.indexCurrentRelationBusiness], relationBusinessData)
      }
      this.indexCurrentRelationBusiness = -1
      Object.assign(this.currentRelationBusiness, this.clearRelationBusiness)
      this.close()
    },
    deleteRelationBusiness: function () {
      this.indexCurrentRelationBusiness = -1
      this.data.relationBusinesses.splice(this.indexCurrentRelationBusiness, 1)
      this.close()
    },
    closeRelationBusiness: function () {
      this.indexCurrentRelationBusiness = -1
      Object.assign(this.currentRelationBusiness, this.clearRelationBusiness)
      this.close()
    },
    setRelationBusinessData: function(relation, business) {
      relation['_data'] = {
        businessName: business.name,
      }
    },
    close: function () {
      this.isConfirmDeleteModal = false
      this.isEditRelationBusinessModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
</style>
