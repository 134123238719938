<template>
  <div class="page-list">
    <school-top-tabs />
    <page-top-bar
        :title="title"
        :subtitle="'Активные процессы обучения'"
        :buttons="[]"
    />
    <div class="page-list-content">
      <div class="card border-0 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <input type="checkbox" v-model="isMy"
                     @change="setIsMy"
                     class="form-check-input" id="taskIsTimeInDeadlineAt">
              <label class="form-check-label" for="taskIsTimeInDeadlineAt">Отобразить только с моим участием</label>
            </div>
          </div>
          <div class="row">
            <div class="col-12 overflow-x-auto" :class="{'edit-planning-process': inArray('editPlanningProcess', buttons)}">
              <table class="table table-sm table-hover">
                <thead>
                <tr>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Наименование'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    Наименование курса
                  </th>
                  <th>Расписание</th>
                  <!-- <th scope="col">Заметки</th> -->
                  <th scope="col" class="td-progress">Прогресс</th>
                  <th scope="col" class="td-money-progress">Средства</th>
                  <th scope="col">Заметки</th>
                </tr>
                <tr>
                  <th scope="col"><search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" /></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.courseName'" :name-field="'courseName'" :input-text="searchField_input" /></th>
                  <th scope="col"></th>
                  <!-- <th scope="col"><search-input :name-input="this.listName + '.search.description'" :name-field="'description'" :input-text="searchField_input" /></th> -->
                  <th scope="col" class="td-progress"></th>
                  <th scope="col" class="td-money-progress"></th>
                  <th scope="col"><search-input :name-input="this.listName + '.search.noteText'" :name-field="'noteText'" :input-text="searchField_input" /></th>
                </tr>
                </thead>
                <tbody>
                <school-processes-list-table-row-view
                    v-for="(item, i_item) in items"
                    :key="'list.' + listName + '.' + i_item"
                    :item-click="openItem_click"
                    :planning-item-click="openPlanningItem_click"
                    :row-data="item"
                    :is-edit-planning-progress="inArray('editPlanningProcess', buttons)"
                    :is-loading-notes="isLoadingNotes"
                    :update-notes="loadNotes"
                />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-list-paginator
        :paginator="paginator"
        :link-page="paginatorLinkPage_click"
    />
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput.vue'
import PageListPaginator from '@/components/PageListPaginator.vue'
import SortInput from '@/components/SortInput.vue'
import SchoolTopTabs from '@/views/app/school/components/SchoolTopTabs'
import PageTopBar from '@/components/PageTopBar'
import SchoolProcessesListTableRowView from '@/views/app/school/components/SchoolProcessesListTableRowView'
export default {
  name: 'SchoolRunProcessesList',
  components: {SchoolProcessesListTableRowView, PageTopBar, SchoolTopTabs, SortInput, PageListPaginator, SearchInput},
  mixins: [listMixin],
  data() {
    return {
      title: '<i class="fa-solid fa-school"></i> Процессы обучения',
      listName: 'SchoolRunProcessesList',
      listUrl: {string: '/school-run-processes', params:[]},
      itemNameRoute: 'school-run-process',

      isLoadingNotes: false,

      listSearch: {
        isMy: false,
        userUuids: [],
        name: '',
        courseName: '',
        description: '',
      },
      listSearchDefault: {
        isMy: false,
        userUuids: [],
        name: '',
        courseName: '',
        description: '',
      },

      isMy: false,
    }
  },
  mounted: function() {
    this.emitter.on('SchoolRunProcessesList', this.emitterSchoolRunProcessesList_reload)
    this.isMy = this.getIsMy()
  },
  unmounted: function() {
    this.emitter.off('SchoolRunProcessesList', this.emitterSchoolRunProcessesList_reload)
  },
  methods: {
    emitterSchoolRunProcessesList_reload: function () {
      this.load()
    },

    afterLoad: function () {
      this.loadNotes()
    },

    loadNotes: function () {
      this.isLoadingNotes = true

      let uuids  = []
      for (let i in this.items) {
        uuids.push(this.items[i].uuid)
      }

      window.axios.post(
          this.globalVar.app.api.url + '/notes' + '?' + (new Date()).getTime(), {
            search: {schoolProgressUuids: uuids, isDone: false, perPage: 1000},
            sort: {}
          })
          .then(response => {
            for (let i in this.items) {
              let item = this.items[i]
              item.notes = []
              for (let c in response.data.items) {
                let note = response.data.items[c]
                for (let o in note.objects) {
                  let object = note.objects[o]
                  if (item.uuid === object.uuid) {
                    item.notes.push(note)
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error, error.data)
          })
          .finally(() => {
            this.isLoadingNotes = false
          })
    },

    openItem_click: function(uuid) {
      let route = {name: this.itemNameRoute, params: {uuid: uuid}}
      this.toRoute(route)
    },

    openPlanningItem_click: function(uuid) {
      this.setFormExitRoute('schoolPlanningProcess', {name: 'school-run-processes'})
      this.toRoute({name:  'school-planning-process', params: {uuid: uuid}})
    },

    getIsMy: function () {
      let is = localStorage.getItem('SchoolRunProcessesList.search.isMy')
      this.listSearch.isMy = 'true' === is
      return this.listSearch.isMy
    },
    setIsMy: function () {
      if (false === this.isMy) {
        this.listSearch.isMy = false
        localStorage.removeItem('SchoolRunProcessesList.search.isMy')
      } else {
        this.listSearch.isMy = true
        localStorage.setItem('SchoolRunProcessesList.search.isMy', 'true')
      }
      this.load()
    }
  },
}
</script>

<style scoped>
.page-list-pagination {
  display: inline-block;
  padding: 0;
}

.add-process-button-container {
  min-height: 38px;
}

.edit-planning-process {
  padding-right: 40px;
}

.edit-planning-process td {
  position: relative;
}

.td-progress {
  width: 150px;
}

.td-money-progress {
  width: 200px;
}
</style>
