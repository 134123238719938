export const functionsMixin = {
  methods: {
    empty(value) {
      if (value === undefined) { return true }
      if (value === "") { return true }
      if (value === 0) { return true }
      if (value === "0") { return true }
      if (value === 0.0) { return true }
      if (value === "0.0") { return true }
      if (value === "0.00") { return true }
      if (value === null) { return true }
      if (value === 'null') { return true }
      if (value === ['null']) { return true }
      if (value === false) { return true }
      if (value === []) { return true }
      if (value === {}) { return true }
      if (JSON.stringify(value) === "{}") { return true }
      if (JSON.stringify(value) === "[]") { return true }
      if (JSON.stringify(value) === "[\"\"]") { return true }
      if (JSON.stringify(value) === "[\"null\"]") { return true }
      return false
    },
    inArray(value, array) {
      for (let i in array) {
        if (value + '' === array[i] + '') {
          return true;
        }
      }
      return false;
    },
    arraySort(array, column) {
      return array.sort(function(a, b) {
        if (b[column] < a[column]) {
          return 1;
        }
        if (b[column] > a[column]) {
          return -1;
        }

        return 0;
      })
    },

    stringDateDmyToYmd(date)
    {
      if (10 !== date.length) {
        return date
      }

      return date.substr(6,4) + '-' +  date.substr(3,2) + '-' + date.substr(0,2)
    },

    stringDateYmdToDmy(date)
    {
      if (this.empty(date)) {
        return ''
      }

      if (10 !== date.length) {
        return date
      }
      return date.substr(8,2) + '.' +  date.substr(5,2) + '.' + date.substr(0,4)
    },

    stringDateYmdhisToHidmy(date, isShowHi)
    {
      if (this.empty(date)) {
        return ''
      }

      if (19 !== date.length) {
        return date
      }
      return (true === isShowHi ? date.substr(11,2) + ':' +  date.substr(14,2) + ' ' : '') +
        date.substr(8,2) + '.' +  date.substr(5,2) + '.' + date.substr(0,4)
    },

    isValidDate(stringDate) {
      let now = Date.now()
      return new Date(stringDate) >= now
    },

    dateIsThanDate(stringDate, stringThanDate) {
      return new Date(stringDate) >= new Date(stringThanDate)
    },

    stringDateAddInterval(stringDate, interval) {
      let date = new Date(stringDate)
      let arrayInterval = interval.split(' ')
      let numberInterval = arrayInterval[0] * 1
      switch (arrayInterval[1]) {
        case 'days':
          date.setDate(date.getDate() + numberInterval)
          break
        case 'months':
          date.setMonth(date.getMonth() + numberInterval)
          break
        case 'years':
          date.setFullYear(date.getFullYear() + numberInterval)
          break
      }

      return this.getDateToStringYmd(date)
    },

    getDateToStringDmy(date) {
      return (9 < date.getDate() ? '' : '0') + date.getDate()
          + '.' + (8 < date.getMonth() ? '' : '0') + (date.getMonth() + 1)
          + '.' + date.getFullYear()
    },

    getDateToStringYmd(date) {
      return date.getFullYear()
          + '-' + (8 < date.getMonth() ? '' : '0') + (date.getMonth() + 1)
          + '-' + (9 < date.getDate() ? '' : '0') + date.getDate()
    },

    getDateToStringYmdHis(date) {
      return date.getFullYear()
          + '-' + (8 < date.getMonth() ? '' : '0') + (date.getMonth() + 1)
          + '-' + (9 < date.getDate() ? '' : '0') + date.getDate()
          + ' ' + (9 < date.getHours() ? '' : '0') + date.getHours()
          + ':' + (9 < date.getMinutes() ? '' : '0') + date.getMinutes()
          + ':' +(9 < date.getSeconds() ? '' : '0') +  date.getSeconds()
    },

    getDateToStringDmyHi(date) {
      return (9 < date.getDate() ? '' : '0') + date.getDate()
          + '.' + (8 < date.getMonth() ? '' : '0') + (date.getMonth() + 1)
          + '.' + date.getFullYear()
          + ', ' + (9 < date.getHours() ? '' : '0') + date.getHours()
          + ':' + (9 < date.getMinutes() ? '' : '0') + date.getMinutes()
    },

    getDateToStringYmd000(date) {
      return date.getFullYear()
          + '-' + (8 < date.getMonth() ? '' : '0') + (date.getMonth() + 1)
          + '-' + (9 < date.getDate() ? '' : '0') + date.getDate()
          + ' 00:00:00'
    },

    app_addPush(message) {
      if (localStorage.appPushes === undefined) {
        localStorage.appPushes = JSON.stringify([])
      }
      let appPushes = JSON.parse(localStorage.appPushes)
      appPushes.unshift({
        created_at: this.getDateToStringDmyHi(new Date()),
        message: message,
      })
      localStorage.appPushes = JSON.stringify(appPushes)

      this.emitter.emit('app', {event: 'push.new'})
      this.emitter.emit('app', {event: 'push.update', data: message})
    },

    setFormExitRoute(formName, route) {
      localStorage.setItem(formName + '.currentExitRoute', JSON.stringify(route))
    },
    toRoute(route) {
      if (!this.isCurrentRoute(route)) {
        let props = this.$router.resolve(route)
        this.$router.push({ path: props.href })
      }
    },

    isCurrentRoute(routeData) {
      if (this.empty(routeData.name)) {
        return false
      }

      if (this.$route.name !== routeData.name) {
        return false
      }

      if (undefined !== routeData.params) {
        for (let param in routeData.params) {
          if (undefined === this.$route.params[param]) {
            return false
          } else if (this.$route.params[param] !== routeData.params[param]) {
            return false
          }
        }
        for (let param in this.$route.params) {
          if (undefined === routeData.params[param]) {
            return false
          } else if (routeData.params[param] !== this.$route.params[param]) {
            return false
          }
        }
      }

      return true
    },
    getValueEnums: function (enums, uuid, fieldName) {
      for (let i in enums) {
        if (uuid === enums[i].uuid) {
          return enums[i][fieldName]
        }
      }
      return ''
    },
    generateUuid: function() {
      return this.getRandomString(6) + '-' + (new Date()).getTime() + '-' + this.getRandomString(6)
    },
    getRandomString: function(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
    getRandomInt: function(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    getValueByCurrentColorTheme(light, dark) {
      let colorTheme = this.globalVar.app.colorTheme + ''

      if ('system' === colorTheme) {
        colorTheme = this.globalVar.app.systemColorTheme + ''
      }

      return 'dark' === colorTheme ? dark : light
    },
    localTimeZone() {
      let offset = new Date().getTimezoneOffset(), absOffset = Math.abs(offset);
      return (offset < 0 ? '' : '-') + absOffset;
    },

    intervalDiffBetweenDate(strDate) {
      let time = (new Date(strDate)).getTime() - (new Date()).getTime()
      time /= 1000

      if (time < 0) {
        time *= -1
      }

      let dayTime = 24 * 60 * 60;
      let hourTime = 60 * 60;
      let minuteTime = 60;
      let days = Math.trunc(time / dayTime);
      let hours = Math.trunc((time - days * dayTime) / hourTime);
      let minutes = Math.trunc((time - days * dayTime - hours * hourTime) / minuteTime);
      let seconds = Math.trunc(time - days * dayTime - hours * hourTime - minutes * minuteTime);

      return (days > 0 ? days + 'д. ' : '')
          + (hours > 9 ? '' : '0') + hours
          + ':'
          + (minutes > 9 ? '' : '0') + minutes
          + ':'
          + (seconds > 9 ? '' : '0') + seconds
    },

    diffBetweenDates(targetDate, date) {
      const targetDateStamp = (new Date(targetDate)).getTime()
      const dateStamp = (new Date(date)).getTime()

      let calcDate;

      if (targetDateStamp > dateStamp) {
        calcDate = new Date(targetDateStamp - dateStamp)
      } else {
        calcDate = new Date(dateStamp - targetDateStamp)
      }

      const calcFormat = [calcDate.getDate(), (calcDate.getMonth() + 1), calcDate.getFullYear()]
      const daysPassed = Number(Math.abs(calcFormat[0]) - 1)
      const monthsPassed = Number(Math.abs(calcFormat[1]) - 1)
      const yearsPassed = Number(Math.abs(calcFormat[2]) - 1970)

      return (yearsPassed > 0 ? yearsPassed + ' ' + this.getNameIntervalTime('years', yearsPassed) + ' ' : '')
          + (monthsPassed > 0 ? monthsPassed + ' ' + this.getNameIntervalTime('months', monthsPassed) + ' ' : '')
          + (daysPassed > 0 ? daysPassed + ' ' + this.getNameIntervalTime('days', daysPassed) + ' ' : '')
    },

    getNameIntervalTime: function(type, number){
      switch (type) {
        case 'days':
          return this.getNumberDescription(number, 'день', 'дня','дней')
        case 'weeks':
          return this.getNumberDescription(number, 'неделя', 'недели','недель')
        case 'months':
          return this.getNumberDescription(number, 'месяц', 'месяца','месяцев')
        case 'years':
          return this.getNumberDescription(number, 'год', 'года','лет')
      }
    },

    getNumberDescription: function(number, description1, description234, description5) {
      let strNumber = number + ''
      let s1 = strNumber.substring(strNumber.length - 1)
      let s2 = strNumber.substring(strNumber.length - 2)

      if ('1' === s1 && '11' !== s2) {
        return description1
      }
      if ('2' === s1 && '12' !== s2) {
        return description234
      }
      if ('3' === s1 && '13' !== s2) {
        return description234
      }
      if ('4' === s1 && '14' !== s2) {
        return description234
      }

      return description5
    },

    percent(current, all) {
      if (0 === 1 * all) {
        return '0%'
      }

      return (current / all * 100).toFixed(2) + '%'
    },

    downloadFile(fileData) {
      let file = window.URL.createObjectURL(new Blob([fileData.content]))
      let docUrl = document.createElement('a')
      docUrl.href = file
      docUrl.setAttribute('id', 'appDownloadFile')
      docUrl.setAttribute('download', fileData.name)
      document.body.appendChild(docUrl)
      docUrl.click()
      document.body.removeChild(docUrl)
    },
  }
}
