<template>
  <div class="page-list">
    <event-top-tabs />
    <page-top-bar
        :title="title"
        :subtitle="'Настройки для регулярных событий'"
        :buttons="pageButtons"
    />

    <div class="page-list-content">
      <div class="card border-0 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-12 overflow-x-auto">
              <table class="table table-sm table-hover">
                <thead>
                <tr>
                  <th scope="col">
                    Интервал
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.time'" :name-field="'time'" :input-name="'Время начала'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Наименование'" :change-sort="sortField_change" />
                  </th>
                  <th class="td-description" scope="col">
                    <sort-input :name-input="this.listName + '.sort.description'" :name-field="'description'" :input-name="'Пояснительная записка'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.url'" :name-field="'url'" :input-name="'URL'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.start_at'" :name-field="'start_at'" :input-name="'Начальная дата'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.end_at'" :name-field="'start_at'" :input-name="'Конечная дата'" :change-sort="sortField_change" />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    <search-input :name-input="this.listName + '.search.intervalName'" :name-field="'intervalName'" :input-text="searchField_input" />
                  </th>
                  <th scope="col">
                    <search-input :name-input="this.listName + '.search.time'" :name-field="'time'" :input-text="searchField_input" />
                  </th>
                  <th scope="col">
                    <search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" />
                  </th>
                  <th class="td-description" scope="col">
                    <search-input :name-input="this.listName + '.search.description'" :name-field="'description'" :input-text="searchField_input" />
                  </th>
                  <th scope="col">
                    <search-input :name-input="this.listName + '.search.url'" :name-field="'url'" :input-text="searchField_input" />
                  </th>
                  <th scope="col">
                    <search-input-date-range :name-input="this.listName + '.search.startAtDateRange'" :name-field="'startAtDateRange'" :input-date="searchField_input" />
                  </th>
                  <th scope="col">
                    <search-input-date-range :name-input="this.listName + '.search.endAtDateRange'" :name-field="'endAtDateRange'" :input-date="searchField_input" />
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in items"
                    :key="'list.' + listName + '.' + i_item"
                    @click="openItem_click(item.uuid)"
                >
                  <td>{{ item.intervalName }}</td>
                  <td>{{ item.time }}</td>
                  <td>{{ item.name }}</td>
                  <td class="td-description">{{ item.description }}</td>
                  <td>{{ item.url }}</td>
                  <td>{{ stringDateYmdhisToHidmy(item.start_at)  }}</td>
                  <td>{{ stringDateYmdhisToHidmy(item.end_at)  }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-list-paginator
        :paginator="paginator"
        :link-page="paginatorLinkPage_click"
    />
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput'
import PageTopBar from '@/components/PageTopBar'
import PageListPaginator from '@/components/PageListPaginator'
import SortInput from '@/components/SortInput'
import SearchInputDateRange from '@/components/SearchInputDateTimeRange'
import EventTopTabs from '@/views/app/event/components/EventTopTabs'

export default {
  name: 'EventRecurringsList',
  components: {
    EventTopTabs,
    SearchInputDateRange, SortInput, PageTopBar, PageListPaginator, SearchInput},
    mixins: [listMixin],
  data() {
    return {
      listName: 'EventRecurringsList',
      listUrl: {string: '/event-recurrings', params:[]},
      itemNameRoute: 'event-recurring',
      pageButtons: [],

      title: '<i class="fa-solid fa-gear"></i> Регулярные события',

      listSearch: {
        time: '',
        intervalName: '',
        startAtDateRange: [],
        endAtDateRange: [],
        name: '',
        description: '',
        url: '',
        userName: '',
      },
      listSearchDefault: {
        time: '',
        intervalName: '',
        startAtDateRange: [],
        endAtDateRange: [],
        name: '',
        description: '',
        url: '',
        userName: '',
      },
    }
  },
  methods: {
    afterLoad: function () {
      let self = this
      self.pageButtons = []
      if (-1 !== self.buttons.indexOf('addEventRecurring')) {
        self.pageButtons.push({
          name: 'Добавить',
          class: 'btn-primary', click: function() {
            self.toRoute({name: self.itemNameRoute, params: {uuid: 'new'}})
          }
        })
      }
    },
  }
}
</script>

<style scoped>
.td-description {
  width: 20%;
}

.div-users {
  max-height: 38px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
