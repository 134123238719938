<template>
  <div class="app-main-container">
    <BaseLayout />
    <div v-show="isAppLoad" class="app-main-spinner-load">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Загрузка...</span>
        </div>
    </div>
  </div>
</template>
<script>
import BaseLayout from './layouts/BaseLayout.vue'

export default {
  name: "App",
  components: {BaseLayout},
  data() {
    return {
      isAppLoad: false,
      lanStatus: true,
    }
  },
  mounted: function() {
    this.setAppColorTheme()

    const currentRoute = localStorage.getItem('router_currentRoute')
    if (null !== currentRoute) {
      this.toRoute(JSON.parse(currentRoute))
    }

    this.emitter.on('app', this.emitterApp_app)
    this.emitter.on('modal', this.emitterApp_modal)
  },
  unmounted: function() {
    this.emitter.off('app', this.emitterApp_app)
    this.emitter.off('modal', this.emitterApp_modal)
  },
  methods: {
    emitterApp_app: function (data) {
      switch (data.event) {
        case 'spinner.show':
          this.isAppLoad = true
          break
        case 'spinner.hide':
          this.isAppLoad = false
          break
        case 'app.colorTheme':
          this.setAppColorTheme()
          break
        case 'status.update':
          this.lanStatus = this.globalVar.app.lan.status
          break
        case 'login':
          this.globalVar.app.isStatusAuthChangedUser = true
          this.toRoute({name: 'dashboard'})
          document.body.classList.remove('modal-open')
          break
        case 'logout':
          if (false === this.globalVar.app.user.isAuth) {
            this.globalVar.app.isStatusAuthChangedUser = true
            window.axios.post(`${this.globalVar.app.api.url}/logout?${(new Date()).getTime()}`)
            localStorage.removeItem('user')
            for(let storeName in this.globalVar.dbConfig.stores) {
              this.db_allDelete(storeName)
            }
            this.toRoute({name: 'auth'})
            document.body.classList.remove('modal-open')
          }
          break
      }
    },
    emitterApp_modal: function (data) {
      switch (data.event) {
        case 'open':
          document.body.classList.add('modal-open')
          break
        case 'close':
          setTimeout(
              () => document.body.classList.remove('modal-open')
              , 200 // У BaseModal есть задержка ухода с экрана в .2s
          )
          break
      }
    },
    setAppColorTheme() {
      let colorTheme = 'light'
      if ('system' === this.globalVar.app.colorTheme) {
        colorTheme = this.globalVar.app.systemColorTheme + ''
      } else {
        colorTheme = this.globalVar.app.colorTheme + ''
      }

      if ('dark' === colorTheme) {
        document.body.classList.add('app-color-theme-dark')
      } else {
        document.body.classList.remove('app-color-theme-dark')
      }
    },
  }
}
</script>
<style scoped>
.app-main-container {
  background: var(--app-bg);
  color: var(--app-color);
}

.app-main-spinner-load {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(var(--app-bg-grb), 0.1);
  z-index: 9000;
}

.app-main-spinner-load > div {
  position: absolute;
  top: calc(50% - var(--bs-spinner-width)/2);
  left: calc(50% - var(--bs-spinner-width)/2);
}
</style>
