<template>
  <div class="card border-0 shadow h-100">
    <base-modal title="" :show="currentNote.isEditModal" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <object-note-view
            :uuid="currentNote.uuid"
            :objects="currentNote.objects"
            :update-parent="loadCalendar"
            :close="close"
        />
      </template>
    </base-modal>

    <div class="card-header">
      Запланировано на {{ day.now ? 'сегодня' : stringDateYmdToDmy(day.date) }} ({{ day.weekNName }})
      <div  v-if="isLoading" class="spinner-border spinner-border-sm text-primary position-absolute end-0 top-0 m-3" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="0 < data.length" :class="{'d-inline-flex': plans.isWidth}">
        <div :class="{'d-inline-block me-2 pe-4 border-end': plans.isWidth}">
          <div class="plans-block" :style="plans.isWidth ? `width:${plans.container.width}px` : ''">
            <div class="plans-container" :style="`min-width:${plans.container.width}px`">
              <div v-for="(row, i_row) in plans.container.rows"
                   :key="'plans.container.row.' + i_row"
                   class="plans-container-row"
                   :style="`height:${row.height}px`">
                <div class="plans-container-row-legend">{{ row.name }}</div>
              </div>

              <div
                  v-if="!empty(this.plans.container.rowCurrentTime.top)"
                   class="plans-container-row-current-time"
                  :style="`top:${this.plans.container.rowCurrentTime.top}px`"
              ></div>

              <div v-for="(object, i_object) in plans.container.objects"
                   :key="'plans.container.object.' + i_object"
                   class="plans-container-object cursor-pointer"
                   :class="{'object-hover': object.hover}"
                   :style="`left:${object.left}px;top:${object.top}px;height:${object.height}px;width:${object.width}px;background-color:rgba(${getUserCaseBgColor(object.caseData.type)}, var(--bs-bg-opacity));`"
                   @mouseover="calcPlanesTable_mouseoverObject(object.objectUuid)"
                   @mouseleave="calcPlanesTable_mouseoverObject('')"
                   @click="clickCase(object.caseData)"
              >
                <div v-if="false === plans.isWidth" class="plans-container-object-tooltip">
                  <dashboard-case-view :case-data="object.caseData" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="{'d-inline-block': plans.isWidth}">
          <table class="table table-sm table-hover">
            <tbody>
            <tr
                class="cursor-pointer"
                v-for="(caseData, i_caseData) in data"
                :key="'case.' + i_caseData"
                @click="clickCase(caseData)"
                :class="{'case-hover': caseData.hover}"
                @mouseover="calcPlanesTable_mouseoverCase(caseData.objectUuid)"
                @mouseleave="calcPlanesTable_mouseoverCase('')"
            >
              <td class="td-case-time">
                <div class="text-secondary">
                  <div v-if="'00:00' === stringDateYmdhisToHidmy(caseData.date, true).substr(0, 5)">
                    <i class="fa-regular fa-clock"></i> <i class="fa-solid fa-arrow-rotate-right"></i>
                  </div>
                  <div v-else class="text-secondary">
                    {{ stringDateYmdhisToHidmy(caseData.date, true).substr(0, 5) }}
                  </div>
                </div>
              </td>
              <td>
                <dashboard-case-view :case-data="caseData" />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="0 === data.length">
        <div class="my-3 text-center text-secondary">
          Нет запланированных дел
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ObjectNoteView from '@/components/ObjectNoteView'
import BaseModal from '@/components/BaseModal'
import DashboardCaseView from '@/views/app/dashboard/components/DashboardСaseView'

export default {
  name: 'DashboardCasesList',
  components: {DashboardCaseView, BaseModal, ObjectNoteView},
  props: {
    day: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,
      data: [],

      plans: {
        isWidth: false,
        minHour: 24,
        maxHour: 0,
        intervalMinuteNotDuration: 20,
        intervalMinute: 60,
        heightMinute: 0.5,
        widthObject: 20,
        container: {
          width: 0,
          pX: 2,
          height: 0,
          rowCurrentTime: {
            top: null,
          },
          rows: [],
          objects: [],
        },
      },

      currentNote: {
        isEditModal: false,
        uuid: '',
      }
    }
  },
  watch: {
    day: function () {
      this.loadCalendar()
    }
  },
  methods: {
    getUserCaseBgColor: function (caseType) {
      let color = localStorage.getItem('WorkplaceParameters.userCases.bg.' + caseType)
      if (true === this.empty(color)) {
        return 'var(--bs-primary-rgb)';
      }
      return color
    },
    loadCalendar: function () {
      let self = this
      self.isLoading = true
      window.axios.post(
          `${this.globalVar.app.api.url}/dashboard-cases?${(new Date()).getTime()}`,
          {date: this.day.date}
      )
          .then(response => {
            self.data = response.data
            self.calcPlanesTable()
          })
          .catch((error) => {
            console.log(error, error.data)
          })
          .finally(() => {
            self.isLoading = false
              self.emitter.emit('app', {event: 'spinner.hide'})
          })
    },

    calcPlanesTable: function () {
      this.calcPlanesTable_setMinAndMaxHour()

      this.plans.container.rows = []
      for (let rowHour = this.plans.minHour; rowHour <= this.plans.maxHour - 1; rowHour++) {
        for (let rowMinute = 0; rowMinute < 60; rowMinute += this.plans.intervalMinute) {
          this.plans.container.rows.push(this.calcPlanesTable_getNewRow(rowHour, rowMinute))
        }
      }

      if (true === this.day.now) {
        let now = new Date
        let timeNow = (now.getHours() + (9 < now.getMinutes() ? '' : '0') + now.getMinutes()) * 1
        for (let r in this.plans.container.rows) {
          let row =  this.plans.container.rows[r]
          if (row.intTime <= timeNow && timeNow <= row.intEndTime) {
            this.plans.container.rowCurrentTime.top = row.top + (timeNow - row.intTime) * this.plans.heightMinute + 1
          }
        }
      } else {
        this.plans.container.rowCurrentTime.top = null
      }

      this.plans.container.objects = []
      for (let d in this.data) {
        if ('00:00' !== this.data[d].date.substr(11, 5)) {
          this.plans.container.objects.push(this.calcPlanesTable_getNewObject(this.data[d]))
        }
      }

      this.plans.container.width = 0
      for(let o in this.plans.container.objects) {
        if (this.plans.container.width < this.plans.container.objects[o].left + this.plans.container.objects[o].width) {
          this.plans.container.width = this.plans.container.objects[o].left + this.plans.container.objects[o].width
        }
      }
      this.plans.container.width += this.plans.container.pX * 2

      this.plans.isWidth = this.plans.widthObject * 6 > this.plans.container.width

      this.plans.container.height = 0
      for(let r in this.plans.container.rows) {
        this.plans.container.height += this.plans.container.rows[r].height
      }
    },
    calcPlanesTable_setMinAndMaxHour: function () {
      this.plans.minHour = 24
      this.plans.maxHour = 0

      for (let i in this.data) {
        let hour =  Math.trunc(this.data[i].intTime / 100)
        let endHour =  Math.ceil(this.data[i].intEndTime / 100)

        if (this.plans.minHour  > hour) {
          this.plans.minHour = hour
        }
        if (this.plans.maxHour < endHour + 1) {
          this.plans.maxHour = endHour + 1
        }
      }

      // ?? ??? - вынести в настройки
      this.plans.minHour = 7
      this.plans.maxHour = 21
    },
    calcPlanesTable_getNewRow: function (rowHour, rowMinute) {
      let endMinute = rowMinute + this.plans.intervalMinute
      if (endMinute >= 60) {
        endMinute = 59
      }
      let rows = this.plans.container.rows

      return {
        name: (9 < rowHour ? '' : '0') + rowHour + ':' + (9 < rowMinute ? '' : '0') + rowMinute,
        intTime: ((rowHour + '') + (9 < rowMinute ? '' : '0') + rowMinute) * 1,
        intEndTime: ((rowHour + '') + (9 < endMinute ? '' : '0') + endMinute) * 1,
        top: this.empty(rows) ? 0 : rows[rows.length - 1].top + rows[rows.length - 1].height,
        height: this.plans.heightMinute * this.plans.intervalMinute,
      }
    },
    calcPlanesTable_getNewObject: function (itemData) {
      let objectUuid = this.generateUuid()
      itemData.hover = false
      itemData.objectUuid = objectUuid

      let height = this.plans.heightMinute * itemData.duration_in_minutes

      let top = 1
      for (let r in this.plans.container.rows) {
        let row = this.plans.container.rows[r]
        if (row.intTime <= itemData.intTime && itemData.intTime <= row.intEndTime) {
          top = row.top + (itemData.intTime - row.intTime) * this.plans.heightMinute + 1
        }
      }

      let left = this.plans.container.pX + 0
      for (let o in this.plans.container.objects) {
        let object = this.plans.container.objects[o]
        if (
            //a2 >= b1 && b2 >= a1
            object.intEndTime >= itemData.intTime && itemData.intEndTime >= object.intTime &&
            left < object.left + object.width + 1
        ) {
          left = object.left + object.width + 1
        }
      }

      return {
        hover: false,
        top: top,
        left: left,
        width: this.plans.widthObject,
        isDuration: 0 < height,
        height: (0 < height ? height : this.plans.heightMinute * this.plans.intervalMinuteNotDuration) - 1,
        intTime: itemData.intTime,
        intEndTime: itemData.intEndTime,
        objectUuid: objectUuid,
        caseData: itemData,
      }
    },

    calcPlanesTable_mouseoverCase: function (objectUuid) {
      for (let o in this.plans.container.objects) {
        let object = this.plans.container.objects[o]
        object.hover = false
        if (objectUuid === object.objectUuid) {
          object.hover = true
        }
      }
    },
    calcPlanesTable_mouseoverObject: function (objectUuid) {
      for (let d in this.data) {
        let object = this.data[d]
        object.hover = false
        if (objectUuid === object.objectUuid) {
          object.hover = true
        }
      }
    },

    clickCase: function (caseData) {
      if ('note' === caseData.type) {
        this.editNote(caseData.uuid)
      }
      if ('task' === caseData.type) {
        this.setFormExitRoute(`task`, {name:  'dashboard'})
        this.toRoute({name:  'task', params: {uuid: caseData.uuid}})
      }
      if ('school-process' === caseData.type) {
        this.setFormExitRoute(`schoolRunProcess`, {name:  'dashboard'})
        this.toRoute({name:  'school-run-process', params: {uuid: caseData.uuid}})
      }
      if ('event' === caseData.type) {
        this.setFormExitRoute(`event`, {name:  'dashboard'})
        this.toRoute({name:  'event', params: {uuid: caseData.uuid}})
      }
    },

    editNote: function (noteUuid) {
      this.currentNote.uuid = noteUuid + ''

      this.currentNote.isEditModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    close: function () {
      this.currentNote.isEditModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.card-header {
  background-color: initial;
  border-bottom: 0;
}

.td-case-time {
  width: 52px;
}

.plans-block {
  position: relative;
  padding-top: 20px;
  margin-left: 36px;
}

.plans-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.plans-container-row {
  position: relative;
  border-top: 1px solid rgba(var(--bs-secondary-rgb), 0.3);
}
.plans-container-row-legend {
  position: absolute;
  left: -35px;
  top: -8px;
  vertical-align: middle;
  font-size: 10px;
  color: rgb(var(--bs-secondary-rgb));
}

.plans-container-row-current-time {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  border-radius: 2px;
  background-color: rgba(var(--bs-danger-rgb), 1);
}

.plans-container-object {
  position: absolute;
  z-index: 1;
  border: 1px solid var(--app-analytics-timetables-table-div-border);
  padding: 2px 3px;
  border-radius: 6px;
  --bs-bg-opacity: 0.6;
}

.plans-container-object:hover
,.plans-container-object.object-hover {
  --bs-bg-opacity: 1;
  z-index: 2;
}

.plans-container-object-tooltip {
  position: absolute;
  display: none;
  left: 22px;
  top: 0;
  background-color: var(--app-input-bg);
  padding: 10px 6px;
  border-radius: 10px;
  box-shadow: var(--bs-box-shadow) !important;
  min-width: 252px;
}

.plans-container-object:hover .plans-container-object-tooltip {
  display: block;
}

.table-hover>tbody>tr.case-hover>* {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}
</style>
