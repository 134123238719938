<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <base-modal title="Контрагент" :show="editData.isModel" :footer="true" @close="dataEditClose">
      <template v-slot:body>
        <div class="mb-2 text-start">
          <div class="row">
            <div class="col-12">
              <label class="form-label">Наименование</label>
              <input type="text" class="form-control" placeholder="ПАО Яблоко или Фамилия Имя Отчество"
                     v-model="data.name"
                     :disabled="!inArray('name', enabledFields)"
                     :class="{ 'is-invalid': !!errors['name'] }"
              >
              <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <label class="form-label">Описание</label>
              <input type="text" class="form-control" placeholder="Оч интересное название кампании"
                     v-model="data.description"
                     :disabled="!inArray('description', enabledFields)"
                     :class="{ 'is-invalid': !!errors['description'] }"
              >
              <div class="fs-10px text-secondary">(по необходимости)</div>
              <div class="text-danger" v-if="!!errors['description']">{{ errors['description'].join(', ') }}</div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <label class="form-label">Контакты</label>
              <div class="overflow-x-auto">
                <table class="table table-sm table-hover table-striped">
                  <tbody>
                  <tr
                      class="cursor-pointer"
                      v-for="(item, i_item) in data.contacts"
                      :key="'contact.' + i_item"
                      @click="openContact_click(i_item, item)"
                  >
                    <td>
                      <div>{{ item.name }}</div>
                    </td>
                    <td>
                      <div>{{ item.email }}</div>
                      <div>{{ item.phone }}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="inArray('contacts', enabledFields)" class="row mt-4">
            <div class="col-12 text-end">
              <button class="btn btn-sm btn-primary" type="submit" @click="openNewContact_click">
                Добавить контакт
              </button>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a
            v-for="(button, i_button) in buttons.filter(function(item) {
              return 'exit' !== item.uuid && 'newExit' !== item.uuid && 'saveAndExit' !== item.uuid && 'openDataModal' !== item.uuid
            })"
            :key="'form-button-' + i_button"
            class="btn sm-w-100 mt-1 ms-sm-2"
            :class="button.class"
            type="submit"
            @click="dataEditButtonClick(button)"
            v-html="button.name"
        />
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="dataEditClose">Отмена</a>
      </template>
    </base-modal>
    <base-modal title="Контакт" :show="isEditContactModal" :is-global-close="false" :footer="true" @close="closeContact">
      <template v-slot:body>
        <div class="row text-start mb-2">
          <div class="col-12">
            <div>
              <label class="form-label">ФИО</label>
              <input class="form-control" type="text" v-model="currentContact.name">
            </div>
          </div>
        </div>
        <div class="row text-start mb-2">
          <div class="col-12">
            <div>
              <label class="form-label">Email</label>
              <input class="form-control" type="text" v-model="currentContact.email">
            </div>
          </div>
        </div>
        <div class="row text-start mb-2">
          <div class="col-12">
            <div>
              <label class="form-label">Телефон</label>
              <input class="form-control" type="text" v-model="currentContact.phone">
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a v-if="-1 !== indexCurrentContact" class="btn btn-danger sm-w-100 mt-1" @click="deleteContact">Удалить</a>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="saveContact">{{ -1 !== indexCurrentContact ? 'Зафиксировать' : 'Добавить' }}</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeContact">Отмена</a>
      </template>
    </base-modal>

    <base-modal title="" :show="crmContractorRelationSuspension.isModel" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <crm-contractor-relation-suspension-view
            :crm-contractor-uuid="this.data.uuid"
            :uuid="crmContractorRelationSuspension.uuid"
            :update-parent="load"
            :close="close"
        />
      </template>
    </base-modal>
    <page-top-bar
        :title="title"
        :subtitle="'Данные контрагента'"
        :buttons="buttons.filter(function(button) {
          return 'newExit' === button.uuid || 'openDataModal' === button.uuid
        })"
    />

    <div class="row">
      <div class="col-xl-8 col-lg-7 col-md-6 col-sm-12">
        <div class="card border-0 shadow">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-7 col-12">
                <div class="row">
                  <div class="col-12">
                    <label class="form-label text-secondary">Наименование</label>
                    <div class="ms-2">{{ data.name }}</div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <label class="form-label text-secondary">Описание</label>
                    <div class="ms-2">{{ data.description }}</div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <label class="form-label text-secondary">Контакты</label>
                    <div class="overflow-x-auto">
                      <table class="table table-sm table-striped">
                        <tbody>
                        <tr v-for="(item, i_item) in data.contacts" :key="'contact.' + i_item">
                          <td>
                            <div>{{ item.name }}</div>
                          </td>
                          <td>
                            <div>{{ item.email }}</div>
                            <div>{{ item.phone }}</div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-12">
                <div v-if="isLoadingNotes">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Загрузка...</span>
                  </div>
                </div>
                <object-note-list
                    v-if="!isLoadingNotes"
                    object-type="crm-contractor"
                    :notes="data.notes"
                    :object-uuid="data.uuid"
                    :object-name="data.name"
                    :object-description="data.description"
                    :update-list="loadNotes"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
        <div class="card border-0 shadow mt-md-0 mt-3">
          <div class="card-body text-center">
            диаграмма тут (сколько сделок с в процессе/с результатом/без результата)
            <br>
            <br>
            ниже диаграмма будет цифровое значение в деньгах и кол-ве
          </div>
        </div>
      </div>
    </div>
    <div v-if="!empty(data.uuid)" class="row mt-4">
      <div class="col-12">
        <ul class="nav nav-underline mb-3" id="taskTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link disabled" id="deals-tab" data-bs-toggle="tab" data-bs-target="#deals-tab-pane" type="button" role="tab" aria-controls="deals-tab-pane" aria-selected="true">
              <i class="fa-solid fa-file-signature"></i> Сделки
            </button>
          </li>
          <li v-if="'school' === globalVar.app.user.businessType" class="nav-item" role="presentation">
            <button class="nav-link active" id="school-course-tab" data-bs-toggle="tab" data-bs-target="#school-course-tab-pane" type="button" role="tab" aria-controls="school-course-tab-pane" aria-selected="false">
              <i class="fa-solid fa-graduation-cap"></i> Обучение
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link disabled" id="hist-tab" data-bs-toggle="tab" data-bs-target="#hist-tab-pane" type="button" role="tab" aria-controls="hist-tab-pane" aria-selected="false">
              <i class="fa-solid fa-clock-rotate-left"></i> История
            </button>
          </li>
        </ul>
        <div class="tab-content my-1" id="taskTabContent">
          <div class="tab-pane fade" id="deals-tab-pane" role="tabpanel" aria-labelledby="deals-tab" tabindex="0">
            <div class="card border-0 shadow mt-md-0">
              <div class="card-body">
                Тут будет список сделок для конкретного контрагента
              </div>
            </div>
          </div>
          <div v-if="'school' === globalVar.app.user.businessType" class="tab-pane fade show active" id="school-course-tab-pane" role="tabpanel" aria-labelledby="school-course-tab" tabindex="0">
            <div class="card border-0 shadow mt-md-0">
              <div class="card-body">
                <div class="mb-3">
                  <div
                      class="mt-1"
                      :class="{'btn btn-sm btn-light fs-14px text-start': inArray('crm-contractor-relation-suspension', globalVar.app.user.routes)}"
                      v-for="(suspension, i_suspension) in data.suspensions"
                       :key="'sus.' + i_suspension"
                      @click="openEditRelationSuspension(suspension.uuid)"
                  >
                    <div class="badge text-bg-warning me-1 fs-12px">Приостановка</div>
                    до <b>{{ stringDateYmdToDmy(suspension.suspend_at) }}</b>
                    <span class="text-secondary ms-1">{{ suspension.suspend_comment }}</span>
                  </div>
                  <div v-if="empty(data.suspensions) && inArray('crm-contractor-relation-suspension', globalVar.app.user.routes)">
                    <button class="btn btn-very-sm btn-outline-secondary" type="submit" @click="openEditRelationSuspension('new')">
                      Добавить данные приостановки
                    </button>
                  </div>
                </div>
                <crm-contractor-relation-school-course-list :crm-contractor-uuid="this.data.uuid" />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="hist-tab-pane" role="tabpanel" aria-labelledby="hist-tab" tabindex="0">
            hist
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import BaseModal from '@/components/BaseModal'
import CrmContractorRelationSchoolCourseList from '@/views/app/crm/components/CrmContractorRelationSchoolCourseList'
import ObjectNoteList from '@/components/ObjectNoteList'
import CrmContractorRelationSuspensionView from '@/views/app/crm/components/CrmContractorRelationSuspensionView'

export default {
  name: 'CrmContractorView',
  components: {
    CrmContractorRelationSuspensionView,
    ObjectNoteList, CrmContractorRelationSchoolCourseList, BaseModal, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      title: '<i class="fa-solid fa-address-book"></i> Контрагент',
      isConfirmDeleteModal: false,
      formName: 'crmContractor',
      formUrl: {string: '/crm-contractor?uuid=${uuid}'},
      editRoute: {name:  'crm-contractor', params: {uuid: 0}},
      exitRoute: {name: 'crm-contractors'},

      isLoadingNotes: false,

      editData: {
        isModel: false,
        name: '',
        description: '',
        contacts: [],
        suspensions: [],
      },

      isEditContactModal: false,
      indexCurrentContact: -1,
      currentContact: {
        uuid: '',
        name: null,
        email: null,
        phone: null,
      },
      clearContact: {
        uuid: '',
        name: null,
        email: null,
        phone: null,
      },

      crmContractorRelationSuspension: {
        isModel: false,
        uuid: ''
      },
    }
  },
  mounted() {
    if ('new' === this.$route.params.uuid) {
      this.openDataModal()
    }
  },
  methods: {
    afterLoad: function () {
      this.loadNotes()
      let self = this
      this.buttons.push({
        uuid: 'openDataModal',
        name: 'Править',
        class: 'btn-primary', click: function() {self.openDataModal()}
      })
      this.buttons.push({
        uuid: 'newExit',
        name: 'Назад',
        class: 'btn-outline-secondary',
        click: function() {self.toRoute(self.exitRoute)}
      })
    },
    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    loadNotes: function () {
      this.isLoadingNotes = true
      let uuids  = [this.data.uuid]
      window.axios.post(
          this.globalVar.app.api.url + '/notes' + '?' + (new Date()).getTime(), {
            search: {crmContractorUuids: uuids, isDone: false, perPage: 1000},
            sort: {}
          })
          .then(response => {
              this.data.notes = []
              for (let c in response.data.items) {
                let note = response.data.items[c]
                for (let o in note.objects) {
                  let object = note.objects[o]
                  if (this.data.uuid === object.uuid) {
                    this.data.notes.push(note)
                  }
                }
            }
          })
          .catch((error) => {
            console.log(error, error.data)
          })
          .finally(() => {
            this.isLoadingNotes = false
          })
    },

    openDataModal: function () {
      this.editData.name = this.data.name + ''
      this.editData.description = this.data.description + ''
      this.editData.contacts = JSON.parse(JSON.stringify(this.data.contacts ?? []))
      this.editData.isModel = true
      this.emitter.emit('modal', {event: 'open'})
    },
    dataEditClose: function () {
      this.data.name = this.editData.name + ''
      this.data.description = this.editData.description + ''
      this.data.contacts = JSON.parse(JSON.stringify(this.editData.contacts ?? []))
      this.close()
      if ('new' === this.$route.params.uuid) {
        this.toRoute(this.exitRoute)
      }
    },
    dataEditButtonClick: function (button) {
      button.click()
      this.close()
    },

    openNewContact_click: function () {
      this.openContact_click(-1, JSON.parse(JSON.stringify(this.clearContact)))
    },
    openContact_click: function (i, contact) {
      if (this.inArray('contacts', this.enabledFields)) {
        this.indexCurrentContact = i
        this.currentContact = JSON.parse(JSON.stringify(contact))
        this.isEditContactModal = true
      }
    },
    saveContact: function () {
      let contactData = JSON.parse(JSON.stringify(this.currentContact))

      if (-1 === this.indexCurrentContact) {
        this.data.contacts.push(contactData)
      } else {
        Object.assign(this.data.contacts[this.indexCurrentContact], contactData)
      }
      this.indexCurrentContact = -1
      Object.assign(this.currentContact, this.clearContact)
      this.isEditContactModal = false
    },
    deleteContact: function () {
      this.data.contacts.splice(this.indexCurrentContact, 1)
      this.indexCurrentContact = -1
      this.isEditContactModal = false
    },
    closeContact: function () {
      this.indexCurrentContact = -1
      Object.assign(this.currentContact, this.clearContact)
      this.isEditContactModal = false
    },

    openEditRelationSuspension(uuid) {
      if (this.inArray('crm-contractor-relation-suspension', this.globalVar.app.user.routes)) {
        this.crmContractorRelationSuspension.isModel = true
        this.crmContractorRelationSuspension.uuid = uuid
        this.emitter.emit('modal', {event: 'open'})
      }
    },

    close: function () {
      this.editData.isModel = false
      this.isConfirmDeleteModal = false
      this.isEditContactModal = false
      this.crmContractorRelationSuspension.isModel = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
</style>
