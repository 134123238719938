<template>
  <div class="container-fluid mt-3">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.network_name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        :is-global-close="false"
        @close="isConfirmDeleteModal = false"
    />
    <div v-if="isLoaded">
      <h5 class="card-title placeholder-glow">
        <span class="placeholder col-6"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
      </p>
      <div class="text-end">
        <a class="btn btn-primary disabled placeholder col-6" aria-disabled="true"></a>
      </div>
    </div>
    <div v-if="false === isLoaded">
      <div v-if="'messenger' === type">
        <div v-if="data.is_validate" class="my-4">
          <div>Дополнительный канал получения уведомлений:</div>
          <div class="fs-16px">
            <span v-show="'telegram' === data.network" style="color: var(--app-telegram-bg)"><i class="fa-brands fa-telegram"></i></span>
            <span v-show="'vk' === data.network" style="color: var(--app-vk-bg)"><i class="fa-brands fa-vk"></i></span>
            Профиль: {{ data.network_name }}
          </div>
        </div>
        <div v-else>
          <div class="py-3">
            <div v-if="inArray('showValidateKey', data.buttons)" class="pb-1">
              <div class="fs-22px mb-2">
                <span class="me-2" style="color: var(--app-telegram-bg)"><i class="fa-brands fa-telegram"></i></span>
                <span>Telegram</span>
              </div>
              <div class="text-secondary fs-18px">
                Направьте короткое сообщение <div class="text-dark my-3 fs-30px">{{ data.validate_key }}</div>
                пользователю <span class="text-dark font-weight-bold">{{ data.apiName }}</span>
              </div>
              <div class="text-secondary fs-12px mt-2">
                <a class="font-weight-bold" target="_blank" :href="data.apiUrl">ссылка на пользователя {{ data.apiName }}</a>
              </div>
              <div class="my-4">
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">...</span>
                </div>
              </div>
              <div class="my-4 text-secondary">
                Пользователь <b>{{ data.apiName }}</b> ожидает сообщение от Вас!
              </div>
            </div>
            <div v-if="inArray('isTelegram', data.buttons) || inArray('isVk', data.buttons)" class="pb-1 fs-14px text-secondary">
              Укажите необходимы профиль из доступных:
            </div>
            <a v-if="inArray('isTelegram', data.buttons)" class="btn btn-light w-100 py-2 my-2 fs-18px" @click="saveByAction('isTelegram')">
              <span class="me-2" style="color: var(--app-telegram-bg)"><i class="fa-brands fa-telegram"></i></span>
              Telegram
            </a>
            <a v-if="inArray('isVk', data.buttons)" class="btn btn-light w-100 py-2 my-2 fs-18px" @click="saveByAction('isVk')">
              <span class="me-2" style="color: var(--app-vk-bg)"><i class="fa-brands fa-vk"></i></span>
              VK
            </a>
          </div>
        </div>
      </div>
      <div v-if="'email' === type">
        <div v-if="data.is_validate" class="my-4">
          <div>Дополнительный канал получения уведомлений:</div>
          <div class="fs-16px"><i class="fa-solid fa-envelope"></i> Электронная почта: {{ data.network_name }}</div>
        </div>
        <div v-else>
          <div class="py-3 text-start">
            <label class="form-label">Email</label>
            <input
                v-model="data.network_id"
                class="form-control"
                :class="{ 'is-invalid': !!errors['network_id'] }"
                type="text"
                placeholder="Введите email"
            />
            <div class="text-danger" v-if="!!errors['network_id']">{{ errors['network_id'].join(', ') }}</div>
            <div class="fs-12px text-secondary">
              Укажите новую почту
            </div>
            <transition name="new-notification-email">
              <div v-if="inArray('confirmEmail', data.buttons)" class="mt-2">
                <label class="form-label">Код</label>
                <input
                    v-model="data.validate_key"
                    class="form-control"
                    :class="{ 'is-invalid': !!errors['validate_key'] }"
                    type="text"
                    placeholder="Введите код"
                />
                <div class="text-danger" v-if="!!errors['validate_key']">{{ errors['validate_key'].join(', ') }}</div>
                <div class="fs-12px text-secondary">
                  На указанную почту направлено письмо с кодом подтверждения, введите его в данное поле
                </div>
              </div>
            </transition>

          </div>
        </div>
      </div>
      <div class="text-end">
        <a v-if="inArray('sendKeyEmail', data.buttons)" class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="saveByAction('sendKeyEmail')">Отправить код</a>
        <a v-if="inArray('confirmEmail', data.buttons)" class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="saveByAction('confirmEmail')">Подключить</a>
        <a v-if="inArray('delete', data.buttons)" class="btn btn-danger sm-w-100 mt-1 ms-sm-2" @click="openModalDelete">Удалить</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeForm">Закрыть</a>
      </div>
    </div>
  </div>
</template>

<script>

import {subformMixin} from '@/mixins/subformMixin'
import ConfirmActionModal from '@/components/ConfirmActionModal'

export default {
  name: 'ProfileNotificationChannelView',
  components: {ConfirmActionModal},
  mixins: [subformMixin],
  props: {
    uuid: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    updateParent: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    }
  },
  beforeMount() {
    this.formUrl.params.uuid = this.uuid + ''
    this.formUrl.params.type = this.type + ''
  },
  data() {
    return {
      formName: 'profile-notification-channel',
      formUrl: {string: '/profile-notification-channel?uuid=${uuid}&type=${type}', params: {uuid: 'new', type: ''}},
      editRoute: {name:  'profile-notification-channel'},
      exitRoute: {name: 'profile'},

      isConfirmDeleteModal: false,

      isAtLeastOnceSaved: false,

      messengerTime: null,
    }
  },
  unmounted: function() {
    clearTimeout(this.messengerTime)
  },
  methods: {
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
    },
    afterSave: function () {
      this.isAtLeastOnceSaved = true

      if (true === this.data.is_validate) {
        let self = this
        setTimeout(function () {
          self.close()
          self.updateParent()
        }, 500)
      }
    },
    afterDelete: function () {
      let self = this
      this.isConfirmDeleteModal = false
      setTimeout(function () {
        self.close()
        self.updateParent()
      }, 200)
    },

    afterLoad: function () {
      this.data.type = this.type + ''

      if ('messenger' === this.data.type && !this.empty(this.data.network) && false === this.data.is_validate) {
        let self = this
        clearTimeout(this.messengerTime)
        this.messengerTime = setTimeout(function () {self.load(false)}, 1000)
      }
    },

    saveByAction: function (name) {
      this.data.action = name
      this.save()
    },
    closeForm: function () {
      clearTimeout(this.messengerTime)
      let self = this
      self.isConfirmDeleteModal = false
      self.close()
      if (true === self.isAtLeastOnceSaved) {
        setTimeout(function () {
          self.updateParent()
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.new-notification-email-enter-active,
.new-notification-email-leave-active {
  transition: opacity 0.5s ease;
}

.new-notification-email-enter-from,
.new-notification-email-leave-to {
  opacity: 0;
}
</style>
