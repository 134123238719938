<template>
  <div class="query-builder-item query-builder-group">
    <div class="text-start mt-1">
      <div v-if="1 < data.rules.length" class="d-inline-block">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio"
                 :checked="'and' === data.connector"
                 :name="'groupConnector.' + indexes.join('.')"
                 :id="'groupConnector.and.' + indexes.join('.')"
                 @change="editGroupConnector(indexes, 'and')"
                 value="and"
          >
          <label class="form-check-label" :for="'groupConnector.and.' + indexes.join('.')">И</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio"
                 :checked="'or' === data.connector"
                 :name="'groupConnector.' + indexes.join('.')"
                 :id="'groupConnector.or.' + indexes.join('.')"
                 @change="editGroupConnector(indexes, 'or')"
                 value="or"
          >
          <label class="form-check-label" :for="'groupConnector.or.' + indexes.join('.')">Или</label>
        </div>
      </div>
      <button v-if="!empty(indexes)" class="btn btn-very-sm btn-outline-danger" @click="deleteObject(indexes)">
        <i class="fa-solid fa-trash"></i>
      </button>
    </div>
    <div v-for="(rule, i_rule) in data.rules" :key="'rule' + i_rule">
      <div v-if="undefined === rule.rules">
        <search-query-builder-rule
            :update-object="updateObject"
            :delete-object="deleteObject"
            :indexes="getIndexByParent(i_rule)"
            :fields="fields"
            :data="rule"
        />
      </div>
      <div v-else>
        <search-query-builder-group
            :fields="fields"
            :data="rule"
            :indexes="getIndexByParent(i_rule)"
            :add-rule="addRule"
            :add-group="addGroup"
            :update-object="updateObject"
            :delete-object="deleteObject"
            :edit-group-connector="editGroupConnector"
        />
      </div>
    </div>
    <div class="text-end" :class="{'mt-2': 0 < data.rules.length}">
      <button class="btn btn-very-sm btn-outline-primary me-1 mb-1" @click="addRule(indexes)">
        Условие
      </button>
      <button class="btn btn-very-sm btn-outline-primary me-1 mb-1" @click="addGroup(indexes)">
        Группа
      </button>
    </div>
  </div>
</template>
<script>
import SearchQueryBuilderRule from '@/components/searchQueryBuilder/SearchQueryBuilderRule'

export default {
  name: 'SearchQueryBuilderGroup',
  components: {SearchQueryBuilderRule},
  props: {
    data: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    indexes: {
      type: Array,
      required: true,
    },
    addRule: {
      type: Function,
      required: true,
    },
    addGroup: {
      type: Function,
      required: true,
    },
    updateObject: {
      type: Function,
      required: true,
    },
    deleteObject: {
      type: Function,
      required: true,
    },
    editGroupConnector: {
      type: Function,
      required: true,
    },
  },
  methods: {
    getIndexByParent(index) {
      let indexes = JSON.parse(JSON.stringify(this.indexes))
      indexes.push(index)

      return indexes
    },
  },
}
</script>

<style scoped>
</style>
