<template>
  <div>
    <base-modal
        :show="show"
        title="Выбор значения"
        :is-bg-click-close="true"
        :header="true"
        :footer="true"
        @close="closeSelectModel"
        :is-global-close="isGlobalClose"
    >
      <template v-slot:body>
        <ul class="list-group mt-1 mb-4">
          <li class="list-group-item cursor-pointer multiselect-form-list-group-item"
              v-for="(option, i_option) in list"
              :key="'o.' + i_option"
              :class="{'active': type === option.uuid}"
              @click="clickOption(option.uuid)"
              v-html="option.name"
          >
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeSelectModel">Отмена</a>
      </template>
    </base-modal>
    <div
        class="form-control cursor-pointer position-relative multiselect-form-control"
        :class="className"
        :data-disabled="disabled"
    >
      <div class="multiselect-indicator text-secondary"><i class="fa-regular fa-hourglass-half"></i></div>
      <input style="outline-offset:unset;outline:unset;background:none" :disabled="disabled" type="number" class="border-0 w-50" v-model="inputNumber" @input="input_number">
      <span @click="openModel" v-html="getLabel(type)" />
    </div>
  </div>
</template>
<script>
import BaseModal from '../BaseModal'

export default {
  name: 'IntervalInput',
  components: {BaseModal},
  props: {
    modelValue: {
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    className: {
      default: {},
      required: false,
    },
    isGlobalClose: {
      type: Boolean,
      default: true,
    },
    afterSelectOption: {
      type: Function,
      default: function () {}
    }
  },
  watch: {
    modelValue: function () {
      this.setValue()
    },
  },
  mounted: function() {
    this.setValue()
    this.setModelValue()
  },
  data() {
    return {
      show: false,
      inputNumber: '1',
      number: 1,
      type: 'days',
    }
  },
  methods: {
    setValue: function () {
      let value = (this.modelValue + '').split(' ')
      this.inputNumber = value[0] ?? '1'
      this.number = (value[0] ?? '1')
      this.type = value[1] ?? 'days'
    },
    openModel: function () {
      if (false === this.disabled) {
        this.show = true
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    clickOption(uuid) {
      this.type = uuid
      this.setModelValue()
      this.closeSelectModel()
    },
    input_number() {
      this.number = this.inputNumber
      this.setModelValue()
    },
    setModelValue() {
      let value = this.number + ' ' + this.type
      let self = this

      if (this.empty(this.number)) {
        value = null
      }

      setTimeout(function () {
        self.$emit('update:modelValue', value)
        self.afterSelectOption()
      }, 20)
    },
    getLabel(uuid) {
      for (let i in this.list) {
        if (uuid + '' === this.list[i].uuid + '') {
          return this.list[i].name
        }
      }
      return ''
    },
    closeSelectModel: function () {
      this.show = false
      if (true === this.isGlobalClose) {
        this.emitter.emit('modal', {event: 'close'})
      }
    },
  }
}
</script>

<style>
.multiselect-form-control .badge .fs-12px {
  font-size: 10px;
}
.multiselect-form-list-group-item.active .text-secondary,
.multiselect-form-control .badge .text-secondary {
  color: #e8e2e2 !important;
}
</style>

<style scoped>
.form-control[data-disabled="true"] {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control {
  min-height: 38px;
  padding-left: 40px;
}

.form-control .badge {
  white-space: initial;
}

.form-control .badge:not(:first-child) {
  margin-left: 4px;
  margin-bottom: 4px;
}

.multiselect-indicator {
  position: absolute;
  top: 6px;
  left: 10px;
}
</style>
