<template>
  <div class="page-list">
    <base-modal title="" :show="currentNote.isEditModal" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <object-note-view
            :uuid="currentNote.uuid"
            :objects="currentNote.objects"
            :update-parent="load"
            :close="close"
        />
      </template>
    </base-modal>

    <page-top-bar
        :title="title"
        :subtitle="tabSubtitle"
        :buttons="pageButtons"
    />

    <div class="fs-12px px-2 px-sm-3">
      <div class="d-inline-block me-3"><div class="badge text-bg-secondary"><i class="fa-solid fa-users-slash"></i></div> - доступна только Вам</div>
      <div class="d-inline-block me-3"><div class="badge text-bg-dark"><i class="fa-solid fa-users"></i></div> - доступна всем на просмотр</div>
      <div class="d-inline-block me-3"><div class="badge text-bg-success"><i class="fa-solid fa-users"></i></div> - доступна всем на редактирование</div>
      <div class="d-inline-block me-3"><div class="badge text-bg-success"><i class="fa-solid fa-bell"></i></div> - установлено уведомление</div>
    </div>

    <page-top-tabs :tabs="listTabs" />

    <div class="page-list-content">
      <div class="card border-0 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-12 overflow-x-auto">
              <table class="table table-sm table-hover">
                <thead>
                <tr>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Заметка'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.description'" :name-field="'description'" :input-name="'Примечание'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.deadline_at'" :name-field="'deadline_at'" :input-name="'Крайний срок'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">Объект</th>
                  <th v-if="listSearch.isDone" scope="col">
                    <sort-input :name-input="this.listName + '.sort.done_at'" :name-field="'done_at'" :input-name="'Дата закрытия'" :change-sort="sortField_change" />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    <search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" />
                  </th>
                  <th scope="col">
                    <search-input :name-input="this.listName + '.search.description'" :name-field="'description'" :input-text="searchField_input" />
                  </th>
                  <th scope="col">
                    <search-input-date-range :name-input="this.listName + '.search.deadlineAtDateRange'" :name-field="'deadlineAtDateRange'" :input-date="searchField_input" />
                  </th>
                  <th scope="col"></th>
                  <th v-if="listSearch.isDone" scope="col">
                    <search-input-date-range :name-input="this.listName + '.search.doneAtDateRange'" :name-field="'doneAtDateRange'" :input-date="searchField_input" />
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in items"
                    :key="'list.' + listName + '.' + i_item"
                    @click="editNote(item.uuid)"
                >
                  <td>
                    <div class="mb-2">{{ item.name }}</div>

                    <div style="width: 70px">
                      <div v-if="false === item.is_show_all && false === item.is_edit_all" class="badge text-bg-secondary"><i class="fa-solid fa-users-slash"></i></div>
                      <div v-if="true === item.is_show_all && false === item.is_edit_all" class="badge text-bg-dark"><i class="fa-solid fa-users"></i></div>
                      <div v-if="true === item.is_edit_all" class="badge text-bg-success"><i class="fa-solid fa-users"></i></div>
                      <div v-if="true === item.is_notification" class="badge text-bg-success ms-2"><i class="fa-solid fa-bell"></i></div>
                    </div>
                  </td>
                  <td>{{ item.description }}</td>
                  <td>
                    <div class="badge" :class="isValidDate(item.deadline_at) ? 'bg-success': 'bg-danger'">
                      {{  stringDateYmdhisToHidmy(item.deadline_at, true) }}
                    </div>
                  </td>
                  <td>
                    <div v-for="(obj, i_obj) in item.objects" :key="'list.' + listName + '.' + i_item + '.object.' + i_obj">
                      <div class="text-secondary fs-12px">{{ getValueEnums(globalVar.db.objectNoteTypes, obj.type, 'name') }}</div>
                      <div v-html="obj.name"></div>
                      <div class="text-secondary" v-html="obj.description"></div>
                    </div>
                  </td>
                  <td v-if="listSearch.isDone">
                    <div class="badge bg-secondary">
                      {{  stringDateYmdhisToHidmy(item.done_at, true) }}
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-list-paginator
        :paginator="paginator"
        :link-page="paginatorLinkPage_click"
    />
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput'
import PageTopBar from '@/components/PageTopBar'
import PageListPaginator from '@/components/PageListPaginator'
import SortInput from '@/components/SortInput'
import PageTopTabs from '@/components/PageTopTabs'
import SearchInputDateRange from '@/components/SearchInputDateTimeRange'
import ObjectNoteView from '@/components/ObjectNoteView'
import BaseModal from '@/components/BaseModal.vue'

export default {
  name: 'NotesList',
  components: {
    BaseModal,
    ObjectNoteView,
    SearchInputDateRange, PageTopTabs, SortInput, PageTopBar, PageListPaginator, SearchInput},
    mixins: [listMixin],
  data() {
    return {
      listName: 'NotesList',
      listUrl: {string: '/notes', params:[]},
      itemNameRoute: 'note',
      pageButtons: [],

      listTabs: [],
      title: '<i class="fa-solid fa-note-sticky"></i> Заметки',
      tabSubtitle: '',

      listSearch: {
        isDone: false,
        name: '',
        deadlineAtDateRange: [],
      },
      listSearchDefault: {
        isDone: false,
        name: '',
        deadlineAtDateRange: [],
      },

      currentNote: {
        isEditModal: false,
        uuid: '',
        objects: [],
      }
    }
  },
  methods: {
    setListTabs: function () {
      let self = this

      let listParams = [
        {
          name: 'Текущие',
          isDone: false,
          subtitle: 'Все активные заметки'
        },
        {
          name: 'Закрытые',
          isDone: true,
          subtitle: 'Все закрытые заметки'
        },
      ]

      this.listTabs = []
      for (let i in listParams) {
        let isActive = listParams[i].isDone === this.listSearch.isDone

        if (isActive) {
          this.tabSubtitle = listParams[i].subtitle
        }

        this.listTabs.push({
          name: listParams[i].name,
          isDisabled: false,
          isActive: isActive,
          click: function () {
            self.searchField_input(listParams[i].isDone ,'isDone', false)
          },
        })
      }
    },

    afterLoad: function () {
      this.setListTabs()
    },

    editNote: function (noteUuid) {
      this.currentNote.uuid = noteUuid + ''

      this.currentNote.isEditModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    close: function () {
      this.currentNote.isEditModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
</style>
