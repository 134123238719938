<template>
  <div class="search-input" :class="{'text-enter': '' !== dataText}">
    <div class="search-input-icon"><i class="fa-solid fa-magnifying-glass"></i></div>
    <input class="form-control" type="search" v-model="dataText" @input="input_textInput">
    <a class="clear-search btn btn-primary" @click="clearSearch">
      <i class="fa-solid fa-magnifying-glass-minus"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    nameInput: {
      type: String,
      required: true,
    },
    nameField: {
      type: String,
      required: true,
    },
    inputText: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isInput: false,
      secondsTimeout: 0,
      time: null,
      dataText: '',
    }
  },
  mounted: function() {
    let self = this

    self.dataText = JSON.parse(localStorage.getItem(self.nameInput) ?? '""')
    self.inputText(self.dataText, self.nameField, true)

    self.time = setInterval(function() {
      if (true === self.isInput) {
        if (self.secondsTimeout >= 3) {
          self.secondsTimeout = 0
          self.inputText(self.dataText, self.nameField, false)
          self.isInput = false
        } else {
          self.secondsTimeout++
        }
      }
    }, 100)
  },
  unmounted: function() {
    clearInterval(this.time)
  },
  methods: {
    input_textInput() {
      this.secondsTimeout = 0
      this.isInput = true
    },
    clearSearch() {
      this.isInput = false
      this.dataText = ''

      let self = this
      self.inputText('', this.nameField, false)
    }
  },
}
</script>

<style scoped>
.search-input {
  position: relative;
}

.search-input .search-input-icon {
  position: absolute;
  left: 10px;
  top: 7px;
  color: rgba(var(--bs-secondary-rgb), 0.8);
}

.search-input .form-control {
  padding-left: 35px;
}

.search-input {
  background: var(--app-page-title-search-bg);

  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.search-input.text-enter {
  margin-right: 45px;
}

.clear-search {
  position: absolute;
  display: none;
  right: -45px;
  top: 0;
}

.search-input.text-enter .clear-search {
  display: initial;
}
</style>
