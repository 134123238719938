<template>
  <div class="container-fluid mt-1">
    <div class="row mt-3">
      <div class="col-sm-12 col-md-6 col-lg-8 col-xl-8 mb-3">
        <dashboard-cases-list :day="selectDay" />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3">
        <dashboard-calendar-list :selection-day="selectionDay" />
        <div class="mt-3"><dashboard-tasks-list /></div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardTasksList from '@/views/app/dashboard/DashboardTasksList'
import DashboardCalendarList from '@/views/app/dashboard/DashboardCalendarList'
import DashboardCasesList from '@/views/app/dashboard/DashboardСasesList'

export default {
  name: 'DashboardView',
  components: {DashboardCasesList, DashboardCalendarList, DashboardTasksList},

  data() {
    return {
      selectDay: {
        date: null,
        now: false,
        day: null,
        weekN: null,
        weekNName: '',
        data: []
      },
    }
  },
  methods: {
    selectionDay: function (day) {
      this.selectDay = day
    },
  }
}
</script>

<style scoped>
</style>
