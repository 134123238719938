<template>
  <div class="page-list">
    <event-top-tabs />
    <page-top-bar
        :title="title"
        :subtitle="tabSubtitle"
        :buttons="pageButtons"
    />
    <page-top-tabs :tabs="listTabs" />

    <div class="page-list-content">
      <div class="card border-0 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-12 overflow-x-auto">
              <table class="table table-sm table-hover">
                <thead>
                <tr>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.event_at'" :name-field="'event_at'" :input-name="'Дата'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Наименование'" :change-sort="sortField_change" />
                  </th>
                  <th class="td-description" scope="col">
                    <sort-input :name-input="this.listName + '.sort.description'" :name-field="'description'" :input-name="'Пояснительная записка'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">
                    <sort-input :name-input="this.listName + '.sort.url'" :name-field="'url'" :input-name="'URL'" :change-sort="sortField_change" />
                  </th>
                  <th scope="col">Участники</th>
                </tr>
                <tr>
                  <th scope="col">
                    <search-input-date-range :name-input="this.listName + '.search.eventAtDateRange'" :name-field="'eventAtDateRange'" :input-date="searchField_input" />
                  </th>
                  <th scope="col">
                    <search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" />
                  </th>
                  <th class="td-description" scope="col">
                    <search-input :name-input="this.listName + '.search.description'" :name-field="'description'" :input-text="searchField_input" />
                  </th>
                  <th scope="col">
                    <search-input :name-input="this.listName + '.search.url'" :name-field="'url'" :input-text="searchField_input" />
                  </th>
                  <th scope="col">
                    <search-input :name-input="this.listName + '.search.userName'" :name-field="'userName'" :input-text="searchField_input" />
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in items"
                    :key="'list.' + listName + '.' + i_item"
                    @click="openItem_click(item.uuid)"
                >
                  <td>{{ stringDateYmdhisToHidmy(item.event_at, true)  }}</td>
                  <td>{{ item.name }}</td>
                  <td class="td-description">{{ item.description }}</td>
                  <td>{{ item.url }}</td>
                  <td>
                    <div class="div-users" v-html="item.users.join('<br>')"></div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-list-paginator
        :paginator="paginator"
        :link-page="paginatorLinkPage_click"
    />
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import SearchInput from '@/components/SearchInput'
import PageTopBar from '@/components/PageTopBar'
import PageListPaginator from '@/components/PageListPaginator'
import SortInput from '@/components/SortInput'
import SearchInputDateRange from '@/components/SearchInputDateTimeRange'
import EventTopTabs from '@/views/app/event/components/EventTopTabs'
import PageTopTabs from '@/components/PageTopTabs'

export default {
  name: 'EventsList',
  components: {
    PageTopTabs,
    EventTopTabs,
    SearchInputDateRange, SortInput, PageTopBar, PageListPaginator, SearchInput},
    mixins: [listMixin],
  data() {
    return {
      listName: 'EventsList',
      listUrl: {string: '/events', params:[]},
      itemNameRoute: 'event',
      pageButtons: [],

      listTabs: [],
      title: '<i class="fa-solid fa-timeline"></i> События',
      tabSubtitle: 'Список событий с вашим участием',

      listSearch: {
        isMy: false,
        typeList: 'today',
        eventAtDateRange: [],
        name: '',
        description: '',
        url: '',
        userName: '',
      },
      listSearchDefault: {
        isMy: false,
        typeList: 'today',
        eventAtDateRange: [],
        name: '',
        description: '',
        url: '',
        userName: '',
      },
    }
  },
  methods: {
    afterLoad: function () {
      this.setListTabs()

      let self = this
      self.pageButtons = []
      if (-1 !== self.buttons.indexOf('addEvent')) {
        self.pageButtons.push({
          name: 'Новое событие',
          class: 'btn-primary', click: function() {
            self.toRoute({name: self.itemNameRoute, params: {uuid: 'new'}})
          }
        })
      }
    },
    setListTabs: function () {
      let self = this

      let listParams = [
        {
          name: 'На сегодня',
          typeList: 'today',
        },
        {
          name: 'Будущие',
          typeList: 'future',
        },
        {
          name: 'Прошедшие',
          typeList: 'past',
        },
      ]
      this.listTabs = []
      for (let i in listParams) {
        let isActive = listParams[i].typeList === this.listSearch.typeList

        this.listTabs.push({
          name: listParams[i].name,
          isDisabled: false,
          isActive: isActive,
          click: function () {
            self.searchField_input(listParams[i].typeList ,'typeList', false)
          },
        })
      }
    }
  }
}
</script>

<style scoped>
.td-description {
  width: 20%;
}

.div-users {
  max-height: 38px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
