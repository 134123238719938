<template>
  <tr class="cursor-pointer">
    <td @click="itemClick(rowData.uuid)">{{ rowData.name }}</td>
    <td @click="itemClick(rowData.uuid)">{{ rowData.courseName }}</td>
    <td @click="itemClick(rowData.uuid)">
      <school-process-timetable-view :timetable-open="null" :timetables="rowData.timetables" :is-edit="false"/>
    </td>
    <!-- <td @click="itemClick(rowData.uuid)" v-html="rowData.description"></td> -->
    <td @click="itemClick(rowData.uuid)" class="td-progress">
      <div>
        <div class="fs-12px text-secondary">Сотрудники:</div>
        <progress-bar
            :all="rowData.userProgress.all"
            :current="rowData.userProgress.current"
            :must="rowData.userProgress.must"
        />
        <div class="fs-12px text-secondary">
          {{ percent(rowData.userProgress.current, rowData.userProgress.must) }}
        </div>
      </div>
      <div>
        <div class="fs-12px text-secondary">Слушатели:</div>
        <progress-bar
            :all="rowData.studentProgress.all"
            :current="rowData.studentProgress.current"
            :must="rowData.studentProgress.must"
        />
        <div class="fs-12px text-secondary">
          {{ percent(rowData.studentProgress.current, rowData.studentProgress.must) }}
        </div>
      </div>
    </td>
    <td @click="itemClick(rowData.uuid)" class="td-money-progress">
      <div class="fs-12px text-secondary">
        <div class="money-progress-indicator-sum">Объем</div>
        <div class="d-inline-block">
          <i class="fa-solid fa-ruble-sign"></i> {{ rowData.moneyProgress.money.all }}
        </div>
      </div>
      <progress-bar :all="rowData.moneyProgress.all" :current="rowData.moneyProgress.current" :must="0"/>
      <div class="fs-12px text-secondary">
        <div class="money-progress-indicator-sum">Отработано</div>
        <div class="d-inline-block">
          <i class="fa-solid fa-ruble-sign"></i> {{ rowData.moneyProgress.money.worked }}
        </div>
      </div>
    </td>
    <td>
      <div v-if="isLoadingNotes">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Загрузка...</span>
        </div>
      </div>
      <object-note-list
          v-if="!isLoadingNotes"
          object-type="school_process"
          :notes="rowData.notes"
          :object-uuid="rowData.uuid"
          :object-name="rowData.name"
          :object-description="rowData.description"
          :update-list="updateNotes"
      />
    </td>
    <td v-if="isEditPlanningProgress">
      <a class="btn btn-sm btn-primary btn-edit-planning-process"
         title="Корректировка учебного процесса"
         @click="planningItemClick(rowData.uuid)">
        <i class="fas fa-edit"></i>
      </a>
    </td>
  </tr>
</template>

<script>

import ProgressBar from '@/components/ProgressBar'
import SchoolProcessTimetableView from '@/views/app/school/components/SchoolProcessTimetableView'
import ObjectNoteList from '@/components/ObjectNoteList'

export default {
  name: 'SchoolProcessesListTableRowView',
  components: {ObjectNoteList, SchoolProcessTimetableView, ProgressBar},
  props: {
    rowData: {
      type: Object,
      required: true
    },
    itemClick: {
      type: Function,
      required: true
    },
    isEditPlanningProgress: {
      type: Boolean,
      required: true,
    },
    planningItemClick: {
      type: Function,
      required: true
    },
    isLoadingNotes: {
      type: Boolean,
      required: true,
    },
    updateNotes: {
      type: Function,
      required: true
    },
  },
}
</script>

<style scoped>
.edit-planning-process td {
  position: relative;
}

.btn-edit-planning-process {
  position: absolute;
  top: 0;
  right: -36px;
}

.td-progress > div {
  width: 150px;
}

.td-money-progress > div {
  width: 200px;
}

.money-progress-indicator-sum {
  width: 88px;
  display: inline-block;
}
</style>
