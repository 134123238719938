<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <base-modal title="Пользователи" :show="isEditRelatedUserModal" :footer="true" @close="close">
      <template v-slot:body>
        <div>
          <ul class="list-group mt-1 mb-4">
            <li class="list-group-item cursor-pointer"
                v-for="(user, i_user) in data.listUsers"
                :key="'o.' + i_user"
                :class="{'d-none': isActiveUser(user.uuid)}"
                @click="itemListUsers_click(user)"
            >
              {{ user.name }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="close">ОК</a>
      </template>
    </base-modal>
    <page-top-bar
        :title="'Штатная структурная единица'"
        :subtitle="'Форма настройки штатной структурной единицы'"
        :buttons="buttons"
    />
    <div class="card border-0 shadow">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label class="form-label">Тип</label>
            <multiselect-input
                v-model="data.is_post"
                :disabled="!inArray('is_post', enabledFields)"
                :class-name="{ 'is-invalid': !!errors['is_post'] }"
                :list="[
                    {uuid: '1', name: 'Должность'},
                    {uuid: '0', name: 'Структурное объединение'},
                ]"
            />
            <div class="text-danger" v-if="!!errors['is_post']">{{ errors['is_post'].join(', ') }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="form-label">"Родительская" структурная единица</label>
            <multiselect-input
                v-model="data.parent_personnel_structure_uuid"
                :disabled="!inArray('parent_personnel_structure_uuid', enabledFields)"
                :class-name="{ 'is-invalid': !!errors['parent_personnel_structure_uuid'] }"
                :list="data.listPersonnelStructures"
            />
            <div class="text-danger" v-if="!!errors['parent_personnel_structure_uuid']">{{ errors['parent_personnel_structure_uuid'].join(', ') }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="form-label">Наименование</label>
            <input type="text" class="form-control" placeholder="Директор по развитию"
                   v-model="data.name"
                   :disabled="!inArray('name', enabledFields)"
                   :class="{ 'is-invalid': !!errors['name'] }"
            >
            <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="form-label">Описание</label>
            <input type="text" class="form-control" placeholder="Краткая информация"
                   v-model="data.description"
                   :disabled="!inArray('description', enabledFields)"
                   :class="{ 'is-invalid': !!errors['description'] }"
            >
            <div class="text-danger" v-if="!!errors['description']">{{ errors['description'].join(', ') }}</div>
          </div>
        </div>
        <div v-if="'1' === data.is_post" class="row mt-3">
          <div class="col-12">
            <label class="form-label">Набор разрешений</label>
            <multiselect-input
                v-model="data.relatedPermissions"
                :multiselect="true"
                :disabled="!inArray('relatedPermissions', enabledFields)"
                :class-name="{ 'is-invalid': !!errors['relatedPermissions'] }"
                :list="data.listPermissions"
            />
            <div class="text-danger" v-if="!!errors['relatedPermissions']">{{ errors['relatedPermissions'].join(', ') }}</div>
          </div>
        </div>
        <div v-if="'1' === data.is_post" class="row mt-3">
          <div class="col-12">
            <label class="form-label">Пользователи</label>
            <div class="overflow-x-auto">
              <table class="table table-sm table-hover table-striped">
                <thead>
                <tr>
                  <th scope="col">ФИО</th>
                  <th v-if="inArray('relatedUsers', enabledFields)" scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in data.relatedUsers"
                    :key="'relatedUsers.' + i_item"
                >
                  <td>{{ item.name }}</td>
                  <td v-if="inArray('relatedUsers', enabledFields)" class="text-end">
                    <button
                        class="btn btn-sm btn-danger fs-10px"
                        @click="itemListUsers_click(item)"
                    >
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="'1' === data.is_post && inArray('relatedUsers', enabledFields)" class="row mt-2">
          <div class="col-12 text-end">
            <button class="btn btn-primary" type="submit" @click="openRelatedUsers_click">
              Добавить пользователей
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import MultiselectInput from '@/components/form/MultiselectInput'
import BaseModal from '@/components/BaseModal'

export default {
  name: 'PersonnelStructureView',
  components: {BaseModal, MultiselectInput, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      isConfirmDeleteModal: false,

      formName: 'crmUser',
      formUrl: {string: '/personnel-structure?uuid=${uuid}'},
      editRoute: {name:  'personnel-structure', params: {uuid: 0}},
      exitRoute: {name: 'personnel-structures'},

      isEditRelatedUserModal: false,
    }
  },
  methods: {
    afterLoad: function () {
      let parentUuid = localStorage.getItem('PersonnelStructureView.newItem.parentId') + ''
      if (null === this.data.uuid && '' !== parentUuid) {
        this.data.parent_personnel_structure_uuid = parentUuid
      }
    },
    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    openRelatedUsers_click: function () {
      this.isEditRelatedUserModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    isActiveUser: function(uuid) {
      return !!this.inArray(uuid + '', this.data.relatedUsers.map(i => i.uuid) );
    },
    itemListUsers_click: function(user) {
      let strUuid = user.uuid + ''

      if (true === this.isActiveUser(strUuid)) {
        this.data.relatedUsers = this.data.relatedUsers.filter(function (item) {return item.uuid !== strUuid})
      } else {
        this.data.relatedUsers.push(user)
      }

      this.close()
    },
    close: function () {
      this.isConfirmDeleteModal = false
      this.isEditRelatedUserModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
</style>
