<template>
  <div>
    <base-modal title="" :show="newRelationType.isModel" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <school-relation-user-type-view
            :uuid="newRelationType.uuid"
            :update-parent="load"
            :close="close"
        />
      </template>
    </base-modal>

    <div>
      <div v-if="inArray('addRelation', buttons)" class="my-2" :class="{'add-relation-button-container': 0 === items.length}">
        <div class="float-start">
          <button class="btn btn-sm btn-outline-secondary w-100" type="submit" @click="openItem_click('new')">
            Добавить перечень
          </button>
        </div>
      </div>
      <page-list-paginator
          v-if="0 < items.length && false === isLoading"
          :paginator="paginatorList"
          :link-page="paginatorLinkPage_click"
          size="sm"
      />
    </div>
    <table class="table table-sm table-hover">
      <thead>
      <tr>
        <th scope="col"><sort-input :name-input="this.listName + '.sort.name'" :name-field="'name'" :input-name="'Имя'" :change-sort="sortField_change" /></th>
        <th scope="col">Список должностей</th>
      </tr>
      <tr>
        <th scope="col"><search-input :name-input="this.listName + '.search.name'" :name-field="'name'" :input-text="searchField_input" /></th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="cursor-pointer"
          v-for="(item, i_item) in items"
          :key="'list.' + listName + '.' + i_item"
          @click="openItem_click(item.uuid)"
      >
        <td>{{ item.name }}</td>
        <td v-html="item.personnelStructures"></td>
      </tr>
      </tbody>
    </table>
    <div class="d-block" v-if="0 === items.length && false === isLoading">
      <div class="my-3 text-center text-secondary">
        Нет списков
      </div>
    </div>
    <div class="d-block text-center py-2" v-if="isLoading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import PageListPaginator from '@/components/PageListPaginator'
import BaseModal from '@/components/BaseModal'
import SchoolRelationUserTypeView from '@/views/app/school/course/SchoolRelationUserTypeView'
import SortInput from "@/components/SortInput.vue";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: 'SchoolRelationUserTypesList',
  components: {SearchInput, SortInput, SchoolRelationUserTypeView, BaseModal, PageListPaginator},
  mixins: [listMixin],
  data() {
    return {
      listName: 'SchoolRelationUserTypesList',
      listUrl: {string: '/school-relation-types', params:[]},
      itemNameRoute: 'school-relation-types',
      pageButtons: [],

      paginatorList: {
        currentPage: 0,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
      },

      newRelationType: {
        isModel: false,
        uuid: ''
      },
    }
  },
  watch: {
    paginator: function (newVal) {
      let self = this
      setTimeout(function () {
        self.paginatorList = newVal
      }, 10)
    },
  },
  mounted: function() {
    this.emitter.on('schoolRelationUserTypesList', this.emitterSchoolRelationUserTypesList_reload)
  },
  unmounted: function() {
    this.emitter.off('schoolRelationUserTypesList', this.emitterSchoolRelationUserTypesList_reload)
  },
  methods: {
    emitterSchoolRelationUserTypesList_reload: function () {
      this.load()
    },

    openItem_click: function (uuid) {
      this.newRelationType.isModel = true
      this.newRelationType.uuid = uuid
      this.emitter.emit('modal', {event: 'open'})
    },

    close: function () {
      this.newRelationType.isModel = false
      this.emitter.emit('modal', {event: 'close'})
    },
  },
}
</script>

<style scoped>
.page-list-pagination {
  display: inline-block;
  padding: 0;
}

.add-relation-button-container {
  min-height: 38px;
}
</style>
