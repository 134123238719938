<template>
  <div class="m-3">
    <base-modal title="" :show="currentNote.isEditModal" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <object-note-view
            :uuid="currentNote.uuid"
            :objects="currentNote.objects"
            :update-parent="loadCases"
            :close="close"
        />
      </template>
    </base-modal>

    <div class="card border-0 shadow h-100">
      <div class="card-body">
        <div class="d-block d-md-flex">
          <div class="w-100">
            <label>Пользователи</label>
            <multiselect-input
                v-model="selectUsers"
                :list="listUsers"
                :multiselect="true"
                :multiselect-finish-selected="selectedUsers"
            />
          </div>
          <div>
            <div class="ps-2">
              <div v-for="(month, i_month) in calendar" :key="'odcm.' + i_month" class="calendar-width m-auto">
                <div class="text-center fs-0_84 text-secondary position-relative">
                  <button class="btn btn-link position-absolute start-0 top-0"
                          v-if="selectDate !== getDateToStringYmd(new Date())"
                          @click="selectNowDate">
                    <i class="fa-solid fa-arrow-rotate-left"></i>
                  </button>
                  <button class="btn btn-link" @click="editMonth(-1)"><i class="fa-solid fa-chevron-left"></i></button>
                  {{month.monthName}} {{month.year}}
                  <button class="btn btn-link" @click="editMonth(1)"><i class="fa-solid fa-chevron-right"></i></button>
                </div>
                <table class="month m-auto">
                  <thead>
                  <tr>
                    <th class="">Пн</th>
                    <th class="">Вт</th>
                    <th class="">Ср</th>
                    <th class="">Чт</th>
                    <th class="">Пт</th>
                    <th class="text-secondary">Сб</th>
                    <th class="text-secondary">Вс</th>
                  </tr>
                  </thead>
                  <tr class="" v-for="(week, i_week) in month.days" :key="'odcm.' + i_month + '.w.' + i_week">
                    <td :class="{'text-secondary': i_day === 5 || i_day === 6}" v-for="(day, i_day) in week" :key="'odcm.' + i_month + '.w.' + i_week + '.d.' + i_day">
                      <div class="day" @click="selectCalendarDay(day, true)"
                           :class="{'day-now': day.now, 'day-selected': selectDate === day.date, 'in-circle-item-8': !empty(day.data) }">
                        <div v-for="(dataItem, i_dataItem) in day.data"
                             :key="'odcm.' + i_month + '.w.' + i_week + '.d.' + i_day + '.dtw.' + i_dataItem"
                             :class="{'d-none': 0 < i_dataItem}"
                             class="item">
                          <div class="data-item"></div>
                        </div>
                        {{day.day}}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="position-relative">
          <div  v-if="isLoading || isLoadingCases" class="spinner-border spinner-border-sm text-primary" role="status">
            <span class="visually-hidden">Загрузка...</span>
          </div>
          <div v-if="!isLoading && !isLoadingCases">
            <div class="mt-2">
              <b>
                {{ selectDay.now ? 'сегодня' : stringDateYmdToDmy(selectDay.date) }} ({{ selectDay.weekNName }})
              </b>
            </div>
            <div class="mt-1">
              <div class="plans-block">
                <div class="plans-row-legend-container">
                  <div v-for="(row, i_row) in plans.container.rows"
                       :key="'plans.container.legend.row.' + i_row"
                       class="position-relative"
                       :style="`height:${row.height}px`">
                    <div class="plans-container-row-legend">{{ row.name }}</div>
                  </div>
                </div>
                <div class="plans-container">
                  <div class="plans-container-objects" :style="`width:${plans.container.width}px`">
                    <div v-for="(row, i_row) in plans.container.rows"
                         :key="'plans.container.row.' + i_row"
                         class="plans-container-row"
                         :style="`height:${row.height}px`">
                    </div>

                    <div
                        v-if="!empty(this.plans.container.rowCurrentTime.top)"
                        class="plans-container-row-current-time"
                        :style="`top:${this.plans.container.rowCurrentTime.top}px;width:${plans.container.width}px`"
                    ></div>

                    <div v-for="(user, i_user) in plans.container.users"
                         :key="'plans.container.user.' + i_user"
                         class="plans-container-user"
                         :style="`left:${user.left}px;width:${user.width}px`"
                    >
                      <div class="fs-10px text-bg-secondary text-center border-radius-10 px-2 py-1">{{user.name}}</div>
                    </div>

                    <div v-for="(object, i_object) in plans.container.objects"
                         :key="'plans.container.object.' + i_object"
                         class="plans-container-object cursor-pointer"
                         :class="{'object-hover': object.hover}"
                         :style="`left:${object.left}px;top:${object.top}px;height:${object.height}px;width:${object.width}px;background-color:rgba(${getUserCaseBgColor(object.caseData.type)}, var(--bs-bg-opacity));`"
                         @click="clickCase(object.caseData)"
                    >
                      <div class="plans-container-object-tooltip">
                        <dashboard-case-view :case-data="object.caseData" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MultiselectInput from '@/components/form/MultiselectInput'
import DashboardCaseView from '@/views/app/dashboard/components/DashboardСaseView'
import BaseModal from '@/components/BaseModal'
import ObjectNoteView from '@/components/ObjectNoteView'

export default {
  name: 'CalendarView',
  components: {ObjectNoteView, BaseModal, DashboardCaseView, MultiselectInput},

  data() {
    return {
      isLoading: false,
      isLoadingCases: false,

      selectUsers: [],

      selectDate: null,
      selectDay: {
        date: null,
        now: false,
        day: null,
        weekN: null,
        weekNName: '',
        data: []
      },

      listUsers: [],
      calendar: [],
      cases: [],

      plans: {
        minHour: 24,
        maxHour: 0,
        intervalMinuteNotDuration: 20,
        intervalMinute: 60,
        heightMinute: 0.5,
        widthObject: 20,
        container: {
          width: 0,
          nimWidthUser: 120,
          pX: 2,
          userPX: 40,
          height: 0,
          rowCurrentTime: {
            top: null,
          },
          rows: [],
          objects: [],
          users: [],
        },
      },

      currentNote: {
        isEditModal: false,
        uuid: '',
      }
    }
  },
  mounted() {
    this.selectDate = localStorage.getItem('CalendarView.selectDate')
    if (this.empty(this.selectDate)) {
      this.selectDate = this.getDateToStringYmd(new Date())
    }

    this.selectUsers = JSON.parse(localStorage.getItem('CalendarView.selectUsers') ?? '[]')
    if (this.empty(this.selectUsers)) {
      this.selectUsers = []
    }

    this.loadCalendar()
  },
  methods: {
    getUserCaseBgColor: function (caseType) {
      let color = localStorage.getItem('WorkplaceParameters.userCases.bg.' + caseType)
      if (true === this.empty(color)) {
        return 'var(--bs-primary-rgb)';
      }
      return color
    },
    loadCalendar: function () {
      let self = this
      self.isLoading = true
      window.axios.post(
          `${this.globalVar.app.api.url}/calendar?${(new Date()).getTime()}`,
          {date: self.selectDate}
      )
          .then(response => {
            self.listUsers = response.data.users
            self.calendar = response.data.calendar

            for(let m in response.data.calendar) {
              for(let w in response.data.calendar[m].days) {
                for(let d in response.data.calendar[m].days[w]) {
                  if (this.selectDate === response.data.calendar[m].days[w][d].date) {
                    self.selectCalendarDay(response.data.calendar[m].days[w][d])
                  }
                }
              }
            }

            self.loadCases()
          })
          .catch((error) => {
            console.log(error, error.data)
          })
          .finally(() => {
            self.isLoading = false
            self.emitter.emit('app', {event: 'spinner.hide'})
          })
    },

    loadCases: function () {
      let self = this
      self.isLoadingCases = true
      window.axios.post(
          `${this.globalVar.app.api.url}/calendar-cases?${(new Date()).getTime()}`,
          {userUuids: self.selectUsers, date: self.selectDate}
      )
          .then(response => {
            self.cases = response.data.cases
            self.calcPlanesTable()
          })
          .catch((error) => {
            console.log(error, error.data)
          })
          .finally(() => {
            self.isLoadingCases = false
            self.emitter.emit('app', {event: 'spinner.hide'})
          })
    },
    editMonth: function (interval) {
      let date = new Date(this.selectDate)
      date.setMonth(date.getMonth() + interval)
      this.selectDate = this.getDateToStringYmd(date)
      this.loadCalendar()
    },
    selectNowDate: function () {
      let now = new Date()

      this.selectDate = this.getDateToStringYmd(now)
      this.loadCalendar()
    },

    selectCalendarDay: function (day, isLoad) {
      localStorage.setItem('CalendarView.selectDate', day.date)
      this.selectDate = day.date
      this.selectDay = day

      if (true === isLoad) {
        this.loadCalendar()
      }
    },

    selectedUsers: function () {
      localStorage.setItem('CalendarView.selectUsers', JSON.stringify(this.selectUsers))
      this.loadCases()
    },

    calcPlanesTable: function () {
      let self = this
      self.calcPlanesTable_setMinAndMaxHour()

      self.plans.container.rows = []
      for (let rowHour = self.plans.minHour; rowHour <= self.plans.maxHour - 1; rowHour++) {
        for (let rowMinute = 0; rowMinute < 60; rowMinute += self.plans.intervalMinute) {
          self.plans.container.rows.push(self.calcPlanesTable_getNewRow(rowHour, rowMinute))
        }
      }

      if (true === self.selectDay.now) {
        let now = new Date
        let timeNow = (now.getHours() + (9 < now.getMinutes() ? '' : '0') + now.getMinutes()) * 1
        for (let r in self.plans.container.rows) {
          let row =  self.plans.container.rows[r]
          if (row.intTime <= timeNow && timeNow <= row.intEndTime) {
            self.plans.container.rowCurrentTime.top = row.top + (timeNow - row.intTime) * self.plans.heightMinute + 1
          }
        }
      } else {
        self.plans.container.rowCurrentTime.top = null
      }

      self.plans.container.objects = []
      self.plans.container.users = []

      for (let su in self.selectUsers) {
        let selectUserUuid = self.selectUsers[su]

        let leftUser = self.calcPlanesTable_getLeftUser()
        let startLeft = leftUser + this.plans.container.pX

        let cases = self.cases.filter(function (itemCase) {
          return self.inArray(selectUserUuid, itemCase.userUuids) && '00:00' !== itemCase.date.substr(11, 5)
        })

        for (let c in cases) {
            self.plans.container.objects.push(self.calcPlanesTable_getNewObject(cases[c], startLeft))
        }

        let widthUser = 0
        for(let o in self.plans.container.objects) {
          if (widthUser < self.plans.container.objects[o].left + self.plans.container.objects[o].width) {
            widthUser = self.plans.container.objects[o].left + self.plans.container.objects[o].width
          }
        }
        widthUser = widthUser - leftUser
        if (widthUser < self.plans.container.nimWidthUser) {
          widthUser = self.plans.container.nimWidthUser + 0
        }

        self.plans.container.users.push({
            name: self.getValueEnums(self.listUsers, selectUserUuid, 'name'),
            left: leftUser,
            width: widthUser + this.plans.container.pX,
          })
      }

      self.plans.container.width = 0
      for(let o in self.plans.container.users) {
        if (self.plans.container.width < self.plans.container.users[o].left + self.plans.container.users[o].width) {
          self.plans.container.width = self.plans.container.users[o].left + self.plans.container.users[o].width
        }
      }
      self.plans.container.width += self.plans.container.pX * 2 + 300

      self.plans.container.height = 0
      for(let r in self.plans.container.rows) {
        self.plans.container.height += self.plans.container.rows[r].height
      }
    },
    calcPlanesTable_setMinAndMaxHour: function () {
      this.plans.minHour = 24
      this.plans.maxHour = 0

      for (let i in this.cases) {
        let hour =  Math.trunc(this.cases[i].intTime / 100)
        let endHour =  Math.ceil(this.cases[i].intEndTime / 100)

        if (this.plans.minHour  > hour) {
          this.plans.minHour = hour
        }
        if (this.plans.maxHour < endHour + 1) {
          this.plans.maxHour = endHour + 1
        }
      }

      // ?? ??? - вынести в настройки
      this.plans.minHour = 7
      this.plans.maxHour = 21
    },
    calcPlanesTable_getNewRow: function (rowHour, rowMinute) {
      let endMinute = rowMinute + this.plans.intervalMinute
      if (endMinute >= 60) {
        endMinute = 59
      }
      let rows = this.plans.container.rows

      return {
        name: (9 < rowHour ? '' : '0') + rowHour + ':' + (9 < rowMinute ? '' : '0') + rowMinute,
        intTime: ((rowHour + '') + (9 < rowMinute ? '' : '0') + rowMinute) * 1,
        intEndTime: ((rowHour + '') + (9 < endMinute ? '' : '0') + endMinute) * 1,
        top: this.empty(rows) ? 60 : rows[rows.length - 1].top + rows[rows.length - 1].height,
        height: this.plans.heightMinute * this.plans.intervalMinute,
      }
    },
    calcPlanesTable_getNewObject: function (itemData, startLeft) {
      let objectUuid = this.generateUuid()
      itemData.hover = false
      itemData.objectUuid = objectUuid

      let height = this.plans.heightMinute * itemData.duration_in_minutes

      let top = 1
      for (let r in this.plans.container.rows) {
        let row = this.plans.container.rows[r]
        if (row.intTime <= itemData.intTime && itemData.intTime <= row.intEndTime) {
          top = row.top + (itemData.intTime - row.intTime) * this.plans.heightMinute + 1
        }
      }

      let left = startLeft + 0
      for (let o in this.plans.container.objects) {
        let object = this.plans.container.objects[o]
        if (
            //a2 >= b1 && b2 >= a1
            object.intEndTime >= itemData.intTime && itemData.intEndTime >= object.intTime &&
            left < object.left + object.width + 1
        ) {
          left = object.left + object.width + 1
        }
      }

      return {
        hover: false,
        top: top,
        left: left,
        width: this.plans.widthObject,
        isDuration: 0 < height,
        height: (0 < height ? height : this.plans.heightMinute * this.plans.intervalMinuteNotDuration) - 1,
        intTime: itemData.intTime,
        intEndTime: itemData.intEndTime,
        objectUuid: objectUuid,
        caseData: itemData,
      }
    },
    calcPlanesTable_getLeftUser: function () {
      // Получили left пользователя
      let leftUser = this.plans.container.pX / 2
      if (0 < this.plans.container.users.length) {
        for (let u in this.plans.container.users) {
          if (leftUser < this.plans.container.users[u].left + this.plans.container.users[u].width) {
            leftUser = this.plans.container.users[u].left + this.plans.container.users[u].width
          }
        }
        leftUser += this.plans.container.userPX
      }

      return leftUser
    },


    clickCase: function (caseData) {
      if ('note' === caseData.type) {
        this.editNote(caseData.uuid)
      }
      if ('task' === caseData.type) {
        this.setFormExitRoute(`task`, {name:  'calendar'})
        this.toRoute({name:  'task', params: {uuid: caseData.uuid}})
      }
      if ('school-process' === caseData.type) {
        this.setFormExitRoute(`schoolRunProcess`, {name:  'calendar'})
        this.toRoute({name:  'school-run-process', params: {uuid: caseData.uuid}})
      }
      if ('event' === caseData.type) {
        this.setFormExitRoute(`event`, {name:  'calendar'})
        this.toRoute({name:  'event', params: {uuid: caseData.uuid}})
      }
    },

    editNote: function (noteUuid) {
      this.currentNote.uuid = noteUuid + ''

      this.currentNote.isEditModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    close: function () {
      this.currentNote.isEditModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.card-calendar {
  min-height: 231px;
}

.day {
  position: relative;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 20px;
  text-align: center;
}

.day.day-selected {
  background: var(--app-calendar-day-selected);
}

.day-now {
  background: var(--app-calendar-day-now);
}

.day-now.day-selected {
  background: var(--app-calendar-day-now-selected);
}

.plans-block {
  position: relative;
  min-width: 100%;
}

.plans-row-legend-container {
  display: inline-block;
  width: 40px;
}

.plans-container {
  display: inline-block;
  position: relative;
  height: 100%;
  width: calc(100% - 40px);
  overflow-x: auto;
  padding-top: 60px;
}

.plans-container-row {
  position: relative;
  border-top: 1px solid rgba(var(--bs-secondary-rgb), 0.3);
}
.plans-container-row-legend {
  position: absolute;
  left: 0;
  top: -8px;
  vertical-align: middle;
  font-size: 10px;
  color: rgb(var(--bs-secondary-rgb));
}

.plans-container-row-current-time {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  border-radius: 2px;
  background-color: rgba(var(--bs-danger-rgb), 1);
}

.plans-container-user {
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.4);
}

.plans-container-objects {
  height: 100%;
}

.plans-container-object {
  position: absolute;
  z-index: 1;
  border: 1px solid var(--app-analytics-timetables-table-div-border);
  padding: 2px 3px;
  border-radius: 6px;
  --bs-bg-opacity: 0.6;
}

.plans-container-object:hover
,.plans-container-object.object-hover {
  --bs-bg-opacity: 1;
  z-index: 2;
}

.plans-container-object-tooltip {
  position: absolute;
  display: none;
  left: 22px;
  top: 0;
  background-color: var(--app-input-bg);
  padding: 10px 6px;
  border-radius: 10px;
  box-shadow: var(--bs-box-shadow) !important;
  min-width: 252px;
}

.plans-container-object:hover .plans-container-object-tooltip {
  display: block;
}

.table-hover>tbody>tr.case-hover>* {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.calendar-width {
  width:244px;
}
</style>
