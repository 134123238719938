<template>
  <div class="py-1">
    <div class="has-text-weight-bold alert alert-light py-2 px-3 mb-1">
      <b>{{ item.name }}</b>
      <button v-if="isEdit" class="btn btn-very-sm btn-outline-primary fs-12px ms-2" @click="editStructureItem(indexes)">
        Править
      </button>
      <div v-if="isDetail">
        <school-course-item-show-fields-view
            :process-params="[]"
            :is-show-params="false"
            :item="item"
            :list-relation-user-type="listRelationTypeEditParam"
        />
      </div>
    </div>
    <div class="container-children-container position-relative" v-if="'structure' === item.type">
      <div v-if="0 < item.children.length">
        <a class="btn btn-link container-children-btn" @click="isChildrenShow = !isChildrenShow">
          <span v-show="!isChildrenShow"><i class="fa-solid fa-plus" /></span>
          <span v-show="isChildrenShow"><i class="fa-solid fa-minus" /></span>
        </a>
        <transition appear name="container-children" mode="out-in">
          <div v-if="false === isChildrenShow" class="py-1">
            <div class="has-text-weight-bold alert alert-light py-2 px-3 mb-1">Содержимое скрыто ...</div>
          </div>
          <div v-if="true === isChildrenShow" class="inputBox input-password w-100">
            <school-course-structure-show-view
                :indexes="getIndexByParent(i_child)"
                :item="child"
                :is-edit="isEdit"
                :is-detail="isDetail"
                :list-relation-type-edit-param="listRelationTypeEditParam"
                :edit-structure-item="editStructureItem"
                v-for="(child, i_child) in item.children" :key="i_child"
            />
          </div>
        </transition>
      </div>
      <div v-if="isEdit && 0 === item.children.length" class="text-end position-relative py-2">
        <div class="add-item-help"></div>
        <button class="btn btn-very-sm btn-outline-primary" @click="editStructureItem(getIndexByParent(-1), 'param')">
          <i class="fa-solid fa-plus"></i> пункт
        </button>
        <button class="btn btn-very-sm btn-outline-primary ms-2" @click="editStructureItem(getIndexByParent(-1), 'structure')">
          <i class="fa-solid fa-plus"></i> группа
        </button>
      </div>
    </div>
    <div v-if="isEdit" class="text-end position-relative py-2">
      <div class="add-item-help"></div>
      <button class="btn btn-very-sm btn-outline-primary" @click="editStructureItem(indexes, 'param')">
        <i class="fa-solid fa-plus"></i> пункт
      </button>
      <button class="btn btn-very-sm btn-outline-primary ms-2" @click="editStructureItem(indexes, 'structure')">
        <i class="fa-solid fa-plus"></i> группа
      </button>
    </div>
  </div>
</template>

<script>

import SchoolCourseItemShowFieldsView from '@/views/app/school/components/SchoolCourseItemShowFieldsView'

export default {
  name: 'SchoolCourseStructureShowView',
  components: {SchoolCourseItemShowFieldsView},
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
    isDetail: {
      type: Boolean,
      required: true,
    },
    indexes: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    editStructureItem: {
      type: Function,
      required: true,
    },
    listRelationTypeEditParam: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isChildrenShow: true,
    }
  },
  methods: {
    getIndexByParent(index) {
      let indexes = JSON.parse(JSON.stringify(this.indexes))
      indexes.push(index)

      return indexes
    }
  },
}
</script>

<style scoped>
.container-children-container {
  padding-left: 44px;
}
.container-children-btn {
  position: absolute;
  left: 0;
  top: 4px;
}
.container-children-enter-active, .container-children-leave-active {
  transition: opacity .3s;
}
.container-children-enter, .container-children-leave-to /* .container-children-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.add-item-help {
  position: absolute;
  left: 4px;
  top: 8px;
  right: 170px;
  height: calc(50% - 8px);
  border-bottom-left-radius: 10px;
  border-left: rgba(var(--bs-primary-rgb), 0.3) solid 1px;
  border-bottom: rgba(var(--bs-primary-rgb), 0.3) solid 1px;
  z-index: 0;
}

.add-item-help:before {
  content: "";
  display: block;
  position: absolute;
  left: -3px;
  top: -2px;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: rgba(var(--bs-primary-rgb), 0.8);
  z-index: 0;
}
</style>
