<template>
  <div class="page-list-pagination" :class="'page-list-pagination-' + size">
    <div class="btn-toolbar" role="toolbar" aria-label="Панель навигации">
      <div class="btn-group mx-1" :class="'btn-group-' + size" role="group">
        <button type="button" class="btn btn-link" :class="{'disabled': !isAnglesLeft}"
                @click="click(1)">
          <i class="fa-solid fa-angles-left"></i>
        </button>
      </div>
      <div class="btn-group mx-1" :class="'btn-group-' + size" role="group">
        <button type="button" class="btn btn-link" :class="{'disabled': !isAnglesLeft}"
                @click="click(paginator.currentPage - 1)">
          <i class="fa-solid fa-angle-left"></i>
        </button>
      </div>
      <div v-show="paginator.currentPage > 2 && paginator.pageCount > 3" class="btn-group mx-1" :class="'btn-group-' + size" role="group">
        <button type="button" class="btn btn-link disabled">
          <i class="fa-solid fa-ellipsis"></i>
        </button>
      </div>
      <div v-for="(btn, i_btn) in buttons" :key="'paginator.p.' + i_btn" class="btn-group mx-1" :class="'btn-group-' + size" role="group">
        <button
            type="button"
            class="btn"
            :class="btn === paginator.currentPage ? 'btn-primary' : 'btn-link'"
            @click="click(btn)">
          {{ btn }}
        </button>
      </div>
      <div  v-show="paginator.currentPage < paginator.pageCount - 2" class="btn-group mx-1" :class="'btn-group-' + size" role="group">
        <button type="button" class="btn btn-link disabled">
          <i class="fa-solid fa-ellipsis"></i>
        </button>
      </div>
      <div class="btn-group mx-1" :class="'btn-group-' + size" role="group">
        <button type="button" class="btn btn-link" :class="{'disabled': !isAnglesRight}"
                @click="click(paginator.currentPage + 1)">
          <i class="fa-solid fa-angle-right"></i>
        </button>
      </div>
      <div class="btn-group mx-1" :class="'btn-group-' + size" role="group">
        <button type="button" class="btn btn-link" :class="{'disabled': !isAnglesRight}"
                @click="click(paginator.pageCount)">
          <i class="fa-solid fa-angles-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'PageListPaginator',
  props: {
    paginator: {
      type: Object,
      required: true,
    },
    linkPage:{
      type: Function,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: '',
    }
  },
  data() {
    return {
      isAnglesLeft: false,
      isAnglesRight: false,
      buttons: [],
    }
  },
  watch: {
    paginator: function(newVal) {
      this.isAnglesLeft = 1 < newVal.currentPage && 1 < newVal.pageCount
      this.isAnglesRight = newVal.pageCount > newVal.currentPage && newVal.pageCount > 1

      this.buttons = []

      let start = newVal.currentPage - 1
      let end = newVal.currentPage + 1

      if (start < 1) {
        start = 1
        end = end + 1
      }

      if (end > newVal.pageCount) {
        start = start - (end - newVal.pageCount)
        end = newVal.pageCount
      }

      if (start < 1) {
        start = 1
      }

      for (let i = start; i <= end; i++) {
        this.buttons.push(i)
      }
    },
  },

  methods: {
    click: function (number) {
      if (number !== this.paginator.currentPage) {
        this.linkPage(number)
      }
    }
  }
}
</script>

<style scoped>

.btn-toolbar {
  justify-content: flex-end;
}

.page-list-pagination {
  height: 50px;
  padding: 6px 1.5rem;
  background: var(--app-nav-panel-bg);
  color: var(--app-nav-panel-color);
}

.page-list-pagination-sm {
  height: 43px;
}

@media screen and (max-width: 575px) {
  .page-list-pagination {
    padding: 6px 0.8rem;
  }
}
</style>
