<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <base-modal title="Должности" :show="isEditRelatedPersonnelStructureModal" :footer="true" @close="close">
      <template v-slot:body>
        <div>
          <ul class="list-group mt-1 mb-4">
            <li class="list-group-item cursor-pointer"
                v-for="(item, i_item) in data.listPersonnelStructures"
                :key="'o.' + i_item"
                :class="{'d-none': isActivePersonnelStructure(item.uuid)}"
                @click="itemListPersonnelStructures_click(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="close">ОК</a>
      </template>
    </base-modal>
    <page-top-bar
        :title="'Пользователь'"
        :subtitle="'Данные пользователя'"
        :buttons="buttons"
    />
    <div class="card border-0 shadow">
      <div class="card-body">
        <div class="row mt-2">
          <div class="col-12">
            <div>
              <input type="checkbox" v-model="data.is_blocked" class="form-check-input" id="userIsBlocked">
              <label class="form-check-label" for="userIsBlocked">Пользователь заблокирован</label>
              <div class="text-danger" v-if="!!errors['is_blocked']">{{ errors['is_blocked'].join(', ') }}</div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-6">
            <label class="form-label">ФИО</label>
            <input type="text" class="form-control" placeholder="Фамилия Имя Отчество"
                   v-model="data.name"
                   :disabled="!inArray('name', enabledFields)"
                   :class="{ 'is-invalid': !!errors['name'] }"
            >
            <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
          </div>
          <div class="col-sm-6">
            <label class="form-label">Email</label>
            <input type="text" class="form-control" placeholder="email"
                   v-model="data.email"
                   :disabled="!inArray('email', enabledFields)"
                   :class="{ 'is-invalid': !!errors['email'] }"
            >
            <div class="text-danger" v-if="!!errors['email']">{{ errors['email'].join(', ') }}</div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <label class="form-label">Занимаемы должности</label>
            <div class="overflow-x-auto">
              <table class="table table-sm table-hover table-striped">
                <thead>
                <tr>
                  <th scope="col">Наименование</th>
                  <th v-if="inArray('relatedPersonnelStructures', enabledFields)" scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    v-for="(item, i_item) in data.relatedPersonnelStructures"
                    :key="'relatedPersonnelStructures.' + i_item"
                >
                  <td>{{ item.name }}</td>
                  <td v-if="inArray('relatedPersonnelStructures', enabledFields)" class="text-end">
                    <button
                        class="btn btn-sm btn-danger fs-10px"
                        @click="itemListPersonnelStructures_click(item)"
                    >
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="inArray('relatedPersonnelStructures', enabledFields)" class="row mt-2">
          <div class="col-12 text-end">
            <button class="btn btn-primary" type="submit" @click="openRelatedPersonnelStructures_click">
              Добавить должность
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import BaseModal from "@/components/BaseModal.vue";

export default {
  name: 'UserView',
  components: {BaseModal, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      isConfirmDeleteModal: false,

      formName: 'user',
      formUrl: {string: '/user?uuid=${uuid}'},
      editRoute: {name:  'user', params: {uuid: 0}},
      exitRoute: {name: 'users'},

      isEditRelatedPersonnelStructureModal: false,
    }
  },
  methods: {
    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    openRelatedPersonnelStructures_click: function () {
      this.isEditRelatedPersonnelStructureModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    isActivePersonnelStructure: function(uuid) {
      return !!this.inArray(uuid + '', this.data.relatedPersonnelStructures.map(i => i.uuid));
    },
    itemListPersonnelStructures_click: function(relatedPersonnelStructure) {
      let strUuid = relatedPersonnelStructure.uuid + ''

      if (true === this.isActivePersonnelStructure(strUuid)) {
        this.data.relatedPersonnelStructures = this.data.relatedPersonnelStructures.filter(function (item) {return item.uuid !== strUuid})
      } else {
        this.data.relatedPersonnelStructures.push(relatedPersonnelStructure)
      }

      this.close()
    },
    close: function () {
      this.isConfirmDeleteModal = false
      this.isEditRelatedPersonnelStructureModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
</style>
