<template>
  <div class="card border-0 shadow my-3">
    <base-modal title="" :show="crmContractorRelationSuspension.isModel" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <crm-contractor-relation-suspension-view
            :crm-contractor-uuid="crmContractorRelationSuspension.contractorUuid"
            :uuid="crmContractorRelationSuspension.uuid"
            :update-parent="load"
            :close="close"
        />
      </template>
    </base-modal>

    <div class="card-body pt-0">
      <page-top-bar
          :title="title"
          :subtitle="'Аналитика контрагентов относительно текущих учебных процессов'"
          :buttons="[]"
      />
      <div>
        <search-query-builder
            :fields="filters.fields"
            v-model="filters.query"
            :after-edit-query="setEditFilters"
            :name-templates="'SchoolAnalyticsCrmContractorsList'"
        />
      </div>
      <div class="overflow-y-hidden overflow-x-auto">
        <table class="table table-sm">
          <tbody>
          <tr v-for="(contractor, i_contractor) in filterItems" :key="'c.' + i_contractor">
            <td>
              <button type="button" class="btn btn-very-sm btn-light mt-1" @click="openContractor(contractor.uuid)">
                {{ contractor.name }}
              </button>
            </td>
            <td>
              <div class="mb-3">
                <div v-if="empty(contractor.processes)">
                  <div>Контрагент приобрел курсы, но пока не добавлен в процессы обучения</div>
                </div>
                <table v-if="!empty(contractor.processes)" class="table table-sm table-hover mb-0">
                  <tbody>
                  <tr v-for="(process, i_process) in contractor.processes" :key="'c.' + i_contractor + '.p.' + i_process"
                      class="cursor-pointer"
                      @click="openProcess(process.uuid, process.is_run)"
                  >
                    <td class="bg-light fs-12px">
                      <div><span class="text-secondary"><i class="fa-solid fa-book-open"></i></span> {{ process.courseName }}</div>
                      <div class="mt-1">{{ process.name }}</div>
                      <div v-if="process.is_run" class="mt-1">
                        <div class="text-secondary fs-10px">Текущий прогресс</div>
                        <progress-bar
                            :all="process.progress.all"
                            :current="process.progress.current"
                            :must="process.progress.must"
                        />
                      </div>
                      <div v-if="!process.is_run" class="mt-1">
                        <div class="badge text-bg-secondary">На стадии планирования</div>
                      </div>
                    </td>
                    <td class="bg-light">
                      <div class="fs-12px text-secondary">Дата старта:</div>
                      <div v-if="empty(process.show.minDate)">
                        <div class="badge text-bg-secondary">Даты не определены</div>
                      </div>
                      <div v-if="!empty(process.show.minDate)">
                        <div>{{ process.show.minDate }} <span class="text-secondary">по плану: {{ process.show.minPlannedDate }}</span></div>
                        <div v-if="false === empty(process.show.diffMinDate)" class="badge"
                             :class="{'text-bg-warning': process.show.isMinDateInvalid, 'text-bg-success': !process.show.isMinDateInvalid}"
                        >
                          {{ process.show.isMinDateInvalid ? 'с отставанием ' : 'c опережением ' }}
                          {{ process.show.diffMinDate }}
                        </div>
                      </div>
                    </td>
                    <td class="bg-light">
                      <div class="fs-12px text-secondary">Дата завершения:</div>
                      <div v-if="empty(process.show.maxDate)">
                        <div class="badge text-bg-secondary">Даты не определены</div>
                      </div>
                      <div v-if="!empty(process.show.maxDate)">
                        <div>{{ process.show.maxDate }} <span class="text-secondary">по плану: {{ process.show.maxPlannedDate }}</span></div>
                        <div v-if="false === empty(process.show.diffMaxDate)" class="badge"
                             :class="{'text-bg-warning': process.show.isMaxDateInvalid, 'text-bg-success': !process.show.isMaxDateInvalid}"
                        >
                          {{ process.show.isMaxDateInvalid ? 'с отставанием ' : 'c опережением ' }}
                          {{ process.show.diffMaxDate }}
                        </div>
                      </div>
                    </td>
                    <td class="bg-light">
                      <div v-for="(field, i_field) in process['fields']" :key="'c.' + i_contractor + '.p.' + i_process + '.f.' + i_field">
                        <b>{{ field.name }}</b>:
                        <div class="badge" :class="{
                                'd-none': itemValue.point + '' !== field.value.current + '',
                                'text-bg-point-0-19': itemValue.point <= 19,
                                'text-bg-point-20-39': 20 <= itemValue.point && itemValue.point <= 39,
                                'text-bg-point-40-59': 40 <= itemValue.point && itemValue.point <= 59,
                                'text-bg-point-60-79': 60 <= itemValue.point && itemValue.point <= 79,
                                'text-bg-point-80-100': 80 <= itemValue.point,
                              }"
                             v-for="(itemValue, i_value) in field.value.values"
                             :key="'c.' + i_contractor + '.p.' + i_process + '.f.' + i_field + '.v.' + i_value">
                          {{ itemValue.name }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="mt-1"
                     :class="{'btn btn-sm btn-light fs-14px text-start': inArray('crm-contractor-relation-suspension', globalVar.app.user.routes)}"
                     v-for="(suspension, i_suspension) in contractor.suspensions"
                     :key="'c.' + i_contractor + '.sus.' + i_suspension"
                     @click="openEditRelationSuspension(contractor.uuid, suspension.uuid)">
                  <div class="badge text-bg-warning me-1 fs-12px">Приостановка</div>
                  до <b>{{ stringDateYmdToDmy(suspension.suspend_at) }}</b>
                  <span class="text-secondary ms-1">{{ suspension.suspend_comment }}</span>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {listMixin} from '@/mixins/listMixin'
import PageTopBar from '@/components/PageTopBar'
import ProgressBar from '@/components/ProgressBar'
import BaseModal from '@/components/BaseModal'
import CrmContractorRelationSuspensionView from '@/views/app/crm/components/CrmContractorRelationSuspensionView'
import SearchQueryBuilder from '@/components/searchQueryBuilder/SearchQueryBuilder'

export default {
  name: 'SchoolAnalyticsCrmContractorsList',
  components: {
    SearchQueryBuilder,
    CrmContractorRelationSuspensionView, BaseModal, ProgressBar, PageTopBar},
  mixins: [listMixin],
  data() {
    return {
      title: '<i class="fa-solid fa-address-book"></i> Контрагенты',
      listName: 'SchoolAnalyticsCrmContractorsList',
      listUrl: {string: '/school-analytics-crm-contractors', params:[]},
      itemNameRoute: 'school-analytics',

      filters: {
        query: {},
        fields: [
          {uuid: 'crmContractorName', name: 'Контрагент', type: 'text'},
          {uuid: 'processesName', name: 'Учебный процесс', type: 'text'},
          {uuid: 'courseName', name: 'Курс', type: 'text'},
          {uuid: 'isSuspension', name: 'Приостановка', type: 'bool', values: {titleTrue: 'Есть', titleFalse: 'Нет'}},
          {uuid: 'isDebt', name: 'Отставание', type: 'bool', values: {titleTrue: 'Есть', titleFalse: 'Нет'}},
        ],
      },
      filterItems: [],

      crmContractorRelationSuspension: {
        isModel: false,
        contractorUuid: '',
        uuid: '',
      },
    }
  },
  mounted: function() {
    this.emitter.on('SchoolAnalyticsCrmContractorsList', this.emitterSchoolAnalyticsCrmContractorsList_reload)
  },
  unmounted: function() {
    this.emitter.off('SchoolAnalyticsCrmContractorsList', this.emitterSchoolAnalyticsCrmContractorsList_reload)
  },
  methods: {
    emitterSchoolAnalyticsCrmContractorsList_reload: function () {
      this.load()
    },
    beforeLoad: function () {
      for (let fieldName in this.filters.default) {
        let currentValue = JSON.parse(localStorage.getItem(`${this.listName}.filter.${fieldName}`) ?? '""')
        if (true === this.empty(currentValue)) {
          this.filters.current[fieldName] = JSON.parse(JSON.stringify(this.filters.default[fieldName]))
        } else {
          this.filters.current[fieldName] = currentValue
        }
      }
    },
    afterLoad: function () {
      for (let i in this.items) {
        let contractor = this.items[i]
        for (let c in contractor.processes) {
          let process = contractor.processes[c]
          process.show = {
            minDate: this.stringDateYmdToDmy(process.minDate),
            minPlannedDate: this.stringDateYmdToDmy(process.minPlannedDate),
            diffMinDate: this.diffBetweenDates(process.minDate + '', process.minPlannedDate + '', false),
            isMinDateInvalid: new Date(process.minDate) >= new Date(process.minPlannedDate),
            maxDate: this.stringDateYmdToDmy(process.maxDate),
            maxPlannedDate: this.stringDateYmdToDmy(process.maxPlannedDate),
            diffMaxDate: this.diffBetweenDates(process.maxDate + '', process.maxPlannedDate + '', false),
            isMaxDateInvalid: new Date(process.maxDate) >= new Date(process.maxPlannedDate),
          }
        }
      }
      this.setFilters(true)
    },

    setEditFilters: function () {
      localStorage.setItem('SchoolAnalyticsCrmContractorsList.query', JSON.stringify(this.filters.query))
      this.setFilters(false)
    },
    setFilters: function (isLoadFromLocalStorage) {
      let self = this

      if (true === isLoadFromLocalStorage) {
        self.filters.query = JSON.parse(localStorage.getItem('SchoolAnalyticsCrmContractorsList.query') ?? '{}')
      }

      this.filterItems = this.items.filter(function (contractor) {
        if (undefined === self.filters.query.rules || 0 === self.filters.query.rules.length) {
          return true
        }

        let isReturn = 'and' === self.filters.query.connector

        for (let i = 0; i < self.filters.query.rules.length; i++) {
          let rule = self.filters.query.rules[i]
          if (
              'and' === self.filters.query.connector &&
              false === self.setFilters_getCheckObject(rule, contractor)
          ) {
            return false
          }

          if (
              'or' === self.filters.query.connector &&
              true === self.setFilters_getCheckObject(rule, contractor)
          ) {
            return true
          }
        }

        return isReturn
      })
    },
    setFilters_getCheckObject: function (filter, contractor) {
      if (undefined === filter.rules) {
        return this['setFilters_' + filter.field](contractor, filter.condition, filter.value)
      } else {
        if (0 === filter.rules.length) {
          return true
        }
        let isReturn = 'and' === filter.connector

        for (let i = 0; i < filter.rules.length; i++) {
          let rule = filter.rules[i]
          if (
              'and' === filter.connector &&
              false === this.setFilters_getCheckObject(rule, contractor)
          ) {
            return false
          }

          if (
              'or' === filter.connector &&
              true === this.setFilters_getCheckObject(rule, contractor)
          ) {
            return true
          }
        }

        return isReturn
      }
    },
    setFilters_getCheckText: function (filterCondition, text, filterText) {
      switch (filterCondition) {
        case 'equal':  //равно
          return text === filterText
        case 'not_equal':  //не равно
          return text !== filterText
        case 'begins_with':  //начинается с
          return 0 === text.indexOf(filterText)
        case 'not_begins_with':  //не начинается с
          return 0 !== text.indexOf(filterText)
        case 'contains':  //содержит
          return -1 !== text.indexOf(filterText)
        case 'not_contains':  //не содержит
          return -1 === text.indexOf(filterText)
        case 'ends_with':  //оканчивается на
          return text.substring(text.length - filterText.length) === filterText
        case 'not_ends_with':  //не оканчивается на
          return text.substring(text.length - filterText.length) !== filterText
        case 'is_empty':  //пусто
          return this.empty(text)
        case 'is_not_empty':  //не пусто
          return !this.empty(text)
      }

      return false
    },
    setFilters_crmContractorName: function(contractor, filterCondition, filterValue) {
      if ('is_empty' !== filterCondition && 'is_not_empty' !== filterCondition && true === this.empty(filterValue)) {
        return true
      }

      return this.setFilters_getCheckText(filterCondition, contractor.name.toLowerCase(), filterValue.toLowerCase())
    },
    setFilters_processesName: function(contractor, filterCondition, filterValue) {
      if ('is_empty' === filterCondition) {
        return 0 === contractor.processes.length
      }

      if ('is_not_empty' === filterCondition) {
        return 0 < contractor.processes.length
      }

      if ('is_empty' !== filterCondition && 'is_not_empty' !== filterCondition && true === this.empty(filterValue)) {
        return true
      }

      for (let p in contractor.processes) {
        if (this.setFilters_getCheckText(filterCondition, contractor.processes[p].name.toLowerCase(), filterValue.toLowerCase())) {
          return true
        }
      }

      return false
    },
    setFilters_courseName: function(contractor, filterCondition, filterValue) {
      if ('is_empty' === filterCondition) {
        return 0 === contractor.processes.length
      }

      if ('is_not_empty' === filterCondition) {
        return 0 < contractor.processes.length
      }

      if ('is_empty' !== filterCondition && 'is_not_empty' !== filterCondition && true === this.empty(filterValue)) {
        return true
      }

      for (let p in contractor.processes) {
        if (
            this.setFilters_getCheckText(
                filterCondition,
                contractor.processes[p].courseName.toLowerCase(),
                filterValue.toLowerCase()
            )
        ) {
          return true
        }
      }

      return false
    },
    setFilters_isSuspension: function(contractor, filterCondition, filterValue) {
      return this.setFilters_getCheckText(
          filterCondition,
          this.empty(contractor.suspensions) ? 'false' : 'true',
          filterValue
      )
    },
    setFilters_isDebt: function(contractor, filterCondition, filterValue) {
      let textFilter = ''

      for (let p in contractor.processes) {
        let process = contractor.processes[p]
        if (0 < process.progress.must && process.progress.current < process.progress.must) {
          textFilter = 'true'
        }
        if ('' === textFilter && 0 < process.progress.must && process.progress.current >= process.progress.must) {
          textFilter = 'false'
        }
      }

      return this.setFilters_getCheckText(filterCondition, textFilter, filterValue)
    },

    openContractor: function (uuid) {
      this.setFormExitRoute('crmContractor', {name: 'school-analytics'})
      this.toRoute({name:  'crm-contractor', params: {uuid: uuid}})
    },
    openProcess: function (uuid, isRun) {
      let typeList = !isRun ? 'planning' : 'run'
      let typeForm = !isRun ? 'Planning' : 'Run'
      this.setFormExitRoute(`school${typeForm}Process`, {name: 'school-analytics'})
      this.toRoute({name:  `school-${typeList}-process`, params: {uuid: uuid}})
    },

    openEditRelationSuspension(contractorUuid, uuid) {
      if (this.inArray('crm-contractor-relation-suspension', this.globalVar.app.user.routes)) {
        this.crmContractorRelationSuspension.isModel = true
        this.crmContractorRelationSuspension.contractorUuid = contractorUuid
        this.crmContractorRelationSuspension.uuid = uuid
        this.emitter.emit('modal', {event: 'open'})
      }
    },

    close: function () {
      this.crmContractorRelationSuspension.isModel = false
      this.emitter.emit('modal', {event: 'close'})
    },
  },
}
</script>

<style scoped>
.date-width {
  width: 140px;
}
</style>
