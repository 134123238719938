<template>
  <div class="container-fluid mt-3">
    <div class="card border-0 shadow h-100">
      <div class="card-body">
        <div class="pb-2">Цветовая палитра для объектов на временном графике</div>
        <div v-for="(field, i_field) in workplaceParameters.userCases.list"
             class="row mt-2"
             :key="'workplaceParameters.userCases.list.' + i_field">
          <div class="col-9">
            <div v-html="field.name"></div>
          </div>
          <div class="col-3">
            <color-input
                :disable-alpha="true"
                v-model="workplaceParameters.userCases.bg[field.field]"
                :position="'bottom left'"
                @change="setUserCaseBgColor"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorInput from 'vue-color-input'

export default {
  name: 'WorkplaceParametersView',
  components: {ColorInput},
  data() {
    return {
      workplaceParameters: {
        userCases: {
          list: [],
          bg: {
            'school-process': '136, 106, 181',
            'event': '136, 106, 181',
            'task': '136, 106, 181',
            'note': '136, 106, 181',
          },
        },
      },
    }

  },
  mounted() {
    this.workplaceParameters.userCases.list = []
    if (this.inArray('school-run-processes', this.globalVar.app.user.routes)) {
      this.workplaceParameters.userCases.list.push({
        name: '<i class="fa-solid fa-school-circle-check"></i> Процесс обучения',
        field: 'school-process',
      })
    }
    if (this.inArray('events', this.globalVar.app.user.routes)) {
      this.workplaceParameters.userCases.list.push({
        name: '<i class="fa-solid fa-timeline"></i> Событие',
        field: 'event',
      })
    }

    this.workplaceParameters.userCases.list.push({
      name: '<i class="fa-solid fa-list-check"></i> Задача',
      field: 'task',
    })
    this.workplaceParameters.userCases.list.push({
      name: '<i class="fa-solid fa-note-sticky"></i> Заметка',
      field: 'note',
    })

    for (let i in this.workplaceParameters.userCases.list) {
      let item = this.workplaceParameters.userCases.list[i]
      this.workplaceParameters.userCases.bg[item.field] = this.getUserCaseBgColor(item.field)
    }
  },
  methods: {
    getUserCaseBgColor: function (caseType) {
      let color = localStorage.getItem('WorkplaceParameters.userCases.bg.' + caseType)
      if (true === this.empty(color)) {
        return 'rgb(136, 106, 181)'
      }
      return 'rgb(' + color + ')'
    },

    setUserCaseBgColor: function () {
      for (let i in this.workplaceParameters.userCases.list) {
        let item = this.workplaceParameters.userCases.list[i]
        localStorage.setItem(
            'WorkplaceParameters.userCases.bg.' + item.field,
            this.workplaceParameters.userCases.bg[item.field].substring(
                4,
                this.workplaceParameters.userCases.bg[item.field].length - 1)
        )
      }
    }
  }
}
</script>

<style scoped>
</style>
