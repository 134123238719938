<template>
  <div>
    <div v-if="'param' === item.type" class="ms-2">
      <div class="row my-1">
        <div class="col-sm-12 col-lg-4">
        <span v-if="item.is_datetime" class="me-3">
          <span :class="{'text-success': false === empty(item.auto_value_day_event_at_param_uuid) }" title="Дата и время мероприятия пункта">
            <i class="fa-solid fa-calendar-days"></i>
          </span>
          <span class="ms-1" v-if="isShowParams">
            {{ stringDateYmdhisToHidmy(getParams(item.uuid).day_event_at, true) }}
          </span>
          <span class="ms-2" title="Продолжительность мероприятия пункта в минутах">
            <i class="fa-solid fa-hourglass-half"></i> {{ item.duration_lesson_in_minutes }}
          </span>
        </span>
        <span v-if="item.is_student_stat" class="me-3" title="Пункт учитывается как показатель слушателя">
          <i class="fas fa-user-graduate"></i>
        </span>
        <span v-if="item.is_money_stat" class="me-3" title="Пункт участвует в расчете отработанных средств">
          <i class="fas fa-ruble-sign"></i>
        </span>
        <span v-if="item.is_deadline" class="me-3" title="Крайняя дата и время заполнения результата пункта">
          <span :class="{'text-success': false === empty(item.auto_value_deadline_at_param_uuid) }">
            <i class="far fa-calendar-check"></i>
          </span>
          <span class="ms-1" v-if="isShowParams">
            {{ stringDateYmdhisToHidmy(getParams(item.uuid).deadline_at, true) }}
          </span>
        </span>
        <span v-if="item.is_crm_contractor" class="me-3" title="Пункт заполняется на контрагента">
          <i class="fa-solid fa-address-book"></i>
        </span>
        <span v-if="item.is_crm_contractor_analytics" class="me-3" title="Пункт отдельно выводится в аналитике по контрагенту">
          <i class="fa-solid fa-chart-area"></i>
        </span>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="badge ms-1 " :class="{
            'text-bg-point-0-19': itemValue.point <= 19,
            'text-bg-point-20-39': 20 <= itemValue.point && itemValue.point <= 39,
            'text-bg-point-40-59': 40 <= itemValue.point && itemValue.point <= 59,
            'text-bg-point-60-79': 60 <= itemValue.point && itemValue.point <= 79,
            'text-bg-point-80-100': 80 <= itemValue.point,

          }"
               v-for="(itemValue, i_itemValue) in item.values"
               :key="'itemValue.' + i_itemValue">
            {{ itemValue.name }} ({{ itemValue.point }})
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="badge ms-1 text-bg-secondary"
               v-for="(relationTypeUuid, i_relationType) in item.relation_type_edit_param"
               :key="'relationType.' + i_relationType">
            {{ getNameUserType(relationTypeUuid) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SchoolCourseItemShowFieldsView',
  props: {
    item: {
      type: Object,
      required: true,
    },
    isShowParams: {
      type: Boolean,
      required: true,
    },
    processParams: {
      type: Array,
      required: true,
    },
    listRelationUserType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isChildrenShow: true,
    }
  },
  methods: {
    getNameUserType: function (relationTypeUuid) {
      for (let i in this.listRelationUserType) {
        if (relationTypeUuid === this.listRelationUserType[i].uuid) {
          return this.listRelationUserType[i].name
        }
      }

      return ''
    },
    getParams(structureUuid) {
      for (let i in this.processParams) {
        if (structureUuid === this.processParams[i].school_course_structure_param_uuid) {
          return this.processParams[i]
        }
      }
      return {
        uuid: '',
        school_course_structure_param_uuid: '',
        day_event_at: null,
        deadline_at: null,
      }
    },
  },
}
</script>

<style scoped>
</style>
