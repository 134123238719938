<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <base-modal title="Доступ к модулю" :show="isEditModuleModal" :footer="true" @close="closeModule">
      <template v-slot:body>
        <div class="row text-start mb-2">
          <div class="col-12">
            <div>
              <label class="form-label">Тип модуля</label>
              <multiselect-input
                  v-model="currentModule.type"
                  :list="data.listModules"
                  :is-global-close="false"
                  :after-select-option="afterSelectOption"
              />
            </div>
          </div>
        </div>
        <div class="row text-start mb-2">
          <div class="col-12">
            <div>
              <label class="form-label">Стоимость за год обслуживания</label>
              <input class="form-control"
                     type="number" min="0" step="0.01"
                     :disabled="null !== currentModule._data.automaticPriceCorrect"
                     v-model="currentModule.final_price_year"
              >
            </div>
          </div>
        </div>
        <div class="row text-start mb-4">
          <div class="col-12">
            <div>
              <label class="form-label">Оплачено до даты</label>
              <date-picker-input
                  v-model="currentModule.paid_to_date"
                  :is-global-close="false"
              />
            </div>
          </div>
        </div>
        <div class="row text-start mb-2">
          <div class="col-12">
            <div>
              <label class="form-label">{{ currentModule._data.paramName }}</label>
              <input class="form-control" type="number" min="0" v-model="currentModule.param_value">
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a v-if="-1 !== indexCurrentModule" class="btn btn-danger sm-w-100 mt-1" @click="deleteModule">Удалить</a>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="saveModule">ОК</a>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="closeModule">Отмена</a>
      </template>
    </base-modal>
    <page-top-bar
        :title="'[SYS] Контрагент'"
        :subtitle="'Данные контрагента aVicrm'"
        :buttons="buttons"
    />
    <div class="card border-0 shadow">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <label class="form-label">Наименование</label>
            <input type="text" class="form-control" placeholder="ПАО Тест"
                   v-model="data.name"
                   :disabled="!inArray('name', enabledFields)"
                   :class="{ 'is-invalid': !!errors['name'] }"
            >
            <div class="fs-10px text-secondary">не видно пользователям</div>
            <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="form-label">Модули</label>
            <div class="overflow-x-auto">
              <table class="table table-sm table-hover table-striped">
                <thead>
                <tr>
                  <th scope="col">Наименование</th>
                  <th scope="col">Стоимость за год обслуживания</th>
                  <th scope="col">Оплачено до даты</th>
                  <th scope="col">Условия</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    class="cursor-pointer"
                    :class="{'table-danger': isValidatePaid(item)}"
                    v-for="(item, i_item) in data.modules"
                    :key="'modules.' + i_item"
                    @click="openModule_click(i_item, item)"
                >
                  <td>{{ item._data.label }}</td>
                  <td>{{ item.final_price_year }} р.</td>
                  <td>{{ stringDateYmdToDmy(item.paid_to_date) }}</td>
                  <td>{{ item._data.paramName + ': ' + item.param_value }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="inArray('modules', enabledFields)" class="row mt-2">
          <div class="col-12 text-end">
            <button class="btn btn-primary" type="submit" @click="openNewModule_click">
              Добавить модуль
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import BaseModal from '@/components/BaseModal'
import MultiselectInput from '@/components/form/MultiselectInput'
import DatePickerInput from '@/components/form/DatePickerInput'

export default {
  name: 'ContractorView',
  components: {DatePickerInput, MultiselectInput, BaseModal, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      isConfirmDeleteModal: false,
      isEditModuleModal: false,
      indexCurrentModule: -1,
      currentModule: {
        uuid: '',
        type: null,
        final_price_year: 0,
        paid_to_date: null,
        param_value: null,
        _data: {
          label: '',
          paramName: '',
          automaticPriceCorrect: null,
        }
      },
      clearModule: {
        uuid: '',
        type: null,
        final_price_year: 0,
        paid_to_date: null,
        param_value: null,
        _data: {
          label: '',
          paramName: '',
          automaticPriceCorrect: null,
        }
      },

      formName: 'sysContractor',
      formUrl: {string: '/sys-contractor?uuid=${uuid}'},
      editRoute: {name:  'sys-contractor', params: {uuid: 0}},
      exitRoute: {name: 'sys-contractors'},
    }
  },
  methods: {
    isValidatePaid: function(item) {
      return new Date() > new Date(item.paid_to_date)
    },
    afterLoad: function () {
      for (let m in this.data.modules) {
        for (let l in this.data.listModules) {
          if (this.data.modules[m].type === this.data.listModules[l].uuid) {
            this.data.modules[m]['_data'] = {
              label: this.data.listModules[l].name,
              paramName: this.data.listModules[l].param.name + '',
              automaticPriceCorrect: this.data.listModules[l].param.automaticPriceCorrect,
            }
          }
        }
      }
    },

    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },

    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    afterSelectOption: function () {
      this.currentModule['_data'] = {
        label: '',
        paramName: '',
        automaticPriceCorrect: null,
      }
      for (let l in this.data.listModules) {
        if (this.currentModule.type === this.data.listModules[l].uuid) {
          this.currentModule['_data'] = {
            label: this.data.listModules[l].name,
            paramName: this.data.listModules[l].param.name + '',
            automaticPriceCorrect: this.data.listModules[l].param.automaticPriceCorrect,
          }
        }
      }
    },
    openNewModule_click: function () {
      this.openModule_click(-1, JSON.parse(JSON.stringify(this.clearModule)))
    },
    openModule_click: function (i, module) {
      if (this.inArray('modules', this.enabledFields)) {
        this.indexCurrentModule = i
        this.currentModule = JSON.parse(JSON.stringify(module))
        this.isEditModuleModal = true
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    saveModule: function () {
      let moduleData = JSON.parse(JSON.stringify(this.currentModule))

      if (-1 === this.indexCurrentModule) {
        this.data.modules.push(moduleData)
      } else {
        Object.assign(this.data.modules[this.indexCurrentModule], moduleData)
      }
      this.indexCurrentModule = -1
      Object.assign(this.currentModule, this.clearModule)
      this.close()
    },
    deleteModule: function () {
      this.indexCurrentModule = -1
      this.data.modules.splice(this.indexCurrentModule, 1)
      this.close()
    },
    closeModule: function () {
      this.indexCurrentModule = -1
      Object.assign(this.currentModule, this.clearModule)
      this.close()
    },
    close: function () {
      this.isConfirmDeleteModal = false
      this.isEditModuleModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
</style>
