<template>
  <div class="page-content">
    <base-modal title="" :show="isEditProgressModal" :header="false" :footer="false" @close="close">
      <template v-slot:body>
        <school-process-progress-view
            :uuid="currentEditProgress.uuid"
            :param-uuid="currentEditProgress.school_process_param_uuid"
            :student-uuid="currentEditProgress.student_uuid"
            :crm-contractor-uuid="currentEditProgress.crm_contractor_uuid"
            :update-parent="loadAfterSaveProgress"
            :close="close"
         />
      </template>
    </base-modal>
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <confirm-action-modal
        :show="isToDoneModel"
        :text="'Завершить учебный процесс?'"
        :btn-text="'Завершить'" :btn-type="'primary'"
        :confirm-action="courseDone_click"
        @close="close"
    />
    <page-top-bar
        :title="title"
        :subtitle="data.schoolCourseName"
        :buttons="buttons"
    />
    <div class="card border-0 shadow mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4">
            <div
                class="ms-2"
                v-for="(type, i_type) in (new Set(data.relationUsers.map(function(item) {return item.type})))"
                :key="'u.i.' + i_type"
            >
              <div class="text-secondary">{{ type }}</div>
              <div class="ms-3">
                <div
                    v-for="(relation, i_relation) in data.relationUsers"
                    :key="'u.i.' + i_type + '.user.' + i_relation"
                    :class="{'d-none': type !== relation.type}"
                >
                  {{ relation.user }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="pb-1">
              <label class="form-label">Расписание</label>
              <school-process-timetable-view :timetable-open="null" :timetables="data.timetables" :is-edit="false"/>
            </div>
            <div class="mt-3 mt-sm-0">
              <div v-if="!inArray('description', enabledFields)" class="mt-2">
                <div v-html="data.description"></div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div v-if="isLoadingNotes">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </div>
            <object-note-list
                v-if="!isLoadingNotes"
                object-type="school_process"
                :notes="data.notes"
                :object-uuid="data.uuid"
                :object-name="data.name"
                :object-description="data.description"
                :update-list="loadNotes"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-6">
            <div class="mb-1">
              <div class="text-secondary">сотрудники:</div>
              <progress-bar
                  :all="data.userProgress.all"
                  :current="data.userProgress.current"
                  :must="data.userProgress.must"
              />
              <div class="text-secondary">
                {{ percent(data.userProgress.current, data.userProgress.must) }}
              </div>
            </div>
            <div>
              <div class="text-secondary">слушатели:</div>
              <progress-bar
                  :all="data.studentProgress.all"
                  :current="data.studentProgress.current"
                  :must="data.studentProgress.must"
              />
              <div class="text-secondary">
                {{ percent(data.studentProgress.current, data.studentProgress.must) }}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="mb-1">
              <div class="text-secondary">отработанные средства:</div>
              <progress-bar
                  :all="data.moneyProgress.all"
                  :current="data.moneyProgress.current"
                  :must="0"
              />
            </div>
            <div class="row">
              <div class="col-6 text-secondary">Объем средств</div>
              <div class="col-6"><i class="fa-solid fa-ruble-sign"></i> {{ data.moneyProgress.money.all }}</div>
            </div>
            <div class="row">
              <div class="col-6 text-secondary">Отработано средств</div>
              <div class="col-6"><i class="fa-solid fa-ruble-sign"></i> {{ data.moneyProgress.money.worked }}</div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div v-if="isShowCheckbox_isHideTdExcluded" class="mb-2">
              <div class="form-control-checkbox">
                <input type="checkbox" v-model="isHideTdExcluded" class="form-check-input" id="table_isHideTdExcluded">
                <label class="form-check-label" for="table_isHideTdExcluded">Скрыть исключенных из процесса слушателей</label>
              </div>
            </div>
            <div class="table-course-progress-container" :class="{'hide-td-excluded': isHideTdExcluded}">
              <div class="overflow-x-auto">
                <table class="table table-sm table-bordered table-course-progress fs-12px">
                  <thead>
                  <tr v-for="(hr, i_hr) in table.header" :key="'t.hr.' + i_hr">
                    <th v-for="(th, i_th) in hr" :key="'t.hr.' + i_hr + '.th.' + i_th"
                        :rowspan="th.rowspan" :colspan="th.colspan" :class="th.class"
                        v-html="th.name"
                    ></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(tr, i_tr) in table.body" :key="'t.tr.' + i_tr">
                    <td v-for="(td, i_td) in tr" :key="'t.tr.' + i_tr + '.td.' + i_td"
                        :rowspan="td.rowspan" :colspan="td.colspan" :class="td.class + (td.isExcluded ? ' td-excluded' : '')"
                        @click="true === td.isEdit ? openEditProgressModal(td.progress) : null">
                      <div v-if="false === empty(td.progressBar)" class="mb-1">
                        <progress-bar :all="td.progressBar.all" :current="td.progressBar.current" :must="td.progressBar.must"/>
                      </div>
                      <div v-html="td.body"></div>
                      <div v-if="false === empty(td.progress)">
                        <div class="badge" :class="{
                                'd-none': value.point + '' !== td.progress.value + '',
                                'text-bg-point-0-19': value.point <= 19,
                                'text-bg-point-20-39': 20 <= value.point && value.point <= 39,
                                'text-bg-point-40-59': 40 <= value.point && value.point <= 59,
                                'text-bg-point-60-79': 60 <= value.point && value.point <= 79,
                                'text-bg-point-80-100': 80 <= value.point,
                              }"
                             v-for="(value, i_value) in td.paramValues"
                             :key="'t.tr.' + i_tr + '.td.' + i_td + '.v.' + i_value">
                          {{ value.name }} ({{ value.point }})
                        </div>
                        <div v-html="td.progress.comment"></div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import BaseModal from '@/components/BaseModal'
import SchoolProcessProgressView from '@/views/app/school/components/SchoolProcessProgressView'
import ProgressBar from '@/components/ProgressBar'
import SchoolProcessTimetableView from '@/views/app/school/components/SchoolProcessTimetableView'
import ObjectNoteList from '@/components/ObjectNoteList'

export default {
  name: 'SchoolRunProcessView',
  components: {
    ObjectNoteList,
    SchoolProcessTimetableView,
    ProgressBar, SchoolProcessProgressView, BaseModal, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      title: '<i class="fa-solid fa-school"></i>',
      isConfirmDeleteModal: false,
      formName: 'schoolRunProcess',
      formUrl: {string: '/school-run-process?uuid=${uuid}'},
      editRoute: {name:  'school-run-process', params: {uuid: ''}},
      exitRoute: {name: 'school-run-processes'},

      isHideTdExcluded: true,
      isShowCheckbox_isHideTdExcluded: false,

      isLoadingNotes: false,

      data: {
        name: '',
        id_run: false,
        description: '',
        school_course_uuid: '',
        schoolCourseName: '',
        relationUsers: [],
        students: [],
        processParams: [],
        structure: [],

        userProgress: {},
        moneyProgress: {money: {}},
        studentProgress: {},
      },

      progresses: [],

      table: {
        readerMaxRow: 1,
        header: [],
        body: [],
      },

      isEditProgressModal: false,
      currentEditProgress: {
        uuid: 'new',
        school_process_param_uuid: '',
        student_uuid: '',
        crm_contractor_uuid: '',
        value: '',
        comment: ''
      },
      clearEditProgress: {
        uuid: 'new',
        school_process_param_uuid: '',
        student_uuid: '',
        crm_contractor_uuid: '',
        value: '',
        comment: ''
      },

      isToDoneModel: false,
    }
  },
  mounted() {
    this.loadProgress()
  },
  methods: {
    afterLoad: function () {
      this.loadNotes()
      this.title = '<i class="fa-solid fa-school"></i> ' + this.data.name

      if ( this.inArray('toDone', this.data.buttons) || this.inArray('editPlanningProcess', this.data.buttons)) {
        let self = this
        let buttons = []
        if (this.inArray('toDone', this.data.buttons)) {
          buttons.push({
            name: 'Завершить',
            class: 'btn-outline-success',
            click: function () {
              self.isToDoneModel = true
              self.emitter.emit('modal', {event: 'open'})
            }
          })
        }
        if (this.inArray('editPlanningProcess', this.data.buttons)) {
          buttons.push({
            name: 'Корректировать',
            class: 'btn-outline-primary',
            click: function () {
              let route = {name:  'school-planning-process', params: {uuid: self.data.uuid}}
              self.setFormExitRoute(
                  'schoolPlanningProcess',
                  {name:  'school-run-process', params: {uuid: self.data.uuid}}
            )
              self.toRoute(route)
            }
          })
        }

        for (let i in this.buttons) {
          buttons.push(this.buttons[i])
        }

        this.buttons = buttons
      }

      this.prepareTableHeader()
      this.prepareTableBody()
    },

    loadNotes: function () {
      this.isLoadingNotes = true
      let uuids  = [this.data.uuid]
      window.axios.post(
          this.globalVar.app.api.url + '/notes' + '?' + (new Date()).getTime(), {
            search: {schoolProgressUuids: uuids, isDone: false, perPage: 1000},
            sort: {}
          })
          .then(response => {
            this.data.notes = []
            for (let c in response.data.items) {
              let note = response.data.items[c]
              for (let o in note.objects) {
                let object = note.objects[o]
                if (this.data.uuid === object.uuid) {
                  this.data.notes.push(note)
                }
              }
            }
          })
          .catch((error) => {
            console.log(error, error.data)
          })
          .finally(() => {
            this.isLoadingNotes = false
          })
    },

    courseDone_click: function() {
      this.data.is_done = true
      this.save(true)
    },

    loadAfterSaveProgress: function () {
      this.load()
      this.loadProgress()
    },

    loadProgress: function () {
      this.emitter.emit('app', {event: 'spinner.show'})
      let self = this
      window.axios.post(
          this.globalVar.app.api.url + '/school-process-progresses' + '?' + (new Date()).getTime(), {search: {
              schoolProcessUuid: self.$route.params.uuid,
            },
            sort: {}
          })
          .then(response => {
            self.progresses = response.data.items
            self.prepareTableBody()
          })
          .catch((error) => {
            console.log(error, error.data)
          })
          .finally(() => {
            this.emitter.emit('app', {event: 'spinner.hide'})
          })
    },

    prepareTableHeader: function () {
      this.calcTableReaderMaxRow()

      this.table.header = []
      for (let i = 0; i < this.table.readerMaxRow + 1; i++) {
        this.table.header[i] = []
      }

      this.table.header[0].push({name: 'Слушатель', rowspan: this.table.readerMaxRow + 1, colspan: 2, class: 'text-center th-fixed'})
      for (let i in this.data.structure) {
        this.subPrepareTableHeader(this.data.structure[i], 0)
      }
    },
    subPrepareTableHeader: function (structure, level) {
      if('param' === structure.type) {
        this.table.header[level].push({
          name: (true === structure.is_student_stat
                  ? '<span class="text-secondary fs-10px" title="Пункт учитывается как показатель слушателя"><i class="fas fa-user-graduate"></i></span> '
                  : '')
              + (true === structure.is_money_stat
                  ? '<span class="text-secondary fs-10px" title="Пункт участвует в расчете отработанных средств"><i class="fas fa-ruble-sign"></i></span> '
                  : '')
              + (true === structure.is_crm_contractor_analytics
                  ? '<span class="text-secondary fs-10px" title="Пункт отдельно выводится в аналитике по контрагенту"><i class="fa-solid fa-chart-area"></i></span>'
                  : '')
          + '<div>' + structure.name + '</div>',
          rowspan: this.table.readerMaxRow - level,
          colspan: 1,
          class: 'text-center',
        })

        this.table.header[this.table.readerMaxRow].push({
          name: this.getDayEventAtAndDeadlineAt(structure),
          rowspan: 1,
          colspan: 1,
          class: 'text-center fs-10px text-secondary',
          paramUuid: this.getParamUuid(structure),
          isEditParam: structure.is_edit_param,
          isCrmContractor: structure.is_crm_contractor,
          paramValues: structure.values,
        })
      } else if('structure' === structure.type) {
        this.table.header[level].push({
          name: structure.name,
          rowspan: 1,
          colspan: this.calcCountChildrenParam(structure),
          class: 'text-center',
        })
        for (let i in structure.children) {
          this.subPrepareTableHeader(structure.children[i], level + 1)
        }
      }
    },
    calcCountChildrenParam: function (structure) {
      if ('param' === structure.type) {
        return 1
      }
      let count = 0
      for (let i in structure.children) {
        count += this.calcCountChildrenParam(structure.children[i])
      }

      return count
    },

    getDayEventAtAndDeadlineAt: function(structure) {
      for (let i in this.data.processParams) {
        if (structure.uuid === this.data.processParams[i].school_course_structure_param_uuid) {
          let returnText = ''
          if (false === this.empty(this.data.processParams[i].day_event_at)) {
            returnText += '<div><i class="fa-solid fa-calendar-days"></i> '
                + this.stringDateYmdhisToHidmy(this.data.processParams[i].day_event_at, true)
                + '</div>'
          }
          if (false === this.empty(this.data.processParams[i].deadline_at)) {
            returnText += '<div><i class="far fa-calendar-check"></i> до '
                + this.stringDateYmdhisToHidmy(this.data.processParams[i].deadline_at, true)
                + '</div>'
          }
          return returnText
        }
      }

      return ''
    },

    getParamUuid: function(structure) {
      for (let i in this.data.processParams) {
        if (structure.uuid === this.data.processParams[i].school_course_structure_param_uuid) {
          return this.data.processParams[i].uuid
        }
      }

      return ''
    },

    calcTableReaderMaxRow: function () {
      this.table.readerMaxRow = 1
      for (let i in this.data.structure) {
        this.subCalcTableReaderMaxRow(this.data.structure[i], 1)
      }
    },
    subCalcTableReaderMaxRow: function (structure, level) {
      if (this.table.readerMaxRow < level) {
        this.table.readerMaxRow = level
      }
      if('structure' === structure.type) {
        for (let i in structure.children) {
          this.subCalcTableReaderMaxRow(structure.children[i], level + 1)
        }
      }
    },

    prepareTableBody: function() {
      this.table.body = []

      for (let i in this.data.students) {
        let groupStudents = this.data.students[i].students
        for (let s in groupStudents) {
          let student = groupStudents[s], newRow = []

          if (student.is_excluded) {
            this.isShowCheckbox_isHideTdExcluded = true
          }

          let isFirstGroupRow = 0 === s * 1
          if (isFirstGroupRow) {
            newRow.push({
              body: `<div class="div-contractor">${student.crmContractorName}<div class="text-secondary fs-10px">${student.crmContractorDescription}</div></div>`,
              rowspan: groupStudents.length,
              colspan: 1,
              class: 'td-fixed',
              progressBar: this.data.students[i].progress,
              isEdit: false,
              isExcluded: student.is_excluded,
            })
          }

          let moveStudent = ''
          if (!this.empty(student.move_student_uuid)) {
            moveStudent += student.is_excluded ? 'заменен на: ' : 'вместо: '
            for (let ms in groupStudents) {
              let moveItemStudent = groupStudents[ms]
              if (student.move_student_uuid === moveItemStudent.uuid && student.uuid !== moveItemStudent.uuid) {
                moveStudent += moveItemStudent.name
              }
            }
          }

          newRow.push({
            body: `<div class="div-student">${student.name}`
                + `<div class="text-secondary fs-10px">${student.phone}</div>`
                + `<div class="text-secondary fs-10px">${student.email}</div>`
                + `<div class="text-secondary fs-10px">${student.comment}</div>`
                + `</div>`
                + `<div class="text-secondary fs-10px">${moveStudent}</div>`,
            rowspan: 1,
            colspan: 1,
            class: 'td-fixed td-student',
            progressBar: student.progress,
            isEdit: false,
            isExcluded: student.is_excluded,
          })

          for (let c = 0; c < this.table.header[this.table.readerMaxRow].length; c++) {
            let headerData = this.table.header[this.table.readerMaxRow][c]
            if ((isFirstGroupRow && headerData.isCrmContractor) || !headerData.isCrmContractor) {
              newRow.push({
                body: '',
                rowspan: headerData.isCrmContractor ? groupStudents.length : 1,
                colspan: 1,
                class: (headerData.isEditParam && false === student.is_excluded) ? 'progress-edit' : 'progress-no-edit',
                isEdit: (headerData.isEditParam && false === student.is_excluded),
                isExcluded: student.is_excluded,
                progress: headerData.isCrmContractor
                    ? this.getCourseParamProcess('crm_contractor_uuid', student.crm_contractor_uuid, headerData.paramUuid)
                    : this.getCourseParamProcess('student_uuid', student.uuid, headerData.paramUuid),
                paramValues: headerData.paramValues
              })
            }
          }

          this.table.body.push(newRow)
        }
      }
    },

    getCourseParamProcess: function (type, typeValue, paramUuid) {
      for (let i in this.progresses) {
        if (
            paramUuid === this.progresses[i].school_process_param_uuid &&
            typeValue === this.progresses[i][type]
        ) {
          return this.progresses[i]
        }
      }

      let newParam = {
        uuid: 'new',
        school_process_param_uuid: paramUuid,
        value: '',
        comment: ''
      }
      newParam[type] = typeValue

      return newParam
    },

    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    openEditProgressModal: function (progress) {
      Object.assign(this.currentEditProgress, this.clearEditProgress)
      Object.assign(this.currentEditProgress, progress)
      this.isEditProgressModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    close: function () {
      this.isEditProgressModal = false
      this.isConfirmDeleteModal = false
      this.isToDoneModel = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style>
.table-course-progress tr > th.th-fixed,
.table-course-progress tr > td.td-fixed {
  position: -webkit-sticky;
  position: sticky;
  left: -1px;
}

.table-course-progress tr > td.td-fixed.td-student {
  left: calc(12vw + 8px);
}

.div-contractor
, .div-student {
  width: 12vw;
}
.table-course-progress tr > th.th-fixed,
.table-course-progress tr > td.td-fixed,
.table-course-progress th {
  background: var(--page-table-fixed-td-bg);
}
</style>

<style scoped>
.progress-no-edit {
  background: var(--app-school-process-progress-no-edit-bg);
}

.progress-edit {
  background: var(--app-school-process-progress-edit-bg);
}

.progress-edit:hover {
  cursor: pointer;
  background: var(--app-school-process-progress-edit-hover-bg);
}

.td-excluded {
  color: #c5c5c5 !important;
  background: #eaeaea !important;
}

.hide-td-excluded .td-excluded {
  display: none;
}
</style>
