export const enums = {
    weekdays: [
        {uuid: 1, name: 'Понедельник'},
        {uuid: 2, name: 'Вторник'},
        {uuid: 3, name: 'Среда'},
        {uuid: 4, name: 'Четверг'},
        {uuid: 5, name: 'Пятница'},
        {uuid: 6, name: 'Суббота'},
        {uuid: 7, name: 'Воскресение'},
    ],

    hours: [
        {uuid: 0, name: '00:00'},
        {uuid: 1, name: '01:00'},
        {uuid: 2, name: '02:00'},
        {uuid: 3, name: '03:00'},
        {uuid: 4, name: '04:00'},
        {uuid: 5, name: '05:00'},
        {uuid: 6, name: '06:00'},
        {uuid: 7, name: '07:00'},
        {uuid: 8, name: '08:00'},
        {uuid: 9, name: '09:00'},
        {uuid: 10, name: '10:00'},
        {uuid: 12, name: '12:00'},
        {uuid: 13, name: '13:00'},
        {uuid: 14, name: '14:00'},
        {uuid: 15, name: '15:00'},
        {uuid: 16, name: '16:00'},
        {uuid: 17, name: '17:00'},
        {uuid: 18, name: '18:00'},
        {uuid: 19, name: '19:00'},
        {uuid: 20, name: '20:00'},
        {uuid: 21, name: '21:00'},
        {uuid: 22, name: '22:00'},
        {uuid: 23, name: '23:00'},
    ]
}