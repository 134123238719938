<template>
  <div>
    <div class="text-center personnel-structure-container">
      <div v-if="true === item.is_post" class="personnel-structure mx-1 my-3 d-inline-block"
           :class="{'child-items': 0 < childItems.length}">
        <div class="test-center fs-10px text-secondary">
          {{ item.name }}
          <div v-if="true === isEdit" class="btn-group btn-group-sm" role="group">
            <button
                type="submit"
                class="btn btn-primary fs-8px"
                @click="clickEditItem('new', item.uuid)">
              <i class="fa-solid fa-plus"></i>
            </button>
            <button
                type="submit"
                class="btn btn-primary fs-8px"
                @click="clickEditItem(item.uuid, item.parent_personnel_structure_uuid)">
              <i class="fa-solid fa-pen-to-square"></i>
            </button>
          </div>
        </div>
        <div class="test-center fs-12px" v-html="empty(item.userNames) ? 'Вакансия' : item.userNames.join('<br>')"></div>
      </div>
      <table v-if="true === item.is_post" class="w-100">
        <tr>
          <td class="personnel-structure-td text-center"
              v-for="(ps, i_ps) in childItems"
              :key="'ps.' + i_ps">
            <item-personnel-structure :item="ps" :items="items" :is-edit="isEdit" :click-edit-item="clickEditItem" />
          </td>
        </tr>
      </table>

      <div v-if="false === item.is_post" class="personnel-structure mx-1 my-3 d-inline-block">
        <div class="test-center fs-10px text-secondary">
          {{ item.name }}
          <div v-if="true === isEdit" class="btn-group btn-group-sm" style="margin-bottom:4px;" role="group">
            <button
                type="submit"
                class="btn btn-primary fs-8px"
                @click="clickEditItem('new', item.uuid)">
              <i class="fa-solid fa-plus"></i>
            </button>
            <button
                type="submit"
                class="btn btn-primary fs-8px"
                @click="clickEditItem(item.uuid, item.parent_personnel_structure_uuid)">
              <i class="fa-solid fa-pen-to-square"></i>
            </button>
          </div>
        </div>
        <table class="w-100">
          <tr>
            <td class="personnel-structure-td text-center"
                v-for="(ps, i_ps) in childItems"
                :key="'ps.' + i_ps">
              <item-personnel-structure :item="ps" :items="items" :is-edit="isEdit" :click-edit-item="clickEditItem" />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ItemPersonnelStructure',
  props: {
    item: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    isEdit:{
      type: Boolean,
      required: true,
    },
    clickEditItem: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      childItems: [],
    }
  },
  mounted: function() {
    let self = this
    self.childItems = self.items.filter(function(filterItem) {
      return self.item.uuid === filterItem.parent_personnel_structure_uuid
    })
  },
}
</script>

<style scoped>
.personnel-structure {
  position: relative;
  background: var(--app-input-bg);
  border: 1px solid rgb(var(--bs-primary-rgb));
  padding: 6px 12px;
}

.personnel-structure.child-items:before {
  content: '';
  position: absolute;
  left: 50%;
  right: 0;
  height: calc(1rem + 1px);
  bottom: calc(-1rem - 2px);
  border-left: 1px solid rgb(var(--bs-secondary-rgb));
}

tr > .personnel-structure-td {
  position: relative;
}

tr > .personnel-structure-td:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 50%;
  border-top: 1px solid rgb(var(--bs-secondary-rgb));
  border-left: 1px solid rgb(var(--bs-secondary-rgb));
}

tr > .personnel-structure-td:not(:first-child):not(:last-child):after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  border-top: 1px solid rgb(var(--bs-secondary-rgb));
}

tr > .personnel-structure-td:first-child:before {
  left: 50%;
  border-left: 1px solid rgb(var(--bs-secondary-rgb));
}

tr > .personnel-structure-td:last-child:before {
  left: 0;
  right: 50%;
  border-left: none;
  border-right: 1px solid rgb(var(--bs-secondary-rgb));
}

tr > .personnel-structure-td:only-child:before {
  border-top: none;
  border-left: none;
  border-right: none;
}

tr > .personnel-structure-td:only-child > div > .personnel-structure-container > .personnel-structure {
  margin-top: 0 !important;
}

</style>
